import { Input } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";

const OrderSearchInput = ({ status, searchQuery, setSearchQuery, onSearch, darkMode }) => {
    const placeholders = {
        A: "Search Active Orders by...     Customer, Customer Code, Order Number",
        Arc: "Search Archived Orders by...     Customer, Customer Code, Order Number",
        P: "Search Pending Orders by...     Customer, Customer Code",
        Planned: "Search Planned Orders by...     Customer, Customer Code"
    };

    // Reset search query when status changes
    useEffect(() => {
        setSearchQuery("");
    }, [status, setSearchQuery]);

    return (
        ["A", "P", "Planned", "Arc"].includes(status) && (
            <div className="w-full flex items-center">
                <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>
                <Input
                    className={`tracking-widest ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} placeholder:text-xs rounded-md p-1.5 w-full focus:outline-none`}
                    placeholder={placeholders[status] || "Search Orders by Customer"}
                    value={searchQuery}
                    onChange={(e) => onSearch(e.target.value)}
                />
            </div>
        )
    );
};

export default OrderSearchInput;