import JugSVG from "../../PFC_Jug.svg"
import DrumSVG from "../../PFC_Drum.svg"
import ToteSVG from "../../PFC_Tote.svg"
import Drum55SVG from "../../PFC_Drum_55.svg"

import logo from "../../main-logo.png";
import {Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle, Input, Select} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {XMarkIcon} from "@heroicons/react/16/solid";
import {useDarkMode} from "../../contexts/darkModeContext";
import {isBoolean} from "pdfmake/src/helpers";
import {ExclamationTriangleIcon, LockClosedIcon, PencilIcon, PencilSquareIcon} from "@heroicons/react/24/outline";
import {ADLProducts, UMMapping} from "../GlobalFunctions";

const ProductInput = ({ index, onRemove, onAdd, product, priceType, discount, contractPrices, region, lineItem }) => {

    // const [id] = useState(product.id);
    // const [price, setPrice] = useState(0);
    // const [orginalPrice, setOrginalPrice] = useState(0);
    // const [quantity, setQuantity] = useState(1);
    // const [productCode, setProductCode] = useState(product.code || "");
    // const [total, setTotal] = useState((price * quantity).toFixed(2));
    // const [actualTotal, setActualTotal] = useState(0);
    // const [selectedVolume, setSelectedVolume] = useState(product.prices.length > 0 ? product.prices[0].um : ""); // Set to the first `um` in prices or an empty string if prices is empty
    // const [name, setName] = useState("");
    
    const [id] = useState(lineItem?.id || product?.id);
    const [price, setPrice] = useState( 0);
    const [orginalPrice, setOrginalPrice] = useState(0);
    const [quantity, setQuantity] = useState(lineItem?.quantity || 1);
    const [productCode, setProductCode] = useState( "");
    const [total, setTotal] = useState((price * quantity).toFixed(2));
    const [actualTotal, setActualTotal] = useState(0);
    const [selectedVolume, setSelectedVolume] = useState(lineItem?.selectedVolume || product?.prices[0].um); // Set to the first `um` in prices or an empty string if prices is empty
    const [name, setName] = useState(lineItem?.reasonCode || "DONATE");
    
    const [edit, setEdit] = useState(lineItem?.edit || false);
    
    // const [lock] = useState(lineItem?.selectedVolume || product?.lock || false);
    const [lock] = useState(
        !!lineItem?.lock || !!product?.lock || false
    );
    
    const [warehouse, setWarehouse] = useState(lineItem?.warehouse || "MAIN");
    const [disabled, setDisabled] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(lineItem?.discountAmount || 0);
    const [unitLabel, setUnitLabel] = useState("Quant");
    const [contract, setContract] = useState(false);
    
    
    const [isNoCharge, setIsNoCharge] = useState(lineItem?.isNoCharge || false);  // Initial state based on defaultChecked
    const [reasonCode, setReasonCode] = useState("");
    
    
    const handleCheckboxChange = (e) => {
        setIsNoCharge(e.target.checked);
        console.log("Checkbox state:", e.target.checked);
    };
    
    useEffect(() => {
        if (!lineItem?.warehouse) {
            if (region === "SE" && ADLProducts[productCode]) {
                setWarehouse(ADLProducts[productCode]);  // Set warehouse to ADL if productCode matches
            } else {
                setWarehouse("MAIN");  // Default to MAIN if region is not SE or productCode is not in ADLProducts
            }
        }
    }, [productCode, region]);  // Re-run the effect whenever productCode or region changes


    

    const { darkMode } = useDarkMode();

    const [selectedImage, setSelectedImage] = useState(logo); // Initial image state
    const [defaultImage, setDefaultImage] = useState(false);

    const isDiscountApplied = selectedVolume !== 'GAL' && discountAmount && discountAmount !== 0;

    
    // Discount
    useEffect(() => {
        // Calculate the total without any discount (always needed)
        const calculatedActualTotal = (price * quantity).toFixed(2);
    
        // Determine if a discount should be applied
        let calculatedTotal = calculatedActualTotal;
        let calculatedDiscountAmount = discount;
    
        if (selectedVolume !== 'GAL' && isDiscountApplied) {
            calculatedTotal = ((price * quantity) * (1 - discount / 100)).toFixed(2);
            calculatedDiscountAmount = discount;
        } else if (selectedVolume === 'GAL') {
            calculatedDiscountAmount = 0; // No discount for GAL
        } else if (lock){
            calculatedDiscountAmount = 0;
        }
    
        // Update the state only if values have changed
        if (actualTotal !== calculatedActualTotal) setActualTotal(calculatedActualTotal);
        if (total !== calculatedTotal) setTotal(calculatedTotal);
        if (discountAmount !== calculatedDiscountAmount) setDiscountAmount(calculatedDiscountAmount);
        
            // eslint-disable-next-line
    }, [price, quantity, selectedVolume, discount, isDiscountApplied, index,]);
    
    
    const handleQuantityChange = (e) => {
        const value = parseFloat(e.target.value) || 0; // Default to 0 if NaN
        setQuantity(value);
    };

    const handleDiscountChange = (e) => {
        const value = parseFloat(e.target.value) || 0; // Default to 0 if NaN
        setDiscountAmount(value);
    };

    const getPriceBasedOnSelection = (prices, priceType, quantity) => {
        // Ensure prices is an array
        if (!Array.isArray(prices)) {
            console.error("Prices must be an array");
            return 0;
        }

        // Convert priceType to lowercase to handle case insensitivity
        const normalizedPriceType = priceType.toLowerCase();

        // Find the price object where 'um' matches the specified quantity
        const priceObject = prices.find((price) => price.um === quantity);

        if (priceObject && priceObject[normalizedPriceType]) {
            return priceObject[normalizedPriceType];
        }

        return 0;
    };


    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    // Handle the remove action with animation
    const handleRemove = () => {
        setIsRemoving(true); // Trigger the animation
        setTimeout(() => onRemove(index), 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    };

    const [priceLimited, setPriceLimited] = useState(false);
    
    useEffect(() => {

        setName(product?.name);

        if (product && priceType && selectedVolume) {
            const prices = product?.prices;
            const newPrice = getPriceBasedOnSelection(prices, priceType, selectedVolume);

            // Find the price object that matches the selected volume and priceType
            const priceObject = prices.find((price) => price.um === selectedVolume);

            if (priceObject) {
                setProductCode(priceObject.code);
                console.log("Selected Price Code:", priceObject.code); // Log the code of the selected price object
            }

            console.log("New Price:", newPrice); // Log the selected price

            const contractPriceEntry = contractPrices.find(
                (contract) => contract.productCode === priceObject.code
            );
            
            if (edit) {
                setPrice(lineItem?.price || 0);
                console.log("Price set to line item price due to edit mode:", lineItem?.price);
            } else {
                if (isNoCharge) {
                    setPrice(0);
                    setContract(false);
                    console.log("Price set to 0 due to no charge condition");
                } else if (contractPriceEntry) {
                    if (contractPriceEntry.percent) {
                        // If percent is true, set discountAmount to newValue
                        setDiscountAmount(contractPriceEntry.newValue);
                        console.log("Discount applied:", contractPriceEntry.newValue);
                    } else {
                        // If percent is false, set newPrice to newValue
                        setPrice(contractPriceEntry.newValue || 0);
                        setContract(true);
                        setPriceLimited(false);
                        console.log("Contract price applied:", contractPriceEntry.newValue);
                    }
                } else {
                    setPrice(newPrice || 0);
                    setOrginalPrice(newPrice);
                    setContract(false);
                    console.log("Standard price applied:", newPrice);
                    setPriceLimited(false);
                }
            }
            

            // Update disabled state based on newPrice being 0, "0", or NaN
            const isDisabled = !newPrice || newPrice === 0 || newPrice === "0" || isNaN(newPrice);
            setDisabled(isDisabled);

            switch (selectedVolume) {
                case "2.5":
                    setSelectedImage(JugSVG);
                    setDefaultImage(false);
                    setUnitLabel("#");
                    break;
                case "30":
                    setSelectedImage(DrumSVG);
                    setDefaultImage(false);
                    setUnitLabel("#");
                    break;
                case "55":
                    setSelectedImage(Drum55SVG);
                    setDefaultImage(false);
                    setUnitLabel("#");
                    break;
                case "275":
                    setSelectedImage(ToteSVG);
                    setDefaultImage(false);
                    setUnitLabel("#");
                    break;
                case "TR":
                    setSelectedImage(logo);
                    setQuantity(lineItem?.quantity || 100);
                    setDefaultImage(true);
                    setUnitLabel("gal");
                    break;
                case "GAL":
                    setSelectedImage(logo);
                    setQuantity(lineItem?.quantity || 600);
                    setDefaultImage(true);
                    setUnitLabel("gal");
                    break;
                default:
                    setSelectedImage(logo);
                    setUnitLabel("#");
                    setDefaultImage(true);
            }
        }
        // eslint-disable-next-line
    }, [product, priceType, selectedVolume, discountAmount, discount, isNoCharge]);

    const handleVolumeChange = (e) => {
        setSelectedVolume(e.target.value);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    const handlePriceChange = (e) => {
        let inputPrice = parseFloat(e.target.value);
        setPrice(inputPrice || 0); // Simply update the price without validation here
        setEdit(true);
    };
    
    const handlePriceBlur = () => {
        const discountPercentage = 15;
        let inputPrice = parseFloat(price) || 0;
        const minPrice = orginalPrice * (1 - discountPercentage / 100); // 15% off the preloaded price
    
        if (inputPrice < minPrice) {
            setPriceLimited(true);
            setPrice(minPrice.toFixed(2) || 0); // Adjust price if it's below the minimum
            setEdit(true);
        } else {
            setPriceLimited(false);
        }
    };

    // Automatically pass data back to the parent whenever any key state changes
    useEffect(() => {
        const productData = {
            price,
            name,
            id,
            quantity,
            productCode,
            total,
            actualTotal,
            selectedVolume,
            warehouse,
            discountAmount,
            isNoCharge,
            reasonCode,
            contract,
            lock,
            edit
        };
        onAdd(index, productData); // Automatically pass the product data to the parent
            // eslint-disable-next-line
    }, [price, name, quantity, productCode, total, selectedVolume, warehouse, actualTotal, discountAmount, discount, index, isNoCharge, reasonCode, id, contract, edit, lock]);
    
    const warehouseOptions = [
        { value: "MAIN", label: "Plant Food Company - NJ", regions: ["NE", "SE", "AG", "DIST1", "DIST2"] },
        { value: "ADL", label: "ADL, Florida 3PL", regions: ["SE"] },
        { value: "CFNJ", label: "Phil String, Storage", regions: ["NE", "DIST1"] },
        { value: "CNCT", label: "Jason Dowgiewicz, Storage", regions: ["NE"] },
        { value: "FLFL", label: "Jim Rattigan, Storage", regions: ["SE"] },
        { value: "FNFL", label: "Christopher Allen, Storage", regions: ["SE"] },
        { value: "FSFL", label: "Jordan Nemitz, Storage", regions: ["SE"] },
        { value: "FWFL", label: "Cory Agar, Storage", regions: ["SE"] },
        { value: "GCPA", label: "Tom Weinert, Storage", regions: ["NE"] },
        { value: "JCNJ", label: "Tom Pepe, Storage", regions: ["NE", "AG"] },
        { value: "MFNJ", label: "Mike Giovanelli, Storage", regions: ["NE"] },
        { value: "NJNJ", label: "Richard Neufeld, Storage", regions: ["NE"] },
        { value: "PFSY", label: "PLANT FOOD SYSTEMS, FL", regions: ["SE", "AG"] },
        { value: "PGPA", label: "Mike Janzer, Storage", regions: ["NE"] },
        { value: "RSNJ", label: "Richard Sweeney, Storage", regions: ["NE"] },
        { value: "SJNJ", label: "David St. John, Storage", regions: ["NE"] },
        { value: "WINY", label: "David Conrad, Storage", regions: ["NE"] },
    ];

    const filteredWarehouses = warehouseOptions.filter(option => option.regions.includes(region));


    return (
        <div className={`w-full font-sans flex flex-row border-b-2 ${darkMode ? 'border-darkBgColor text-white' : 'text-black'} mb-1.5 p-1 pb-2 transition-transform duration-500 ease-in-out ${isRemoving ? 'transform scale-75 animate-fadeOut' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*PRODCUT NAME*/}
            <div className="flex flex-row w-1/6 mr-3">
                <div className="flex flex-col mt-auto mb-auto w-1/6 mr-2">
                    {/*<svg xmlns={selectedImage} className={`h-8`}/>*/}

                    <div className={`w-fit mr-auto ml-auto p-1.5 rounded-lg ${defaultImage ? '' : 'bg-white'}`}>
                        <img src={selectedImage} className={`h-8 w-fit hover:scale-105`} alt="Jug"/>
                    </div>

                </div>

                {/*Name and Product Code*/}
                <div className="flex flex-col mt-auto mb-auto w-5/6">
                    {/*Scrollable Name by Hover*/}
                    <div ref={(container) => {
                            if (container) {
                                const textElement = container.querySelector('.scrollable-text');
                                if (textElement) {
                                    container.classList.toggle(
                                        'hover-enabled',
                                        textElement.scrollWidth > container.offsetWidth
                                    );
                                }
                            }
                        }}
                        className={`relative overflow-hidden ${
                            darkMode ? 'text-white' : 'text-black'
                        } text-sm font-medium w-full`}
                    >
                        <div className="scrollable-text inline-block whitespace-nowrap transition-transform duration-1000 ease-linear"
                            style={{
                                transform: 'translateX(0)',
                            }}
                            onMouseEnter={(e) => {
                                const container = e.currentTarget.parentElement;
                                if (container.classList.contains('hover-enabled')) {
                                    e.currentTarget.style.transform = 'translateX(-50%)';
                                }
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.transform = 'translateX(0)';
                            }}
                        >
                            {name}
                        </div>
                    </div>
                    <div className={`ml-3 text-xxs ${darkMode ? 'text-borderColor' : 'text-borderColor'}`}>{productCode}</div>
                </div>
            </div>

            {/*PRODUCT PRICING/QUANT*/}
            <div className="flex flex-row w-2/3 mr-3">

                {/*PRICE*/}
                <div className={`relative w-1/3 p-1.5`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} ${disabled ? 'border-2 border-red-600 rounded-lg' : ''} relative flex flex-row px-2 rounded-lg h-full items-center justify-center`}>
                        <div className={`text-borderColor text-md mr-1 ${disabled ? 'hidden' : ''}`}>$</div>
                        
                        {/*<div className={`${darkMode ? 'text-white' : 'text-black'} ${disabled ? 'hidden' : ''} rounded-md text-sm p-1.5 w-full tracking-wider line-clamp-1`}>*/}
                        {/*    {price && !isNaN(price) && price !== 0 ? numberWithCommas(parseFloat(price).toFixed(2)) : "No Price"}*/}
                        {/*</div>*/}
                        
                        <Input
                            className={`rounded-md bg-transparent text-sm focus:outline-none p-1.5 w-full ${disabled ? 'hidden' : ''} ${darkMode ? 'text-white' : 'text-black'}`}
                            type="number"
                            placeholder="0.00"
                            value={price}
                            // value={price && !isNaN(price) && price !== 0 ? numberWithCommas(parseFloat(price).toFixed(2)) : "No Price"}
                            disabled={contract || isNoCharge || lock}
                            onChange={handlePriceChange} // This will still allow live input, but without validation
                            onBlur={handlePriceBlur} // Validation happens when input loses focus
                        />
                        
                        <div className={`text-gray-500 font-bold text-xxs ml-1 ${disabled ? 'hidden' : ''}`}>USD</div>

                    </div>
                    <div className={`absolute top-0 right-0`}>
                        <div className={`${contract ? 'hidden' : ''} ${lock ? 'hidden' : ''} ${edit ? 'hidden' : ''} flex-none rounded-full p-1 ${disabled ? 'bg-red-500/40 animate-heartbeat' : 'bg-emerald-500/30 animate-rubberBand-delay'}`}>
                          <div className={`size-2 rounded-full ${disabled ? 'bg-red-400' : 'bg-emerald-500'}`} />
                        </div>
                    </div>

                    {contract && (
                        <div className={`absolute top-0 right-0`}>
                            <div className={`flex-none rounded-full p-1 bg-yellow-500/30 animate-rubberBand-delay`}>
                                <div className={`size-2 rounded-full bg-yellow-500`}/>
                            </div>
                        </div>
                    )}
                    
                    {lock && (
                        <div className={`absolute top-0 right-0`}>
                            <div className={`flex-none rounded-full animate-rubberBand-delay`}>
                                <LockClosedIcon className={`size-4 stroke-2 rounded-full text-slate-500`}/>
                            </div>
                        </div>
                    )}
                    
                    {edit && (
                        <div className={`absolute top-0 right-0`}>
                            <div className={`flex-none rounded-full animate-rubberBand-delay`}>
                                <PencilIcon className={`size-4 stroke-2 text-blue-500`}/>
                            </div>
                        </div>
                    )}
                    
                    {disabled && (
                        <div
                            className={`w-full left-0 top-0 text-center text-sm absolute bg-white rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 px-2 ${darkMode ? 'text-white' : 'text-black'} flex items-center justify-center line-clamp-1`}
                            style={{ height: 'fit-content', transform: 'translateY(-50%)', top: '50%' }}
                        >
                            Request Pricing
                        </div>
                    )}

                </div>
                
                <div className="flex flex-row px-2 h-full items-center justify-center">
                    <XMarkIcon className={`text-borderColor w-3`}/>
                </div>

                {/*QUANTITY*/}
                <div className={`relative w-1/3 p-1.5 mr-3`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} ${disabled ? 'border-2 border-red-600 rounded-lg' : ''} flex flex-row px-4 rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                        {/*// When quantity changes, trigger the onChange for recalculation*/}
                        <Input
                            className={`rounded-md bg-transparent text-sm focus:outline-none p-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                            type="string"
                            placeholder="Quantity"
                            value={quantity}
                            // disabled={disabled}
                            onChange={handleQuantityChange}
                        />

                        <div className="text-gray-500 font-bold text-xs ml-1">{unitLabel}</div>


                        {/*// When price changes (in your select or other logic), ensure price updates are sent to parent.*/}
                    </div>
                    <div className={`absolute top-0 right-0`}>
                    <div className={`flex-none rounded-full p-1 ${disabled ? 'bg-red-500/40 animate-heartbeat' : 'bg-emerald-500/30 animate-rubberBand-delay'}`}>
                          <div className={`size-2 rounded-full ${disabled ? 'bg-red-400' : 'bg-emerald-500'}`} />
                        </div>
                    </div>
                </div>

                {/*U/M*/}
                <div className={`relative w-1/4 p-1.5 mr-3`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} ${disabled ? 'border-2 border-red-600 rounded-lg' : ''} flex flex-row rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                        <Select
                            className={`p-1.5 rounded-md bg-transparent text-xs focus:outline-none ${darkMode ? 'text-white' : 'text-black'} w-full`}
                            onChange={handleVolumeChange}
                            value={selectedVolume}
                        >
                            {/* Map over prices array to generate options */}
                            {product?.prices.map((price, index) => {
                                const label = UMMapping[price.um]; // Get label from mapping object
                                return label ? (
                                    <option key={index} value={price.um}>
                                        {label}
                                    </option>
                                ) : null; // Exclude options with no matching label
                            })}

                        </Select>
                    </div>
                    <div className={`absolute top-0 right-0`}>
                        <div className={`flex-none rounded-full p-1 ${disabled ? 'bg-red-500/40 animate-heartbeat' : 'bg-emerald-500/30 animate-rubberBand-delay'}`}>
                          <div className={`size-2 rounded-full ${disabled ? 'bg-red-400' : 'bg-emerald-500'}`} />
                        </div>
                    </div>
                </div>

                {/*Warehouse*/}
                <div className={`relative w-1/6 p-1.5`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} ${disabled ? 'border-2 border-red-600 rounded-lg' : ''} flex flex-row rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                        <Select
                            value={warehouse}
                            onChange={(e) => setWarehouse(e.target.value)}
                            className={`text-xs p-1.5 rounded-md bg-transparent focus:outline-none ${darkMode ? 'text-white' : 'text-black'} w-full`}
                        >
                            {filteredWarehouses.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className={`absolute top-0 right-0`}>
                        <div
                            className={`flex-none rounded-full p-1 ${disabled ? 'bg-red-500/40 animate-heartbeat' : 'bg-emerald-500/30 animate-rubberBand-delay'}`}>
                            <div className={`size-2 rounded-full ${disabled ? 'bg-red-400' : 'bg-emerald-500'}`}/>
                        </div>
                    </div>
                
                </div>
                
                {/*Discount*/}
                <div className={`relative w-1/6 p-1.5 h-full`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} ${disabled ? 'border-2 border-red-600 rounded-lg' : ''} px-1 flex flex-row rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                        <div className="text-borderColor text-xs font-bold">%</div>

                        <Input
                            className={`rounded-md bg-transparent text-xs focus:outline-none ml-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                            type="number"
                            placeholder="DISC"
                            value={discountAmount}
                            disabled={true}
                            // disabled={!isDiscountApplied}
                            onChange={handleDiscountChange}
                        />
                    </div>
                    <div className={`absolute top-0 right-0`}>
                        {isBoolean(isDiscountApplied) && (
                            <div className={`flex-none rounded-full p-1 ${disabled ? 'bg-red-500/40 animate-heartbeat' : 'bg-blue-500/30 animate-rubberBand-delay'}`}>
                                <div className={`size-2 rounded-full ${disabled ? 'bg-red-400' : 'bg-blue-500'}`}/>
                            </div>
                        )}
                    </div>
                    
                    {lock && (
                        <div className={`absolute top-0 right-0`}>
                            <div className={`flex-none rounded-full animate-rubberBand-delay`}>
                                <LockClosedIcon className={`size-4 stroke-2 rounded-full text-slate-500`}/>
                            </div>
                        </div>
                    )}
                    
                </div>
                
                {/*No Charge*/}
                <div className={`relative w-1/6 h-full flex items-center`}>
                    <fieldset>
                        <div className="flex items-center gap-1">
                            <div className="flex h-6 shrink-0 items-center">
                                <div className="group grid size-4 grid-cols-1">
                                    <Input
                                        defaultChecked
                                        id="comments"
                                        name="comments"
                                        type="checkbox"
                                        checked={isNoCharge}
                                        onChange={handleCheckboxChange}
                                        aria-describedby="comments-description"
                                        className={`col-start-1 row-start-1 appearance-none rounded border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-white border-gray-300'} checked:border-green-600 checked:bg-green-600 indeterminate:border-green-600 indeterminate:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:border-green-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto`}
                                    />
                                    
                                    <svg
                                        fill="none"
                                        viewBox="0 0 14 14"
                                        className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                                    >
                                        <path
                                            d="M3 8L6 11L11 3.5"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="opacity-0 group-has-[:checked]:opacity-100"
                                        />
                                        <path
                                            d="M3 7H11"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="opacity-0 group-has-[:indeterminate]:opacity-100"
                                        />
                                    </svg>
                                    
                                </div>
                            </div>
                            <div className="text-xxs/6">
                                
                                {isNoCharge ? (
                                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} ml-2 flex flex-row rounded-md h-full items-center justify-center hover:scale-y-105`}>
                                        <Select
                                            value={reasonCode} // Will display the code (e.g., JCNJ) after selection
                                            onChange={(e) => setReasonCode(e.target.value)} // Store the selected code in state
                                            className={`text-xxs p-1 rounded-sm bg-transparent focus:outline-none ${darkMode ? 'text-white' : 'text-black'} w-full`}
                                            // disabled={disabled}
                                        >
                                            <option value="DONATE">DONATE</option>
                                            <option value="R&DTAX">R&DTAX</option>
                                            <option value="REPLAC">REPLAC</option>
                                            <option value="TSTPRD">TSTPRD</option>
                                            <option value="N/A">N/A</option>
                                        </Select>
                                    </div>
                                ) : (
                                    <label htmlFor="comments" className="font-medium">
                                        No Charge
                                    </label>
                                )}
                            </div>
                        </div>
                    </fieldset>
                </div>


            </div>

            {/*PRODUCT TOTAL*/}
            <div className="flex flex-row w-1/6 mr-3 justify-end align-middle items-center">
                
                <div className="ml-auto w-full flex flex-row justify-end">
                    
                    <div
                        className={`flex flex-row mt-auto mb-auto align-middle justify-end items-center ${darkMode ? 'text-white' : 'text-black'}`}>
                        <div className="text-md mr-3 font-light">$</div>
                        <div className={`relative p-1.5`}>
                            
                            <div className="text-md mr-3 font-light tracking-wider">{numberWithCommas(total)}</div>
                            
                            <div className={`absolute top-0 right-1`}>
                                <div
                                    className={`flex-none rounded-full p-1 ${isDiscountApplied ? 'bg-blue-500/30 animate-heartbeat' : ''}`}>
                                    <div className={`size-2 rounded-full ${isDiscountApplied ? 'bg-blue-500' : ''}`}/>
                                </div>
                            </div>
                        
                        
                        </div>
                        <div className="text-borderColor text-sm">USD</div>
                    </div>
                    
                    <Button className="rounded-full ml-3 h-8 w-8 items-center justify-center align-middle"
                            onClick={handleRemove}>
                        <XMarkIcon
                            className="h-6 text-borderColor hover:text-red-600 hover:animate-pulse hover:scale-110 m-auto"/>
                    </Button>
                </div>
                
            </div>
        
            
            <Dialog open={priceLimited} onClose={setPriceLimited} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-600/15 sm:mx-0 sm:size-10">
                        <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Price Limited Reached
                        </DialogTitle>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                You are only able to set a maximum discount of 15% off of the original price.
                            </p>
                            
                            <div className="text-sm text-gray-500 mt-3">Original Price: ${numberWithCommas(parseFloat(orginalPrice).toFixed(2))}</div>
                            <div className="text-sm text-gray-500 mt-3">Maximum Discount: ${numberWithCommas(parseFloat(orginalPrice * 0.85).toFixed(2))}</div>

                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={() => setPriceLimited(false)}
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      >
                        I Understand
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>

            
        </div>
    )
}

export default ProductInput;
