import {
    Button,
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Input,
    Select,
} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {
    doc,
    setDoc,
    deleteDoc,
    getDoc,
    updateDoc,
    collection,
    getCountFromServer,
    getDocs, addDoc, arrayUnion,
} from "firebase/firestore";
import {XMarkIcon} from "@heroicons/react/16/solid";
import {
    ArrowPathIcon,
    ArrowTopRightOnSquareIcon,
    CheckCircleIcon,
    ClipboardDocumentListIcon,
    DocumentArrowUpIcon, LinkIcon,
    ShareIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {db} from "../../firebase/firebase";
import {useAuth} from "../../contexts/authContext";
import {useDarkMode} from "../../contexts/darkModeContext";
import {formatPhoneNumber, numberWithCommas} from "../GlobalFunctions";
import FireworksEffect from "../FireworksEffect";

const CustomerCard = ({ customer, handleViewAccount, isShared, plant, onUpdate }) => {

    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });
    
    const { currentUser } = useAuth();

    const [showFireworks, setShowFireworks] = useState(false)
    
    const [customerCode, setCustomerCode] = useState("");
    const [type] = useState("");
    const [creditLimit, setCreditLimit] = useState("");
    const [priceCode, setPriceCode] = useState("");
    const [customerType, setCustomerType] = useState("");

    const [orderLength, setOrderLength] = useState(0);
    
    const getSubcollectionCount = async (clientId) => {
        // active
        try {
            // Reference to the subcollection (e.g., 'pending')
            const activeCollectionRef = collection(db, `Customers/${customer.salesID}/clients/${clientId}/active`);

            // Get the count from the server without fetching documents
            const activeCountSnapshot = await getCountFromServer(activeCollectionRef);

            // Get the count from the aggregate query result
            const count = activeCountSnapshot.data().count;

            setOrderLength(count);
        } catch (error) {
            console.error("Error getting subcollection count:", error);
            return 0; // Return 0 in case of an error
        }
    };
    
    useEffect(() => {
        if (currentUser) {
            // Call the function to get the document count
            getSubcollectionCount(customer.id).then(count => {
                console.log(`Fetched count: ${count}`);
            });
        }
        // eslint-disable-next-line
    }, [currentUser]);


    const [salesID, setSalesID] = useState("");


    const { darkMode } = useDarkMode();

    const [isAdding, setIsAdding] = useState(false);
    const [isMoving, setIsMoving] = useState(false);
    const [isSharing, setIsSharing] = useState(false);

    const [accountLevel, setAccountLevel] = useState("");
    const [disabled, setDisabled] = useState(false);
    

    const toggleAdding = () => {
        setIsAdding(!isAdding);
    }
    
    const toggleMoving = () => {
        setIsMoving(!isMoving);
    }
    
    const toggleSharing = () => {
        setIsSharing(!isSharing);
    }

    useEffect(() => {
        setIsLoaded(true);
        setSalesID(customer.salesID);
        // eslint-disable-next-line
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving] = useState(false); // Track whether the item is being removed

    
    
    const moveToActive = async (customerId) => {
        console.log("Sales ID:", salesID);
        console.log("Customer ID:", customerId);
        
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${salesID}/pending`, customerId);
    
            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the pending collection");
            }
    
            // Get the customer data and update it
            const customerData = customerSnapshot.data();
            const updatedCustomerData = {
                ...customerData,
                customerCode,
                type,
                creditLimit,
                priceType: priceCode,
                customerType: customerType,
                status: "active" // Set status as "active" when moving
            };
    
            // Reference to the destination document in the "clients" collection
            const activeDocRef = doc(db, `Customers/${salesID}/clients`, customerId);
    
            // Update the document in "pending" before moving
            await updateDoc(customerDocRef, updatedCustomerData);
    
            // Move the document to "clients" collection
            await setDoc(activeDocRef, updatedCustomerData);
    
            // Delete the document from "pending" after moving
            await deleteDoc(customerDocRef);
    
            console.log("Customer moved to clients collection and status updated to active.");
            if (alertsOn) {
                alert("Customer successfully updated and moved to Active Status.");
            }
    
            // Generate formatted date and time
            const now = new Date();
            const options = {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            };
            const formattedDateTime = now.toLocaleString('en-US', options);
    
            // Add a notification document to the Notifications collection
            const notificationsCollectionRef = collection(db, `Users/${salesID}/Notifications`);
            const notificationData = {
                type: "C", // Notification type
                identifier: customerId,
                createdAt: formattedDateTime, // Formatted date and time
            };
    
            await addDoc(notificationsCollectionRef, notificationData);
            console.log("Notification document added successfully.");
            
            setIsAdding(false);
            
            setShowFireworks(true)
            setTimeout(() => setShowFireworks(false), 5000) // Hide fireworks after 3 seconds
            
            onUpdate();
    
        } catch (error) {
            console.error("Error processing customer update:", error);
            if (alertsOn) {
                alert("Error updating customer data.");
            }
        }
    };
    
    
    
    
    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    useEffect(() => {
        setDisabled(accountLevel === "S" || accountLevel === "DIST" || accountLevel === "A" || !customer.id);
    }, [accountLevel]);


    const [activeOrdersTotal, setActiveOrdersTotal] = useState(0);

    useEffect(() => {
        const fetchActiveOrdersTotal = async () => {
            try {
                const activeCollectionRef = collection(db, `Customers/${customer.salesID}/clients/${customer.id}/active`);
                const querySnapshot = await getDocs(activeCollectionRef);

                let total = 0;
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.grandTotal) {
                        total += data.grandTotal;
                    }
                });

                setActiveOrdersTotal(total);
            } catch (error) {
                console.error("Error fetching active orders total:", error);
            }
        };

        fetchActiveOrdersTotal();
        
        // eslint-disable-next-line
    }, []);
    
    
    const [users, setUsers] = useState([]);
    const [NewSalesID, setNewSalesID] = useState("");
    const [NewSalesName, setNewSalesName] = useState("");
    const [NewSalesCode, setNewSalesCode] = useState("");
    const [NewSalesEmail, setNewSalesEmail] = useState("");
    
    const moveToNewSalesRep = async (customerId, NewSalesID) => {
        console.log("Customer ID", customerId);
        console.log("Sales ID", customer.salesID);
        console.log("New Sales ID", NewSalesID);
        
        
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${customer.salesID}/clients`, customerId);
            
            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the active collection");
            }
            
            // Get the customer data and set status to 'active'
            const customerData = customerSnapshot.data();
            const updatedCustomerData = {...customerData,
                status: "active",
                salesCode: NewSalesCode,
                salesRep: NewSalesName,
                salesEmail: NewSalesEmail,
            };
            
            // Reference to the destination document in the "clients" collection
            const activeDocRef = doc(db, `Customers/${NewSalesID}/clients`, customerId);
            
            // Copy document to the "clients" collection with updated status
            await setDoc(activeDocRef, updatedCustomerData);
            
            // Delete document from the "pending" collection
            await deleteDoc(customerDocRef);
            
            console.log("Customer moved to new Sales Rep");
            if (alertsOn) {
                alert("Customer moved to new Sales Rep");
            }
            
            setNewSalesID("");
            setNewSalesCode("");
            setNewSalesEmail("");
            setNewSalesName("");
            
            setIsMoving(false);
            setShowFireworks(true)
            setTimeout(() => setShowFireworks(false), 5000) // Hide fireworks after 3 seconds
            onUpdate();
            
        } catch (error) {
            console.error("Error moving customer to new Sales Rep:", error);
            if (alertsOn) {
                alert("Error moving customer to new Sales Rep:");
            }
        }
    };

    // Fetch users from Firestore on component mount
    useEffect(() => {
        const fetchUsers = async () => {
            const usersCollection = collection(db, 'Users');
            const userSnapshot = await getDocs(usersCollection);
            const userList = userSnapshot.docs.map(doc => ({
                id: doc.id, // UID from Firestore document ID
                name: `${doc.data().fname} ${doc.data().lname}`, // Combining fname and lname into a single name field
                accountLevel: doc.data().accountLevel,
                salesCode: doc.data().salesCode,
                salesEmail: doc.data().email,
            }));
            setUsers(userList);
        };
        fetchUsers();
    }, []);
    
    // Handle selection change
    const handleChange = (event) => {
        const selectedID = event.target.value;
        const selectedUser = users.find(user => user.id === selectedID);
    
        if (selectedUser) {
            setNewSalesID(selectedUser.id);
            setNewSalesName(selectedUser.name);
            setNewSalesCode(selectedUser.salesCode);
            setNewSalesEmail(selectedUser.salesEmail);
        }
    };
    
    const [selectedReps, setSelectedReps] = useState([]);
    
    // Handle selection of a user
    const handleSelectChange = (e) => {
        const selectedUserId = e.target.value;
        const selectedUser = users.find(user => user.id === selectedUserId);

        // Add selected user to selectedReps array if not already selected
        if (selectedUser && !selectedReps.some(rep => rep.id === selectedUserId)) {
            setSelectedReps([...selectedReps, selectedUser]);
        }
    };

    // Handle removing a rep from the selectedReps list
    const handleRemoveRep = (repId) => {
        setSelectedReps(selectedReps.filter(rep => rep.id !== repId));
    };
    
    
    
    const handleShareCustomers = async (customerID) => {
        if (!selectedReps.length || !customerID) return;
    
        try {
            const batchUpdates = selectedReps.map(async (rep) => {
                const repRef = doc(db, "Users", rep.id);
    
                await updateDoc(repRef, {
                    sharedCustomers: arrayUnion({
                        sharedBy: currentUser.uid,
                        customerID: customerID
                    })
                });
            });
    
            await Promise.all(batchUpdates);
            console.log("Shared customers updated successfully!");
            setIsSharing(false);
            setShowFireworks(true)
            setTimeout(() => setShowFireworks(false), 5000) // Hide fireworks after 3 seconds
            onUpdate();
        } catch (error) {
            console.error("Error updating shared customers:", error);
        }
    };
    
    
    const handleUnShare = async (customerID) => {
        if (!customerID) return;
    
        console.log("Unsharing Customer:", customerID);
    
        try {
            const currentUserRef = doc(db, "Users", currentUser.uid); // Get reference to current user's document
            const currentUserDoc = await getDoc(currentUserRef); // Fetch the document
    
            if (currentUserDoc.exists()) {
                const data = currentUserDoc.data();
                const sharedCustomers = data.sharedCustomers || []; // Get the sharedCustomers array
    
                // Filter out the customer with the matching customerID
                const updatedSharedCustomers = sharedCustomers.filter(
                    (customer) => customer.customerID !== customerID
                );
    
                // Update the document with the new sharedCustomers array
                await updateDoc(currentUserRef, {
                    sharedCustomers: updatedSharedCustomers,
                });
    
                console.log("Customer successfully removed from sharedCustomers!");
            } else {
                console.log("User document not found!");
            }
        } catch (error) {
            console.error("Error removing shared customer:", error);
        }
    };
    
    
    
    return (
        <div className={`w-full flex flex-col z-50 border-b-2 ${darkMode ? 'border-darkBgColor' : ''} p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>
        
            {showFireworks && <FireworksEffect show={showFireworks} />}
            
            {/*HEADER*/}
            <div className={`w-full flex flex-row ${darkMode ? 'hover:bg-white' : 'hover:bg-slate-400'} hover:bg-opacity-5 rounded-lg`}>
                
                <div className="w-full flex flex-row">
                    
                    {/*Buttons Delete*/}
                    <div className="flex flex-col items-center mr-3">
                        <Button
                            className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            // onClick={handleRemove}
                        >
                            <TrashIcon className="h-5 hidden text-borderColor hover:text-red-600 hover:scale-110 m-auto"/>
                        </Button>
                    </div>
                    
                    {/*Sales Rep*/}
                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">
                        
                        <div className={`w-8 p-1 h-8 bg-bgColor rounded-full hover:scale-105 line-clamp-1 ${customer.existing ? `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}` : `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}`}`}>
                            <div className="flex items-center justify-center h-full text-xs line-clamp-1">{customer.salesCode || "--"}</div>
                        </div>
                        
                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-xs xl:text-sm line-clamp-1">{customer.salesRep || "- - - - - - - - - - -"}</div>
                            <div className="text-xxs xl:text-xs line-clamp-1">{customer.salesEmail || "- - - - - - - - -"}</div>
                        </div>
                    
                    </div>
                    
                    {/*Customer*/}
                    <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">
                        
                        <div className={`w-fit p-1 px-2 h-8 rounded-lg hover:scale-105 line-clamp-1 ${customer.existing ? `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}` : `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}`}`}>
                            <div className="flex items-center justify-center h-full text-xs line-clamp-1">{customer.customerCode || "- - - - -"}</div>
                        </div>
                        
                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-xs xl:text-sm line-clamp-1">{customer.name || "- - - - - - - - -"}</div>
                            <div className={`text-xxs text-gray-500 line-clamp-1`}>{customer.contact || "- - - - -"} | {formatPhoneNumber(customer.contactPhone) || "(---) --- - ----"}</div>
                        </div>
                    
                    
                    </div>
                    
                    {/*Status*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">
                        
                        {/*Status*/}
                        <div className="mr-auto w-1/2 justify-center items-center flex">
                            {customer.status === "active" && (
                                <div>
                                    
                                    <div className={`w-fit p-1 h-6 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-orderColor' : 'bg-emerald-200/60 text-emerald-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <CheckCircleIcon className="w-3 xl:w-5 mr-3 hover:animate-pulse"/>
                                        <div className="flex items-center justify-center h-full text-xxs xl:text-xs mr-3">
                                            {customer.existing ? 'Existing' : 'Active'}
                                        </div>
                                    </div>
                                
                                </div>
                            )}
                            
                            {customer.status === "pending" && (
                                <div>
                                    
                                    <div className={`w-fit p-1 h-6 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-pendingColor' : 'bg-pendingColor text-yellow-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <ArrowPathIcon className="w-3 xl:w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xxs xl:text-xs mr-3">Pending</div>
                                    </div>
                                
                                </div>
                            )}
                        </div>
                        
                        
                        {/*Button*/}
                        <div className="ml-auto w-1/2 justify-center items-center flex">
                            {customer.status === "active" && (
                                <div>
                                    
                                    <div className={`w-16 p-1 h-6 rounded-full flex flex-row hover:scale-105 ${darkMode ? '' : ''} ${orderLength ? `${darkMode ? 'border border-blue-700 bg-blue-600/15 text-blue-200' : 'border border-blue-700 bg-blue-500/15 text-blue-700'}` : `${darkMode ? 'border border-red-500 bg-red-500/15 text-red-200' : 'border border-red-500 bg-red-500/15 text-red-500'}`}`}>
                                        <ClipboardDocumentListIcon className="w-5 mr-3 hover:animate-pulse"/>
                                        <div className="flex items-center justify-center font-semibold h-full text-xs mr-3 ml-auto">
                                            {orderLength}
                                        </div>
                                    </div>
                                
                                </div>
                            )}
                            
                            {customer.status === "pending" && (
                                <div>

                                    <div>
                                        
                                        <Button
                                            className={`w-fit px-2 py-1.5 ${disabled ? '' : 'hover:scale-105 hover:bg-opacity-50'} bg-green-700 text-white line-clamp-1 rounded-xl shadow-lg text-xs flex flex-row items-center justify-center`}
                                            onClick={toggleAdding}
                                            disabled={disabled}
                                        >
                                            <DocumentArrowUpIcon className="w-4 mt-auto hover:rotate-12 mb-auto mr-2 line-clamp-1"/>Status
                                        
                                            {!customer.id && (
                                                <div className={`absolute bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-50 px-2 py-0.5`}>No UserID</div>
                                            )}
                                            
                                        </Button>
    
                                    </div>
    
                                </div>
                            
                            )}
                        </div>
                    
                    </div>
                    
                    {/*YTD*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">
                        
                        <div className={`w-1/2 p-1 h-8 bg-green-600/15 border border-green-600 rounded-full`}>
                            <div className="flex items-center justify-center h-full text-sm px-2">$ {numberWithCommas(activeOrdersTotal.toFixed(2))}</div>
                        </div>
                    
                    </div>
                    
                    
                    {/* Buttons DropDown */}
                    <div className={`flex flex-row items-center ${customer.existing ? '' : ''}`}>
                        <Button
                            className={` rounded-full mt-auto mb-auto h-8 w-8 mr-2 items-center justify-center align-middle`}
                            onClick={toggleMoving}
                        >
                            <LinkIcon
                                className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"
                            />
                        </Button>
                        
                        <Button
                            className={`${isShared ? 'hidden' : ''} ${plant ? 'hidden' : ''} rounded-full mt-auto mb-auto h-8 w-8 mr-2 items-center justify-center align-middle`}
                            onClick={toggleSharing}
                        >
                            <ShareIcon
                                className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"
                            />
                        </Button>
                        
                        <Button
                            className={`${isShared ? '' : 'hidden'} rounded-full mt-auto mb-auto h-8 w-8 mr-2 items-center justify-center align-middle`}
                            onClick={() => handleUnShare(customer.id)}
                        >
                            <XMarkIcon
                                className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"
                            />
                        </Button>
                        
                        <Button
                            className={`rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle`}
                            // onClick={toggleVisibility}
                            onClick={() => handleViewAccount(customer)}
                        >
                            <ArrowTopRightOnSquareIcon
                                className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"
                            />
                        </Button>
                    </div>
                
                </div>
            
            </div>
            
            
            <Dialog open={isAdding} onClose={setIsAdding} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <DocumentArrowUpIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle as="h3" className="text-base font-semibold">
                                Customer Internal SetUp
                            </DialogTitle>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Make sure that you have reviewed all of the customers information. Once you have
                                    done so please enter in the fields below.
                                </p>
                            </div>
                            
                            <div className="mt-2 grid grid-cols-2 gap-3">
                                
                                {/*Code*/}
                                <div className={`relative col-span-full`}>
                                    <label
                                        htmlFor="code"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Customer Code
                                    </label>
                                    <Input
                                        id="code"
                                        name="code"
                                        type="text"
                                        value={customerCode}
                                        onChange={(e) => setCustomerCode(e.target.value)}
                                        placeholder={`Customer Code`}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    />
                                </div>
                                
                                {/*Pricing Type*/}
                                <div className={`relative col-span-1`}>
                                    <label
                                        htmlFor="type"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Pricing Type
                                    </label>
                                    <Select
                                        id="type"
                                        name="type"
                                        // onChange={(event) => {
                                        //     const selectedValue = event.target.value;
                                        //     setPriceCode(selectedValue); // Update priceCode state
                                        //     console.log("Selected Price Code:", selectedValue); // Debugging to verify if it's working
                                        // }}
                                        value={priceCode}
                                        onChange={(e) => setPriceCode(e.target.value)}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    >
                                        <option value="">Select Pricing Type...</option>
                                        <option value="AG">Agriculture</option>
                                        <option value="DIST1">Distributor NE/INTL</option>
                                        <option value="DIST2">Distributor SE/CAN</option>
                                        <option value="NE">Northeast</option>
                                        <option value="SE">Southeast</option>
                                    </Select>
                                </div>
                                
                                {/*Credit Limit*/}
                                <div className={`relative col-span-1`}>
                                    <label
                                        htmlFor="cl"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Credit Limit
                                    </label>
                                    <Input
                                        id="cl"
                                        name="cl"
                                        type="number"
                                        value={creditLimit}
                                        onChange={(e) => setCreditLimit(e.target.value)}
                                        placeholder={'Credit Limit'}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    />
                                </div>
                                
                                {/*Customer Type*/}
                                <div className={`relative col-span-full`}>
                                    <label
                                        htmlFor="type"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Customer Type
                                    </label>
                                    <Select
                                        id="type"
                                        name="type"
                                        // onChange={(event) => {
                                        //     const selectedValue = event.target.value;
                                        //     setPriceCode(selectedValue); // Update priceCode state
                                        //     console.log("Selected Price Code:", selectedValue); // Debugging to verify if it's working
                                        // }}
                                        value={customerType}
                                        onChange={(e) => setCustomerType(e.target.value)}
                                        placeholder={`Product Code`}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    >
                                        <option value="">Select Customer Type...</option>
                                        <option value="5">Level 1 [5%]</option>
                                        <option value="10">Level 2 [10%]</option>
                                        <option value="15">Level 3 [15%]</option>
                                        <option value="20">Level 4 [20%]</option>
                                        <option value="25">GLG</option>
                                    </Select>
                                </div>
                            
                            </div>
                        
                        
                        </div>
                    </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <Button
                              type="button"
                              onClick={() => moveToActive(customer.id)}
                              className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                          >
                              Connect
                          </Button>
                          <Button
                              type="button"
                              data-autofocus
                              onClick={() => setIsAdding(false)}
                              className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                          >
                              Cancel
                          </Button>
                      </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
            
            <Dialog open={isMoving} onClose={setIsMoving} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            <div className="sm:flex sm:items-start">
                                <div
                                    className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                    <LinkIcon aria-hidden="true" className="size-6 text-green-600"/>
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold">
                                        Transfer Customer
                                    </DialogTitle>
                                    <div className="mt-2 mb-4">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to transfer this Customer? All of its data will be
                                            permanently transfered from
                                            your account. This action cannot be undone.
                                        </p>
                                    </div>
                                    
                                    <div className={`relative w-full`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Sales Rep
                                        </label>
                                        <Select
                                            type="text"
                                            value={NewSalesID}
                                            onChange={handleChange}
                                            required={true}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        >
                                            <option value="">Select a Sales Rep</option>
                                            {users
                                                .filter(user => user.accountLevel === "S" || user.accountLevel === "A" || user.accountLevel === "D")
                                                .map(user => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                        </Select>
                                    </div>
                                    
                                    {NewSalesID && (
                                        <div className={`relative w-full text-sm text-gray-600 flex flex-col space-y-2`}>
                                            <p>Selected User ID: {NewSalesID}</p>
                                            <p>Selected User Code: {NewSalesCode}</p>
                                            <p>Selected User Name: {NewSalesName}</p>
                                            <p>Selected User Email: {NewSalesEmail}</p>
                                        </div>
                                    )}
                                
                                
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <Button
                                    type="button"
                                    onClick={() => moveToNewSalesRep(customer.id, NewSalesID)}
                                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                                >
                                    Transfer
                                </Button>
                                <Button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setIsMoving(false)}
                                    className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            
            <Dialog open={isSharing} onClose={setIsSharing} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                    <ShareIcon aria-hidden="true" className="size-6 text-green-600"/>
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold">
                                        Share Customer
                                    </DialogTitle>
                                    <div className="mt-2 mb-4">
                                        <p className="text-sm text-gray-500">
                                            Sharing this customer means that others will be granted access to see this
                                            customers account. This also grants then permission to place orders with
                                            that customer.
                                        </p>
                                    </div>
                                    
                                    <div className={`relative w-full`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Sales Rep
                                        </label>
                                        <Select
                                            type="text"
                                            onChange={handleSelectChange}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        >
                                            <option value="">Select a Sales Rep</option>
                                            {users
                                                .filter(user => user.accountLevel === "S" || user.accountLevel === "A" || user.accountLevel === "D")
                                                .map(user => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                        </Select>
                                    </div>
                                    
                                    <div className={`columns-2 gap-2 space-y-2 p-2 mt-2 rounded-lg border-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                        {selectedReps.map((rep, index) => (
                                            <div key={index}
                                                 className={`flex hover:scale-105 w-full items-center py-1.5 border ${darkMode ? 'border-neutral-700' : ''} bg-opacity-50 rounded-lg px-2`}>
                                                
                                                <span className="mr-2 text-sm">{rep.name}</span>
                                                
                                                <Button
                                                    onClick={() => handleRemoveRep(rep.id)}
                                                    className="text-red-500 hover:text-red-700 ml-auto"
                                                >
                                                    <XMarkIcon
                                                        className="h-5 text hover:text-red-500 hover:animate-pulse hover:scale-110 m-auto"/>
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                    
                                    {/*{NewSalesID && (*/}
                                    {/*    <p className="mt-2 text-sm text-gray-600">*/}
                                    {/*        Selected User ID: {NewSalesID}*/}
                                    {/*    </p>*/}
                                    {/*)}*/}
                                    
                                    {/*<Select*/}
                                    {/*    id="user-select"*/}
                                    {/*    value={NewSalesID}*/}
                                    {/*    onChange={handleChange}*/}
                                    {/*    required={true}*/}
                                    {/*    className={`rounded-lg block w-full p-1.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}*/}
                                    {/*>*/}
                                    {/*    <option value="">Select a User</option>*/}
                                    {/*    {users*/}
                                    {/*      .filter(user => user.accountLevel === "S" || user.accountLevel === "A")*/}
                                    {/*      .map(user => (*/}
                                    {/*        <option key={user.id} value={user.id}>*/}
                                    {/*          {user.name}*/}
                                    {/*        </option>*/}
                                    {/*      ))}*/}
                                    {/*</Select>*/}
                                    {/*{NewSalesID && (*/}
                                    {/*    <p className="mt-2 text-sm text-gray-600">*/}
                                    {/*        Selected User ID: {NewSalesID}*/}
                                    {/*    </p>*/}
                                    {/*)}*/}
                                
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <Button
                                    type="button"
                                    onClick={() => handleShareCustomers(customer.id)}
                                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                                >
                                    Share
                                </Button>
                                <Button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setIsSharing(false)}
                                    className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        
        
        </div>
    )
}

export default CustomerCard;
