import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {numberWithCommas, stateMapping, UMMapping} from "../GlobalFunctions";
import logo from "../../main-logo.png";
import {
    Button, Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions,
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Field,
    Input, Label,
    Select,
    Switch
} from "@headlessui/react";
import {
    ArrowRightIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon, ArrowUpTrayIcon, CheckIcon, DocumentArrowUpIcon,
    DocumentCurrencyDollarIcon, EyeSlashIcon,
    MagnifyingGlassIcon,
    MapPinIcon, PlusCircleIcon,
    PlusIcon, SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import {ChevronUpDownIcon, XMarkIcon} from "@heroicons/react/16/solid";
import {useAuth} from "../../contexts/authContext";
import {
    addDoc, arrayUnion,
    collection,
    deleteDoc,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    setDoc,
    updateDoc
} from "firebase/firestore";
import {db} from "../../firebase/firebase";
import fipsCode from '../../fipCodes.json';
import SmallMap from "./SmallMap";
import OrderSection from "../Orders/OrderSection";
import AddressInput from "../CustomerForm/AddressInput";
import FireworksEffect from "../FireworksEffect";


const AccountView = ({ customer, onUpdate, plant, reps }) => {
    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });
    
    const { currentUser } = useAuth();
    
    const [showFireworks, setShowFireworks] = useState(false)
    
    // MAIN DATA POINTS
    const [CustName, setCustName] = useState(customer.name || "");
    const [customerCode, setCustomerCode] = useState(customer.customerCode || "");
    const [managementName, setManagementName] = useState(customer.managementName || "");
    const [address1, setAddress1] = useState(customer.address1 || "");
    const [address2, setAddress2] = useState(customer.address2 || "");
    const [city, setCity] = useState(customer.city || "");
    const [selectedState, setSelectedState] = useState(customer.state || "");
    const [zip, setZip] = useState(customer.zip || "");
    const [selectedCounty, setSelectedCounty] = useState(customer.county || "");
    const [fips, setFips] = useState(customer.fips || "");
    const [country, setCountry] = useState(customer.country || "");
    const [industry, setIndustry] = useState(customer.industry || "");
    
    const [customerType, setCustomerType] = useState(customer.customerType || "");
    const [priceType, setPriceType] = useState(customer.priceType || "");

    // CONTACTS DATA POINTS
    const [contact, setContact] = useState(customer.contact || "");
    const [contactPosition, setContactPosition] = useState(customer.contactPosition || "");
    const [contactPhone, setContactPhone] = useState(customer.contactPhone || "");
    const [contactEmail, setContactEmail] = useState(customer.contactEmail || "");
    const [billingContact, setBillingContact] = useState(customer.billingContact || "");
    const [billingPhone, setBillingPhone] = useState(customer.billingPhone || "");
    const [invoiceEmails, setInvoiceEmails] = useState(customer.invoiceEmails || "");
    const [statementEmails, setStatementEmails] = useState(customer.statementEmails || "");
    const [website, setWebsite] = useState(customer.website || "");
    
    // FINANCES DATA POINTS
    const [taxID, setTaxID] = useState(customer.taxID || "");
    const [salesTaxStatus, setSalesTaxStatus] = useState(customer.salesTaxStatus || "");
    const [taxExempCode, setTaxExempCode] = useState(customer.taxExempCode || "");
    const [paymentType, setPaymentType] = useState(customer.paymentType || "");
    
    // DELIVERY DATA POINTS
    const [TTA, setTTA] = useState(customer.TTA || false);
    const [LAS, setLAS] = useState(customer.LAS || false);
    const [LDA, setLDA] = useState(customer.LDA || false);
    const [PJR, setPJR] = useState(customer.PJR || false);
    const [DAR, setDAR] = useState(customer.DAR || false);
    const [HSA, setHSA] = useState(customer.HSA || false);
    const [FLC, setFLC] = useState(customer.FLC || false);
    const [RPY, setRPY] = useState(customer.RPY || false);
    
    
    
    

    // eslint-disable-next-line
    const [orderLength, setOrderLength] = useState(0);
    const [pendingLength, setPendingLength] = useState(0);
    const [plannedLength, setPlannedLength] = useState(0);

    const [salesID, setSalesID] = useState("");
    
    // const [reps, setReps] = useState([]);



    const { darkMode } = useDarkMode();

    const [accountLevel, setAccountLevel] = useState("");
    const [disabled, setDisabled] = useState(false);


    // eslint-disable-next-line
    const [Addresses, setAddresses] = useState(customer.ShippingAddresses || []);
    

    useEffect(() => {
        setIsLoaded(true);
        setSalesID(customer.salesID);
        // eslint-disable-next-line
    }, []);

    // eslint-disable-next-line
    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    // eslint-disable-next-line
    const [isRemoving] = useState(false); // Track whether the item is being removed
    

    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };
    

    const getSubcollectionCount = async (clientId) => {
        // Planned
        try {
            // Reference to the subcollection (e.g., 'planned')
            const pendingCollectionRed = collection(db, `Customers/${customer.salesID}/clients/${clientId}/pending`);

            // Get the count from the server without fetching documents
            const pendingCountSnapshot = await getCountFromServer(pendingCollectionRed);

            // Get the count from the aggregate query result
            const pendingCount = pendingCountSnapshot.data().count;

            setPendingLength(pendingCount);
        } catch (error) {
            console.error("Error getting subcollection count:", error);
            return 0; // Return 0 in case of an error
        }

        // Pending
        try {
            // Reference to the subcollection (e.g., 'pending')
            const activeCollectionRef = collection(db, `Customers/${customer.salesID}/clients/${clientId}/active`);

            // Get the count from the server without fetching documents
            const activeCountSnapshot = await getCountFromServer(activeCollectionRef);

            // Get the count from the aggregate query result
            const count = activeCountSnapshot.data().count;

            setOrderLength(count);
        } catch (error) {
            console.error("Error getting subcollection count:", error);
            return 0; // Return 0 in case of an error
        }

        // Planned
        try {
            // Reference to the subcollection (e.g., 'planned')
            const plannedCollectionRef = collection(db, `Customers/${customer.salesID}/clients/${clientId}/planned`);

            // Get the count from the server without fetching documents
            const plannedCountSnapshot = await getCountFromServer(plannedCollectionRef);

            // Get the count from the aggregate query result
            const plannedCount = plannedCountSnapshot.data().count;

            setPlannedLength(plannedCount);
        } catch (error) {
            console.error("Error getting subcollection count:", error);
            return 0; // Return 0 in case of an error
        }
    };

    useEffect(() => {
        if (currentUser) {
            // Call the function to get the document count
            getSubcollectionCount(customer.id).then(count => {
                console.log(`Fetched count: ${count}`);
            });
        }
        // eslint-disable-next-line
    }, [currentUser]);
    
    useEffect(() => {
        setDisabled(accountLevel === "S" || accountLevel === "DIST" || accountLevel === "A");
    }, [accountLevel]);


    const [mainExpanded, setMainExpanded] = useState(false);
    const [contactExpanded, setContactExpanded] = useState(false);
    const [financesExpanded, setFinancesExpanded] = useState(false);
    const [shippingExpanded, setShippingExpanded] = useState(false);
    const [deliveryExpanded, setDeliveryExpanded] = useState(false);

    const toggleMain = () => {
        setMainExpanded(!mainExpanded);
    }
    const toggleContacts = () => {
        setContactExpanded(!contactExpanded);
    }
    const toggleFinances = () => {
        setFinancesExpanded(!financesExpanded);
    }
    const toggleShipping = () => {
        setShippingExpanded(!shippingExpanded);
    }
    const toggleDelivery = () => {
        setDeliveryExpanded(!deliveryExpanded);
    }

    const collapseAll = () => {
        setMainExpanded(false);
        setContactExpanded(false);
        setFinancesExpanded(false);
        setShippingExpanded(false);
        setDeliveryExpanded(false);
    }
    const expandAll = () => {
        setMainExpanded(true);
        setContactExpanded(true);
        setFinancesExpanded(true);
        setShippingExpanded(true);
        setDeliveryExpanded(true);
    }



    const [products, setProducts] = useState([]);
    // const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    // eslint-disable-next-line
    const [showProducts, setShowProducts] = useState(false); // To control product list visibility
    
    const categoryColors = [
        { category: 'Biostimulants', color: 'bg-bsColor', shortened: 'BS' },
        { category: 'Liquid Fertilizer', color: 'bg-lfColor', shortened: 'LF' },
        { category: 'Dry Fertilizer', color: 'bg-teal-700', shortened: 'DF' },
        { category: 'Secondary Nutrients', color: 'bg-snColor', shortened: 'SN' },
        { category: 'Micronutrients', color: 'bg-mnColor', shortened: 'MN' },
        { category: 'Penetrants & Water Holding Agents', color: 'bg-whColor', shortened: 'PHA' },
        { category: 'A-Plus® Solubilizing Surfactants', color: 'bg-apColor', shortened: 'AP' },
        { category: 'Turfgrass Pigment', color: 'bg-tpColor', shortened: 'TP' },
        { category: 'Water Treatments', color: 'bg-wtColor', shortened: 'WT' },
        { category: 'Soil Amendments', color: 'bg-saColor', shortened: 'SA' },
        { category: 'Fungicides', color: 'bg-fgColor', shortened: 'FG' },
        { category: 'Equipment', color: 'bg-gray-600', shortened: 'EQ' },
    ];

// Example usage
    // eslint-disable-next-line
    const getCategoryInfo = (category) => {
        const categoryInfo = categoryColors.find(c => c.category === category);
        return categoryInfo ? { color: categoryInfo.color, shortened: categoryInfo.shortened } : { color: '', shortened: '' };
    };

    // eslint-disable-next-line
    const [activeOrdersTotal, setActiveOrdersTotal] = useState(0);

    useEffect(() => {
        const fetchActiveOrdersTotal = async () => {
            try {
                const activeCollectionRef = collection(db, `Customers/${customer.salesID}/clients/${customer.id}/active`);
                const querySnapshot = await getDocs(activeCollectionRef);

                let total = 0;
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.grandTotal) {
                        total += data.grandTotal;
                    }
                });

                setActiveOrdersTotal(total);
            } catch (error) {
                console.error("Error fetching active orders total:", error);
            }
        };

        fetchActiveOrdersTotal();
        
        // eslint-disable-next-line
    }, []);
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    const [contracts] = useState(customer.contractPrices || []);
    
    const filteredProducts =
        searchTerm === ''
            ? products
            : products.filter((product) => {
                return product.name.toLowerCase().includes(searchTerm.toLowerCase())
            })

    useEffect(() => {
        const fetchProducts = async () => {
            if (currentUser) {
                try {
                    // Fetch the 'productUpdated' field for the current user
                    const userDocRef = doc(db, "Users", currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const { productUpdated } = userData;

                        if (productUpdated) {
                            // If 'productUpdated' is true, fetch products from Firestore
                            console.log("Fetching products from Firestore...");
                            const productsCollection = collection(db, "Products");
                            const snapshot = await getDocs(productsCollection);

                            if (!snapshot.empty) {
                                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                                setProducts(productData);
                                // setFilteredProducts(productData);
                                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));

                                // Set 'productUpdated' to false
                                await updateDoc(userDocRef, { productUpdated: false });
                                console.log("Set productUpdated to false after fetching from Firestore.");
                            } else {
                                console.log("No products found in Firestore.");
                            }
                        } else {
                            // If 'productUpdated' is false, load products from localStorage
                            console.log("Loading products from localStorage...");
                            const storedProducts = localStorage.getItem(`products_${currentUser.uid}`);
                            if (storedProducts) {
                                const storedData = JSON.parse(storedProducts);
                                setProducts(storedData);
                                // setFilteredProducts(storedData);
                            } else {
                                console.log("No products found in localStorage.");
                            }
                        }
                    } else {
                        console.log("User document does not exist.");
                    }
                } catch (error) {
                    console.error("Error fetching product data:", error);
                }
            }
        };

        fetchProducts();
    }, [currentUser]);
    
    const [contract, setContract] = useState(false);
    
    const toggleContract = () => {
        setContract(!contract);
    }
    
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [name, setName] = useState("");
    const [prices, setPrices] = useState([]);
    const [selectedVolume, setSelectedVolume] = useState(""); // Set to the first `um` in prices or an empty string if prices is empty
    const [price, setPrice] = useState(0);
    const [newValue, setNewValue] = useState("");
    const [productCode, setProductCode] = useState("");
    const [unitLabel, setUnitLabel] = useState("Custom Price");
    const [percentLabel, setPercentLabel] = useState("$");

    const [percent, setPercent] = useState(false);
    
    const handleSelectChange = (product) => {
        if (product) {
            // Set selected customer and related fields
            setSelectedProduct(product);
            setName(product.name);
            setPrices(product.prices);
            setSelectedVolume(product?.prices[0].um);
        } else {
            // Reset fields if no customer is selected
            setSelectedProduct(null);
            setName("");
            setPrices([]);
            setSelectedVolume("");
        }
        setSearchTerm('');
    };
    
    const clear = async () => {
        setSelectedProduct(null);
        setName("");
        setPrices([]);
        setSelectedVolume("");
        setNewValue(0);
        setProductCode("");
        setUnitLabel("Custom Price");
        setPercentLabel("$");
        setPercent(false);
        setPrice(0);
    }
    
    const getPriceBasedOnSelection = (prices, priceType, um) => {
        // Ensure prices is an array
        if (!Array.isArray(prices)) {
            console.error("Prices must be an array");
            return "Invalid data";
        }

        // Convert priceType to lowercase to handle case insensitivity
        const normalizedPriceType = priceType.toLowerCase();

        // Find the price object where 'um' matches the specified um
        const priceObject = prices.find((price) => price.um === um);

        if (priceObject && priceObject[normalizedPriceType]) {
            return priceObject[normalizedPriceType];
        }

        return "Price not available";
    };
    
    useEffect(() => {
        // setSelectedVolume(prices.length > 0 ? prices[0].um : "");

        if (selectedProduct && selectedVolume) {
            const newPrice = getPriceBasedOnSelection(prices, customer.priceType, selectedVolume);

            // Find the price object that matches the selected volume and priceType
            const priceObject = prices.find((price) => price.um === selectedVolume);

            if (priceObject) {
                setProductCode(priceObject.code);
                console.log("Selected Price Code:", priceObject.code); // Log the code of the selected price object
            }

            console.log("New Price:", newPrice); // Log the selected price

            setPrice(newPrice);

            // eslint-disable-next-line default-case
            switch (percent){
                case true:
                    setPercentLabel("%");
                    setUnitLabel("Percent Off");
                    break;
                case false:
                    setPercentLabel("$");
                    setUnitLabel("Custom Price");
                    break;
            }
            
        }
        // eslint-disable-next-line
    }, [selectedProduct, selectedVolume, percent]);
    
    
    const handleVolumeChange = (e) => {
        setSelectedVolume(e.target.value);
    };
    
    const addContractPrice = async () => {
        try {
            // Reference to the customer document
            const customerDocRef = doc(db, `Customers/${customer.salesID}/clients`, customer.id);
    
            // Data to be added to the array
            const contractPriceEntry = {
                price: price,
                name: name,
                productCode: productCode,
                selectedVolume: selectedVolume,
                percent: percent,
                newValue: newValue,
            };
    
            // Update the document to add the new entry to the contractPrices array
            await updateDoc(customerDocRef, {
                "contractPrices": arrayUnion(contractPriceEntry),
            });
    
            console.log("New contract price added successfully");
            
            setContract(false);
            onUpdate();
            await clear();
            
            if (alertsOn) {
                alert("Contract Price added successfully!");
            }
        } catch (error) {
            console.error("Error adding contract price: ", error);
        }
    };
    
    const removeContractPrice = async (productCodeToRemove) => {
        try {
            // Reference to the customer document
            const customerDocRef = doc(db, `Customers/${customer.salesID}/clients`, customer.id);
    
            // Fetch the current document to get the existing array
            const customerDocSnap = await getDoc(customerDocRef);
    
            if (customerDocSnap.exists()) {
                const customerData = customerDocSnap.data();
                const updatedContractPrices = customerData.contractPrices.filter(
                    (item) => item.productCode !== productCodeToRemove
                );
    
                // Update Firestore with the filtered array
                await updateDoc(customerDocRef, {
                    contractPrices: updatedContractPrices
                });
    
                console.log(`Contract price with product code ${productCodeToRemove} removed successfully`);
                if (alertsOn) {
                    alert("Contract Price Removed Successfully!");
                }
            } else {
                console.log("Customer document does not exist");
            }
        } catch (error) {
            console.error("Error removing contract price: ", error);
        }
    };
    
    
    
    
    const handleStateChange = (event) => {
        const stateCode = event.target.value;
        setSelectedState(stateCode);
        setSelectedCounty(''); // Reset county selection when state changes
    };

    const handleCountyChange = (event) => {
        const countyName = event.target.value;
        setSelectedCounty(countyName);

        // Find the selected county's data based on the selected county name
        const countyData = filteredCounties.find(county => county.county === countyName);

        // Set the fips code if county data exists, otherwise set it to an empty string
        if (countyData) {
            setFips(countyData.fips);
        } else {
            setFips("");  // Handle case where no county is selected or found
        }
    };
    
    // Filter counties based on the selected state
    const filteredCounties = fipsCode.county.filter(county => county.state === selectedState);


    const [orders, setOrders] = useState([]);
    
    const [filteredOrders, setFilteredOrders] = useState([]);
    
    
    useEffect(() => {

        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);
                        // setReps(userData.reps || []);
                        // console.log("Reps", userData.reps);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);
                            // setReps(userData.reps || []);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
        
        const fetchOrdersActive = async () => {
            if (currentUser && customer?.customerCode) {
                try {
                    const storedActiveData = localStorage.getItem(`activeOrders_${currentUser.uid}`);
                    let allActiveData = [];
        
                    if (storedActiveData) {
                        // Load from local storage and filter by customerCode
                        const customersData = JSON.parse(storedActiveData);
                        allActiveData = customersData.filter(order => order.customerCode === customer.customerCode);
                    } else {
                        // Fetch from Firestore
                        const clientsCollectionRef = collection(db, `Customers/${currentUser.uid}/clients`);
                        const clientsSnapshot = await getDocs(clientsCollectionRef);
        
                        for (const clientDoc of clientsSnapshot.docs) {
                            const clientId = clientDoc.id;
                            const activeCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/active`);
                            const activeSnapshot = await getDocs(activeCollectionRef);
        
                            activeSnapshot.forEach((activeDoc) => {
                                const orderData = {
                                    clientId,
                                    id: activeDoc.id,
                                    ...activeDoc.data(),
                                };
                                if (orderData.customerCode === customer.customerCode) {
                                    allActiveData.push(orderData);
                                }
                            });
                        }
        
                        // Store in local storage if data exists
                        if (allActiveData.length > 0) {
                            localStorage.setItem(`activeOrders_${currentUser.uid}`, JSON.stringify(allActiveData));
                        }
                    }
        
                    // Update state with the filtered data
                    setOrders(allActiveData);
                    setFilteredOrders(allActiveData);
        
                } catch (error) {
                    console.error('Error fetching active orders:', error);
                    setOrders([]);
                    setFilteredOrders([]);
                }
            }
        };
        
        const fetchAllUsersActiveData = async () => {
            if (currentUser && customer?.customerCode) {
                try {
                    if (!reps || reps.length === 0) {
                        console.log("Reps is null or empty. Returning an empty array.");
                        setOrders([]);
                        setFilteredOrders([]);
                        return;
                    }
                    
                    const storedAllData = localStorage.getItem(`allOrders_${currentUser.uid}`);
                    let allActiveData = [];
        
                    if (storedAllData) {
                        const activeData = JSON.parse(storedAllData);
                        // Filter by customerCode
                        allActiveData = activeData.filter(order => order.customerCode === customer.customerCode);
                    } else {
                        const repsIds = reps.map((rep) => rep.id);
                        
                        const userPromises = repsIds.map(async (userId) => {
                            const clientsCollectionRef = collection(db, `Customers/${userId}/clients`);
                            const clientsSnapshot = await getDocs(clientsCollectionRef);
                            
                            const activePromises = clientsSnapshot.docs.map(async (clientDoc) => {
                                const clientId = clientDoc.id;
                                const activeCollectionRef = collection(db, `Customers/${userId}/clients/${clientId}/active`);
                                const activeSnapshot = await getDocs(activeCollectionRef);
                                
                                activeSnapshot.forEach((activeDoc) => {
                                    const orderData = {
                                        userId,
                                        clientId,
                                        ...activeDoc.data(),
                                        id: activeDoc.id,
                                    };
                                    if (orderData.customerCode === customer.customerCode) {
                                        allActiveData.push(orderData);
                                    }
                                });
                            });
                            
                            await Promise.all(activePromises);
                        });
                        
                        await Promise.all(userPromises);
                    }
                    
                    if (allActiveData.length > 0) {
                        // Sort in descending order before setting state
                        allActiveData.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
                        setOrders(allActiveData);
                        setFilteredOrders(allActiveData);
                    } else {
                        setOrders([]);
                        setFilteredOrders([]);
                        console.log('No active orders found, clearing data.');
                    }
                } catch (error) {
                    console.error("Error fetching active data for all users: ", error);
                }
            }
        };
        
        
        fetchUserName();
        if (plant) {
            fetchAllUsersActiveData();
            console.log("Plant is True Ran All Active Data");
        } else {
            fetchOrdersActive();
        }
        // fetchOrdersActive();
        // eslint-disable-next-line
    }, [currentUser]);

    
    
    
    const calculateTotalGrandTotal = (orders) => {
        return orders.reduce((total, order) => {
            return total + (parseFloat(order.grandTotal) || 0); // Add 0 for undefined grandTotal
        }, 0);
    };
    
    const grandTotal = calculateTotalGrandTotal(orders);
    
    
    
    
    
    
    
    
    
    
    const [shippingAddress, setShippingAddress] = useState(customer.ShippingAddresses || []);

    const handleAddComponent = () => {
        const newId = shippingAddress.length + Math.random(); // Create a unique id for each new address
        setShippingAddress([...shippingAddress, { id: newId }]); // Add a new address with its unique id
    };

    // Function to remove an address input component based on ID
    const handleRemoveComponent = (idToRemove) => {
        setShippingAddress(shippingAddress.filter(item => item.id !== idToRemove)); // Remove the item with the matching id
    };

    // Function to handle input changes from child components
    const handleAddressChange = (index, updatedAddress) => {
        const updatedAddresses = [...shippingAddress];
        updatedAddresses[index] = { ...updatedAddresses[index], ...updatedAddress }; // Ensure we maintain other properties like id
        setShippingAddress(updatedAddresses);
    };
    
    
    
    
    
    

    
    
    const tabs = [
        { name: 'Account Info', href: '#', current: true },
        { name: 'Contract Pricing', href: '#', count: `${contracts.length}`, current: false },
        { name: 'Active Orders', href: '#', count: `${orders.length}`, current: false },
        { name: 'Test Results', href: '#', count: `0`, current: false },
    ];
    
    const [activeTab, setActiveTab] = useState('Account Info');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Account Info':
                return (
                    <div className={`flex flex-col space-y-20 p-2`}>
                        
                        {/*Main Done*/}
                        <div className="w-full h-fit">
                            
                            <div className="relative mb-3">
                                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                    <div
                                        className={`w-full border-t-2 ${darkMode ? 'border-neutral-700' : ''}`}/>
                                </div>
                                <div className="relative flex items-center justify-between">
                                    <span className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Main</span>
                                <Button
                                  type="button"
                                  onClick={toggleMain}
                                  className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                >
                                    {mainExpanded ? (
                                        <>
                                            <XMarkIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                            <span>Collapse</span>
                                        </>
                                    ) : (
                                        <>
                                            <PlusIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                            <span>Expand</span>
                                        </>
                                    )}
                                </Button>
                              </div>
                            </div>

                            {/*Content*/}
                            {mainExpanded && (
                                <div className={`w-full animate-slideInBottom flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                    {/*Field Grid*/}
                                    <div className="mx-1 mt-3 grid grid-cols-6 gap-x-3 gap-y-6">
                                        
                                        {/*Name*/}
                                        <div className={`relative col-span-3`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Customer Name
                                                <div className={`ml-1`}>
                                                    <div className={`flex-none rounded-full p-1 ${CustName ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                        <div className={`size-2 rounded-full ${CustName ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                    </div>
                                                </div>
                                            </label>
                                            <Input
                                                type="text"
                                                required
                                                value={CustName}
                                                onChange={(e) => setCustName(e.target.value)}
                                                placeholder={`Customer Name`}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            />
                                        </div>
                                        
                                        {/*Management Name*/}
                                        <div className={`relative col-span-3`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Management Name
                                            </label>
                                            <Input
                                                type="text"
                                                value={managementName}
                                                onChange={(e) => setManagementName(e.target.value)}
                                                placeholder={`Management Group Name`}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            />
                                        </div>
                                        
                                        
                                        
                                        <label htmlFor="region" className="col-span-full block text-sm font-medium leading-6">
                                            BILLING ADDRESS
                                        </label>
                                        
                                        
                                        
                                        {/*Address 1*/}
                                        <div className={`relative col-span-full`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Address [1]
                                                <div className={`ml-1`}>
                                                    <div className={`flex-none rounded-full p-1 ${address1 ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                        <div className={`size-2 rounded-full ${address1 ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                    </div>
                                                </div>
                                            </label>
                                            <Input
                                                type="text"
                                                required
                                                placeholder="Street Address 1"
                                                value={address1}
                                                onChange={(e) => setAddress1(e.target.value)}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            />
                                        </div>
                                        
                                        {/*Address 2*/}
                                        <div className={`relative col-span-full`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Address [2]:
                                            </label>
                                            <Input
                                                type="text"
                                                placeholder="Optional"
                                                value={address2}
                                                onChange={(e) => setAddress2(e.target.value)}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            />
                                        </div>
                                        
                                        {/*City*/}
                                        <div className={`relative col-span-4`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                City
                                                <div className={`ml-1`}>
                                                    <div className={`flex-none rounded-full p-1 ${city ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                        <div className={`size-2 rounded-full ${city ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                    </div>
                                                </div>
                                            </label>
                                            <Input
                                                type="text"
                                                required
                                                placeholder="City"
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            />
                                        </div>
                                        
                                        {/*State*/}
                                        <div className={`relative col-span-1`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                State
                                                <div className={`ml-1`}>
                                                    <div className={`flex-none rounded-full p-1 ${selectedState ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                        <div className={`size-2 rounded-full ${selectedState ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                    </div>
                                                </div>
                                            </label>
                                            <Select
                                                required
                                                value={selectedState}
                                                onChange={handleStateChange}
                                                // onChange={(e) => setSelectedState(e.target.value)}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            >
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>
                                            </Select>
                                        </div>
                                        
                                        {/*ZipCode*/}
                                        <div className={`relative col-span-1`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Zip Code
                                                <div className={`ml-1`}>
                                                    <div className={`flex-none rounded-full p-1 ${zip ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                        <div className={`size-2 rounded-full ${zip ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                    </div>
                                                </div>
                                            </label>
                                            <Input
                                                type="text"
                                                required
                                                placeholder="Zip Code"
                                                value={zip}
                                                onChange={(e) => setZip(e.target.value)}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            />
                                        </div>
                                        
                                        
                                        
                                        <label htmlFor="region" className="col-span-full block text-sm font-medium leading-6">
                                            FIPS CODE
                                        </label>
                                        
                                        
                                        
                                        {/*County*/}
                                        <div className={`relative col-span-4`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                County
                                                <div className={`ml-1`}>
                                                    <div className={`flex-none rounded-full p-1 ${selectedCounty ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                        <div className={`size-2 rounded-full ${selectedCounty ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                    </div>
                                                </div>
                                            </label>
                                            <Select
                                                required
                                                value={selectedCounty}
                                                onChange={handleCountyChange}
                                                // disabled={true}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            >
                                                <option value="">Select a County...</option>
                                                {filteredCounties.map(county => (
                                                    <option key={county.fips}
                                                            value={county.county}>{county.county}</option>
                                                ))}
                                            </Select>
                                        </div>
                                        
                                        {/*Fips Code*/}
                                        <div className={`relative col-span-2`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Fips Code
                                                <div className={`ml-1`}>
                                                    <div className={`flex-none rounded-full p-1 ${fips ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                        <div className={`size-2 rounded-full ${fips ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                    </div>
                                                </div>
                                            </label>
                                            <Input
                                                type="text"
                                                required
                                                disabled={true}
                                                placeholder="Fips Code"
                                                value={formatFipsCode(fips)}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            />
                                        </div>
                                        
                                        
                                        
                                        {/*Country*/}
                                        <div className={`relative col-span-4`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Country
                                                <div className={`ml-1`}>
                                                    <div className={`flex-none rounded-full p-1 ${country ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                        <div className={`size-2 rounded-full ${country ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                    </div>
                                                </div>
                                            </label>
                                            <Select
                                                required
                                                value={country}
                                                onChange={(e) => setCountry(e.target.value)}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            >
                                                <option value="United States">United States</option>
                                                <option value="Afghanistan">Afghanistan</option>
                                                <option value="Albania">Albania</option>
                                                <option value="Algeria">Algeria</option>
                                                <option value="Andorra">Andorra</option>
                                                <option value="Angola">Angola</option>
                                                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                <option value="Argentina">Argentina</option>
                                                <option value="Armenia">Armenia</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Azerbaijan">Azerbaijan</option>
                                                <option value="Bahamas">Bahamas</option>
                                                <option value="Bahrain">Bahrain</option>
                                                <option value="Bangladesh">Bangladesh</option>
                                                <option value="Barbados">Barbados</option>
                                                <option value="Belarus">Belarus</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Belize">Belize</option>
                                                <option value="Benin">Benin</option>
                                                <option value="Bhutan">Bhutan</option>
                                                <option value="Bolivia">Bolivia</option>
                                                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                <option value="Botswana">Botswana</option>
                                                <option value="Brazil">Brazil</option>
                                                <option value="Brunei">Brunei</option>
                                                <option value="Bulgaria">Bulgaria</option>
                                                <option value="Burkina Faso">Burkina Faso</option>
                                                <option value="Burundi">Burundi</option>
                                                <option value="Cabo Verde">Cabo Verde</option>
                                                <option value="Cambodia">Cambodia</option>
                                                <option value="Cameroon">Cameroon</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Central African Republic">Central African Republic</option>
                                                <option value="Chad">Chad</option>
                                                <option value="Chile">Chile</option>
                                                <option value="China">China</option>
                                                <option value="Colombia">Colombia</option>
                                                <option value="Comoros">Comoros</option>
                                                <option value="Congo (Congo-Brazzaville)">Congo (Congo-Brazzaville)</option>
                                                <option value="Congo (Democratic Republic)">Congo (Democratic Republic)</option>
                                                <option value="Costa Rica">Costa Rica</option>
                                                <option value="Croatia">Croatia</option>
                                                <option value="Cuba">Cuba</option>
                                                <option value="Cyprus">Cyprus</option>
                                                <option value="Czechia">Czechia</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Djibouti">Djibouti</option>
                                                <option value="Dominica">Dominica</option>
                                                <option value="Dominican Republic">Dominican Republic</option>
                                                <option value="Ecuador">Ecuador</option>
                                                <option value="Egypt">Egypt</option>
                                                <option value="El Salvador">El Salvador</option>
                                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                <option value="Eritrea">Eritrea</option>
                                                <option value="Estonia">Estonia</option>
                                                <option value="Eswatini">Eswatini</option>
                                                <option value="Ethiopia">Ethiopia</option>
                                                <option value="Fiji">Fiji</option>
                                                <option value="Finland">Finland</option>
                                                <option value="France">France</option>
                                                <option value="Gabon">Gabon</option>
                                                <option value="Gambia">Gambia</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Germany">Germany</option>
                                                <option value="Ghana">Ghana</option>
                                                <option value="Greece">Greece</option>
                                                <option value="Grenada">Grenada</option>
                                                <option value="Guatemala">Guatemala</option>
                                                <option value="Guinea">Guinea</option>
                                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                <option value="Guyana">Guyana</option>
                                                <option value="Haiti">Haiti</option>
                                                <option value="Honduras">Honduras</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Iceland">Iceland</option>
                                                <option value="India">India</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Iran">Iran</option>
                                                <option value="Iraq">Iraq</option>
                                                <option value="Ireland">Ireland</option>
                                                <option value="Israel">Israel</option>
                                                <option value="Italy">Italy</option>
                                                <option value="Jamaica">Jamaica</option>
                                                <option value="Japan">Japan</option>
                                                <option value="Jordan">Jordan</option>
                                                <option value="Kazakhstan">Kazakhstan</option>
                                                <option value="Kenya">Kenya</option>
                                                <option value="Kiribati">Kiribati</option>
                                                <option value="Kuwait">Kuwait</option>
                                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                <option value="Laos">Laos</option>
                                                <option value="Latvia">Latvia</option>
                                                <option value="Lebanon">Lebanon</option>
                                                <option value="Lesotho">Lesotho</option>
                                                <option value="Liberia">Liberia</option>
                                                <option value="Libya">Libya</option>
                                                <option value="Liechtenstein">Liechtenstein</option>
                                                <option value="Lithuania">Lithuania</option>
                                                <option value="Luxembourg">Luxembourg</option>
                                                <option value="Madagascar">Madagascar</option>
                                                <option value="Malawi">Malawi</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Maldives">Maldives</option>
                                                <option value="Mali">Mali</option>
                                                <option value="Malta">Malta</option>
                                                <option value="Marshall Islands">Marshall Islands</option>
                                                <option value="Mauritania">Mauritania</option>
                                                <option value="Mauritius">Mauritius</option>
                                                <option value="Mexico">Mexico</option>
                                                <option value="Micronesia">Micronesia</option>
                                                <option value="Moldova">Moldova</option>
                                                <option value="Monaco">Monaco</option>
                                                <option value="Mongolia">Mongolia</option>
                                                <option value="Montenegro">Montenegro</option>
                                                <option value="Morocco">Morocco</option>
                                                <option value="Mozambique">Mozambique</option>
                                                <option value="Myanmar">Myanmar</option>
                                                <option value="Namibia">Namibia</option>
                                                <option value="Nauru">Nauru</option>
                                                <option value="Nepal">Nepal</option>
                                                <option value="Netherlands">Netherlands</option>
                                                <option value="New Zealand">New Zealand</option>
                                                <option value="Nicaragua">Nicaragua</option>
                                                <option value="Niger">Niger</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="North Korea">North Korea</option>
                                                <option value="North Macedonia">North Macedonia</option>
                                                <option value="Norway">Norway</option>
                                                <option value="Oman">Oman</option>
                                                <option value="Pakistan">Pakistan</option>
                                                <option value="Palau">Palau</option>
                                                <option value="Panama">Panama</option>
                                                <option value="Papua New Guinea">Papua New Guinea</option>
                                                <option value="Paraguay">Paraguay</option>
                                                <option value="Peru">Peru</option>
                                                <option value="Philippines">Philippines</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Portugal">Portugal</option>
                                                <option value="Qatar">Qatar</option>
                                                <option value="Romania">Romania</option>
                                                <option value="Russia">Russia</option>
                                                <option value="Rwanda">Rwanda</option>
                                                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                <option value="Saint Lucia">Saint Lucia</option>
                                                <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                <option value="Samoa">Samoa</option>
                                                <option value="San Marino">San Marino</option>
                                                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                <option value="Saudi Arabia">Saudi Arabia</option>
                                                <option value="Senegal">Senegal</option>
                                                <option value="Serbia">Serbia</option>
                                                <option value="Seychelles">Seychelles</option>
                                                <option value="Sierra Leone">Sierra Leone</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Slovakia">Slovakia</option>
                                                <option value="Slovenia">Slovenia</option>
                                                <option value="Solomon Islands">Solomon Islands</option>
                                                <option value="Somalia">Somalia</option>
                                                <option value="South Africa">South Africa</option>
                                                <option value="South Korea">South Korea</option>
                                                <option value="South Sudan">South Sudan</option>
                                                <option value="Spain">Spain</option>
                                                <option value="Sri Lanka">Sri Lanka</option>
                                                <option value="Sudan">Sudan</option>
                                                <option value="Suriname">Suriname</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Syria">Syria</option>
                                                <option value="Taiwan">Taiwan</option>
                                                <option value="Tajikistan">Tajikistan</option>
                                                <option value="Tanzania">Tanzania</option>
                                                <option value="Thailand">Thailand</option>
                                                <option value="Togo">Togo</option>
                                                <option value="Tonga">Tonga</option>
                                                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                <option value="Tunisia">Tunisia</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="Turkmenistan">Turkmenistan</option>
                                                <option value="Tuvalu">Tuvalu</option>
                                                <option value="Uganda">Uganda</option>
                                                <option value="Ukraine">Ukraine</option>
                                                <option value="United Arab Emirates">United Arab Emirates</option>
                                                <option value="United Kingdom">United Kingdom</option>
                                                <option value="Uruguay">Uruguay</option>
                                                <option value="Uzbekistan">Uzbekistan</option>
                                                <option value="Vanuatu">Vanuatu</option>
                                                <option value="Vatican City">Vatican City</option>
                                                <option value="Venezuela">Venezuela</option>
                                                <option value="Vietnam">Vietnam</option>
                                                <option value="Yemen">Yemen</option>
                                                <option value="Zambia">Zambia</option>
                                                <option value="Zimbabwe">Zimbabwe</option>
                                            </Select>
                                            
                                        </div>
                                        
                                        {/*Industry*/}
                                        <div className={`relative col-span-2`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Industry
                                                <div className={`ml-1`}>
                                                    <div className={`flex-none rounded-full p-1 ${industry ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                        <div className={`size-2 rounded-full ${industry ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                    </div>
                                                </div>
                                            </label>
                                            <Select
                                                required
                                                value={industry}
                                                onChange={(e) => setIndustry(e.target.value)}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            >
                                                <option value="">Select Industry...</option>
                                                <option value="BOTANICAL">Botanical Garden</option>
                                                <option value="DISTRIB">Product Distributor</option>
                                                <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                                <option value="FARM">Agriculture - Farm</option>
                                                <option value="GOLF">Golf Course</option>
                                                <option value="GOVERNMENT">Government Entity</option>
                                                <option value="INDUSTRIAL">Industrial</option>
                                                <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                                <option value="MRSALE">One Time Purchase Expected</option>
                                                <option value="MUNICIPAL">Municipal/ School</option>
                                                <option value="Non-Profit">Non-Profit Account</option>
                                                <option value="OTHER">Other</option>
                                                <option value="SPORTFLD">Sportsfield</option>
                                            </Select>
                                        </div>
                                        
                                        
                                        
                                        <label htmlFor="region" className="col-span-full block text-sm font-medium leading-6">
                                            CSR Section
                                        </label>
                                        
                                        
                                        {/*Customer Code*/}
                                        <div className={`relative col-span-2`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Customer Code
                                            </label>
                                            <Input
                                                type="text"
                                                value={customerCode}
                                                onChange={(e) => setCustomerCode(e.target.value)}
                                                placeholder={`Customer Code`}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            />
                                        </div>
                                        
                                        {/*Price Type*/}
                                        <div className={`relative col-span-2`}>
                                            <label
                                                className={`absolute flex z-10 -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Price Type
                                            </label>
                                            <Select
                                                required
                                                value={priceType}
                                                disabled={accountLevel !== "CSR" && accountLevel !== "D"}
                                                onChange={(e) => setPriceType(e.target.value)}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            >
                                                <option value="">Select Pricing Type...</option>
                                                <option value="AG">Agriculture</option>
                                                <option value="DIST1">Distributor NE/INTL</option>
                                                <option value="DIST2">Distributor SE/CAN</option>
                                                <option value="NE">Northeast</option>
                                                <option value="SE">Southeast</option>
                                            </Select>
                                        </div>
                                        
                                        {/*Customer Type*/}
                                        <div className={`relative col-span-2`}>
                                            <label
                                                className={`absolute flex z-10 -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Customer Type
                                            </label>
                                            <Select
                                                required
                                                value={customerType}
                                                disabled={accountLevel !== "CSR" && accountLevel !== "D"}
                                                onChange={(e) => setCustomerType(e.target.value)}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            >
                                                <option value="">Select Customer Type...</option>
                                                <option value="5">Level 1 [5%]</option>
                                                <option value="10">Level 2 [10%]</option>
                                                <option value="15">Level 3 [15%]</option>
                                                <option value="20">Level 4 [20%]</option>
                                                <option value="25">GLG</option>
                                            </Select>
                                        </div>
                                    
                                    
                                    </div>
                                
                                
                                </div>
                            )}
                        
                        
                        </div>
                        
                        {/*Contacts Done*/}
                        <div className="w-full h-fit">
                        
                        {/*Header*/}
                        <div className="relative mb-3">
                            <div aria-hidden="true" className="absolute inset-0 flex items-center">
                            <div className={`w-full border-t-2 ${darkMode ? 'border-neutral-700' : ''}`} />
                            </div>
                            <div className="relative flex items-center justify-between">
                                <span
                                    className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Contacts</span>
                                <Button
                                    type="button"
                                    onClick={toggleContacts}
                                    className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                >
                                    {contactExpanded ? (
                                        <>
                                        <XMarkIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                        <span>Collapse</span>
                                    </>
                                ) : (
                                    <>
                                        <PlusIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                        <span>Expand</span>
                                    </>
                                )}
                            </Button>
                          </div>
                        </div>


                        {contactExpanded && (
                            <div className={`w-full animate-slideInLeft flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                {/*Fields Grid*/}
                                <div className="mx-1 mt-3 grid grid-cols-6 gap-x-3 gap-y-6">
                                    
                                    {/*Contact Name*/}
                                    <div className={`relative col-span-3`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Contact Name
                                            <div className={`ml-1`}>
                                                <div className={`flex-none rounded-full p-1 ${contact ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                    <div className={`size-2 rounded-full ${contact ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                </div>
                                            </div>
                                        </label>
                                        <Input
                                            type="text"
                                            required
                                            value={contact}
                                            onChange={(e) => setContact(e.target.value)}
                                            placeholder={`Contact Name`}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        />
                                    </div>
                                    
                                    {/*Position*/}
                                    <div className={`relative col-span-3`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Contact Position
                                        </label>
                                        <Input
                                            type="text"
                                            value={contactPosition}
                                            onChange={(e) => setContactPosition(e.target.value)}
                                            placeholder={`Contact Position`}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        />
                                    </div>
                                    
                                    

                                    <label htmlFor="region" className="col-span-full block text-sm font-medium leading-6">
                                        CONTACT METHODS
                                    </label>

                                    
                                    
                                    {/*Contact Phone*/}
                                    <div className={`relative col-span-full`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Contact Phone
                                            <div className={`ml-1`}>
                                                <div className={`flex-none rounded-full p-1 ${contactPhone ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                    <div className={`size-2 rounded-full ${contactPhone ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                </div>
                                            </div>
                                        </label>
                                        <Input
                                            type="phone"
                                            required
                                            value={contactPhone}
                                            onChange={(e) => setContactPhone(e.target.value)}
                                            placeholder={`Contact Phone Number`}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        />
                                    </div>
                                    
                                    {/*Contact Email*/}
                                    <div className={`relative col-span-3`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Contact Email
                                        </label>
                                        <Input
                                            type="email"
                                            value={contactEmail}
                                            onChange={(e) => setContactEmail(e.target.value)}
                                            placeholder={`Contact Email`}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        />
                                    </div>
                                    
                                    {/*Website*/}
                                    <div className={`relative col-span-3`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Website
                                        </label>
                                        <Input
                                            type="text"
                                            value={website}
                                            onChange={(e) => setWebsite(e.target.value)}
                                            placeholder={`Website`}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        />
                                    </div>
                                    
                                    

                                    <label htmlFor="region" className="col-span-full block text-sm font-medium leading-6">
                                        BILLING CONTACTS
                                    </label>

                                    
                                    
                                    {/*Billing Name*/}
                                    <div className={`relative col-span-full`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Billing Contact
                                            <div className={`ml-1`}>
                                                <div className={`flex-none rounded-full p-1 ${billingContact ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                    <div className={`size-2 rounded-full ${billingContact ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                </div>
                                            </div>
                                        </label>
                                        <Input
                                            type="text"
                                            required
                                            value={billingContact}
                                            onChange={(e) => setBillingContact(e.target.value)}
                                            placeholder={`Billing Contact Name`}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        />
                                    </div>
                                    
                                    {/*Billing Phone*/}
                                    <div className={`relative col-span-full`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Billing Phone
                                            <div className={`ml-1`}>
                                                <div className={`flex-none rounded-full p-1 ${billingPhone ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                    <div className={`size-2 rounded-full ${billingPhone ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                </div>
                                            </div>
                                        </label>
                                        <Input
                                            type="phone"
                                            required
                                            value={billingPhone}
                                            onChange={(e) => setBillingPhone(e.target.value)}
                                            placeholder={`Billing Phone Number`}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        />
                                    </div>
                                    
                                    
                                    
                                    <label htmlFor="region" className=" col-span-full block text-sm font-medium leading-6">
                                        INVOICE AND STATEMENTS
                                    </label>
                                    
                                    
                                    
                                    {/*Invoice Email*/}
                                    <div className={`relative col-span-full`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Invoice Email
                                            <div className={`ml-1`}>
                                                <div className={`flex-none rounded-full p-1 ${invoiceEmails ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                    <div className={`size-2 rounded-full ${invoiceEmails ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                </div>
                                            </div>
                                        </label>
                                        <Input
                                            type="text"
                                            required
                                            value={invoiceEmails}
                                            onChange={(e) => setInvoiceEmails(e.target.value)}
                                            placeholder={`Invoices Email Address`}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        />
                                    </div>
                                    
                                    {/*Statement Email*/}
                                    <div className={`relative col-span-full`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Statement Email
                                            <div className={`ml-1`}>
                                                <div className={`flex-none rounded-full p-1 ${statementEmails ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                    <div className={`size-2 rounded-full ${statementEmails ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                </div>
                                            </div>
                                        </label>
                                        <Input
                                            type="text"
                                            required
                                            value={statementEmails}
                                            onChange={(e) => setStatementEmails(e.target.value)}
                                            placeholder={`Statements Email Address`}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        />
                                    </div>
                                    
                                    
                                </div>


                            </div>
                        )}


                        </div>

                        {/*Shipping*/}
                        <div className="w-full h-fit">

                            {/*Header*/}
                            <div className="relative mb-3">
                              <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                  <div className={`w-full border-t-2 ${darkMode ? 'border-neutral-700' : ''}`}/>
                              </div>
                                <div className="relative flex items-center justify-between">
                                <span className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Shipping [{shippingAddress.length}]</span>
                                <Button
                                  type="button"
                                  onClick={toggleShipping}
                                  className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                >
                                    {shippingExpanded ? (
                                        <>
                                            <XMarkIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                            <span>Collapse</span>
                                        </>
                                    ) : (
                                        <>
                                            <PlusIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                            <span>Expand</span>
                                        </>
                                    )}
                                </Button>
                              </div>
                            </div>


                            {shippingExpanded && (
                                <div className={`w-full animate-slideInRight flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                <div className="text-xs w-full flex items-center mb-0.5 font-semibold">
                                    
                                    <div className={``}>Shipping Locations [{shippingAddress.length}]</div>
                                
                                    <Button
                                        className={`ml-auto flex items-center hover:text-green-600 text-sm`}
                                        onClick={handleAddComponent}
                                    >
                                        Add Location
                                        <PlusCircleIcon className="ml-2 size-6" />
                                    </Button>
                                </div>

                                    {shippingAddress.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center py-10">
                                            <div className={`flex items-center border-2 border-dashed ${
                                                    darkMode ? "text-white border-borderColor" : "text-black bg-neutral-50"
                                                } w-fit px-12 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}
                                            >
                                                <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                    <MapPinIcon className="w-10 stroke-2 mr-3"/>
                                                    <p className="text-lg mt-2 line-clamp-1 tracking-widest flex">This Customer has no Shipping Locations</p>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        shippingAddress.map((item, index) => (
                                            <AddressInput
                                                id={item.id}
                                                key={item.id}
                                                index={index}
                                                
                                                item={item}
                                                
                                                Eaddress1={address1}
                                                Eaddress2={address2}
                                                Ecity={city}
                                                Estate={selectedState}
                                                Ezip={zip}
                                                Ecounty={selectedCounty}
                                                Efips={fips}
                                                
                                                onChange={handleAddressChange}
                                                onRemove={handleRemoveComponent}
                                            />
                                        ))

                                    )}


                                </div>
                            )}


                        </div>

                        {/*Finances Done*/}
                        <div className="w-full h-fit">


                            {/*Header*/}
                            <div className="relative mb-3">
                                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                <div className={`w-full border-t-2 ${darkMode ? 'border-neutral-700' : ''}`} />
                                </div>
                                <div className="relative flex items-center justify-between">
                                    <span
                                        className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Finances</span>
                                    <Button
                                        type="button"
                                        onClick={toggleFinances}
                                        className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                    >
                                        {financesExpanded ? (
                                            <>
                                                <XMarkIcon aria-hidden="true"
                                                           className="-ml-1 -mr-0.5 size-5"/>
                                                <span>Collapse</span>
                                            </>
                                        ) : (
                                            <>
                                                <PlusIcon aria-hidden="true"
                                                          className="-ml-1 -mr-0.5 size-5"/>
                                                <span>Expand</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>


                            {financesExpanded && (
                                <div className={`w-full animate-slideInLeft flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                    {/*Fields Grid*/}
                                        <div className="mt-3 grid grid-cols-1 gap-x-3 gap-y-6">

                                            <label htmlFor="region" className="block text-sm font-medium leading-6 col-span-full">
                                                TAXES
                                            </label>
                                            
                                            
                                            
                                            {/*TaxID*/}
                                            <div className={`relative col-span-full`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Tax ID#
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${taxID ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${taxID ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Input
                                                    type="text"
                                                    required
                                                    value={taxID}
                                                    onChange={(e) => setTaxID(e.target.value)}
                                                    placeholder={`Tax ID#`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            {/*Sales Tax Status*/}
                                            <div className={`relative col-span-full`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Sales Tax Status
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${salesTaxStatus ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${salesTaxStatus ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Input
                                                    type="text"
                                                    required
                                                    value={salesTaxStatus}
                                                    onChange={(e) => setSalesTaxStatus(e.target.value)}
                                                    placeholder={`Sales Tax Status`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            {/*Tax Exemption Code*/}
                                            <div className={`relative col-span-full`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Tax Exemption Code
                                                </label>
                                                <Input
                                                    type="text"
                                                    value={taxExempCode}
                                                    onChange={(e) => setTaxExempCode(e.target.value)}
                                                    placeholder={`Optional`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            
                                            
                                            <label htmlFor="region" className="block text-sm font-medium leading-6 col-span-full">
                                                PAYMENT
                                            </label>
                                            
                                            
                                            {/*Payment Type*/}
                                            <div className={`relative col-span-2`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Payement Type
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${paymentType ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${paymentType ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Select
                                                    required
                                                    value={paymentType}
                                                    onChange={(e) => setPaymentType(e.target.value)}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select a Payment Option...</option>
                                                    <option value="Check">Check</option>
                                                    <option value="ACH">ACH</option>
                                                    <option value="Credit">Credit Card (3% Service Fee)</option>
                                                </Select>
                                            </div>
                                            
                                        </div>
                                        

                                </div>
                            )}


                        </div>

                        {/*Delivery Done*/}
                        <div className="w-full h-fit">


                            <div className="relative mb-3">
                                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                <div className={`w-full border-t-2 ${darkMode ? 'border-neutral-700' : ''}`} />
                                </div>
                                <div className="relative flex items-center justify-between">
                                    <span
                                        className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Delivery</span>
                                    <Button
                                        type="button"
                                        onClick={toggleDelivery}
                                        className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                    >
                                        {deliveryExpanded ? (
                                            <>
                                                <XMarkIcon aria-hidden="true"
                                                           className="-ml-1 -mr-0.5 size-5"/>
                                                <span>Collapse</span>
                                            </>
                                        ) : (
                                            <>
                                                <PlusIcon aria-hidden="true"
                                                          className="-ml-1 -mr-0.5 size-5"/>
                                                <span>Expand</span>
                                            </>
                                        )}
                                </Button>
                              </div>
                            </div>


                            {deliveryExpanded && (
                                <div className={`w-full animate-slideInRight flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                    <div className={`flex flex-col w-full rounded-lg`}>

                                        <div className={`flex flex-row w-full ${darkMode ? 'border-neutral-700' : ''} border-b-2`}>
                                            <div className="w-3/4 p-3 ">Delivery Questions?</div>
                                            <div className="w-1/4 text-center p-3">[Yes] [No]</div>
                                        </div>

                                        <div className="flex w-full flex-row">
                                            <div className="space-y-7 w-3/4 p-3">
                                                <div className={`p-1 border-b ${darkMode ? 'border-neutral-700' : ''}`}>Tractor Trailer Accessible?</div>
                                                <div className={`p-1 border-b ${darkMode ? 'border-neutral-700' : ''}`}>Limited Access Site?</div>
                                                <div className={`p-1 border-b ${darkMode ? 'border-neutral-700' : ''}`}>Loading Dock Available?</div>
                                                <div className={`p-1 border-b ${darkMode ? 'border-neutral-700' : ''}`}>Lift Gate & Pallet Jack Required?</div>
                                                <div className={`p-1 border-b ${darkMode ? 'border-neutral-700' : ''}`}>Delivery Appointment Required?</div>
                                                <div className={`p-1 border-b ${darkMode ? 'border-neutral-700' : ''}`}>Heated Storage Available?</div>
                                                <div className={`p-1 border-b ${darkMode ? 'border-neutral-700' : ''}`}>Forklift Available w/ 3,000 lbs Capacity?</div>
                                                <div className={`p-1 border-b ${darkMode ? 'border-neutral-700' : ''}`}>Residential Property</div>
                                            </div>

                                            <div className={`ml-5 w-1/4 space-y-7 drop-shadow p-3 rounded-lg`}>
                                                
                                                {/*TTA*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={TTA}
                                                      onChange={setTTA}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*LAS*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={LAS}
                                                      onChange={setLAS}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*LDA*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={LDA}
                                                      onChange={setLDA}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*PJR*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={PJR}
                                                      onChange={setPJR}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*DAR*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={DAR}
                                                      onChange={setDAR}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*HSA*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={HSA}
                                                      onChange={setHSA}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*FLC*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={FLC}
                                                      onChange={setFLC}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*RPY*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={RPY}
                                                      onChange={setRPY}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                           
                                           
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            )}


                        </div>
                        
                    </div>
                );
                
            case 'Contract Pricing':
                return (
                    <div className={`flex h-full flex-col space-y-12`}>
                        {/*Contract Pricing*/}
                        <div className={`w-full h-full animate-slideInLeft space-y-2`}>
                            
                            <div className={`w-full h-full flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>
                                
                                {/*PRODUCT SEARCH BAR*/}
                                <div className="w-full flex flex-row relative">
                                    
                                    {/*Search Bar*/}
                                    <div className={`${darkMode ? 'bg-darkMainColor border-2 border-neutral-700' : 'bg-white border-2'} w-full rounded-md flex items-center justify-center hover:scale-y-110 transition-all duration-500 ease-in-out`}>
                                        <MagnifyingGlassIcon className="text-gray-500 stroke-2 h-5 mr-6 ml-6"/>
                                        <Input
                                            className={`${darkMode ? 'bg-darkMainColor text-white' : 'bg-white'} text-sm transition-all duration-500 ease-in-out rounded-md p-1 w-full focus:outline-none`}
                                            placeholder={`Search Contract Prices`}
                                            disabled={true}
                                            // value={searchTerm}
                                            // onChange={handleSearch}
                                            // onFocus={() => setShowProducts(true)}
                                            // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                        />
                                    </div>
                                    
                                    <Button
                                        className={`bg-green-600 hover:bg-opacity-50 text-white px-4 ml-3 rounded-lg`}
                                        onClick={toggleContract}
                                        disabled={disabled}
                                        // onClick={handleSaveAllContractPrices}
                                    >
                                        Add
                                    </Button>
                                
                                </div>
                                
                                <div className={`w-full h-full overflow-y-scroll scrollbar-hide`}>
                                    
                                    {contracts.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center">
                                            
                                            <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-24 py-2 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                <div className="p-1 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                    <SquaresPlusIcon className={`w-8 stroke-1 mr-3`}/>
                                                    <p className="text-lg mt-2 line-clamp-1 tracking-widest flex">No Contract Pricing</p>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        contracts.map((product, index) => (
                                            <div className={`h-12 flex flex-row px-3 border-2 ${darkMode ? 'border-neutral-700' : ''} mt-2 rounded-lg`}>
                                            
                                                {/*PRODCUT NAME*/}
                                                <div className="flex flex-row items-center w-1/3 mr-3">
                                                    {/*Name and Product Code*/}
                                                    <div className="flex flex-col mt-auto mb-auto w-2/3">
                                                        {/*Scrollable Name by Hover*/}
                                                        <div ref={(container) => {
                                                            if (container) {
                                                                const textElement = container.querySelector('.scrollable-text');
                                                                if (textElement) {
                                                                    container.classList.toggle(
                                                                        'hover-enabled',
                                                                        textElement.scrollWidth > container.offsetWidth
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                             className={`relative overflow-hidden ${
                                                                 darkMode ? 'text-white' : 'text-black'
                                                             } text-sm font-medium w-full`}
                                                        >
                                                            <div className="scrollable-text inline-block whitespace-nowrap transition-transform duration-1000 ease-linear"
                                                                 style={{
                                                                     transform: 'translateX(0)',
                                                                 }}
                                                                 onMouseEnter={(e) => {
                                                                     const container = e.currentTarget.parentElement;
                                                                     if (container.classList.contains('hover-enabled')) {
                                                                         e.currentTarget.style.transform = 'translateX(-50%)';
                                                                     }
                                                                 }}
                                                                 onMouseLeave={(e) => {
                                                                     e.currentTarget.style.transform = 'translateX(0)';
                                                                 }}
                                                            >
                                                                {product.name}
                                                            </div>
                                                        </div>
                                                        <div className={`ml-3 text-xxs ${darkMode ? 'text-borderColor' : 'text-borderColor'}`}>{product.productCode}</div>
                                                    </div>
                                    
                                                    {/*Percent Toggle*/}
                                                    <Field className="flex flex-col items-center w-1/3">
                                                        <Switch
                                                            checked={product.percent}
                                                            // onChange={setPercent}
                                                            className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                        >
                                                            <span className="sr-only">Use setting</span>
                                                            <span aria-hidden="true" className="pointer-events-none absolute size-full rounded-md" />
                                                            <span
                                                                aria-hidden="true"
                                                                className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                            />
                                                            <span
                                                                aria-hidden="true"
                                                                className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                            />
                                                        </Switch>
                                                        <Label as="span" className="mt-1 text-xxs">
                                    
                                                            {product.percent ? (
                                                                <span className="font-medium line-clamp-1">Percentage Off</span>
                                                            ) : (
                                                                <span className="font-medium line-clamp-1">Custom Price</span>
                                                            )}
                                    
                                                        </Label>
                                                    </Field>
                                                    
                                                </div>
                                    
                                                {/*PRODUCT PRICING/QUANT*/}
                                                <div className="flex flex-row w-2/3">
                                    
                                                    {/*PRICE*/}
                                                    <div className={`relative w-1/2 p-1.5`}>
                                                        <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} relative flex flex-row px-4 rounded-lg h-full items-center justify-center`}>
                                                            <div className="text-borderColor text-md mr-1">$</div>
                                                            <div className={`${darkMode ? 'text-white' : 'text-black'} rounded-md text-sm p-1.5 w-full tracking-wider line-clamp-1`}>
                                                                {parseFloat(product.price).toFixed(2)}
                                                            </div>
                                                            <div className="text-borderColor text-sm">USD</div>
                                                        </div>
                                                    </div>
                                    
                                                    <div className="flex flex-row px-1 h-full items-center justify-center">
                                                        <ArrowRightIcon className={`text-borderColor w-4 stroke-2`}/>
                                                    </div>
                                    
                                                    {/*New Price*/}
                                                    <div className={`relative w-1/2 p-1.5 mr-3`}>
                                                        <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} flex flex-row px-4 rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                                                            {/*// When newValue changes, trigger the onChange for recalculation*/}
                                                            <div className="text-borderColor text-sm">{}*</div>
                                    
                                                            <Input
                                                                className={`rounded-md bg-transparent text-sm focus:outline-none p-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                                                type="number"
                                                                disabled={true}
                                                                // placeholder={unitLabel}
                                                                value={product.newValue}
                                                                // onChange={handleNewValueChange}
                                                            />
                                    
                                                            {/*// When price changes (in your select or other logic), ensure price updates are sent to parent.*/}
                                                        </div>
                                                    </div>
                                    
                                                    {/*U/M*/}
                                                    <div className={`relative w-1/4 p-1.5`}>
                                                        <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} line-clamp-1 overflow-x-hidden flex flex-row text-xs rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                                                            {UMMapping[product.selectedVolume]}
                                                        </div>
                                                    </div>
                                    
                                                </div>
                                    
                                                {/*PRODUCT TOTAL*/}
                                                <div className="flex flex-row w-fit justify-end align-middle items-center">

                                                    <div className="ml-auto w-full flex flex-row justify-end">
                                    
                                                        <Button className="rounded-full ml-3 h-8 w-8 items-center justify-center align-middle"
                                                                onClick={() => removeContractPrice(product.productCode)}
                                                        >
                                                            <XMarkIcon className="h-6 text-borderColor hover:text-red-600 hover:animate-pulse hover:scale-110 m-auto"/>
                                                        </Button>
                                                    </div>
                                    
                                    
                                                </div>
                                                
                                            </div>
                                        ))
                                    )}
                                
                                </div>
                            
                            </div>
                        
                        
                        </div>
                        
                        
                        
                        
                        
                        <Dialog open={contract} onClose={setContract} className="relative z-50">
                          <DialogBackdrop
                            transition
                            className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                          />
                    
                          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                              <DialogPanel
                                transition
                                className={`relative h-96 transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                              >
                                <div className="sm:flex sm:items-start">
                                  <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                    <DocumentCurrencyDollarIcon aria-hidden="true" className="size-6 text-green-600" />
                                  </div>
                                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                      <DialogTitle as="h3" className="text-base font-semibold">
                                        Add Contract Price {name}
                                      </DialogTitle>
                                      <div className="mt-2">
                                          <p className="text-sm text-gray-500">
                                              This is a special pricing specific to that customer for a certain product only.
                                          </p>
                                      </div>
                                      
                                      {/*Body*/}
                                      <div className="mt-2 grid grid-cols-2 gap-3">
                                          {/*Product Select*/}
                                          <div className={`col-span-full z-30`}>
                                              <Combobox
                                                  as="div"
                                                  value={selectedProduct}
                                                  onChange={handleSelectChange} // Use the updated handler
                                              >
                                                  <div className="relative">
                                                      <label
                                                          htmlFor=""
                                                          className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                      >
                                                          Product
                                                      </label>
                                                      
                                                      <ComboboxInput
                                                          className={`w-full rounded-md transition-all duration-500 ease-in-out outline ${darkMode ? 'bg-darkMainColor outline-gray-500' : 'bg-white outline-gray-300'} px-3 py-2 pl-3 pr-12 sm:text-sm/6`}
                                                          onChange={(event) => setSearchTerm(event.target.value)}
                                                          onBlur={() => setSearchTerm('')}
                                                          placeholder={`Select a product from ${filteredProducts.length} available products`}
                                                          displayValue={(customer) => customer?.name || ""}
                                                      />
                                                      <ComboboxButton
                                                          className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                          <ChevronUpDownIcon className="size-5 text-gray-400"
                                                                             aria-hidden="true"/>
                                                      </ComboboxButton>
                                                      
                                                      <ComboboxOptions
                                                          className={`absolute z-10 mt-1 max-h-96 animate-fadeIn w-full overflow-auto rounded-lg ${darkMode ? 'bg-neutral-800' : 'bg-white'} py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm`}>
                                                          {Array.isArray(filteredProducts) && filteredProducts.length > 0 ? (
                                                              filteredProducts.map((product) => (
                                                                  <ComboboxOption
                                                                      key={product.id}
                                                                      value={product} // Pass the whole customer object as the value
                                                                      className={`group relative rounded-full cursor-default select-none py-2 pl-3 pr-9 data-[focus]:outline-none ${
                                                                          darkMode
                                                                              ? 'data-[focus]:bg-darkMainColor data-[focus]:bg-opacity-50'
                                                                              : 'data-[focus]:bg-neutral-200'
                                                                      }`}
                                                                  >
                                                                      <div className="flex items-center">
                                                                          <div className={`rounded-full py-0.5 px-2 ${darkMode ? 'bg-neutral-600' : 'bg-bgColor'}`}>
                                                                              xx
                                                                          </div>
                                                                          <span className="ml-3 truncate group-data-[selected]:font-semibold">{product.name}</span>
                                                                      </div>
                                                                      
                                                                      <span
                                                                          className="absolute inset-y-0 right-0 hidden items-center pr-4 text-green-600-600 group-data-[selected]:flex group-data-[focus]:text-white">
                                                                        <CheckIcon className="size-5"
                                                                                   aria-hidden="true"/>
                                                                    </span>
                                                                  </ComboboxOption>
                                                              ))
                                                          ) : (
                                                              <div
                                                                  className={`flex items-center justify-center flex-col p-3`}>
                                                                  <EyeSlashIcon
                                                                      className={`w-6 stroke-2 text-gray-500`}/>
                                                                  <div className="px-4 py-2 text-sm text-gray-500">
                                                                      No customer found for <span
                                                                      className={`font-bold ${darkMode ? 'text-white' : 'text-black'}`}>'{searchTerm}'</span>.
                                                                      Please try again.
                                                                  </div>
                                                              </div>
                                                          )}
                                                      </ComboboxOptions>
                                                  </div>
                                              </Combobox>
                                          </div>
                                          
                                          
                                          {/*Name*/}
                                          <div className={`relative col-span-1`}>
                                              <label
                                                  htmlFor="name"
                                                  className={`absolute z-20 -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                              >
                                                  Name
                                              </label>
                                              <Input
                                                  id="name"
                                                  name="name"
                                                  type="text"
                                                  value={name}
                                                  placeholder={`Product Name`}
                                                  disabled={true}
                                                  className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                              />
                                          </div>
                                          
                                          {/*Code*/}
                                          <div className={`relative col-span-1`}>
                                              <label
                                                  htmlFor="code"
                                                  className={`absolute z-20 -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                              >
                                                  Code
                                              </label>
                                              <Input
                                                  id="code"
                                                  name="code"
                                                  type="text"
                                                  value={productCode}
                                                  placeholder={`Product Code`}
                                                  disabled={true}
                                                  className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                              />
                                          </div>
                                          
                                          
                                          {/*UM*/}
                                          <div className={`relative col-span-1`}>
                                              <label
                                                  htmlFor="um"
                                                  className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                              >
                                                  U/M
                                              </label>
                                              <Select
                                                  id="um"
                                                  name="um"
                                                  value={selectedVolume}
                                                  onChange={handleVolumeChange}
                                                  placeholder={`Product Code`}
                                                  className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                              >
                                                  {prices.map((price, index) => {
                                                      const label = UMMapping[price.um]; // Get label from mapping object
                                                      return label ? (
                                                          <option key={index} value={price.um}>
                                                              {label}
                                                          </option>
                                                      ) : null; // Exclude options with no matching label
                                                  })}
                                              </Select>
                                          </div>
                                          
                                          {/*Percent*/}
                                          <div className={`flex items-centercol-span-1`}>
                                          
                                              <Field className="flex items-center">
                                                  <Switch
                                                        checked={percent}
                                                        onChange={setPercent}
                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                  >
                                                        <span className="sr-only">Use setting</span>
                                                        <span aria-hidden="true" className="pointer-events-none absolute size-full rounded-md" />
                                                        <span
                                                            aria-hidden="true"
                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                        />
                                                        <span
                                                            aria-hidden="true"
                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                        />
                                                  </Switch>
                                                  <Label as="span" className="ml-3 text-sm">
                                                    <span className="font-medium">{unitLabel}</span>{' '}
                                                    <span className="text-gray-500">({percentLabel})</span>
                                                  </Label>
                                              </Field>
                                              
                                          </div>
                                          
                                          
                                          {/*Current Price*/}
                                          <div className={`relative col-span-1`}>
                                              <label
                                                  htmlFor="cp"
                                                  className={`absolute z-20 -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                              >
                                                  Current Price
                                              </label>
                                              <Input
                                                  id="cp"
                                                  name="cp"
                                                  type="text"
                                                  value={price}
                                                  placeholder={`Current Price`}
                                                  disabled={true}
                                                  className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                              />
                                          </div>
                                          
                                          {/*New Price*/}
                                          <div className={`relative col-span-1`}>
                                              <label
                                                  htmlFor="np"
                                                  className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                              >
                                                  New Price
                                              </label>
                                              <Input
                                                  id="np"
                                                  name="np"
                                                  type="text"
                                                  value={newValue}
                                                  onChange={(e) => setNewValue(e.target.value)}
                                                  placeholder={unitLabel}
                                                  className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                              />
                                          </div>
                                      
                                      </div>
                                  
                                  </div>
                                </div>
                                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                      <Button
                                          type="button"
                                          onClick={() => addContractPrice()}
                                          className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                                      >
                                          Save
                                      </Button>
                                      <Button
                                          type="button"
                                          data-autofocus
                                          onClick={() => clear()}
                                          className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                      >
                                          Clear
                                      </Button>
                                  </div>
                              </DialogPanel>
                            </div>
                          </div>
                        </Dialog>
                    
                    
                    </div>
                );
            
            case 'Active Orders':
                return (
                    <div>
                        
                        <div className={`h-12 mb-3 border-2 rounded-lg p-0.5 ${darkMode ? 'border-neutral-700' : ''}`}>
                            <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                                <div className="font-semibold text-sm mr-1 line-clamp-1">Active Orders Total:</div>
                                <div className="text-sm rounded-md bg-orderColor bg-opacity-20 border-2 border-orderColor px-2 line-clamp-1">$ {numberWithCommas(grandTotal.toFixed(2))}</div>
                                <div className="text-xs rounded-md bg-orderColor bg-opacity-20 border-2 border-orderColor px-1 ml-1 line-clamp-1">{orders.length}</div>
                            </div>
                        </div>
                        
                        <div className={`border-2 h-[calc(100dvh-17.4rem)] overflow-y-scroll scrollbar-hide rounded-lg p-0.5 ${darkMode ? 'border-neutral-700' : ''}`}>
                            <OrderSection
                                orders={filteredOrders}
                                accountView={true}
                                emptyTitle={"No Active Orders"}
                                emptyDescription={"Attached to this customers account are no active orders"}
                                darkMode={darkMode}
                            />
                        </div>
                    </div>
                );
                
            case 'Test Results':
                return (
                    <div>
                        <h2>Test Results</h2>
                        <p>Track all the orders currently in progress.</p>
                        {/* Add large content for Current Orders here */}
                    </div>
                );
            
            default:
                return null;
        }
    };
    
    const onUpdateMain = async (customerId) => {
    
        console.log("Customer ID", customerId);
        console.log("Sales ID", customer.salesID);
        
        if (!customerId) {
            console.error("No customer ID provided for update");
            return;
        }
    
        const customerDocRef = db
            .collection("Customers")
            .doc(customer.salesID)
            .collection("clients")
            .doc(customerId);
    
        const updatedFormData = {
            // Main
            name: CustName,
            managementName: managementName,
            customerCode: customerCode,
            address1: address1,
            address2: address2,
            city: city,
            state: selectedState,
            zip: zip,
            county: selectedCounty,
            fips: fips,
            country: country,
            industry: industry,
            
            priceType: priceType,
            customerType: customerType,
    
            // Contacts
            contact: contact,
            contactPosition: contactPosition,
            contactPhone: contactPhone,
            contactEmail: contactEmail,
            billingContact: billingContact,
            billingPhone: billingPhone,
            invoiceEmails: invoiceEmails,
            statementEmails: statementEmails,
            website: website,
            
            // Shipping
            ShippingAddresses: shippingAddress,
    
            // Finances
            taxID: taxID,
            salesTaxStatus: salesTaxStatus,
            taxExempCode: taxExempCode,
            paymentType: paymentType,
    
            // Delivery
            TTA: TTA,
            LAS: LAS,
            LDA: LDA,
            PJR: PJR,
            DAR: DAR,
            HSA: HSA,
            FLC: FLC,
            RPY: RPY,
            // deliveryInstructions: deliveryInstructions,
        };
    
        try {
            await customerDocRef.update(updatedFormData);
            if (alertsOn) {
                alert(`Customer data updated successfully`);
            }
            onUpdate();
            setShowFireworks(true)
            setTimeout(() => setShowFireworks(false), 5000) // Hide fireworks after 3 seconds
        } catch (error) {
            console.error("Error updating customer:", error.message);
        }
    };
    
    // const address = '38 Hightstown-Cranbury Station Rd, Cranbury, NJ 08512'; // Example address
    const address = `${customer.address1}, ${customer.city}, ${customer.state} ${customer.zip}`;
    
    
    const [isAdding, setIsAdding] = useState(false);
    
    const toggleAdding = () => {
        setIsAdding(!isAdding);
    }
    
    const [type] = useState("");
    const [creditLimit, setCreditLimit] = useState("");
    const [priceCode, setPriceCode] = useState("");
    
    const moveToActive = async (customerId) => {
        console.log("Sales ID:", salesID);
        console.log("Customer ID:", customerId);
        
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${salesID}/pending`, customerId);

            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the pending collection");
            }

            // Get the customer data and update it
            const customerData = customerSnapshot.data();
            const updatedCustomerData = {
                ...customerData,
                customerCode,
                type,
                creditLimit,
                priceType: priceCode,
                customerType: customerType,
                status: "active" // Set status as "active" when moving
            };

            // Reference to the destination document in the "clients" collection
            const activeDocRef = doc(db, `Customers/${salesID}/clients`, customerId);

            // Update the document in "pending" before moving
            await updateDoc(customerDocRef, updatedCustomerData);

            // Move the document to "clients" collection
            await setDoc(activeDocRef, updatedCustomerData);

            // Delete the document from "pending" after moving
            await deleteDoc(customerDocRef);

            console.log("Customer moved to clients collection and status updated to active.");
            if (alertsOn) {
                alert("Customer successfully updated and moved to Active Status.");
            }

            // Generate formatted date and time
            const now = new Date();
            const options = {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            };
            const formattedDateTime = now.toLocaleString('en-US', options);

            // Add a notification document to the Notifications collection
            const notificationsCollectionRef = collection(db, `Users/${salesID}/Notifications`);
            const notificationData = {
                type: "C", // Notification type
                identifier: customerId,
                createdAt: formattedDateTime, // Formatted date and time
            };

            await addDoc(notificationsCollectionRef, notificationData);
            console.log("Notification document added successfully.");

            setIsAdding(false);

            setShowFireworks(true)
            setTimeout(() => setShowFireworks(false), 5000) // Hide fireworks after 3 seconds

            onUpdate();

        } catch (error) {
            console.error("Error processing customer update:", error);
            if (alertsOn) {
                alert("Error updating customer data.");
            }
        }
    };

    
    return (
        <div className={`h-full overflow-y-scroll ${darkMode ? 'text-white' : 'text-black'}`}>
            
            {showFireworks && <FireworksEffect show={showFireworks} />}
            
            {/*Grid*/}
            <div className="grid grid-cols-3 grid-rows-4 gap-3 h-full">
                
                {/*Account Information*/}
                <div className={`col-start-1 overflow-hidden relative rounded-lg border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} animate-slideInLeft`}>
                   
                    <div className={`top-1.5 right-1.5 absolute text-center text-xs tracking-widest line-clamp-1 bg-green-600/30 border-green-600 border rounded-md px-2`}>{customer.customerCode}</div>
                    <div className={`top-1.5 left-1.5 absolute text-center text-xxs tracking-widest line-clamp-1 bg-green-600/30 border-green-600 border rounded-md px-2`}>{customer.priceType}</div>
                    <div className={`bottom-1.5 right-1.5 absolute text-center text-nano tracking-widest line-clamp-1`}>ID - {customer.id}</div>
                    
                    
                    {/*Grid*/}
                    <div className={`grid grid-cols-3 w-full h-full`}>
                        {/*Logo*/}
                        <div className={`col-span-1 flex items-center justify-center`}>
                            <div className={`hover:animate-heartbeat ${darkMode ? 'bg-darkBgColor border border-neutral-600' : 'bg-neutral-100 border'} rounded-full p-6 size-24 flex items-center justify-center`}>
                                <img
                                    alt=""
                                    src={logo}
                                    className="flex-none"
                                />
                            </div>
                        </div>
                        
                        {/*Info*/}
                        <div className={`col-span-2 space-y-1 w-full flex flex-col justify-center items-center`}>
                            <div className={`tracking-widest w-full text-lg`}>{customer.name}</div>
                            <div className={`indent-4 w-full text-xs`}>Contact - <span className={`text-gray-500`}>{customer.contact}</span> </div>
                            <div className={`indent-4 w-full text-xs`}>State - <span className={`text-gray-500`}>{stateMapping[customer.state]}</span> </div>
                            <div className={`indent-4 w-full text-xs`}>Industry - <span className={`text-gray-500`}>{customer.industry}</span> </div>
                        </div>
                    </div>
                    
                </div>
                
                {/*Account Totals*/}
                <div className={`row-span-3 h-full relative col-start-1 row-start-2 rounded-lg border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} animate-slideInBottom`}>
                    
                    {/*Totals*/}
                    <div className={`flex flex-col`}>
                        <div className={`p-3 flex flex-row items-center mb-6`}>
                            <div className={`px-4 py-1 rounded-lg w-3/4 font-bold text-sm tracking-widest`}>Active Orders</div>
                            <div className={`px-4 text-center rounded-lg w-1/4 bg-orderColor bg-opacity-20 border-2 border-orderColor`}>{filteredOrders.length}</div>
                        </div>
                        
                        <div className={`p-3 flex flex-row items-center mb-6`}>
                            <div className={`px-4 py-1 rounded-lg w-3/4 font-bold text-sm tracking-widest`}>Pending Orders</div>
                            <div className={`px-4 text-center rounded-lg w-1/4 bg-pendingColor bg-opacity-20 border-2 border-pendingColor`}>{pendingLength}</div>
                        </div>
                        
                        <div className={`p-3 flex flex-row items-center`}>
                            <div className={`px-4 py-1 rounded-lg w-3/4 font-bold text-sm tracking-widest`}>Planned Orders</div>
                            <div className={`px-4 text-center rounded-lg w-1/4 bg-plannedColor bg-opacity-20 border-2 border-plannedColor`}>{plannedLength}</div>
                        </div>
                        
                    </div>
                    
                    {/*Map*/}
                    <div className={`flex border ${darkMode ? 'border-neutral-700' : ''} h-[calc(100%-14.2rem)] m-2 rounded-lg overflow-y-scroll flex-col`}>
                        {/*{address}*/}
                        <SmallMap key={address} address={address}/>
                        
                    </div>
                    
                </div>
                
                {/*Tab View Layout*/}
                <div className={`col-span-2 relative row-span-4 col-start-2 rounded-lg border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} animate-slideInRight`}>
                
                    {/*Tabs*/}
                    <div className={`w-full h-12 mb-2 border-b-2 flex px-2 items-center p-1 ${darkMode ? 'border-neutral-700' : ''} flex overflow-y-scroll transition-all duration-500 ease-in-out`}>
                        
                        <nav aria-label="Tabs" className="-mb-px flex items-center space-x-2">
                            {tabs.map((tab) => (
                                <Button
                                    key={tab.name}
                                    onClick={() => setActiveTab(tab.name)}
                                    className={`flex whitespace-nowrap border-b-2 px-2 py-1 text-xs rounded-t-lg font-medium ${
                                        activeTab === tab.name
                                            ? 'border-green-600 animate-fadeIn bg-green-600/30 text-green-600'
                                            : 'border-transparent hover:bg-green-600 hover:bg-opacity-30 hover:border-b hover:border-green-600 hover:text-green-600'
                                    }`}
                                >
                                    {tab.name}
                                    {tab.count ? (
                                        <span
                                            className={`ml-3 hidden rounded-lg px-1.5 text-xs font-medium md:inline-block ${
                                                activeTab === tab.name
                                                    ? 'bg-emerald-700 bg-opacity-50 border border-green-600'
                                                    : 'bg-green-600 bg-opacity-50 border border-green-600'
                                            }`}
                                        >
                                            <span className={`${darkMode ? 'text-white' : 'text-black'}`}>{tab.count}</span>
                                        </span>
                                    ) : null}
                                </Button>
                            ))}
                        </nav>
                    
                        {/*Buttons*/}
                        <div className={`flex ml-auto h-fit items-center gap-x-3`}>
                            <Button
                                className={`flex text-sm hover:text-opacity-50 hover:scale-105 tracking-widest py-1 text-gray-500 rounded-full`}
                                onClick={expandAll}
                            >
                                <ArrowsPointingOutIcon className={`w-5`}/>
                            </Button>
                            
                            <Button
                                className={` flex text-sm hover:text-opacity-50 hover:scale-105 tracking-widest py-1 text-gray-500 rounded-full`}
                                onClick={collapseAll}
                            >
                                <ArrowsPointingInIcon className={`w-5`}/>
                            </Button>
                            
                            {customer.status === "pending" && (
                                <div>

                                    <div>
                                        
                                        <Button
                                            className={`w-fit px-2 py-1 ${disabled ? '' : 'hover:scale-105 hover:bg-opacity-50'} bg-green-700 text-white line-clamp-1 rounded-lg shadow-lg text-xs flex flex-row items-center justify-center`}
                                            onClick={toggleAdding}
                                            disabled={disabled}
                                        >
                                            <DocumentArrowUpIcon className="w-4 mt-auto hover:rotate-12 mb-auto mr-2 line-clamp-1"/>Status
                                        
                                            {!customer.id && (
                                                <div className={`absolute bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-50 px-2 py-0.5`}>No UserID</div>
                                            )}
                                            
                                        </Button>
    
                                    </div>
    
                                </div>
                            
                            )}
                            
                            <Button
                                className={`ms-auto flex text-xs items-center px-2 bg-blue-500 hover:text-opacity-50 hover:scale-105 tracking-widest py-1 text-white rounded-lg`}
                                onClick={() => onUpdateMain(customer.id)}
                            >
                                <ArrowUpTrayIcon className={`w-4 mr-2`}/> Update
                            </Button>
                        </div>
                        
                    </div>
                    
                    {/*Account Info Long*/}
                    <div className={`w-full h-[calc(100dvh-12.6rem)] overflow-y-scroll ${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50 drop-shadow'} space-y-12 rounded-lg p-2 hide-scrollbar flex flex-col overflow-y-scroll transition-all duration-500 ease-in-out`}>
                        
                        {renderTabContent()}
                    
                    </div>
                    
                </div>
            
            </div>
            
            <Dialog open={isAdding} onClose={setIsAdding} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <DocumentArrowUpIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle as="h3" className="text-base font-semibold">
                                Customer Internal SetUp
                            </DialogTitle>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Make sure that you have reviewed all of the customers information. Once you have
                                    done so please enter in the fields below.
                                </p>
                            </div>
                            
                            <div className="mt-2 grid grid-cols-2 gap-3">
                                
                                {/*Code*/}
                                <div className={`relative col-span-full`}>
                                    <label
                                        htmlFor="code"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Customer Code
                                    </label>
                                    <Input
                                        id="code"
                                        name="code"
                                        type="text"
                                        value={customerCode}
                                        onChange={(e) => setCustomerCode(e.target.value)}
                                        placeholder={`Customer Code`}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    />
                                </div>
                                
                                {/*Pricing Type*/}
                                <div className={`relative col-span-1`}>
                                    <label
                                        htmlFor="type"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Pricing Type
                                    </label>
                                    <Select
                                        id="type"
                                        name="type"
                                        // onChange={(event) => {
                                        //     const selectedValue = event.target.value;
                                        //     setPriceCode(selectedValue); // Update priceCode state
                                        //     console.log("Selected Price Code:", selectedValue); // Debugging to verify if it's working
                                        // }}
                                        value={priceCode}
                                        onChange={(e) => setPriceCode(e.target.value)}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    >
                                        <option value="">Select Pricing Type...</option>
                                        <option value="AG">Agriculture</option>
                                        <option value="DIST1">Distributor NE/INTL</option>
                                        <option value="DIST2">Distributor SE/CAN</option>
                                        <option value="NE">Northeast</option>
                                        <option value="SE">Southeast</option>
                                    </Select>
                                </div>
                                
                                {/*Credit Limit*/}
                                <div className={`relative col-span-1`}>
                                    <label
                                        htmlFor="cl"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Credit Limit
                                    </label>
                                    <Input
                                        id="cl"
                                        name="cl"
                                        type="number"
                                        value={creditLimit}
                                        onChange={(e) => setCreditLimit(e.target.value)}
                                        placeholder={'Credit Limit'}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    />
                                </div>
                                
                                {/*Customer Type*/}
                                <div className={`relative col-span-full`}>
                                    <label
                                        htmlFor="type"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Customer Type
                                    </label>
                                    <Select
                                        id="type"
                                        name="type"
                                        // onChange={(event) => {
                                        //     const selectedValue = event.target.value;
                                        //     setPriceCode(selectedValue); // Update priceCode state
                                        //     console.log("Selected Price Code:", selectedValue); // Debugging to verify if it's working
                                        // }}
                                        value={customerType}
                                        onChange={(e) => setCustomerType(e.target.value)}
                                        placeholder={`Product Code`}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    >
                                        <option value="">Select Customer Type...</option>
                                        <option value="5">Level 1 [5%]</option>
                                        <option value="10">Level 2 [10%]</option>
                                        <option value="15">Level 3 [15%]</option>
                                        <option value="20">Level 4 [20%]</option>
                                        <option value="25">GLG</option>
                                    </Select>
                                </div>
                            
                            </div>
                        
                        
                        </div>
                    </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <Button
                              type="button"
                              onClick={() => moveToActive(customer.id)}
                              className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                          >
                              Connect
                          </Button>
                          <Button
                              type="button"
                              data-autofocus
                              onClick={() => setIsAdding(false)}
                              className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                          >
                              Cancel
                          </Button>
                      </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>

        
        </div>
    )
}

export default AccountView;