import React, {useState} from 'react';
import {Button} from "@headlessui/react";
import logo from "../main-logo.png";
import course from "../NCFP.JPG"
import {Link} from "react-router-dom";

const LandingPage = () => {

    
    return (
        
        <div className="bg-white h-screen overflow-y-hidden">
            
            <header className="absolute animate-fadeIn inset-x-0 top-0 z-50">
                <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img
                                alt=""
                                src={logo}
                                className="h-16 w-auto"
                            />
                        </a>
                    </div>
                    
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <Link to={'/login'} className="text-sm/6 font-semibold text-gray-900">
                            Log in <span aria-hidden="true">&rarr;</span>
                        </Link>
                    </div>
                </nav>
            </header>
            
            <div className="relative isolate overflow-hidden bg-gradient-to-b from-green-100/20 pt-14">
                <div
                    aria-hidden="true"
                    className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-green-600/10 ring-1 ring-green-50 sm:-mr-80 lg:-mr-96"
                />
                <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-8 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
                        <h1 className="animate-slideInLeft max-w-2xl text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl lg:col-span-2 xl:col-auto">
                            Welcome to the Plant Food Sales Portal
                        </h1>
                        <div className="animate-slideInLeft mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                            <p className="text-pretty text-md font-medium text-gray-500">
                                Welcome to the Plant Food Sales Portal, your all-in-one solution for managing orders,
                                exploring products, and streamlining sales processes. Designed with efficiency and ease
                                of use in mind, our platform empowers you to stay organized and focus on what matters
                                most—growing your business. Dive in and discover how we make sales smarter, faster, and simpler!
                            </p>
                            <div className="mt-10 flex items-center gap-x-6">
                                <Link
                                    to={'/login'}
                                    className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Sales Rep
                                </Link>
                                <Link
                                    to={'/Customer'}
                                    className="text-sm/6 font-semibold text-gray-900"
                                >
                                    New Customer <span aria-hidden="true">→</span>
                                </Link>
                            </div>
                        </div>
                        <img
                            alt=""
                            src={course}
                            className="animate-slideInRight mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
                        />
                    </div>
                </div>
                <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32"/>
            </div>
            
        </div>
    
    )
};


export default LandingPage;
