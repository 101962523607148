import React, {useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {numberWithCommas, UMMapping} from "../GlobalFunctions";
import {Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle} from "@headlessui/react";
import {DocumentCurrencyDollarIcon} from "@heroicons/react/24/outline";

const DataCard = ({ data, index }) => {

    const { darkMode } = useDarkMode();
    
    const [open, setOpen] = useState(false);
    
    return (
        <>
            <Button onClick={() => setOpen(true)} className={`px-2 py-1 w-full flex flex-col text-sm line-clamp-1 rounded-lg ${darkMode ? 'hover:bg-white border-b border-neutral-700' : 'hover:bg-slate-400'} hover:bg-opacity-5`}>
                <div className={`text-xs line-clamp-1 w-full text-start`}>[{index + 1}] {data.name}</div>
                <div className={`flex mt-1 w-full`}>
                    <div className={`text-xs bg-green-600/30 border border-green-600 w-fit px-2 rounded-md mr-auto`}>${numberWithCommas(parseFloat(data.totalPrice).toFixed(2))}</div>
                    <div className={`text-xs bg-yellow-600/30 border border-yellow-600 w-fit px-2 rounded-md`}>{data.value}</div>
                </div>
            </Button>
            
            <Dialog open={open} onClose={setOpen} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
        
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                    <DocumentCurrencyDollarIcon aria-hidden="true" className="size-6 text-green-600" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold">
                                        {data.name} Analytics
                                    </DialogTitle>
                                    <div className="mt-2 w-full">
                                        
                                        <p className="text-sm text-gray-500">
                                            These are some quick Analytics for the Products that you have sold and appear in your orders.
                                        </p>
                                        
                                        <div className="grid grid-cols-2 w-full mt-2 gap-x-3 gap-y-2">
                                           
                                            <div className={`col-span-1 text-sm`}>Total Occurrences:</div>
                                            <div className={`col-span-1 text-xs ml-auto px-4 rounded-lg bg-green-600/30 border border-green-600 w-fit`}>{data.value}</div>
                                            
                                            <div className={`col-span-1 text-sm`}>Total Price:</div>
                                            <div className={`col-span-1 text-xs ml-auto px-4 rounded-lg bg-green-600/30 border border-green-600 w-fit`}>${numberWithCommas(parseFloat(data.totalPrice).toFixed(2))}</div>
                                            
                                            {/*UM*/}
                                            <div className={`relative col-span-full mt-4`}>
                                                <label
                                                    className={`absolute flex -top-2.5 left-1/2 -translate-x-1/2 w-fit rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-sm font-medium`}
                                                >
                                                    U/M Occurrences
                                                </label>
                                                
                                                <div className={`flex w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} p-4 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                    <div className="grid grid-cols-3 max-h-32 overflow-y-scroll scrollbar-hide gap-3 auto-rows-auto w-full">
                                                        {Array.isArray(data.umCounts) && (
                                                             data.umCounts.map((data, index) => (
                                                                 <div className={`col-span-1 flex flex-col justify-center border rounded-lg py-2 ${darkMode ? 'border-neutral-700' : ''}`} key={index}>
                                                                     <div className={`text-center text-xs line-clamp-1`}>{UMMapping[data.um]}</div>
                                                                     <div className={`px-2 text-xs rounded-lg bg-green-600/30 border border-green-600 w-fit mr-auto ml-auto`}>{data.count}</div>
                                                                 </div>
                                                             ))
                                                        )}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/*Buttons*/}
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <Button
                                    type="button"
                                    onClick={() => setOpen(false)}
                                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                                >
                                    Close
                                </Button>
                            </div>
                        
                        
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>

        </>
    )
}

export default DataCard;

