import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";

const ProvenCard = ({ program }) => {

    const { darkMode } = useDarkMode();

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving] = useState(false); // Track whether the item is being removed

    // const numberWithCommas = (number) => {
    //     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }

    const categoryColors = [
        { category: 'Greens', color: 'bg-green-600', borderColor: 'border-green-600' },
        { category: 'Stress Reduction', color: 'bg-blue-800', borderColor: 'border-blue-800' },
        { category: 'Aerification', color: 'bg-gray-700', borderColor: 'border-gray-700' },
        { category: 'LDS Reduction', color: 'bg-red-800', borderColor: 'border-red-800' },
        { category: 'Microbial', color: 'bg-purple-800', borderColor: 'border-purple-800' },
        { category: 'Spring Greenup', color: 'bg-yellow-700', borderColor: 'border-yellow-700' },
        { category: 'Grow-In', color: 'bg-green-700', borderColor: 'border-green-700' },
        { category: 'Bunkers', color: 'bg-teal-900', borderColor: 'border-teal-900' },
        { category: 'Warm Season Turf', color: 'bg-teal-700', borderColor: 'border-teal-700' },
        { category: 'Fairways', color: 'bg-emerald-700', borderColor: 'border-emerald-700' },
        { category: 'Tees', color: 'bg-indigo-800', borderColor: 'border-indigo-800' },
        { category: 'Flowers', color: 'bg-pink-700', borderColor: 'border-pink-700' },
        { category: 'Trees', color: 'bg-amber-800', borderColor: 'border-amber-800' }
    ];

    const bgColor = categoryColors.find(c => c.category === program.category)?.color || '';
    const borderColor = categoryColors.find(c => c.category === program.category)?.borderColor || '';
    

    return (
        <div className={`w-full flex flex-col rounded-lg h-96 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white drop-shadow'} overflow-x-scroll scrollbar-hide p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className="relative w-full flex flex-row mb-2">

                <div className="relative w-full flex flex-row mb-1">

                    {/*Category*/}
                    <div className="w-fit absolute flex flex-row">

                        <div className={`justify-center flex flex-col h-full ${bgColor} bg-opacity-30 border-2 ${borderColor} ${borderColor} ${darkMode ? 'text-white' : 'text-black'} px-4 py-0.5 rounded-lg`}>
                            <div className="text-xs line-clamp-1">{program.category || "- - - - - - - - - - -"}</div>
                        </div>

                    </div>

                    {/*Name*/}
                    <div className="w-full items-center justify-center mr-1.5 flex flex-row h-full">

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-md line-clamp-1 text-center tracking-widest font-bold">{program.name || "- - - - - - - - - - -"}</div>
                            <div className="text-xs w-full mr-auto ml-auto text-center line-clamp-2 overflow-y-auto italic text-gray-500">{program.description || "- - - - - - - - -"}</div>
                        </div>

                    </div>


                </div>

            </div>

            {/*Table Header*/}
            <div className="relative w-full flex flex-col">

                <div className="relative w-full flex flex-row">

                    {/*Sales Rep*/}
                    <div className={`w-full text-xs items-center px-2 justify-center tracking-widest flex flex-row border-b-2 ${darkMode ? 'border-neutral-700' : ''} border-dotted h-full`}>

                        <div className="w-[60%] line-clamp-1 font-bold">PFC Product</div>
                        <div className={`w-[20%] line-clamp-1 text-center font-bold border-r-2 ${darkMode ? 'border-neutral-700' : ''} border-dotted`}>Oz/1K Sq. Ft.</div>
                        <div className="w-[20%] line-clamp-1 text-center font-bold">Gal/Acre</div>

                    </div>


                </div>

            </div>

            {/*Table*/}
            <div className={`relative w-full flex flex-col mt-2 h-44 overflow-y-scroll scrollbar-hide border-2 rounded-lg p-1.5 ${darkMode ? 'border-neutral-700' : ''}`}>

                <div>
                    {program.products.length === 0 ? (
                        // Display custom dialog if sprays array is empty
                        <div className="flex w-full h-full justify-center items-center py-10">
                            <div className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                    <p className="text-xl">No Products in this Program</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // Display SprayCard components if products array is not empty
                        (() => {
                            // Array of 10 background colors to cycle through
                            const colorMapping = {
                                "bg-green-600/30 border border-green-600": "border-green-600",
                                "bg-blue-600/30 border border-blue-600": "border-blue-600",
                                "bg-red-600/30 border border-red-600": "border-red-600",
                                "bg-yellow-600/30 border border-yellow-600": "border-yellow-600",
                                "bg-purple-600/30 border border-purple-600": "border-purple-600",
                                "bg-teal-600/30 border border-teal-600": "border-teal-600",
                                "bg-pink-600/30 border border-pink-600": "border-pink-600",
                                "bg-indigo-600/30 border border-indigo-600": "border-indigo-600",
                                "bg-orange-600/30 border border-orange-600": "border-orange-600",
                                "bg-gray-600/30 border border-gray-600": "border-gray-600"
                            };


                            // Variable to store the previous background color
                            let prevColor = '';

                            // Map through products with a color check for consecutive repeats
                            return program.products.map((product, index) => {
                                // Function to get a new color that is not the same as the previous one
                                const getRandomColor = () => {
                                    let color;
                                    do {
                                        color = Object.keys(colorMapping)[Math.floor(Math.random() * Object.keys(colorMapping).length)];
                                    } while (color === prevColor);
                                    prevColor = color;
                                    return color;
                                };
                                
                                const bgColor = getRandomColor();
                                const borderColor = colorMapping[bgColor];


                                return (
                                    <div key={index} className={`w-full items-center ${bgColor} bg-opacity-50 border-2 ${darkMode ? 'text-white' : 'text-black'} border-opacity-100 hover:bg-opacity-80 rounded-lg mb-1.5 justify-center flex flex-row p-1`}>
                                        
                                        <div className="text-xs w-[60%] line-clamp-1">{product.name}</div>
                                        
                                        <div className="text-xs w-[20%] line-clamp-1 tracking-widest text-center">
                                            {product.oz.toFixed(2)} oz
                                        </div>
                                        
                                        <div className="text-xs w-[20%] line-clamp-1 tracking-widest text-center">
                                            {product.gal.toFixed(2)} gal
                                        </div>
                                        
                                    </div>
                                );
                            });
                        })()
                    )}
                </div>
            </div>

            {/*Nutrient Label*/}
            <div className="relative w-full flex flex-col mb-2">

                <div className="relative w-full flex flex-row">

                    {/*Sales Rep*/}
                    <div className="w-full flex flex-row h-full">

                        <div className={`text-sm tracking-widest w-full line-clamp-1 border-b-2 border-dotted ${darkMode ? 'border-neutral-700' : ''}`}>{program.nutrients !== undefined ? 'Nutrients / 1,000 sq. ft. / app.' : ''}</div>

                    </div>


                </div>

            </div>

            {/*Nutrient Header*/}
            <div className="relative w-full flex flex-col">

                <div className="relative w-full flex flex-col">

                    {/*Nutrient Labels*/}
                    <div className="w-full flex flex-row h-full mb-4">

                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.n ? 'N' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.p ? 'P' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.k ? 'K' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-green-600">{program.nutrients?.ca ? 'Ca' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-green-600">{program.nutrients?.mg ? 'Mg' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-green-600">{program.nutrients?.s ? 'S' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.b ? 'B' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.cu ? 'Cu' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.fe ? 'Fe' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.mn ? 'Mn' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.si ? 'Si' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.mo ? 'Mo' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-blue-500">{program.nutrients?.zn ? 'Zn' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.ha ? 'Ha' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.c ? 'C' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold">{program.nutrients?.aas ? 'AAS' : ''}</div>
                        <div className="w-[5.88%] text-center text-xs line-clamp-1 font-bold text-green-600">{program.nutrients?.pp ? 'PP' : ''}</div>


                    </div>

                    {/*Nutrients*/}
                    <div className="w-full flex flex-row h-full">

                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.n ? program.nutrients.n.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.p ? program.nutrients.p.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.k ? program.nutrients.k.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-green-600">{program.nutrients?.ca ? program.nutrients.ca.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-green-600">{program.nutrients?.mg ? program.nutrients.mg.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-green-600">{program.nutrients?.s ? program.nutrients.s.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.b ? program.nutrients.b.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.cu ? program.nutrients.cu.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.fe ? program.nutrients.fe.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.mn ? program.nutrients.mn.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.si ? program.nutrients.si.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.mo ? program.nutrients.mo.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-blue-400">{program.nutrients?.zn ? program.nutrients.zn.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.ha ? program.nutrients.ha.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.c ? program.nutrients.c.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold">{program.nutrients?.aas ? program.nutrients.aas.toFixed(5) : ''}</div>
                        <div className="w-[5.88%] text-n line-clamp-1 transform -rotate-50 font-bold text-green-600">{program.nutrients?.pp ? program.nutrients.pp.toFixed(5) : ''}</div>


                    </div>


                </div>

            </div>

            <div className="relative w-full text-center tracking-widest mt-3">{program.BioStims !== undefined ? `BioStimulants: ${program.BioStims.toFixed(5)}` : ''}</div>


        </div>
    )
}

export default ProvenCard;

