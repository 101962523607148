import React, { useEffect, useState } from 'react';
import SideBar from "./SideBar";
import { useDarkMode } from "../contexts/darkModeContext";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { useAuth } from "../contexts/authContext";
import OrderCard from "./Orders/OrderCard";
import {
    TrashIcon
} from "@heroicons/react/24/outline";
import logo from "../main-logo.png";
import OrderSection from "./Orders/OrderSection";
import Loading from "./Loading";
import OrderSearchInput from "./Orders/OrderSearchInput";
import {Button, Select} from "@headlessui/react";
import NewOrder from "./NewOrder";
import {numberWithCommas} from "./GlobalFunctions";

const Archive = () => {
    const { currentUser } = useAuth();
    const { darkMode } = useDarkMode();
    const [archiveNames, setArchiveNames] = useState([]);
    // eslint-disable-next-line
    const [archiveData, setArchiveData] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [selectedDataFiltered, setSelectedDataFiltered] = useState(null);
    const [selectedArchive, setSelectedArchive] = useState(null); // State to track selected archive
    const [accountCreationDate, setAccountCreationDate] = useState(null);

    const [updating, setUpdating] = useState(false);
    
    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });
    
    const fetchAccountCreationDate = async () => {
        // Check if account creation date exists in localStorage
        const storedAccountCreationDate = localStorage.getItem(`accountCreationDate_${currentUser.uid}`);

        if (storedAccountCreationDate) {
            // Use the stored value if it exists
            setAccountCreationDate(storedAccountCreationDate);
        } else {
            // If not, fetch from Firebase
            try {
                const userDocRef = doc(db, `Users/${currentUser.uid}`);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setAccountCreationDate(data.created);

                    // Save the account creation date to localStorage
                    localStorage.setItem(`accountCreationDate_${currentUser.uid}`, data.created);
                }
            } catch (error) {
                console.error("Error fetching account creation date:", error);
            }
        }
    };

    const generateArchiveNames = () => {
        if (!accountCreationDate) return;
    
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentQuarter = Math.floor(now.getMonth() / 3) + 1; // Get the current quarter
    
        const startDate = new Date(accountCreationDate);
        const archiveNamesArray = [];
    
        while (startDate < now) {
            const year = startDate.getFullYear();
            const quarter = Math.floor(startDate.getMonth() / 3) + 1;
    
            // Skip adding the current quarter
            if (!(year === currentYear && quarter === currentQuarter)) {
                const archiveName = `archive_Q${quarter}_${year}`;
                if (!archiveNamesArray.includes(archiveName)) {
                    archiveNamesArray.push(archiveName);
                }
            }
    
            // Move to the next quarter
            startDate.setMonth(startDate.getMonth() + 3);
        }
    
        setArchiveNames(archiveNamesArray);
    };
    
    async function fetchArchivedData(archiveName) {
        setUpdating(true);
        const startTime = Date.now();
        
        const cachedData = localStorage.getItem(`archiveData_${archiveName}_${currentUser.uid}`);
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            setArchiveData(parsedData);
            setSelectedData(parsedData);
            setSelectedDataFiltered(parsedData);
            setSelectedArchive(archiveName); // Set selected archive name
            // alert(`Archive data loaded from local Storage`);
            setUpdating(false);
            return;
        }

        try {
            const clientsCollectionRef = collection(db, `Customers/${currentUser.uid}/clients`);
            const clientsSnapshot = await getDocs(clientsCollectionRef);

            let allArchiveData = [];

            for (const clientDoc of clientsSnapshot.docs) {
                const clientId = clientDoc.id;
                const archiveCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/${archiveName}`);
                const archiveSnapshot = await getDocs(archiveCollectionRef);

                archiveSnapshot.forEach((doc) => {
                    allArchiveData.push({
                        userId: currentUser.uid,    // Add user ID
                        clientId,  // Add client ID
                        archiveName,
                        ...doc.data(),
                        id: doc.id, // Add document ID
                    });
                });
            }

            localStorage.setItem(`archiveData_${archiveName}_${currentUser.uid}`, JSON.stringify(allArchiveData));
            setArchiveData(allArchiveData);
            setSelectedData(allArchiveData);
            setSelectedDataFiltered(allArchiveData);
            setSelectedArchive(archiveName); // Set selected archive name after fetching
            
            if (alertsOn) {
                alert(`Archive data saved to local Storage`);
            }
            
        } catch (error) {
            console.error("Error fetching archived data:", error);
        }
        
        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        
        if (alertsOn) {
            alert(`Orders Updated in ${timeElapsed} secs`);
        }
    }
    
    // async function fetchAllArchivedData(archiveName) {
    //     setUpdating(true);
    //     const startTime = Date.now();
    //
    //     const cachedData = localStorage.getItem(`allArchiveData_${archiveName}_${currentUser.uid}`);
    //     if (cachedData) {
    //         const parsedData = JSON.parse(cachedData);
    //         setArchiveData(parsedData);
    //         setSelectedData(parsedData);
    //         setSelectedDataFiltered(parsedData);
    //         setSelectedArchive(archiveName); // Set selected archive name
    //         // alert(`Archive data loaded from local Storage`);
    //         setUpdating(false);
    //         return;
    //     }
    //
    //     try {
    //         const clientsCollectionRef = collection(db, `Customers/${currentUser.uid}/clients`);
    //         const clientsSnapshot = await getDocs(clientsCollectionRef);
    //
    //         let allArchiveData = [];
    //
    //         for (const clientDoc of clientsSnapshot.docs) {
    //             const clientId = clientDoc.id;
    //             const archiveCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/${archiveName}`);
    //             const archiveSnapshot = await getDocs(archiveCollectionRef);
    //
    //             archiveSnapshot.forEach((doc) => {
    //                 allArchiveData.push({
    //                     userId: currentUser.uid,    // Add user ID
    //                     clientId,  // Add client ID
    //                     archiveName,
    //                     ...doc.data(),
    //                     id: doc.id, // Add document ID
    //                 });
    //             });
    //         }
    //
    //         localStorage.setItem(`allArchiveData_${archiveName}_${currentUser.uid}`, JSON.stringify(allArchiveData));
    //         setArchiveData(allArchiveData);
    //         setSelectedData(allArchiveData);
    //         setSelectedDataFiltered(allArchiveData);
    //         setSelectedArchive(archiveName); // Set selected archive name after fetching
    //
    //         if (alertsOn) {
    //             alert(`Archive data saved to local Storage`);
    //         }
    //
    //     } catch (error) {
    //         console.error("Error fetching archived data:", error);
    //     }
    //
    //     const endTime = Date.now();
    //     const timeElapsed = (endTime - startTime) /1000;
    //     setUpdating(false);
    //
    //     if (alertsOn) {
    //         alert(`Orders Updated in ${timeElapsed} secs`);
    //     }
    // }

    useEffect(() => {
        if (currentUser) {
            fetchAccountCreationDate();
        }
        // eslint-disable-next-line
    }, [currentUser]);

    useEffect(() => {
        generateArchiveNames();
        // eslint-disable-next-line
    }, [accountCreationDate]);

    const handleButtonClick = (archiveName) => {
        fetchArchivedData(archiveName);
    };
    
    
    const formatArchiveName = (archiveName) => {
        const parts = archiveName.split('_'); // Split by '_'
        const quarter = parts[1]; // Extract the quarter (e.g., "Q1")
        const year = parts[2]; // Extract the year (e.g., "2025")
    
        // Return formatted string
        return `${quarter} ${year}`;
    };

    const [count, setCount] = useState(0);

    useEffect(() => {
        // Check if selectedData is not null and has a length property
        if (selectedData && Array.isArray(selectedData)) {
            setCount(selectedData.length);
        } else {
            setCount(0); // Set count to 0 if selectedData is null or not an array
        }
    }, [selectedData]);

    
    const [searchQuery, setSearchQuery] = useState('');

    const handleArchiveSearch = (query) => {
        setSearchQuery(query);
        const filtered = selectedData.filter((order) =>
            order.customerName.toLowerCase().includes(query.toLowerCase()) ||
            order.customerCode.toLowerCase().includes(query.toLowerCase()) ||
            order.orderERP.toString().includes(query.toLowerCase())
        );
        setSelectedDataFiltered(filtered);
    }
    
    const filterOrdersByIndustry = (industry) => {
        if (!selectedData) {
            setSelectedDataFiltered([]); // Set an empty array if selectedData is null
            return;
        }
    
        const filtered = selectedData.filter(order => industry === '' || order.customerIndustry === industry);
        setSelectedDataFiltered(filtered);
    };
    
    const filterOrdersByPrice = (price) => {
        if (!selectedData) {
            setSelectedDataFiltered([]); // Set an empty array if selectedData is null
            return;
        }
        
        const filtered = selectedData.filter(order => price === '' || order.priceType === price);
        setSelectedDataFiltered(filtered);
    };
    
    const [orderForm, setOrderForm] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    
    // Function to handle both accountView and selected customer
    const handleViewOrder = (order) => {
        setOrderForm(true);
        setSelectedOrder(order);
    };
    
    const calculateTotalGrandTotal = (orders) => {
        if (!selectedData) {
            return 0;
        }
        return orders.reduce((total, order) => {
            return total + (parseFloat(order.grandTotal) || 0); // Add 0 for undefined grandTotal
        }, 0);
    };


    const grandTotal = calculateTotalGrandTotal(selectedData);

    return (
        
        <>
            {orderForm ? (
                <NewOrder
                    order={selectedOrder}
                    isEditing={true}
                    close={() => setOrderForm(false)}
                />
            ) : (
                <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-[calc(100dvh-4rem)] w-full mt-16 transition-all duration-500 ease-in-out`}>
                    
                    <SideBar/>
                    
                    {updating && (
                        <Loading/>
                    )}
                    
                    <div className="z-20 animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] mt-3 mb-3 mr-3">
        
                        {/*Customers Header*/}
                        <div className="w-full h-fit flex mb-3 items-center">
        
                            <div className={`text-2xl w-1/6 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row`}>
                                <div className={`line-clamp-1`}>Archived Orders</div>
                            </div>
        
        
                            {/*TimeLine*/}
                            <div className={`text-xl border ${darkMode ? 'bg-darkMainColor text-white border-neutral-700' : 'bg-white text-black'} w-5/6 max-w-[calc(100%)] flex rounded-md border drop-shadow shadow-gray-900`}>
        
                                <div className={`relative flex w-full ${darkMode ? 'bg-darkMainColor' : 'bg-white drop-shadow'} rounded-lg overflow-x-auto py-1`}>
                                    <div className="flex space-x-4">
                                        {archiveNames.map((archiveName, index) => (
                                            <React.Fragment key={archiveName}>
                                                <Button
                                                    onClick={() => handleButtonClick(archiveName)}
                                                    className={`py-1.5 w-20 rounded items-center justify-center flex flex-col hover:scale-105`}
                                                >
                                                    <div className={`text-xs`}>{formatArchiveName(archiveName)}</div>
        
                                                    <div className={`w-5 h-5 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor drop-shadow border'} rounded-full flex items-center justify-center`}>
                                                        <div className={`w-3 h-3 rounded-full ${selectedArchive === archiveName ? 'bg-green-500 animate-pulse' : `${darkMode ? 'bg-darkMainColor' : 'bg-white drop-shadow border'}`}`}></div>
                                                    </div>
                                                </Button>
        
                                                {/* Add a separator between buttons, but not after the last one */}
                                                {index < archiveNames.length - 1 && (
                                                    <div className={`w-6 h-0.5 mt-auto mb-auto rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-300'}`}/> // Example separator, you can style this as needed
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
        
        
                            </div>
        
                        </div>
        
                        {/*Search bar row*/}
                        <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>
        
                            {/*Search Bar*/}
                            <div className="w-1/2 mr-1.5 h-fit text-sm">
                                {/*<div className="text-sm mb-1">Customer</div>*/}
        
                                <div className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                                    <OrderSearchInput
                                        status={"Arc"}
                                        searchQuery={searchQuery}
                                        setSearchQuery={setSearchQuery}
                                        onSearch={handleArchiveSearch}
                                        darkMode={darkMode}
                                    />
                                </div>
        
                            </div>
        
                            {/*Status*/}
                            <div className="hidden w-1/6 mr-1.5 ml-1.5 h-full text-sm">
        
                                <div className="flex flex-col w-full mr-3 h-full">
                                    {/*<div className="text-sm mb-1">Status</div>*/}
                                    <Select
                                        className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                        // value={status}
                                        // onChange={handleStatusChange}
                                    >
                                        <option value="A">Active</option>
                                        <option value="P">Pending</option>
                                        <option value="Planned">Planned</option>
                                        {/*<option value="All">All Orders</option>*/}
                                    </Select>
                                </div>
        
                            </div>
        
                            {/*Total*/}
                            <div className="w-1/4 mr-1.5 ml-1.5 h-full text-sm">
        
                                <div className={`h-full rounded-lg border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                                    <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                                        <div className="font-semibold text-sm mr-1 line-clamp-1">Total:</div>
                                        <div className="text-sm rounded-md bg-orderColor bg-opacity-20 border-2 border-orderColor px-2 line-clamp-1">$ {numberWithCommas(grandTotal.toFixed(2))}</div>
                                        <div className="text-xs rounded-md bg-orderColor bg-opacity-20 border-2 border-orderColor px-1 ml-1 line-clamp-1">{selectedData?.length || 0}</div>
                                    </div>
                                </div>
        
                            </div>
        
                            {/*Pricing Type*/}
                            <div className="w-1/4 ml-1.5 h-full text-sm">
        
                                <div className="flex flex-col w-full mr-3 h-full">
                                    {/*<div className="text-sm mb-1">Pricing Type</div>*/}
                                    <Select
                                        className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                        onChange={(e) => filterOrdersByPrice(e.target.value)}
                                    >
                                        <option value="">Select Price Type...</option>
                                        <option value="AG">Agriculture</option>
                                        <option value="DIST1">Distributor NE/INTL</option>
                                        <option value="DIST2">Distributor SE/CAN</option>
                                        <option value="NE">Northeast</option>
                                        <option value="SE">Southeast</option>
                                    </Select>
                                </div>
        
                            </div>
        
        
                        </div>
                        
                        {/*Orders Body*/}
                        <div className="w-full h-full rounded-md">
        
                            {/*Orders Maps*/}
                            <div className="w-full flex h-full flex-col">
        
                                <div className={`w-full h-full border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} rounded-lg`}>
        
                                    {/*HEADER*/}
                                    <div className="w-full flex flex-row pl-3 pr-3">
        
                                        <div
                                            className={`w-full flex flex-row border-b-2 ${darkMode ? 'border-darkBgColor' : ''}`}>
        
                                            {/*Buttons Delete*/}
                                            <div className="flex flex-col items-center mr-3">
                                                <div className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                                    {/*<TrashIcon className="h-6 text-transparent m-auto"/>*/}
                                                    <div className="justify-center flex flex-col items-center h-full">
                                                        <div className="text-sm font-semibold">[{count}]</div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            {/*Contact Name*/}
                                            <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">
        
                                                <div className="justify-center flex flex-col h-full">
                                                    <div className="text-sm font-semibold">Sales Rep</div>
                                                </div>
        
                                            </div>
        
                                            {/*Contact Phone*/}
                                            <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">
        
                                                <div className="justify-center flex flex-col h-full">
                                                    <div className="text-sm font-semibold">Customer</div>
                                                </div>
        
        
                                            </div>
        
                                            <div className="flex w-1/6 items-center justify-center h-full text-sm font-semibold">ERP</div>
        
                                            {/**/}
                                            <div className="w-1/2 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">
        
                                                {/*Status*/}
                                                <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">
        
                                                    <div className="w-fit p-1 h-8 rounded-full flex flex-row">
                                                        <div
                                                            className="flex items-center text-center justify-center h-full text-sm font-semibold">Status
                                                        </div>
                                                    </div>
        
                                                </div>
        
                                                <div className="w-1/3 p-1 h-8">
                                                    <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Order Date</div>
                                                </div>
        
                                                <div className="w-1/3 h-8 items-center justify-center flex">
                                                    <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Order
                                                        Total
                                                    </div>
                                                </div>
        
        
        
                                            </div>
        
                                            {/*Buttons DropDown*/}
                                            <div className="flex flex-col items-center ml-3">
                                                <div
                                                    className=" mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                                    <TrashIcon className="h-6 text-transparent m-auto"/>
                                                </div>
                                            </div>
        
        
                                        </div>
        
                                    </div>
        
                                    
                                    {/*Orders MAP*/}
                                    <div className={`overflow-y-auto h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} p-1 rounded-lg max-h-[calc(100dvh-16rem)] scrollbar-hide`}>
        
                                        <OrderSection
                                            orders={selectedDataFiltered || []}
                                            emptyTitle={"No Archived Orders"}
                                            emptyDescription={"No Archived Orders For Selected Time Period"}
                                            darkMode={darkMode}
                                            status={"A"}
                                            archived={true}
                                            // onUpdate={onUpdate}
                                            // products={products}
                                            handleViewOrder={handleViewOrder}
                                            // onDelete={(customerUID, id) => {
                                            //     if (status === "Planned") handleDeleteOrder(customerUID, id);
                                            // }}
                                        />
                                        
                                    </div>
        
        
                                </div>
        
        
                            </div>
        
                        </div>
                        
                    </div>
        
                </div>
            )}
        </>
    );
};

export default Archive;