import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";
import {Button, Input, Popover, PopoverButton, PopoverPanel, Select} from "@headlessui/react";
import {
    ArrowDownTrayIcon,
    ArrowPathIcon,
    BookOpenIcon, EyeSlashIcon, FunnelIcon,
    MagnifyingGlassIcon, PhoneIcon, SquaresPlusIcon
} from "@heroicons/react/24/outline";
import ProductCardV2 from "./Catalog/ProductCardV2";
import PDFModal from "./Catalog/PDFModal";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import catalogPDF from "../Plant Food Company_2024_Catalog.pdf"
import {collection, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";
import {ChevronDownIcon, CursorArrowRaysIcon, FingerPrintIcon, PlayCircleIcon} from "@heroicons/react/16/solid";
import {ChartPieIcon} from "@heroicons/react/24/solid";
import {categoryColors, getCategoryInfo} from "./GlobalFunctions";
import ProductExpanded from "./Catalog/ProductExpanded";



const Catalog = () => {

    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });
    
    const { darkMode } = useDarkMode();

    const { currentUser } = useAuth();


    // const [products] = useState(productsData.products);
    // const [filteredProducts, setFilteredProducts] = useState(productsData.products);
    const [searchQuery, setSearchQuery] = useState("");

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        
        
        const fetchProducts = async () => {
            if (currentUser) {
                try {
                    // Fetch the 'productUpdated' field for the current user
                    const userDocRef = doc(db, "Users", currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const { productUpdated } = userData;

                        if (productUpdated) {
                            // If 'productUpdated' is true, fetch products from Firestore
                            console.log("Fetching products from Firestore...");
                            const productsCollection = collection(db, "Products");
                            const snapshot = await getDocs(productsCollection);

                            if (!snapshot.empty) {
                                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                                setProducts(productData);
                                setFilteredProducts(productData);
                                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));

                                // Set 'productUpdated' to false
                                await updateDoc(userDocRef, { productUpdated: false });
                                console.log("Set productUpdated to false after fetching from Firestore.");
                            } else {
                                console.log("No products found in Firestore.");
                            }
                        } else {
                            // If 'productUpdated' is false, load products from localStorage
                            console.log("Loading products from localStorage...");
                            const storedProducts = localStorage.getItem(`products_${currentUser.uid}`);
                            if (storedProducts) {
                                const storedData = JSON.parse(storedProducts);
                                setProducts(storedData);
                                setFilteredProducts(storedData);
                            } else {
                                console.log("No products found in localStorage.");
                            }
                        }
                    } else {
                        console.log("User document does not exist.");
                    }
                } catch (error) {
                    console.error("Error fetching product data:", error);
                }
            }
        };


        fetchProducts();
    }, [currentUser]);

    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Products
        try {
            const productsCollection = db.collection("Products");
            const snapshot = await productsCollection.get();

            if (!snapshot.empty) {
                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProducts(productData);
                setFilteredProducts(productData);
                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));
            } else {
                console.log('Product documents not found');
            }

        } catch (error) {
            console.error("Error updating products", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        if (alertsOn) {
            alert(`Products Updated in ${timeElapsed} secs`);
        }
    };
    
    const filterProductsByCatagory = (catagory) => {
        const filtered = products.filter(product => catagory === '' || product.category === catagory);
        setFilteredProducts(filtered);
    }

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = products.filter((product) =>
            product.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredProducts(filtered);
    }
    
    const callsToAction = [
      { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
      { name: 'Contact sales', href: '#', icon: PhoneIcon },
    ]
    
    
    const [selectedProduct, setSelectedProduct] = useState(null);
    
    // Function to handle both accountView and selected customer
    const handleViewProduct = (product) => {
        setSelectedProduct(product);
    };
    
    const isIPad = /iPad|Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1;

    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-[calc(100dvh-4rem)] w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>
            
            {/*<div className={`animate-fadeIn flex w-1/6 p-2 mr-3 ml-3 rounded-lg mt-3 overflow-y-auto hide-scrollbar mb-3 ${darkMode ? 'bg-darkMainColor' : 'bg-white drop-shadow-lg'} flex-col`}>*/}



            {/*</div>*/}
            
            
            <div className="z-50 animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] mt-3 mr-3">
                
                
                {/*Catalog Header*/}
                <div className="w-full h-fit flex mb-3">
                    
                    <div className={`text-2xl w-1/6 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row`}>Catalog
                        
                        <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                            <Button
                                className={`rounded-full ${darkMode ? 'bg-darkMainColor text-gray-500' : 'bg-white text-gray-700'} p-1 hover:scale-105 hover:rotate-12 mt-auto mb-auto h-7 w-7 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                onClick={onUpdate}
                            >
                                <ArrowPathIcon className="m-auto"/>
                            </Button>
                        </div>
                    
                    </div>
                    
                    <div className={`text-xl border ${darkMode ? 'bg-darkMainColor border-neutral-700 text-white' : 'bg-white border text-black'} w-5/6 flex rounded-md border drop-shadow shadow-gray-900`}>
                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Products:</div>
                            <div className={`text-sm rounded-md border ${darkMode ? 'border-neutral-700' : ''} px-2 line-clamp-1`}>{products.length}</div>
                        </div>
                        
                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Filtered Products:</div>
                            <div className={`text-sm rounded-md border ${darkMode ? 'border-neutral-700' : ''} px-2 line-clamp-1`}>{filteredProducts.length}</div>
                        </div>
                        
                        <div className="flex w-fit mr-1.5 flex-row items-center align-middle justify-center h-full">
                            <a
                                href={catalogPDF}
                                download="PFC_2024_Catalog.pdf" // Specify the name for the downloaded file
                                className="font-semibold flex text-xs text-white bg-green-600 px-2 py-0.5 rounded-md hover:opacity-50 line-clamp-1"
                            >
                                <ArrowDownTrayIcon className={`w-4 mr-1.5`}/>
                                Catalog
                            </a>
                        </div>
                    
                    </div>
                
                </div>
                
                
                {/*Search Column + Expanded View*/}
                <div className="grid grid-cols-3 grid-rows-3 gap-3 h-full mb-3">
                    
                    {/*Side Bar Column*/}
                    <div className={`row-span-3 col-start-1 rounded-lg border p-3 ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                        
                        {/*Search Bar*/}
                        <div className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center`}>
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mx-3"/>
                            
                            <div className="w-full">
                                
                                <Input
                                    className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md tracking-widest p-1.5 w-full focus:outline-none`}
                                    placeholder="Search For Product By Name"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            
                            </div>
                            
                            <Popover className="relative">
                              <PopoverButton className="inline-flex items-center text-sm/6 font-semibold text-gray-900">
                                  <FunnelIcon className={`size-6 mr-2 text-green-600 hover:text-opacity-50`}/>
                              </PopoverButton>
                        
                              <PopoverPanel
                                transition
                                className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                              >
                                <div className={`w-screen max-w-md flex-auto overflow-hidden rounded-xl border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} text-sm/6 shadow-lg ring-1 ring-gray-900/5`}>
                                    <div className="p-4">
                                        
                                        <div className={`relative`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Category
                                            </label>
                                            <Select
                                                onChange={(e) => filterProductsByCatagory(e.target.value)}
                                                className={`block w-full rounded-md ${isIPad ? 'appearance-none' : ''} ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            >
                                                <option value="">Select Category...</option>
                                                <option value="Biostimulants">Biostimulants</option>
                                                <option value="Liquid Fertilizer">Liquid Fertilizers</option>
                                                <option value="Dry Fertilizer">Dry Fertilizers</option>
                                                <option value="Secondary Nutrients">Secondary Nutrients</option>
                                                <option value="Micronutrients">Micronutrients</option>
                                                <option value="Penetrants & Water Holding Agents">Penetrants & Water Holding Agents</option>
                                                <option value="A-Plus® Solubilizing Surfactants">A-Plus® Solubilizing Surfactants</option>
                                                <option value="Turfgrass Pigment">Turfgrass Pigment</option>
                                                <option value="Water Treatments">Water Treatments</option>
                                                <option value="Soil Amendments">Soil Amendments</option>
                                                <option value="Fungicides">Fungicides</option>
                                                <option value="Equipment">Equipment</option>
                                                <option value="Test">Test</option>
                                            </Select>
                                        </div>
                                    
                                    </div>
                                </div>
                              </PopoverPanel>
                            </Popover>
                        
                        </div>
                        
                        {/**/}
                        <div className={`h-[calc(100dvh-13.75rem)] overflow-y-scroll scrollbar-hide mt-3 rounded-lg border ${darkMode ? 'border-neutral-700' : ''}`}>
                            
                            
                            {filteredProducts.length > 0 ? (
                              (() => {
                                const categoryOrder = categoryColors.map(c => c.category);
                                
                                // Sort by category first
                                filteredProducts.sort((a, b) => categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category));
                            
                                // Helper function for natural sorting (handles numbers in names)
                                const naturalSort = (a, b) => {
                                  return a.name.localeCompare(b.name, undefined, { numeric: true });
                                };
                            
                                // Group products by category and sort each group alphabetically
                                const groupedProducts = filteredProducts.reduce((acc, product) => {
                                  if (!acc[product.category]) acc[product.category] = [];
                                  acc[product.category].push(product);
                                  return acc;
                                }, {});
                            
                                Object.keys(groupedProducts).forEach(category => {
                                  groupedProducts[category].sort(naturalSort);
                                });
                            
                                return Object.entries(groupedProducts).flatMap(([category, products]) => {
                                  return [
                                    // Divider for the category
                                    <div key={`divider-${category}`} className="relative my-1">
                                      <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                        <div className={`w-full border-t-2 ${darkMode ? 'border-neutral-700' : ''} mx-3`} />
                                      </div>
                                      <div className="relative flex justify-center">
                                        <span className={`${darkMode ? 'bg-darkMainColor' : 'bg-white'} px-3 text-base font-semibold tracking-widest`}>
                                          {category}
                                        </span>
                                      </div>
                                    </div>,
                            
                                    // Sorted products within the category
                                    ...products.map(product => {
                                      const { color, shortened } = getCategoryInfo(product.category);
                            
                                      return (
                                        <li
                                          key={product.id}
                                          className={`z-40 cursor-pointer ${product.PLAIF ? 'text-gray-500 bg-opacity-20 bg-gray-500' : ''} tracking-widest p-2 rounded-lg flex items-center ${
                                            darkMode ? 'hover:bg-darkBgColor' : 'hover:bg-gray-200'
                                          }`}
                                          onMouseDown={() => handleViewProduct(product)}
                                        >
                                          <div className={`flex flex-row items-center w-full`}>
                                            <div className={`mr-3`}>
                                              <div className={`flex-none w-12 rounded-full p-1 ${color} bg-opacity-40`}>
                                                <div className={`text-xxs rounded-full ${color} bg-opacity-80 font-bold px-1 text-center text-white tracking-normal`}>
                                                  {shortened}
                                                </div>
                                              </div>
                                            </div>
                                            <div className={`line-clamp-1`}>{product.name}</div>
                                          </div>
                                        </li>
                                      );
                                    })
                                  ];
                                });
                              })()
                            ) : (
                              <div className={`flex items-center justify-center flex-col p-3`}>
                                <EyeSlashIcon className={`w-6 stroke-2 text-gray-500`} />
                                <div className="px-4 py-2 text-sm text-gray-500">
                                  No product found for{' '}
                                  <span className={`font-bold ${darkMode ? 'text-white' : 'text-black'}`}>'{searchQuery}'</span>.
                                  Please try again.
                                </div>
                              </div>
                            )}
                            
                            
                            
                            
                            
                        </div>
                    
                    </div>
                    
                    {/*Product List*/}
                    <div className={`col-span-2 row-span-3 col-start-2 rounded-lg border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                        
                        <ProductExpanded
                            product={selectedProduct}
                        />
                        
                    </div>
                
                </div>
            
            
            </div>
            
        
        </div>
    )
};


export default Catalog;
