import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";
import {
    ArrowPathIcon,
    BookOpenIcon,
    ClipboardDocumentListIcon,
    MagnifyingGlassIcon, UserGroupIcon,
    UsersIcon
} from "@heroicons/react/24/outline";
import {Button, Input, Select} from "@headlessui/react";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import UserCard from "./SalesTeam/UserCard";

const SalesTeam = () => {
    const { currentUser } = useAuth();
    
    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });

    const { darkMode } = useDarkMode();

    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {

        const fetchUsers = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`users_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const usersData = JSON.parse(storedUserData);
                        setUsers(usersData);
                        setFilteredUsers(usersData);
                    } else {
                        // Fetch all user documents from Firestore 'Users' collection
                        const usersSnapshot = await db.collection('Users').get();

                        if (!usersSnapshot.empty) {
                            // Map over the documents and extract user data
                            const usersData = usersSnapshot.docs.map(doc => ({
                                id: doc.id, // Retrieve the document ID (UID)
                                ...doc.data() // Get the user data
                            }));

                            // Save user data to localStorage
                            localStorage.setItem(`users_${currentUser.uid}`, JSON.stringify(usersData));

                            // Set the users state with fetched data
                            setUsers(usersData);
                            setFilteredUsers(usersData);
                        } else {
                            console.log('No users found in the collection');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUsers();
    }, [currentUser]);

    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Pending
        try {

            // Fetch all user documents from Firestore 'Users' collection
            const usersSnapshot = await db.collection('Users').get();

            if (!usersSnapshot.empty) {
                // Map over the documents and extract user data
                const usersData = usersSnapshot.docs.map(doc => ({
                    id: doc.id, // Retrieve the document ID (UID)
                    ...doc.data() // Get the user data
                }));

                // Save user data to localStorage
                localStorage.setItem(`users_${currentUser.uid}`, JSON.stringify(usersData));

                // Set the users state with fetched data
                setUsers(usersData);
                setFilteredUsers(usersData);
            } else {
                console.log('No users found in the collection');
            }

        } catch (error) {
            console.error("Error updating users", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        if (alertsOn) {
            alert(`Sales Team Updated in ${timeElapsed} secs`);
        }
    };
    
    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = users.filter((user) =>
            user.fname.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredUsers(filtered);
    }
    
    const filterUsersByRegion = (region) => {
        const filtered = users.filter(user => region === '' || user.region === region);
        setFilteredUsers(filtered);
    };
    
    const filterUsersByAccountLevel = (level) => {
        const filtered = users.filter(user => level === '' || user.accountLevel === level);
        setFilteredUsers(filtered);
    };
    
    const filterUsersBySuper = (supervisor) => {
        const filtered = users.filter(user => supervisor === '' || user.supervisor === supervisor);
        setFilteredUsers(filtered);
    };

    

    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-[calc(100dvh-4rem)] w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>
            

            <div className="z-20 animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] mt-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3">

                    <div className={`text-2xl w-1/3 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row`}>Sales Team
                        <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                            <Button className={`rounded-full ${darkMode ? 'bg-darkMainColor text-gray-500' : 'bg-white text-gray-700'} p-1 hover:scale-105 hover:rotate-12 mt-auto mb-auto h-7 w-7 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                onClick={onUpdate}
                            >
                                <ArrowPathIcon className="m-auto"/>
                            </Button>
                        </div>
                    </div>

                    <div className={`text-xl mr-1.5 border ${darkMode ? 'bg-darkMainColor border-neutral-700 text-white' : 'bg-white border text-black'} w-5/6 flex rounded-md border drop-shadow shadow-gray-900`}>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Users:</div>
                            <div className={`text-sm rounded-md border ${darkMode ? 'border-neutral-700' : ''} px-2 line-clamp-1`}>{users.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Filtered Users:</div>
                            <div className={`text-sm rounded-md border ${darkMode ? 'border-neutral-700' : ''} px-2 line-clamp-1`}>{filteredUsers.length}</div>
                        </div>
                    
                    </div>
                
                </div>
                
                {/*Search bar row*/}
                <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>

                    {/*Search Bar*/}
                    <div className="w-1/2 mr-1.5 h-fit text-sm">
                        {/*<div className="text-sm mb-1">Product</div>*/}

                        <div className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>

                            <div className="w-full">

                                <Input
                                    className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                    placeholder="Search Sales Staff By Name"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />

                            </div>

                        </div>

                    </div>

                    {/*Region*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Category</div>*/}
                            <Select
                                className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                onChange={(e) => filterUsersByRegion(e.target.value)}
                            >
                                <option value="">Select Region...</option>
                                <option value="NE">North East</option>
                                <option value="SE">South East</option>
                                <option value="AG">Agriculture</option>
                                <option value="DIST">Distributor</option>
                                <option value="COM">Commercial</option>
                            </Select>
                        </div>
                    
                    </div>
                    
                    {/*Account Level*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full text-sm">
                        
                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Industry</div>*/}
                            
                            <Select
                                className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                onChange={(e) => filterUsersByAccountLevel(e.target.value)}
                            >
                                <option value="">Select Account Level...</option>
                                <option value="D">Developer</option>
                                <option value="A">Admin</option>
                                <option value="CSR">Customer Service Rep</option>
                                <option value="PFC">PFC Fulfillment</option>
                                <option value="DIST">Distributor</option>
                                <option value="M">Marketing</option>
                                <option value="S">Sales Rep</option>
                            </Select>
                        </div>
                    
                    </div>
                    
                    {/*SuperVisor*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full text-sm">
                        
                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Industry</div>*/}
                            
                            <Select
                                className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                onChange={(e) => filterUsersBySuper(e.target.value)}
                            >
                                <option value="">Select SuperVisor...</option>
                                <option value="GP">Grant Platz</option>
                                <option value="JN">Jordan Nemitz</option>
                                <option value="TW">Tom Weinert</option>
                            </Select>
                        </div>
                    
                    </div>
                
                </div>
                
                {/*Users Body*/}
                <div className="w-full h-full rounded-md">
                    
                    {/*Users*/}
                    <div className="w-full flex h-full flex-col">
                        
                        
                        <div className={`w-full h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg`}>

                            {/*Users MAP*/}
                            <div className={`overflow-y-auto relative scrollbar-hide h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg max-h-[calc(100dvh-12rem)] scroll`}>

                                <div>

                                    {filteredUsers.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="absolute flex w-full h-full justify-center items-center">
                                            <div className={`flex items-center border-2 border-dashed ${
                                                darkMode ? "text-white border-borderColor" : "text-black"
                                            } w-fit px-24 py-3 h-fit mr-auto ml-auto backdrop-blur-md justify-center rounded-lg hover:scale-105`}
                                            >
                                                <div
                                                    className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                    <UserGroupIcon className="w-10 stroke-2 mr-3"/>
                                                    <p className="text-xl mt-2 line-clamp-1 tracking-widest flex">No Users</p>
                                                    <p className="text-md mt-2 line-clamp-1 tracking-widest flex">You have have no users that match your search or your filters</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // Display ProductCard components if products array is not empty
                                        <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
                                            {filteredUsers
                                                .sort((a, b) => a.id - b.id) // Ensure items are sorted by ID
                                                .map((user, index) => (
                                                    <UserCard
                                                        key={index}
                                                        user={user}
                                                    />
                                                ))}
                                        </div>
                                    
                                    )}
                                
                                
                                </div>
                            
                            </div>
                        
                        
                        </div>
                    
                    
                    </div>
                
                </div>
            
            
            </div>
        
        
        </div>
    )
};


export default SalesTeam;
