import {Button, Input, Select} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {XMarkIcon} from "@heroicons/react/16/solid";
import fipsCode from "../../fipCodes.json";
import {ArrowDownCircleIcon, ArrowUpCircleIcon} from "@heroicons/react/24/outline";
import {useDarkMode} from "../../contexts/darkModeContext";

const AddressInput = ({ id, index, item, onRemove, onChange, Eaddress1, Eaddress2, Ecity, Ezip, Estate, Ecounty, Efips }) => {

    const [address1, setAddress1] = useState(item?.address1 || "");
    const [address2, setAddress2] = useState(item?.address2 || "");
    const [city, setCity] = useState(item?.city || "");
    const [selectedState, setSelectedState] = useState(item?.selectedState || 'NJ'); // Preselect NJ
    const [zip, setZip] = useState(item?.zip || "");
    const [selectedCounty, setSelectedCounty] = useState(item?.selectedCounty || '');
    const [fips, setFips] = useState(item?.fips || "");
    const [country, setCountry] = useState(item?.country || "United States");
    const [contactName, setContactName] = useState(item?.contactName || "");
    const [contactPhone, setContactPhone] = useState(item?.contactPhone || "");
    const [shipEmail, setShippingEmail] = useState(item?.shipEmail || "");
    const [shipNotes, setShippingNotes] = useState(item?.shipNotes || "");

    const { darkMode } = useDarkMode();

    const [nickName, setNickName] = useState(item?.nickName || "");


    const [isVisible, setIsVisible] = useState(true); // State to track visibility

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        if (contactName.trim() !== '' && contactPhone.trim() !== '') {
            setIsVisible(true); // Set visibility to true if both inputs are filled
        } else {
            setIsVisible(true); // Set visibility to false otherwise
        }
    }, [contactName, contactPhone]); // Runs whenever fname or lname changes

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    // Handle the remove action with animation
    const handleRemove = () => {
        setIsRemoving(true); // Trigger the animation
        setTimeout(() => onRemove(id), 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    };
    
    const handleCountyChange = (event) => {
        const countyName = event.target.value;
        setSelectedCounty(countyName);

        // Find the selected county's data based on the selected county name
        const countyData = filteredCounties.find(county => county.county === countyName);

        // Set the fips code if county data exists, otherwise set it to an empty string
        if (countyData) {
            setFips(countyData.fips);
        } else {
            setFips("");  // Handle case where no county is selected or found
        }
    };

    // Filter counties based on the selected state
    const filteredCounties = fipsCode.county.filter(county => county.state === selectedState);

    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };
    
    
    
    const [isChecked, setIsChecked] = useState(false);

     const handleCheckboxChange = () => {
        setIsChecked(prev => {
            const newCheckedState = !prev;
            
            setAddress1(newCheckedState ? Eaddress1 : ""); // Set or reset nickName
            setAddress2(newCheckedState ? Eaddress2 : ""); // Set or reset nickName
            setCity(newCheckedState ? Ecity : ""); // Set or reset nickName
            setSelectedState(newCheckedState ? Estate : ""); // Set or reset nickName
            setZip(newCheckedState ? Ezip : ""); // Set or reset nickName
            setSelectedCounty(newCheckedState ? Ecounty : ""); // Set or reset nickName
            setFips(newCheckedState ? Efips : ""); // Set or reset nickName
            
            return newCheckedState;
        });
    };



    useEffect(() => {
        onChange(index, {
            address1,
            address2,
            city,
            selectedState,
            zip,
            selectedCounty,
            fips,
            country,
            contactName,
            contactPhone,
            shipEmail,
            shipNotes,
            nickName
        });
        // eslint-disable-next-line
    }, [address1, address2, city, selectedState, zip, selectedCounty, fips, country, contactName, contactPhone, shipEmail, shipNotes, nickName]); // Update on change



    return (
        <div className={`w-full mb-3 flex flex-col p-1.5 border-2 ${darkMode ? 'border-neutral-700 text-white' : 'text-black'} rounded-lg transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 border-red-500' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className="w-full flex flex-row items-center">

                {/*Field Grid*/}
                <div className="w-full mx-1 mt-2 grid grid-cols-6 gap-x-5 gap-y-4">
                    
                    {/*Nick Name*/}
                    <div className={`relative col-span-2`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            Location Nick Name
                            <div className={`ml-1`}>
                                <div className={`flex-none rounded-full p-1 ${nickName ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                    <div className={`size-2 rounded-full ${nickName ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                </div>
                            </div>
                        </label>
                        <Input
                            type="text"
                            required
                            value={nickName}
                            onChange={(e) => setNickName(e.target.value)}
                            placeholder={`Shipping Location Nick Name`}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        />
                    </div>
                    
                    {/*Contact Name*/}
                    <div className={`relative col-span-2`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            Contact Name
                            <div className={`ml-1`}>
                                <div className={`flex-none rounded-full p-1 ${contactName ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                    <div className={`size-2 rounded-full ${contactName ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                </div>
                            </div>
                        </label>
                        <Input
                            type="text"
                            value={contactName}
                            onChange={(e) => setContactName(e.target.value)}
                            placeholder={`Contact Name`}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        />
                    </div>
                    
                    {/*ContactPhone*/}
                    <div className={`relative col-span-2`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            Contact Phone
                            <div className={`ml-1`}>
                                <div className={`flex-none rounded-full p-1 ${contactPhone ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                    <div className={`size-2 rounded-full ${contactPhone ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                </div>
                            </div>
                        </label>
                        <Input
                            type="text"
                            value={contactPhone}
                            onChange={(e) => setContactPhone(e.target.value)}
                            placeholder={`Website`}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        />
                    </div>
                    
                </div>
                
                {/*Buttons*/}
                <div className="flex flex-col items-center">
                    <Button
                        className="rounded-full ml-3 mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                        onClick={handleRemove}
                    >
                        <XMarkIcon className="h-6 text-gray-500 hover:text-red-600 hover:scale-110 m-auto"/>
                    </Button>

                    <Button
                        className="rounded-full ml-3 mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                        onClick={toggleVisibility}
                    >
                        {isVisible ? (
                            <ArrowUpCircleIcon
                                className="h-6 text-borderColor hover:text-green-600 stroke-2 hover:scale-110 m-auto"/>
                        ) : (
                            <ArrowDownCircleIcon
                                className="h-6 text-borderColor hover:text-green-600 stroke-2 hover:scale-110 m-auto"/>
                        )}
                    </Button>
                </div>

            </div>

            {/* Conditionally render content based on isVisible */}
            {isVisible && (
                <div className={`w-full px-1 pb-2 mt-4 grid grid-cols-6 gap-x-4 gap-y-5 border-t-4 border-dotted ${darkMode ? 'border-neutral-700' : ''}`}>

                    <label htmlFor="region" className="col-span-full block text-sm font-medium leading-6 mt-2">
                        SHIPPING INFO
                    </label>
                    
                    
                    
                    {/*Shipping Email*/}
                    <div className={`relative col-span-2`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            Ship To Email
                        </label>
                        <Input
                            type="text"
                            required
                            value={shipEmail}
                            onChange={(e) => setShippingEmail(e.target.value)}
                            placeholder={`Optional`}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        />
                    </div>
                    
                    {/*Shipping Notes*/}
                    <div className={`relative col-span-2`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            Ship To Notes
                        </label>
                        <Input
                            type="text"
                            value={shipNotes}
                            onChange={(e) => setShippingNotes(e.target.value)}
                            placeholder={`Optional`}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        />
                    </div>
                    
                    {/*Country*/}
                    <div className={`relative col-span-2`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            Country
                            <div className={`ml-1`}>
                                <div className={`flex-none rounded-full p-1 ${country ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                    <div className={`size-2 rounded-full ${country ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                </div>
                            </div>
                        </label>
                        <Select
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            placeholder={`Country`}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        >
                            <option value="United States">United States</option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Brazil">Brazil</option>
                            <option value="Brunei">Brunei</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cabo Verde">Cabo Verde</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo (Congo-Brazzaville)">Congo (Congo-Brazzaville)</option>
                            <option value="Congo (Democratic Republic)">Congo (Democratic Republic)</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czechia">Czechia</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Eswatini">Eswatini</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Greece">Greece</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Laos">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia">Micronesia</option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="North Korea">North Korea</option>
                            <option value="North Macedonia">North Macedonia</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Korea">South Korea</option>
                            <option value="South Sudan">South Sudan</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Togo">Togo</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City">Vatican City</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Vietnam</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                        </Select>
                    </div>
                    
                    
                    <div className={`col-span-full flex items-center`}>
                        <label htmlFor="region" className=" block text-sm font-medium leading-6">
                            ADDRESS
                        </label>
                        
                        <div className="flex gap-3 ml-6">
                            <div className="flex h-6 shrink-0 items-center">
                                <div className="group grid size-3.5 grid-cols-1">
                                    <Input
                                        id="comments"
                                        name="comments"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        aria-describedby="comments-description"
                                        className={`col-start-1 row-start-1 appearance-none rounded border border-gray-300 ${darkMode ? 'bg-darkMainColor' : 'bg-white'} checked:border-green-600 checked:bg-green-600 indeterminate:border-green-600 indeterminate:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto`}
                                    />
                                    <svg
                                        fill="none"
                                        viewBox="0 0 14 14"
                                        className="pointer-events-none col-start-1 row-start-1 size-2.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                                    >
                                        <path
                                            d="M3 8L6 11L11 3.5"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="opacity-0 group-has-[:checked]:opacity-100"
                                        />
                                        <path
                                            d="M3 7H11"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="opacity-0 group-has-[:indeterminate]:opacity-100"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="text-xs/6">
                                <label htmlFor="comments" className="font-medium">
                                    Same as Billing Address
                                </label>
                            </div>
                        </div>
                    
                    </div>
                    
                    
                    {/*Address 1*/}
                    <div className={`relative col-span-3`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            Street Address [1]
                            <div className={`ml-1`}>
                                <div
                                    className={`flex-none rounded-full p-1 ${address1 ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                    <div
                                        className={`size-2 rounded-full ${address1 ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                </div>
                            </div>
                        </label>
                        <Input
                            type="text"
                            required
                            value={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                            placeholder={`Street Address 1`}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        />
                    </div>
                    
                    {/*Address 2*/}
                    <div className={`relative col-span-3`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            Street Address [2]
                        </label>
                        <Input
                            type="text"
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                            placeholder={`Optional`}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        />
                    </div>
                    
                    
                    
                    {/*City*/}
                    <div className={`relative col-span-2`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            City
                            <div className={`ml-1`}>
                                <div className={`flex-none rounded-full p-1 ${city ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                    <div className={`size-2 rounded-full ${city ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                </div>
                            </div>
                        </label>
                        <Input
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            placeholder={`City`}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        />
                    </div>
                    
                    {/*State*/}
                    <div className={`relative col-span-2`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            State
                            <div className={`ml-1`}>
                                <div className={`flex-none rounded-full p-1 ${selectedState ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                    <div className={`size-2 rounded-full ${selectedState ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                </div>
                            </div>
                        </label>
                        <Select
                            value={selectedState}
                            onChange={(e) => setSelectedState(e.target.value)}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        >
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </Select>
                    </div>
                    
                    {/*Zip Code*/}
                    <div className={`relative col-span-2`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            Zip Code
                            <div className={`ml-1`}>
                                <div className={`flex-none rounded-full p-1 ${zip ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                    <div className={`size-2 rounded-full ${zip ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                </div>
                            </div>
                        </label>
                        <Input
                            type="text"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            placeholder={`00000`}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        />
                    </div>
                    
                    
                    
                    <label htmlFor="region" className="col-span-full block text-sm font-medium leading-6">
                        FIPS CODE
                    </label>

                    
                    
                    {/*County*/}
                    <div className={`relative col-span-4`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            County
                            <div className={`ml-1`}>
                                <div className={`flex-none rounded-full p-1 ${selectedCounty ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                    <div className={`size-2 rounded-full ${selectedCounty ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                </div>
                            </div>
                        </label>
                        <Select
                            required
                            value={selectedCounty}
                            onChange={handleCountyChange}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        >
                            <option value="">Select a County...</option>
                            {filteredCounties.map(county => (
                                <option key={county.fips}
                                        value={county.county}>{county.county}</option>
                            ))}
                        </Select>
                    </div>
                    
                    {/*Fips Code*/}
                    <div className={`relative col-span-2`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            Fips Code
                            <div className={`ml-1`}>
                                <div className={`flex-none rounded-full p-1 ${fips ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                    <div className={`size-2 rounded-full ${fips ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                </div>
                            </div>
                        </label>
                        <Input
                            type="text"
                            required
                            disabled={true}
                            placeholder="Fips Code"
                            value={formatFipsCode(fips)}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        />
                    </div>
                    

                </div>
            )}


        </div>
    )
}

export default AddressInput;
