import React, {useEffect, useMemo, useState} from 'react';

import SideBar from "./SideBar";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import {
    ArrowLongDownIcon,
    CheckIcon,
    CreditCardIcon, EnvelopeIcon,
    ExclamationTriangleIcon,
    IdentificationIcon,
    MapPinIcon,
    PaperAirplaneIcon,
    PhoneIcon,
    PlusCircleIcon,
    PlusIcon,
    ShieldCheckIcon, TrashIcon,
    TruckIcon, UserIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {
    Button,
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Field,
    Input,
    Label,
    Select,
    Switch
} from "@headlessui/react";
import fipsCode from '../fipCodes.json';
import AddressInput from "./CustomerForm/AddressInput";
import websiteSRC from "../NCFP.JPG"
import {useDarkMode} from "../contexts/darkModeContext";
import FireworksEffect from "./FireworksEffect";
import logo from "../main-logo.png";
import {Link} from "react-router-dom";
import {collection, getDocs} from "firebase/firestore";



const NewCustomer = () => {

    const { currentUser } = useAuth();
    
    const [selectSales, setSelectSales] = useState(!currentUser);
    
    

    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });
    
    
    const [showFireworks, setShowFireworks] = useState(false)


    const { darkMode } = useDarkMode();
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isClearing, setIsClearing] = useState(false);

    const [date, setDate] = useState("");

    const [fullname, setFullname] = useState("");
    const [salesCode, setSalesCode] = useState("");
    const [salesEmail, setSalesEmail] = useState("");
    const [salesID, setSalesID] = useState("");


    // MAIN DATA POINTS
    const [name, setName] = useState("");
    const [managementName, setManagementName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [selectedState, setSelectedState] = useState('NJ'); // Preselect NJ
    const [zip, setZip] = useState("");
    const [selectedCounty, setSelectedCounty] = useState('');
    const [fips, setFips] = useState("");
    const [country, setCountry] = useState("United States");
    const [industry, setIndustry] = useState("");


    // CONTACTS DATA POINTS
    const [contact, setContact] = useState("");
    const [contactPosition, setContactPosition] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [billingContact, setBillingContact] = useState("");
    const [billingPhone, setBillingPhone] = useState("");
    const [invoiceEmails, setInvoiceEmails] = useState("");
    const [statementEmails, setStatementEmails] = useState("");

    const [website, setWebsite] = useState("");


    // SHIPPING DATA POINTS
    const [shippingAddress, setShippingAddress] = useState([]);


    // TAXES DATA POINTS
    const [taxID, setTaxID] = useState("");
    const [salesTaxStatus, setSalesTaxStatus] = useState("");
    const [taxExempCode, setTaxExempCode] = useState("");


    // PAYMENT DATA POINTS
    const [paymentType, setPaymentType] = useState("");
    
    // DELIVERY DATA POINTS
    const [TTA, setTTA] = useState(false);
    const [LAS, setLAS] = useState(false);
    const [LDA, setLDA] = useState(false);
    const [PJR, setPJR] = useState(false);
    const [DAR, setDAR] = useState(false);
    const [HSA, setHSA] = useState(false);
    const [FLC, setFLC] = useState(false);
    const [RPY, setRPY] = useState(false);
    
    const [deliveryInstructions, setDeliveryInstructions] = useState("");

    const handleStateChange = (event) => {
        const stateCode = event.target.value;
        setSelectedState(stateCode);
        setSelectedCounty(''); // Reset county selection when state changes
    };

    const handleCountyChange = (event) => {
        const countyName = event.target.value;
        setSelectedCounty(countyName);

        // Find the selected county's data based on the selected county name
        const countyData = filteredCounties.find(county => county.county === countyName);

        // Set the fips code if county data exists, otherwise set it to an empty string
        if (countyData) {
            setFips(countyData.fips);
        } else {
            setFips("");  // Handle case where no county is selected or found
        }
    };

    // Filter counties based on the selected state
    const filteredCounties = fipsCode.county.filter(county => county.state === selectedState);


    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };


    const features = [
        {
            name: 'Representative Assistance',
            description:
                'After submitting your form, a PlantFood Company representative will contact you to help complete your financial section and guide you through the credit application process.',
            icon: PhoneIcon, // Consider using a phone or contact-related icon
        },
        {
            name: 'Secure and Confidential',
            description:
                'Your financial information will be handled with the utmost care. Our process ensures your data is securely protected every step of the way.',
            icon: ShieldCheckIcon, // A security-related icon like a shield
        },
    ];



    const tabOrder = ['main', 'contacts', 'shipping', 'taxes', 'delivery'];

    const handleNextTab = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        if (currentIndex < tabOrder.length - 1) {
            setActiveTab(tabOrder[currentIndex + 1]);
        }
    };

    const handlePreviousTab = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        if (currentIndex > 0) {
            setActiveTab(tabOrder[currentIndex - 1]);
        }
    };

    const [users, setUsers] = useState([]);
    
    const [selectedRep, setSelectedRep] = useState(null);
    
    const handleSelectChange = (event) => {
        const selectedUser = event.target.value ? JSON.parse(event.target.value) : null;
    
        if (selectedUser) {
            setSelectedRep(selectedUser);
            setFullname(selectedUser.salesRep || "");
            setSalesCode(selectedUser.salesCode || "");
            setSalesEmail(selectedUser.salesEmail || "");
            setSalesID(selectedUser.salesID || "");
        } else {
            setSelectedRep(null);
            setFullname("");
            setSalesCode("");
            setSalesEmail("");
            setSalesID("");
        }
    };

    useEffect(() => {
        if (!currentUser) {
            if(salesID){
                setSelectSales(false);
            } else {
                setSelectSales(true);
            }
        }
        // eslint-disable-next-line
    }, [selectSales]);
    
    // Fetch users from Firestore on component mount
    useEffect(() => {
        const fetchUsers = async () => {
            const usersCollection = collection(db, 'Users');
            const userSnapshot = await getDocs(usersCollection);
            const userList = userSnapshot.docs.map(doc => ({
                salesID: doc.id, // UID from Firestore document ID
                salesRep: `${doc.data().fname} ${doc.data().lname}`, // Combining fname and lname into a single name field
                accountLevel: doc.data().accountLevel,
                salesEmail: doc.data().email,
                salesCode: doc.data().salesCode,
            }));
            setUsers(userList);
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setSalesCode(userData.salesCode);
                        setSalesEmail(userData.email);
                        setSalesID(userData.id);

                        setFullname(userData.fname + " " + userData.lname);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setSalesCode(userData.salesCode);
                            setSalesEmail(userData.email);
                            setSalesID(userData.id);

                            setFullname(userData.fname + " " + userData.lname);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    const [activeTab, setActiveTab] = useState('main');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const onSubmitMain = async (e) => {
        e.preventDefault();

        console.log("Sales ID", salesID ?? currentUser?.uid);
        console.log("Sales Rep", fullname);
        console.log("Sales Code", salesCode);
        
        const collectionRef = db.collection("Customers").doc(salesID ?? currentUser?.uid).collection("pending");

        const parsedFormData = {

            // Sales Info
            salesRep: fullname,
            salesCode: salesCode,
            salesID: salesID ?? currentUser?.uid,
            status: "pending",
            salesEmail: salesEmail,

            // Main
            name: name,
            managementName: managementName,
            address1: address1,
            address2: address2,
            city: city,
            state: selectedState, // Using selectedState for state
            zip: zip,
            county: selectedCounty, // Using selectedCounty for county
            fips: fips,
            country: country,
            industry: industry,

            // Contacts
            contact: contact,
            contactPosition: contactPosition,
            contactPhone: contactPhone,
            contactEmail: contactEmail,
            billingContact: billingContact,
            billingPhone: billingPhone,
            invoiceEmails: invoiceEmails,
            statementEmails: statementEmails,
            website: website,

            // Shipping
            ShippingAddresses: shippingAddress,

            // Finances
            taxID: taxID,
            salesTaxStatus: salesTaxStatus,
            taxExempCode: taxExempCode,
            paymentType: paymentType,

            // Delivery
            TTA: TTA,
            LAS: LAS,
            LDA: LDA,
            PJR: PJR,
            DAR: DAR,
            HSA: HSA,
            FLC: FLC,
            RPY: RPY,
            deliveryInstructions: deliveryInstructions,


        };

        if (isFormIncomplete) {
            setShowPopup(true);
        } else {
             try {
                await collectionRef.add(parsedFormData);
                // handleNextTab();
                // alert(`Customer Submitted to the Cloud`);
                if (alertsOn) {
                    alert(`Customer Added to the Cloud`); // Optional: alert for user feedback
                }
                
                setShowFireworks(true);
                setIsSubmitting(false);
                setTimeout(() => setShowFireworks(false), 5000) // Hide fireworks after 3 seconds
                
                
             } catch (error) {
                 console.error('Error registering client:', error.message);
                 // setIsRegistered(false);
             }
        }
        
    };
    
    const handleAddComponent = () => {
        const newId = shippingAddress.length + Math.random(); // Create a unique id for each new address
        setShippingAddress([...shippingAddress, { id: newId }]); // Add a new address with its unique id
    };

// Function to remove an address input component based on ID
    const handleRemoveComponent = (idToRemove) => {
        setShippingAddress(shippingAddress.filter(item => item.id !== idToRemove)); // Remove the item with the matching id
    };

// Function to handle input changes from child components
    const handleAddressChange = (index, updatedAddress) => {
        const updatedAddresses = [...shippingAddress];
        updatedAddresses[index] = { ...updatedAddresses[index], ...updatedAddress }; // Ensure we maintain other properties like id
        setShippingAddress(updatedAddresses);
    };



    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission on Enter
            // handleNextTab(); // Navigate to the next tab
        }
    };

    // fetch time and date
    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();
    }, []);

    
    const clearAllFields = () => {
        setName("");
        setManagementName("");
        setAddress1("");
        setAddress2("");
        setCity("");
        setSelectedState("NJ"); // Reset to default "NJ"
        setZip("");
        setSelectedCounty("");
        setFips("");
        setCountry("United States"); // Reset to default
        setIndustry("");
    
        setContact("");
        setContactPosition("");
        setContactPhone("");
        setContactEmail("");
        setBillingContact("");
        setBillingPhone("");
        setInvoiceEmails("");
        setStatementEmails("");
    
        setWebsite("");
    
        setShippingAddress([]); // Reset to an empty array
    
        setTaxID("");
        setSalesTaxStatus("");
        setTaxExempCode("");
    
        setPaymentType("");
    
        setTTA(false);
        setLAS(false);
        setLDA(false);
        setPJR(false);
        setDAR(false);
        setHSA(false);
        setFLC(false);
        setRPY(false);
        setDeliveryInstructions("");
        
        setActiveTab("main")
        
        setIsClearing(false);
    };
    
    const [isFormIncomplete, setIsFormIncomplete] = useState(true);
    const [missingFields, setMissingFields] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    
    // Tab mapping
    const fieldToTab = {
        name: "main",
        address1: "main",
        city: "main",
        zip: "main",
        selectedState: "main",
        selectedCounty: "main",
        fips: "main",
        country: "main",
        industry: "main",
        contact: "contacts",
        contactPhone: "contacts",
        // paymentType: "taxes",
        billingContact: "contacts",
        billingPhone: "contacts",
        invoiceEmails: "contacts",
        statementEmails: "contacts",
        // taxID: "taxes",
        // salesTaxStatus: "taxes",
    };
    
    // Labels for each field
    const fieldLabels = {
        name: "Business Name",
        address1: "Address",
        city: "City",
        zip: "Zip Code",
        selectedState: "State",
        selectedCounty: "County",
        fips: "FIPS Code",
        country: "Country",
        industry: "Industry",
        contact: "Primary Contact",
        contactPhone: "Primary Contact Phone",
        // paymentType: "Payment Type",
        billingContact: "Billing Contact",
        billingPhone: "Billing Contact Phone",
        invoiceEmails: "Invoice Email(s)",
        statementEmails: "Statement Email(s)",
        // taxID: "Tax ID",
        // salesTaxStatus: "Sales Tax Status",
    };
    
    useEffect(() => {
        const requiredFields = {
            name,
            address1,
            city,
            zip,
            selectedState,
            selectedCounty,
            fips,
            country,
            industry,
            contact,
            contactPhone,
            // paymentType,
            billingContact,
            billingPhone,
            invoiceEmails,
            statementEmails,
            // taxID,
            // salesTaxStatus,
        };

        // Find missing fields
        const missing = Object.keys(requiredFields).filter(
            (key) => requiredFields[key].trim() === ""
        );

        setMissingFields(missing);
        setIsFormIncomplete(missing.length > 0);
    }, [
        name,
        address1,
        city,
        zip,
        selectedState,
        selectedCounty,
        fips,
        country,
        industry,
        contact,
        contactPhone,
        // paymentType,
        billingContact,
        billingPhone,
        invoiceEmails,
        statementEmails,
        // taxID,
        // salesTaxStatus,
    ]);
    

    const handleErrorSubmit = () => {
        if (isFormIncomplete) {
            setShowPopup(true);
        } else {
            setIsSubmitting(true);
        }
    };

    const stepsBase = [
        { id: '01', name: 'Main', key: 'main', href: '#', status: 'upcoming' },
        { id: '02', name: 'Contacts', key: 'contacts', href: '#', status: 'upcoming' },
        { id: '03', name: 'Shipping', key: 'shipping', href: '#', status: 'upcoming' },
        { id: '04', name: 'Finances', key: 'taxes', href: '#', status: 'upcoming' },
        { id: '05', name: 'Delivery', key: 'delivery', href: '#', status: 'upcoming' }
    ]
    
    const steps = useMemo(() => {
        return stepsBase.map((step) => {
            const stepIndex = tabOrder.indexOf(step.key);
            const activeIndex = tabOrder.indexOf(activeTab);

            return {
                ...step,
                status: stepIndex < activeIndex ? 'complete' : stepIndex === activeIndex ? 'current' : 'upcoming'
            };
        });
    }, [activeTab]);

    
    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex w-full ${currentUser ? 'mt-16 h-[calc(100dvh-4rem)]' : 'h-dvh'} transition-all duration-500 ease-in-out`}>
            
            {currentUser && (
                <SideBar/>
            )}
            
            {showFireworks && <FireworksEffect show={showFireworks} />}

            <div className={`z-20 flex flex-col w-full ${currentUser ? 'max-w-[calc(100%-6rem)]' : 'w-full ml-3'} mb-3 mt-3 mr-3 animate-fadeIn`}>

                {/*NEW CUSTOMER HEADER [Sales Rep Info]*/}
                <div className={`w-full flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>
                    
                    {/*Label*/}
                    <div className="text-2xl w-1/4 flex items-center">
                        {!currentUser && (
                            <Link to={'/'}>
                                <img
                                    alt=""
                                    src={logo}
                                    className="h-12 w-auto mr-3"
                                />
                            </Link>
                        )}
                        {currentUser ? 'New Customer' : 'Customer Form'}
                        {/*Buttons Delete*/}
                        <div className={`flex justify-center items-center ml-3 mt-auto mb-auto`}>
                            <Button className={`rounded-full border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} p-1 hover:scale-105 hover:rotate-12 mt-auto mb-auto h-7 w-7 items-center justify-center align-middle`}
                                onClick={() => setIsClearing(true)}
                            >
                                <TrashIcon className="m-auto text-red-500"/>
                            </Button>
                        </div>
                    </div>
                    
                    {/*Sales Info*/}
                    <div className={`text-xl border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white border'} w-3/4 flex rounded-md drop-shadow shadow-gray-900`}>
                        
                        <div className={`w-full`}>
                            <nav aria-label="Progress">
                                <ol role="list"
                                    className="divide-y divide-gray-300 rounded-md md:flex md:divide-y-0">
                                    {steps.map((step, stepIdx) => (
                                        <li key={step.name} className="relative md:flex md:flex-1">
                                            {step.status === 'complete' ? (
                                                <a href={step.href} className="group flex w-full items-center">
                                                    <span className="flex items-center px-6 py-3 text-sm font-medium">
                                                      <span
                                                          className="flex size-6 shrink-0 items-center justify-center rounded-full bg-green-600 group-hover:bg-green-800">
                                                        <CheckIcon aria-hidden="true" className="size-4 text-white"/>
                                                      </span>
                                                      <span className="ml-4 text-sm font-medium">{step.name}</span>
                                                    </span>
                                                </a>
                                            ) : step.status === 'current' ? (
                                                <a href={step.href} aria-current="step"
                                                   className="flex items-center px-6 py-3 text-sm font-medium">
                                                    <span
                                                        className="flex size-6 shrink-0 items-center justify-center rounded-full border-2 border-green-600">
                                                      <span className="text-green-600 text-xs">{step.id}</span>
                                                    </span>
                                                    <span className="ml-4 text-sm font-medium">{step.name}</span>
                                                </a>
                                            ) : (
                                                <a href={step.href} className="group flex items-center">
                                                    <span className="flex items-center justify-center px-6 py-3 text-sm font-medium">
                                                          <span
                                                              className={`flex size-6 shrink-0 items-center justify-center rounded-full border-2 ${darkMode ? 'border-neutral-700' : 'border-gray-300'}`}>
                                                            <span className="text-gray-500 text-xs">{step.id}</span>
                                                          </span>
                                                        <span className="ml-4 text-sm font-medium">{step.name}</span>
                                                    </span>
                                                </a>
                                            )}
                                            
                                            {stepIdx !== steps.length - 1 ? (
                                                <>
                                                    {/* Arrow separator for lg screens and up */}
                                                    <div aria-hidden="true"
                                                         className="absolute right-0 top-0 hidden h-full w-5 md:block">
                                                        <svg fill="none" viewBox="0 0 22 80" preserveAspectRatio="none"
                                                             className={`size-full stroke-2 ${darkMode ? 'text-neutral-700' : 'text-gray-500`'}`}>
                                                            <path
                                                                d="M0 -2L20 40L0 82"
                                                                stroke="currentcolor"
                                                                vectorEffect="non-scaling-stroke"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </div>
                                                </>
                                            ) : null}
                                        </li>
                                    ))}
                                </ol>
                            </nav>
                        </div>
                    
                    </div>
                
                </div>
                
                <form onSubmit={onSubmitMain} method="POST" onKeyDown={handleKeyDown}
                      className="h-full w-full flex flex-col justify-between">
                    
                    
                    <div className="flex flex-row w-full h-full">
                        
                        
                        {/* Tabs Navigation */}
                        <div className={`me-3 flex w-48 list-none border animate-fadeIn ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white border'} rounded-md justify-center items-center flex-col flex-wrap p-3 drop-shadow shadow-gray-900 max-h-[calc(100dvh-3.5rem)] scroll overflow-y-auto`}
                            role="tablist">
                            
                            {/*MAIN*/}
                            <li role="presentation" className="flex-grow w-full text-center">
                                <Button
                                    onClick={() => handleTabClick('main')}
                                    className={`my-2 flex items-center w-full justify-center hover:scale-105 px-4 py-1 text-xs rounded-lg bg-opacity-50 font-medium uppercase leading-tight ${
                                        activeTab === 'main'
                                            ? 'bg-green-600 text-white text-primary'
                                            : `hover:bg-green-600/30 ${darkMode ? ' text-white' : ' text-black'}`
                                    }`}
                                    role="tab"
                                    aria-controls="tabs-main"
                                    aria-selected={activeTab === 'main'}
                                >
                                    <IdentificationIcon className={`w-5 mr-2`}/>
                                    Main
                                </Button>
                            </li>
                            
                            <li role="presentation" className="flex-grow  text-center">
                                <ArrowLongDownIcon className="h-8 text-green-600"/>
                            </li>
                            
                            {/*CONTACTS*/}
                            <li role="presentation" className="flex-grow w-full text-center">
                                <Button
                                    onClick={() => handleTabClick('contacts')}
                                    className={`my-2 hover:scale-105 flex items-center w-full justify-center px-4 py-1.5 text-xs rounded-lg bg-opacity-50 font-medium uppercase leading-tight ${
                                        activeTab === 'contacts'
                                            ? 'bg-green-600 text-white text-primary'
                                            : `hover:bg-green-600/30 ${darkMode ? 'text-white' : 'text-black'}`
                                    }`}
                                    role="tab"
                                    aria-controls="tabs-contacts"
                                    aria-selected={activeTab === 'contacts'}
                                >
                                    <PhoneIcon className={`w-4 mr-2`}/>
                                    Contacts
                                </Button>
                            </li>
                            
                            <li role="presentation" className="flex-grow  text-center">
                                <ArrowLongDownIcon className="h-8 text-green-600"/>
                            </li>
                            
                            {/*SHIPPING*/}
                            <li role="presentation" className="flex-grow w-full text-center">
                                <Button
                                    onClick={() => handleTabClick('shipping')}
                                    className={`my-2 hover:scale-105 flex items-center px-4 py-1 text-xs rounded-lg w-full justify-center bg-opacity-50 font-medium uppercase leading-tight ${
                                        activeTab === 'shipping'
                                            ? 'bg-green-600 text-white text-primary'
                                            : `hover:bg-green-600/30 ${darkMode ? 'text-white' : 'text-black'}`
                                    }`}
                                    role="tab"
                                    aria-controls="tabs-shipping"
                                    aria-selected={activeTab === 'shipping'}
                                >
                                    <MapPinIcon className={`w-5 mr-2`}/>
                                    Shipping
                                </Button>
                            </li>

                            <li role="presentation" className="flex-grow  text-center">
                                <ArrowLongDownIcon className="h-8 text-green-600"/>
                            </li>

                            {/*FINANCES*/}
                            <li role="presentation" className="flex-grow w-full text-center">
                                <Button
                                    onClick={() => handleTabClick('taxes')}
                                    className={`my-2 hover:scale-105 flex items-center px-4 py-1 text-xs rounded-lg w-full justify-center bg-opacity-50 font-medium uppercase leading-tight ${
                                        activeTab === 'taxes'
                                            ? 'bg-green-600 text-white text-primary'
                                            : `hover:bg-green-600/30 ${darkMode ? ' text-white' : ' text-black'}`
                                    }`}
                                    role="tab"
                                    aria-controls="tabs-taxes"
                                    aria-selected={activeTab === 'taxes'}
                                >
                                    <CreditCardIcon className={`w-5 mr-2`}/>
                                    Finances
                                </Button>
                            </li>

                            <li role="presentation" className="flex-grow  text-center">
                                <ArrowLongDownIcon className="h-8 text-green-600"/>
                            </li>
                            
                            {/*DELIVERY*/}
                            <li role="presentation" className="flex-grow w-full text-center">
                                <Button
                                    onClick={() => handleTabClick('delivery')}
                                    className={`my-2 hover:scale-105 flex items-center px-4 py-1 text-xs rounded-lg w-full justify-center bg-opacity-50 font-medium uppercase leading-tight ${
                                        activeTab === 'delivery'
                                            ? 'bg-green-600 text-white text-primary'
                                            : `hover:bg-green-600/30 ${darkMode ? 'text-white' : 'text-black'}`
                                    }`}
                                    role="tab"
                                    aria-controls="tabs-delivery"
                                    aria-selected={activeTab === 'delivery'}
                                >
                                    <TruckIcon className={`w-5 mr-2`}/>
                                    Delivery
                                </Button>
                            </li>

                        </div>


                        {/* Tabs Content */}
                        <div className={`rounded-md p-3 border animate-fadeIn ${darkMode ? 'bg-darkMainColor border-neutral-700 text-white' : 'bg-white border text-black'} w-full drop-shadow shadow-gray-900 max-h-[calc(100dvh-5.5rem)] scroll overflow-y-auto`}>
                            

                            {/*MAIN DONE*/}
                            <div className={`${
                                activeTab === 'main' ? 'block opacity-100' : 'hidden opacity-0'
                            } transition-opacity duration-150 ease-linear h-full`}
                                 id="tabs-home"
                                 role="tabpanel"
                                 aria-labelledby="tabs-main"
                            >

                                <div className="h-full w-full flex flex-col justify-between">
                                    {/* Your tab content goes here */}
                                    <div className={`flex-grow rounded-lg ${currentUser ? 'max-h-[calc(100dvh-13.5rem)]' : 'max-h-[calc(100dvh-3.5rem)]'} scroll overflow-y-auto`}>
                                        {/* Replace with the content of the tab */}
                                        
                                        {/*Field Grid*/}
                                        <div className="mx-1 mt-6 grid grid-cols-6 gap-x-5 gap-y-10">
                                            
                                            {/*Name*/}
                                            <div className={`relative col-span-3`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Customer Name
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${name ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${name ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Input
                                                    type="text"
                                                    required
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    placeholder={`Customer Name`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            {/*Management Name*/}
                                            <div className={`relative col-span-3`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Management Name
                                                </label>
                                                <Input
                                                    type="text"
                                                    value={managementName}
                                                    onChange={(e) => setManagementName(e.target.value)}
                                                    placeholder={`Management Group Name`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            
                                            
                                            <label htmlFor="region" className="col-span-full block text-sm font-medium leading-6">
                                                BILLING ADDRESS
                                            </label>
                                            
                                            
                                            
                                            {/*Address 1*/}
                                            <div className={`relative col-span-full`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Address [1]
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${address1 ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${address1 ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Input
                                                    type="text"
                                                    required
                                                    placeholder="Street Address 1"
                                                    value={address1}
                                                    onChange={(e) => setAddress1(e.target.value)}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            {/*Address 2*/}
                                            <div className={`relative col-span-full`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Address [2]:
                                                </label>
                                                <Input
                                                    type="text"
                                                    placeholder="Optional"
                                                    value={address2}
                                                    onChange={(e) => setAddress2(e.target.value)}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            {/*City*/}
                                            <div className={`relative col-span-4`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    City
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${city ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${city ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Input
                                                    type="text"
                                                    required
                                                    placeholder="City"
                                                    value={city}
                                                    onChange={(e) => setCity(e.target.value)}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            {/*State*/}
                                            <div className={`relative col-span-1`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    State
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${selectedState ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${selectedState ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Select
                                                    required
                                                    value={selectedState}
                                                    onChange={handleStateChange}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="AL">Alabama</option>
                                                    <option value="AK">Alaska</option>
                                                    <option value="AZ">Arizona</option>
                                                    <option value="AR">Arkansas</option>
                                                    <option value="CA">California</option>
                                                    <option value="CO">Colorado</option>
                                                    <option value="CT">Connecticut</option>
                                                    <option value="DE">Delaware</option>
                                                    <option value="FL">Florida</option>
                                                    <option value="GA">Georgia</option>
                                                    <option value="HI">Hawaii</option>
                                                    <option value="ID">Idaho</option>
                                                    <option value="IL">Illinois</option>
                                                    <option value="IN">Indiana</option>
                                                    <option value="IA">Iowa</option>
                                                    <option value="KS">Kansas</option>
                                                    <option value="KY">Kentucky</option>
                                                    <option value="LA">Louisiana</option>
                                                    <option value="ME">Maine</option>
                                                    <option value="MD">Maryland</option>
                                                    <option value="MA">Massachusetts</option>
                                                    <option value="MI">Michigan</option>
                                                    <option value="MN">Minnesota</option>
                                                    <option value="MS">Mississippi</option>
                                                    <option value="MO">Missouri</option>
                                                    <option value="MT">Montana</option>
                                                    <option value="NE">Nebraska</option>
                                                    <option value="NV">Nevada</option>
                                                    <option value="NH">New Hampshire</option>
                                                    <option value="NJ">New Jersey</option>
                                                    <option value="NM">New Mexico</option>
                                                    <option value="NY">New York</option>
                                                    <option value="NC">North Carolina</option>
                                                    <option value="ND">North Dakota</option>
                                                    <option value="OH">Ohio</option>
                                                    <option value="OK">Oklahoma</option>
                                                    <option value="OR">Oregon</option>
                                                    <option value="PA">Pennsylvania</option>
                                                    <option value="RI">Rhode Island</option>
                                                    <option value="SC">South Carolina</option>
                                                    <option value="SD">South Dakota</option>
                                                    <option value="TN">Tennessee</option>
                                                    <option value="TX">Texas</option>
                                                    <option value="UT">Utah</option>
                                                    <option value="VT">Vermont</option>
                                                    <option value="VA">Virginia</option>
                                                    <option value="WA">Washington</option>
                                                    <option value="WV">West Virginia</option>
                                                    <option value="WI">Wisconsin</option>
                                                    <option value="WY">Wyoming</option>
                                                </Select>
                                            </div>
                                            
                                            {/*ZipCode*/}
                                            <div className={`relative col-span-1`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Zip Code
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${zip ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${zip ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Input
                                                    type="text"
                                                    required
                                                    placeholder="Zip Code"
                                                    value={zip}
                                                    onChange={(e) => setZip(e.target.value)}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            
                                            
                                            <label htmlFor="region" className="col-span-full block text-sm font-medium leading-6">
                                                FIPS CODE
                                            </label>
                                            
                                            
                                            
                                            {/*County*/}
                                            <div className={`relative col-span-4`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    County
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${selectedCounty ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${selectedCounty ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Select
                                                    required
                                                    value={selectedCounty}
                                                    onChange={handleCountyChange}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select a County...</option>
                                                    {filteredCounties.map(county => (
                                                        <option key={county.fips}
                                                                value={county.county}>{county.county}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                            
                                            {/*Fips Code*/}
                                            <div className={`relative col-span-2`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Fips Code
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${fips ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${fips ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Input
                                                    type="text"
                                                    required
                                                    disabled={true}
                                                    placeholder="Fips Code"
                                                    value={formatFipsCode(fips)}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            
                                            
                                            {/*Country*/}
                                            <div className={`relative col-span-4`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Country
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${country ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${country ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Select
                                                    required
                                                    value={country}
                                                    onChange={(e) => setCountry(e.target.value)}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="United States">United States</option>
                                                    <option value="Afghanistan">Afghanistan</option>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="Andorra">Andorra</option>
                                                    <option value="Angola">Angola</option>
                                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Armenia">Armenia</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Azerbaijan">Azerbaijan</option>
                                                    <option value="Bahamas">Bahamas</option>
                                                    <option value="Bahrain">Bahrain</option>
                                                    <option value="Bangladesh">Bangladesh</option>
                                                    <option value="Barbados">Barbados</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Belize">Belize</option>
                                                    <option value="Benin">Benin</option>
                                                    <option value="Bhutan">Bhutan</option>
                                                    <option value="Bolivia">Bolivia</option>
                                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                    <option value="Botswana">Botswana</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="Brunei">Brunei</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Burkina Faso">Burkina Faso</option>
                                                    <option value="Burundi">Burundi</option>
                                                    <option value="Cabo Verde">Cabo Verde</option>
                                                    <option value="Cambodia">Cambodia</option>
                                                    <option value="Cameroon">Cameroon</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Central African Republic">Central African Republic</option>
                                                    <option value="Chad">Chad</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="China">China</option>
                                                    <option value="Colombia">Colombia</option>
                                                    <option value="Comoros">Comoros</option>
                                                    <option value="Congo (Congo-Brazzaville)">Congo (Congo-Brazzaville)</option>
                                                    <option value="Congo (Democratic Republic)">Congo (Democratic Republic)</option>
                                                    <option value="Costa Rica">Costa Rica</option>
                                                    <option value="Croatia">Croatia</option>
                                                    <option value="Cuba">Cuba</option>
                                                    <option value="Cyprus">Cyprus</option>
                                                    <option value="Czechia">Czechia</option>
                                                    <option value="Denmark">Denmark</option>
                                                    <option value="Djibouti">Djibouti</option>
                                                    <option value="Dominica">Dominica</option>
                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                    <option value="Ecuador">Ecuador</option>
                                                    <option value="Egypt">Egypt</option>
                                                    <option value="El Salvador">El Salvador</option>
                                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                    <option value="Eritrea">Eritrea</option>
                                                    <option value="Estonia">Estonia</option>
                                                    <option value="Eswatini">Eswatini</option>
                                                    <option value="Ethiopia">Ethiopia</option>
                                                    <option value="Fiji">Fiji</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="Gabon">Gabon</option>
                                                    <option value="Gambia">Gambia</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Grenada">Grenada</option>
                                                    <option value="Guatemala">Guatemala</option>
                                                    <option value="Guinea">Guinea</option>
                                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                    <option value="Guyana">Guyana</option>
                                                    <option value="Haiti">Haiti</option>
                                                    <option value="Honduras">Honduras</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="India">India</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="Iran">Iran</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Kiribati">Kiribati</option>
                                                    <option value="Kuwait">Kuwait</option>
                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                    <option value="Laos">Laos</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lesotho">Lesotho</option>
                                                    <option value="Liberia">Liberia</option>
                                                    <option value="Libya">Libya</option>
                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Luxembourg">Luxembourg</option>
                                                    <option value="Madagascar">Madagascar</option>
                                                    <option value="Malawi">Malawi</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Maldives">Maldives</option>
                                                    <option value="Mali">Mali</option>
                                                    <option value="Malta">Malta</option>
                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                    <option value="Mauritania">Mauritania</option>
                                                    <option value="Mauritius">Mauritius</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Micronesia">Micronesia</option>
                                                    <option value="Moldova">Moldova</option>
                                                    <option value="Monaco">Monaco</option>
                                                    <option value="Mongolia">Mongolia</option>
                                                    <option value="Montenegro">Montenegro</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Mozambique">Mozambique</option>
                                                    <option value="Myanmar">Myanmar</option>
                                                    <option value="Namibia">Namibia</option>
                                                    <option value="Nauru">Nauru</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherlands">Netherlands</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Nicaragua">Nicaragua</option>
                                                    <option value="Niger">Niger</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="North Korea">North Korea</option>
                                                    <option value="North Macedonia">North Macedonia</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Oman">Oman</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Palau">Palau</option>
                                                    <option value="Panama">Panama</option>
                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                    <option value="Paraguay">Paraguay</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Philippines">Philippines</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Qatar">Qatar</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russia">Russia</option>
                                                    <option value="Rwanda">Rwanda</option>
                                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                    <option value="Saint Lucia">Saint Lucia</option>
                                                    <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                    <option value="Samoa">Samoa</option>
                                                    <option value="San Marino">San Marino</option>
                                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Senegal">Senegal</option>
                                                    <option value="Serbia">Serbia</option>
                                                    <option value="Seychelles">Seychelles</option>
                                                    <option value="Sierra Leone">Sierra Leone</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                    <option value="Somalia">Somalia</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="South Korea">South Korea</option>
                                                    <option value="South Sudan">South Sudan</option>
                                                    <option value="Spain">Spain</option>
                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                    <option value="Sudan">Sudan</option>
                                                    <option value="Suriname">Suriname</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syria">Syria</option>
                                                    <option value="Taiwan">Taiwan</option>
                                                    <option value="Tajikistan">Tajikistan</option>
                                                    <option value="Tanzania">Tanzania</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Togo">Togo</option>
                                                    <option value="Tonga">Tonga</option>
                                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                    <option value="Tuvalu">Tuvalu</option>
                                                    <option value="Uganda">Uganda</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="Uruguay">Uruguay</option>
                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                    <option value="Vanuatu">Vanuatu</option>
                                                    <option value="Vatican City">Vatican City</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Vietnam">Vietnam</option>
                                                    <option value="Yemen">Yemen</option>
                                                    <option value="Zambia">Zambia</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                </Select>
                                                
                                            </div>
                                            
                                            {/*Industry*/}
                                            <div className={`relative col-span-2`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Industry
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${industry ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${industry ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Select
                                                    required
                                                    value={industry}
                                                    onChange={(e) => setIndustry(e.target.value)}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select Industry...</option>
                                                    <option value="BOTANICAL">Botanical Garden</option>
                                                    <option value="DISTRIB">Product Distributor</option>
                                                    <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                                    <option value="FARM">Agriculture - Farm</option>
                                                    <option value="GOLF">Golf Course</option>
                                                    <option value="GOVERNMENT">Government Entity</option>
                                                    <option value="INDUSTRIAL">Industrial</option>
                                                    <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                                    <option value="MRSALE">One Time Purchase Expected</option>
                                                    <option value="MUNICIPAL">Municipal/ School</option>
                                                    <option value="Non-Profit">Non-Profit Account</option>
                                                    <option value="OTHER">Other</option>
                                                    <option value="SPORTFLD">Sportsfield</option>
                                                </Select>
                                            </div>
                                            
                                        
                                        
                                        </div>
                                    
                                    </div>
                                    
                                    {/* Buttons aligned at the bottom */}
                                    <div className="w-1/2 mr-auto ml-auto flex flex-row justify-end space-x-2">
                                        <Button
                                            type="submit"
                                            onClick={handleNextTab}
                                            className="w-full bg-green-600 tracking-widest text-sm  text-white px-4 py-1 rounded-lg hover:bg-opacity-80"
                                        >
                                            Next →
                                        </Button>
                                    </div>
                                </div>
                            
                            </div>
                            
                            
                            {/*CONTACTS DONE*/}
                            <div
                                className={`${
                                    activeTab === 'contacts' ? 'block opacity-100' : 'hidden opacity-0'
                                } transition-opacity duration-150 ease-linear h-full`}
                                id="tabs-contacts"
                                role="tabpanel"
                                aria-labelledby="tabs-contacts"
                            >
                                <div className="h-full w-full flex flex-col justify-between">
                                        {/* Your tab content goes here */}
                                        <div className={`flex-grow rounded-lg ${currentUser ? 'max-h-[calc(100dvh-13.5rem)]' : 'max-h-[calc(100dvh-3.5rem)]'} scrollbar-hide overflow-y-auto`}>
                                            {/* Replace with the content of the tab */}
    
                                            {/*Fields Grid*/}
                                            <div className="mx-1 mt-6 grid grid-cols-6 gap-x-5 gap-y-8">
                                                
                                                {/*Contact Name*/}
                                                <div className={`relative col-span-3`}>
                                                    <label
                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Contact Name
                                                        <div className={`ml-1`}>
                                                            <div className={`flex-none rounded-full p-1 ${contact ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                                <div className={`size-2 rounded-full ${contact ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={contact}
                                                        onChange={(e) => setContact(e.target.value)}
                                                        placeholder={`Contact Name`}
                                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>
                                                
                                                {/*Position*/}
                                                <div className={`relative col-span-3`}>
                                                    <label
                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Contact Position
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        value={contactPosition}
                                                        onChange={(e) => setContactPosition(e.target.value)}
                                                        placeholder={`Contact Position`}
                                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>
                                                
                                                
    
                                                <label htmlFor="region" className="col-span-full block text-sm font-medium leading-6">
                                                    CONTACT METHODS
                                                </label>
    
                                                
                                                
                                                {/*Contact Phone*/}
                                                <div className={`relative col-span-full`}>
                                                    <label
                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Contact Phone
                                                        <div className={`ml-1`}>
                                                            <div className={`flex-none rounded-full p-1 ${contactPhone ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                                <div className={`size-2 rounded-full ${contactPhone ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <Input
                                                        type="phone"
                                                        required
                                                        value={contactPhone}
                                                        onChange={(e) => setContactPhone(e.target.value)}
                                                        placeholder={`Contact Phone Number`}
                                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>
                                                
                                                {/*Contact Email*/}
                                                <div className={`relative col-span-3`}>
                                                    <label
                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Contact Email
                                                    </label>
                                                    <Input
                                                        type="email"
                                                        value={contactEmail}
                                                        onChange={(e) => setContactEmail(e.target.value)}
                                                        placeholder={`Contact Email`}
                                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>
                                                
                                                {/*Website*/}
                                                <div className={`relative col-span-3`}>
                                                    <label
                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Website
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        value={website}
                                                        onChange={(e) => setWebsite(e.target.value)}
                                                        placeholder={`Website`}
                                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>
                                                
                                                
    
                                                <label htmlFor="region" className="col-span-full block text-sm font-medium leading-6">
                                                    BILLING CONTACTS
                                                </label>
    
                                                
                                                
                                                {/*Billing Name*/}
                                                <div className={`relative col-span-full`}>
                                                    <label
                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Billing Contact
                                                        <div className={`ml-1`}>
                                                            <div className={`flex-none rounded-full p-1 ${billingContact ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                                <div className={`size-2 rounded-full ${billingContact ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={billingContact}
                                                        onChange={(e) => setBillingContact(e.target.value)}
                                                        placeholder={`Billing Contact Name`}
                                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>
                                                
                                                {/*Billing Phone*/}
                                                <div className={`relative col-span-full`}>
                                                    <label
                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Billing Phone
                                                        <div className={`ml-1`}>
                                                            <div className={`flex-none rounded-full p-1 ${billingPhone ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                                <div className={`size-2 rounded-full ${billingPhone ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <Input
                                                        type="phone"
                                                        required
                                                        value={billingPhone}
                                                        onChange={(e) => setBillingPhone(e.target.value)}
                                                        placeholder={`Billing Phone Number`}
                                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>
                                                
                                                
                                                
                                                <label htmlFor="region" className=" col-span-full block text-sm font-medium leading-6">
                                                    INVOICE AND STATEMENTS
                                                </label>
                                                
                                                
                                                
                                                {/*Invoice Email*/}
                                                <div className={`relative col-span-full`}>
                                                    <label
                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Invoice Email
                                                        <div className={`ml-1`}>
                                                            <div className={`flex-none rounded-full p-1 ${invoiceEmails ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                                <div className={`size-2 rounded-full ${invoiceEmails ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={invoiceEmails}
                                                        onChange={(e) => setInvoiceEmails(e.target.value)}
                                                        placeholder={`Invoices Email Address`}
                                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>
                                                
                                                {/*Statement Email*/}
                                                <div className={`relative col-span-full`}>
                                                    <label
                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Statement Email
                                                        <div className={`ml-1`}>
                                                            <div className={`flex-none rounded-full p-1 ${statementEmails ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                                <div className={`size-2 rounded-full ${statementEmails ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={statementEmails}
                                                        onChange={(e) => setStatementEmails(e.target.value)}
                                                        placeholder={`Statements Email Address`}
                                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>
                                                
                                                
                                            </div>
    
                                        </div>
    
                                        {/* Buttons aligned at the bottom */}
                                        <div className="w-full flex flex-row justify-end space-x-2">
                                            <Button
                                                onClick={handlePreviousTab}
                                                className="w-full bg-green-600 tracking-widest text-sm  text-white px-4 py-1 rounded hover:bg-opacity-80"
                                            >
                                                ← Previous
                                            </Button>
                                            <Button
                                                onClick={handleNextTab}
                                                className="w-full bg-green-600 tracking-widest text-sm  text-white px-4 py-1 rounded hover:bg-opacity-80"
                                            >
                                                Next →
                                            </Button>
                                        </div>
                                    </div>
                            </div>


                            {/*SHIPPING*/}
                            <div
                                className={`${
                                    activeTab === 'shipping' ? 'block opacity-100' : 'hidden opacity-0'
                                } transition-opacity duration-150 ease-linear h-full`}
                                id="tabs-shipping"
                                role="tabpanel"
                                aria-labelledby="tabs-shipping"
                            >
                                <div className="h-full w-full flex flex-col justify-between">
                                    {/* Your tab content goes here */}
                                    <div className="flex-grow h-full">
                                        {/* Replace with the content of the tab */}

                                        <div className="flex flex-col h-full">

                                            {/*Header*/}
                                            <div className="w-full flex flex-row">

                                                {/*Shipping Label*/}
                                                <div className="w-2/3 items-center flex mr-2.5">
                                                    
                                                    <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                                        <MapPinIcon aria-hidden="true" className="size-6 text-green-600" />
                                                    </div>
                                                    
                                                    <div className={`ml-6`}>
                                                        <label className="block text-md tracking-widest font-medium leading-6">
                                                            Shipping Locations
                                                        </label>
                                                        <label className="block ml-4 text-xs text-gray-500 font-medium leading-6">
                                                            You may add more than one Shipping Location for each Customer
                                                        </label>
                                                    </div>
                                                
                                                </div>
                                                
                                                {/*Shipping Button*/}
                                                <div className="w-1/3 ml-auto">
                                                    <div className="mt-2 w-full flex justify-end">
                                                        <Button
                                                            onClick={handleAddComponent}
                                                            className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold hover:text-green-600 focus-visible:outline focus-visible:outline-2"
                                                        >
                                                            Add Location
                                                            <PlusCircleIcon aria-hidden="true" className="-mr-0.5 ml-1.5 size-6"/>
                                                        </Button>
                                                    </div>
                                                </div>

                                            </div>

                                            {/*Shipping Map*/}
                                            <div className={`w-full h-full mt-3 border-2 ${darkMode ? 'border-neutral-700' : ''} shadow-md rounded-lg p-3 mb-3`}>

                                                <div className={`${currentUser ? 'max-h-[calc(100dvh-18.75rem)]' : 'max-h-[calc(100dvh-15.5rem)]'} scrollbar-hide rounded-lg scroll overflow-y-auto overflow-x-hidden`}>
                                                    {shippingAddress.length === 0 ? (
                                                        // Display custom dialog if sprays array is empty
                                                        
                                                        <div className="flex w-full h-full justify-center items-center py-10">
                                                            <div
                                                                className={`flex items-center border-2 border-dashed ${
                                                                    darkMode ? "text-white border-neutral-700" : "text-black bg-neutral-50"
                                                                } w-fit px-16 py-1 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}
                                                            >
                                                                <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                                    <MapPinIcon className="w-10 stroke-2 mr-3"/>
                                                                    <p className="text-xl mt-2 line-clamp-1 tracking-widest flex">No Shipping Locations</p>
                                                                    <p className="text-md mt-2 line-clamp-1 tracking-widest flex text-gray-500">You may add in multiple shipping locations</p>
                                                                    <div className="mt-6">
                                                                        <Button
                                                                            onClick={handleAddComponent}
                                                                            className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                        >
                                                                            <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5"/>
                                                                            Add Location
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    ) : (
                                                        // Display SprayCard components if sprays array is not empty
                                                        shippingAddress.map((item, index) => (
                                                            <AddressInput
                                                                id={item.id}
                                                                key={item.id}
                                                                index={index}
                                                                
                                                                Eaddress1={address1}
                                                                Eaddress2={address2}
                                                                Ecity={city}
                                                                Estate={selectedState}
                                                                Ezip={zip}
                                                                Ecounty={selectedCounty}
                                                                Efips={fips}
                                                                
                                                                onChange={handleAddressChange}
                                                                onRemove={handleRemoveComponent}
                                                            />
                                                        ))
                                                    )}
                                                </div>

                                            </div>


                                        </div>

                                    </div>

                                    {/* Buttons aligned at the bottom */}
                                    <div className="w-full flex flex-row justify-end space-x-2">
                                        <Button
                                            onClick={handlePreviousTab}
                                            className="w-full bg-green-600 tracking-widest text-sm  text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            ← Previous
                                        </Button>
                                        <Button
                                            onClick={handleNextTab}
                                            className="w-full bg-green-600 tracking-widest text-sm  text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            Next →
                                        </Button>
                                    </div>
                                    
                                </div>
                            </div>


                            {/*FINANCES DONE*/}
                            <div
                                className={`${
                                    activeTab === 'taxes' ? 'block opacity-100' : 'hidden opacity-0'
                                } transition-opacity duration-150 ease-linear h-full`}
                                id="tabs-taxes"
                                role="tabpanel"
                                aria-labelledby="tabs-taxes"
                            >
                                <div className="h-full w-full flex flex-col justify-between">
                                    {/* Your tab content goes here */}
                                    <div className={`flex ${currentUser ? 'max-h-[calc(100dvh-13.5rem)]' : 'max-h-[calc(100dvh-3.5rem)]'} h-full scroll overflow-y-auto`}>

                                        {/*Fields Grid*/}
                                        <div className="w-1/3 h-fit mt-6 grid grid-cols-1 gap-x-5 gap-y-10">

                                            <label htmlFor="region" className="block text-sm font-medium leading-6 col-span-full">
                                                TAXES
                                            </label>
                                            
                                            
                                            
                                            {/*TaxID*/}
                                            <div className={`relative col-span-full`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Tax ID#
                                                    
                                                    {salesTaxStatus === "Tax Exempt" && (
                                                        <div className={`ml-1`}>
                                                            <div className={`flex-none rounded-full p-1 ${taxID ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                                <div className={`size-2 rounded-full ${taxID ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                            </div>
                                                        </div>
                                                    )}
                                                    
                                                </label>
                                                <Input
                                                    type="text"
                                                    required
                                                    value={taxID}
                                                    onChange={(e) => setTaxID(e.target.value)}
                                                    placeholder={`Tax ID#`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                                
                                                <div className={`text-sm text-gray-500 mt-1`}>If Tax Exempt, please email Tax Exemption Certification to:
                                                    <a
                                                        href={`mailto:accountingAR@plantfoodco.com`}
                                                        className="flex items-center hover:text-green-600"
                                                    >
                                                        <EnvelopeIcon className="size-5 mr-1"/>
                                                        accountingAR@plantfoodco.com
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            {/*Sales Tax Status*/}
                                            <div className={`relative col-span-full`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Sales Tax Status
                                                    
                                                </label>
                                                
                                                <Select
                                                    required
                                                    value={salesTaxStatus}
                                                    onChange={(e) => setSalesTaxStatus(e.target.value)}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select a status option...</option>
                                                    <option value="Taxable">Taxable</option>
                                                    <option value="Tax Exempt">Tax Exempt</option>
                                                </Select>
                                                
                                            </div>
                                            
                                            
                                            {/*Tax Exemption Code*/}
                                            <div className={`relative col-span-full`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Tax Exemption Code
                                                </label>
                                                <Input
                                                    type="text"
                                                    value={taxExempCode}
                                                    onChange={(e) => setTaxExempCode(e.target.value)}
                                                    placeholder={`Optional`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            
                                            
                                            <label htmlFor="region" className="block text-sm font-medium leading-6 col-span-full">
                                                PAYMENT
                                            </label>
                                            
                                            
                                            {/*Payment Type*/}
                                            <div className={`relative col-span-2`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Payement Type
                                                    
                                                </label>
                                                <Select
                                                    required
                                                    value={paymentType}
                                                    onChange={(e) => setPaymentType(e.target.value)}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select a Payment Option...</option>
                                                    <option value="Check">Check</option>
                                                    <option value="ACH">ACH</option>
                                                    <option value="Credit">Credit Card (3% Service Fee)</option>
                                                </Select>
                                            </div>
                                            
                                        </div>
                                        
                                        {/*Content*/}
                                        <div className="flex flex-col w-2/3 ml-1.5 h-full">

                                            <div className="mx-auto max-w-7xl px-6 mt-auto mb-auto">
                                                <div className="mx-auto max-w-2xl lg:text-center">
                                                    <h2 className="text-base font-semibold leading-7 text-green-600">Next
                                                        Steps</h2>
                                                    <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
                                                        You're Almost Done!
                                                    </p>
                                                    <p className="mt-6 text-lg leading-8">
                                                        Upon completing your onboarding form a
                                                        representative from PlantFood Company will be in touch
                                                        shortly to assist you with completing your financial section
                                                        and help you fill out your credit application.
                                                    </p>
                                                    <p className="mt-6 text-lg leading-8 text-green-600">
                                                        We look forward to working with you!
                                                    </p>
                                                </div>
                                                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                                                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                                                        {features.map((feature) => (
                                                            <div key={feature.name} className="relative pl-16">
                                                            <dt className="text-base font-semibold leading-7">
                                                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-bgLightGreen">
                                                                        <feature.icon aria-hidden="true" className="h-6 w-6 text-white"/>
                                                                    </div>
                                                                    {feature.name}
                                                                </dt>
                                                                <dd className="mt-2 text-base leading-7">{feature.description}</dd>
                                                            </div>
                                                        ))}
                                                    </dl>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    {/* Buttons aligned at the bottom */}
                                    <div className="w-full flex flex-row justify-end space-x-2">
                                        <Button
                                            onClick={handlePreviousTab}
                                            className="w-full bg-green-600 tracking-widest text-sm text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            ← Previous
                                        </Button>
                                        <Button
                                            onClick={handleNextTab}
                                            className="w-full bg-green-600 tracking-widest text-sm text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            Next →
                                        </Button>
                                    </div>
                                </div>
                            </div>


                            {/*DELIVERY*/}
                            <div
                                className={`${
                                    activeTab === 'delivery' ? 'block opacity-100' : 'hidden opacity-0'
                                } transition-opacity duration-150 ease-linear h-full`}
                                id="tabs-delivery"
                                role="tabpanel"
                                aria-labelledby="tabs-delivery"
                            >
                                <div className="h-full w-full flex flex-col justify-between">
                                    {/* Your tab content goes here */}
                                    <div className={`flex-grow ${currentUser ? 'max-h-[calc(100dvh-13.5rem)]' : 'max-h-[calc(100dvh-3.5rem)]'} scrollbar-hide overflow-y-auto`}>

                                        <div className="flex flex-row h-full">

                                            {/*Questions / Notes*/}
                                            <div className="w-1/3 m-3 flex flex-col max-h-[calc(87vh-5.5rem)] hide-scrollbar overflow-y-auto mr-1.5">

                                                {/*Delivery Questions*/}
                                                <div className={`flex flex-col w-full border-2 ${darkMode ? 'border-neutral-700' : 'border'} rounded-lg mb-3`}>

                                                    {/*Label*/}
                                                    <div className={`grid items-center grid-cols-3 gap-x-5 gap-y-6 w-full border-b-2 p-3 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                        <div className="col-span-2 ">Delivery Questions?</div>
                                                        <div className="col-span-1 text-center text-gray-500 text-xs">[Yes] [No]</div>
                                                    </div>
                                                    
                                                    {/*Field Grid*/}
                                                    <div className={`mt-6 grid grid-cols-1 gap-x-5 gap-y-8 mb-6`}>
                                                        
                                                        {/*TTA*/}
                                                        <div className={`col-span-full grid grid-cols-3 gap-x-5 px-4`}>
                                                            <div className={`col-span-2 text-xs border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                                Tractor Trailer Accessible?
                                                            </div>
                                                            <div className={`col-span-1 flex items-center justify-center`}>
                                                                <Field className="flex items-center">
                                                                    <Switch
                                                                        checked={TTA}
                                                                        onChange={setTTA}
                                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                                    >
                                                                        <span className="sr-only">Use setting</span>
                                                                        <span aria-hidden="true"
                                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                                        />
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                                        />
                                                                    </Switch>
                                                                  <Label as="span" className={`ml-3 text-sm ${darkMode ? 'text-white' : 'text-black'}`}>
                                                                      <span className="font-medium text-sm">{TTA ? "Yes" : "No"}</span>
                                                                  </Label>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        
                                                        {/*LAS*/}
                                                        <div className={`col-span-full grid grid-cols-3 gap-x-5 px-4`}>
                                                            <div className={`col-span-2 text-xs border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                                Limited Access Site?
                                                            </div>
                                                            <div className={`col-span-1 flex items-center justify-center`}>
                                                                <Field className="flex items-center">
                                                                    <Switch
                                                                        checked={LAS}
                                                                        onChange={setLAS}
                                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                                    >
                                                                        <span className="sr-only">Use setting</span>
                                                                        <span aria-hidden="true"
                                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                                        />
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                                        />
                                                                    </Switch>
                                                                  <Label as="span" className={`ml-3 text-sm ${darkMode ? 'text-white' : 'text-black'}`}>
                                                                      <span className="font-medium text-sm">{LAS ? "Yes" : "No"}</span>
                                                                  </Label>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        
                                                        {/*LDA*/}
                                                        <div className={`col-span-full grid grid-cols-3 gap-x-5 px-4`}>
                                                            <div className={`col-span-2 text-xs border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                                Loading Dock Available?
                                                            </div>
                                                            <div className={`col-span-1 flex items-center justify-center`}>
                                                                <Field className="flex items-center">
                                                                    <Switch
                                                                        checked={LDA}
                                                                        onChange={setLDA}
                                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                                    >
                                                                        <span className="sr-only">Use setting</span>
                                                                        <span aria-hidden="true"
                                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                                        />
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                                        />
                                                                    </Switch>
                                                                  <Label as="span" className={`ml-3 text-sm ${darkMode ? 'text-white' : 'text-black'}`}>
                                                                      <span className="font-medium text-sm">{LDA ? "Yes" : "No"}</span>
                                                                  </Label>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        
                                                        {/*PJR*/}
                                                        <div className={`col-span-full grid grid-cols-3 gap-x-5 px-4`}>
                                                            <div className={`col-span-2 text-xs border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                                Lift Gate & Pallet Jack Required?
                                                            </div>
                                                            <div className={`col-span-1 flex items-center justify-center`}>
                                                                <Field className="flex items-center">
                                                                    <Switch
                                                                        checked={PJR}
                                                                        onChange={setPJR}
                                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                                    >
                                                                        <span className="sr-only">Use setting</span>
                                                                        <span aria-hidden="true"
                                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                                        />
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                                        />
                                                                    </Switch>
                                                                  <Label as="span" className={`ml-3 text-sm ${darkMode ? 'text-white' : 'text-black'}`}>
                                                                      <span className="font-medium text-sm">{PJR ? "Yes" : "No"}</span>
                                                                  </Label>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        
                                                        {/*DAR*/}
                                                        <div className={`col-span-full grid grid-cols-3 gap-x-5 px-4`}>
                                                            <div className={`col-span-2 text-xs border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                                Delivery Appointment Required?
                                                            </div>
                                                            <div className={`col-span-1 flex items-center justify-center`}>
                                                                <Field className="flex items-center">
                                                                    <Switch
                                                                        checked={DAR}
                                                                        onChange={setDAR}
                                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                                    >
                                                                        <span className="sr-only">Use setting</span>
                                                                        <span aria-hidden="true"
                                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                                        />
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                                        />
                                                                    </Switch>
                                                                  <Label as="span" className={`ml-3 text-sm ${darkMode ? 'text-white' : 'text-black'}`}>
                                                                      <span className="font-medium text-sm">{DAR ? "Yes" : "No"}</span>
                                                                  </Label>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        
                                                        {/*HSA*/}
                                                        <div className={`col-span-full grid grid-cols-3 gap-x-5 px-4`}>
                                                            <div className={`col-span-2 text-xs border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                                Heated Storage Available?
                                                            </div>
                                                            <div className={`col-span-1 flex items-center justify-center`}>
                                                                <Field className="flex items-center">
                                                                    <Switch
                                                                        checked={HSA}
                                                                        onChange={setHSA}
                                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                                    >
                                                                        <span className="sr-only">Use setting</span>
                                                                        <span aria-hidden="true"
                                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                                        />
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                                        />
                                                                    </Switch>
                                                                  <Label as="span" className={`ml-3 text-sm ${darkMode ? 'text-white' : 'text-black'}`}>
                                                                      <span className="font-medium text-sm">{HSA ? "Yes" : "No"}</span>
                                                                  </Label>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        
                                                        {/*FLC*/}
                                                        <div className={`col-span-full grid grid-cols-3 gap-x-5 px-4`}>
                                                            <div className={`col-span-2 text-xs border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                                Forklift Available w/ 3,000 lbs Capacity?
                                                            </div>
                                                            <div className={`col-span-1 flex items-center justify-center`}>
                                                                <Field className="flex items-center">
                                                                    <Switch
                                                                        checked={FLC}
                                                                        onChange={setFLC}
                                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                                    >
                                                                        <span className="sr-only">Use setting</span>
                                                                        <span aria-hidden="true"
                                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                                        />
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                                        />
                                                                    </Switch>
                                                                  <Label as="span" className={`ml-3 text-sm ${darkMode ? 'text-white' : 'text-black'}`}>
                                                                      <span className="font-medium text-sm">{FLC ? "Yes" : "No"}</span>
                                                                  </Label>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        
                                                        {/*RPY*/}
                                                        <div className={`col-span-full grid grid-cols-3 gap-x-5 px-4`}>
                                                            <div className={`col-span-2 text-xs border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                                Residential Property
                                                            </div>
                                                            <div className={`col-span-1 flex items-center justify-center`}>
                                                                <Field className="flex items-center">
                                                                    <Switch
                                                                        checked={RPY}
                                                                        onChange={setRPY}
                                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                                    >
                                                                        <span className="sr-only">Use setting</span>
                                                                        <span aria-hidden="true"
                                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                                        />
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                                        />
                                                                    </Switch>
                                                                  <Label as="span" className={`ml-3 text-sm ${darkMode ? 'text-white' : 'text-black'}`}>
                                                                      <span className="font-medium text-sm">{RPY ? "Yes" : "No"}</span>
                                                                  </Label>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                                
                                                
                                                {/*Delivery Instructions*/}
                                                <div className={`relative col-span-full mt-3`}>
                                                    <label
                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Special Delivery Instructions
                                                    </label>
                                                    <textarea
                                                        required
                                                        value={deliveryInstructions}
                                                        onChange={(e) => setDeliveryInstructions(e.target.value)}
                                                        placeholder={`Notes...`}
                                                        className={`block w-full rounded-md h-32 ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                </div>
                                                

                                            </div>

                                            {/*Info Center*/}
                                            <div className="w-2/3 flex flex-col mt-auto mb-auto h-full ml-1.5">

                                                <div
                                                    className={`mx-auto max-w-7xl px-6 lg:px-8 mt-auto mb-auto overflow-hidden`}>
                                                    <div
                                                        className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                                                        <div
                                                            className="lg:pr-8 lg:pt-4 max-h-[calc(87vh-5.5rem)] scroll overflow-y-auto">
                                                            <div className="lg:max-w-lg">
                                                                <h2 className="text-base font-semibold leading-7 text-green-600">Thank
                                                                    You for Joining Us</h2>
                                                                <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">You're
                                                                    All Set!</p>
                                                                <p className="mt-6 text-xs leading-8">
                                                                    Thank you for completing the onboarding
                                                                    process.
                                                                    We're excited to have you on board as a
                                                                    valued
                                                                    customer.
                                                                    For more information about our products,
                                                                    services,
                                                                    and the latest updates, visit our website at
                                                                    <a href="https://www.plantfoodco.com/"
                                                                       className="text-green-600 hover:text-opacity-50"> www.plantfoodco.com</a>.
                                                                    There, you’ll find product guides,
                                                                    application tips,
                                                                    and support resources to help you maximize
                                                                    the
                                                                    benefits of
                                                                    our fertilizers.
                                                                </p>
                                                                <p className="mt-6 text-xs leading-8">
                                                                    If you have any further questions or need
                                                                    assistance, feel free to reach out to our
                                                                    customer
                                                                    support team through
                                                                    our <a
                                                                    href="https://www.plantfoodco.com/contact/"
                                                                    className="text-green-600 hover:text-opacity-50"> Contact
                                                                    Us</a> page.
                                                                </p>
                                                                {/*<dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">*/}
                                                                {/*    {features.map((feature) => (*/}
                                                                {/*        <div key={feature.name}*/}
                                                                {/*             className="relative pl-9">*/}
                                                                {/*            <dt className="inline font-semibold text-gray-900">*/}
                                                                {/*                <feature.icon aria-hidden="true"*/}
                                                                {/*                              className="absolute left-1 top-1 h-5 w-5 text-bgLightGreen"/>*/}
                                                                {/*                {feature.name}*/}
                                                                {/*            </dt>*/}
                                                                {/*            {' '}*/}
                                                                {/*            <dd className="inline text-xs">{feature.description}</dd>*/}
                                                                {/*        </div>*/}
                                                                {/*    ))}*/}
                                                                {/*</dl>*/}
                                                            </div>
                                                        </div>
                                                        <img
                                                            alt="Product screenshot"
                                                            src={websiteSRC}
                                                            width={2432}
                                                            height={1442}
                                                            className="w-[35rem] max-w-none mt-auto mb-auto rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[35rem] md:-ml-4 lg:-ml-0"
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    {/* Buttons aligned at the bottom */}
                                    <div className="w-full flex flex-row justify-end space-x-2">
                                        <Button
                                            onClick={handlePreviousTab}
                                            className="w-full bg-green-600 tracking-widest text-sm text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            ← Previous
                                        </Button>
                                        <Button
                                            // disabled={isFormIncomplete}
                                            // onClick={() => setIsSubmitting(true)}
                                            onClick={handleErrorSubmit}
                                            className={`${isFormIncomplete ? 'bg-opacity-50' : 'hover:bg-opacity-80'} w-full bg-green-600 tracking-widest text-sm text-white px-4 py-1 rounded`}
                                        >
                                            Submit Customer To Plant
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                    
                    
                    <Dialog open={isSubmitting} onClose={setIsSubmitting} className="relative z-50">
                      <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                      />
                
                      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                          <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                          >
                            <div className="sm:flex sm:items-start">
                              <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                <PaperAirplaneIcon aria-hidden="true" className="size-6 text-green-600" />
                              </div>
                              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle as="h3" className="text-base font-semibold">
                                  Submit Customer to Plant
                                </DialogTitle>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Submitting this customer is sending them to the plant for onboarding. You may not place orders with this customer until the plant has moved them to active
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                              <Button
                                type="button"
                                onClick={onSubmitMain}
                                // disabled={!selectedCustomer}
                                className={`inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                              >
                                Submit Customer
                              </Button>
                              <Button
                                type="button"
                                data-autofocus
                                onClick={() => setIsSubmitting(false)}
                                className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                              >
                                Cancel
                              </Button>
                            </div>
                          </DialogPanel>
                        </div>
                      </div>
                    </Dialog>
                    
                    <Dialog open={showPopup} onClose={setShowPopup} className="relative z-50">
                      <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                      />
                
                      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                          <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                          >
                            <div className="sm:flex sm:items-start">
                              <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-600/15 sm:mx-0 sm:size-10">
                                <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600" />
                              </div>
                              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle as="h3" className="text-base font-semibold">
                                  Missing Information
                                </DialogTitle>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        You are missing required information needed by PFC in order to successfully onboard this customer. You may Jump to the correct tab and look for the red indicator light!
                                    </p>
                                    <div className={`h-48 mt-2 overflow-y-scroll hide-scrollbar space-y-2`}>
                                        {missingFields.map((field, index) => (
                                            <li key={field} className="flex justify-between items-center">
                                                <span className={`text-sm tracking-widest`}>[{index+1}] {fieldLabels[field]}</span>
                                                <Button
                                                    className="bg-red-600 hover:bg-opacity-50 text-white text-xs px-6 py-0.5 rounded-lg"
                                                    onClick={() => {
                                                        setActiveTab(fieldToTab[field]);
                                                        setShowPopup(false);
                                                    }}
                                                >
                                                    Jump
                                                </Button>
                                            </li>
                                        ))}
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                              <Button
                                type="button"
                                onClick={() => setShowPopup(false)}
                                // disabled={!selectedCustomer}
                                className={`inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                              >
                                I Understand
                              </Button>
                              
                            </div>
                          </DialogPanel>
                        </div>
                      </div>
                    </Dialog>


                </form>

            </div>
            
            
            
            <Dialog open={isClearing} onClose={setIsClearing} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-600/15 sm:mx-0 sm:size-10">
                        <XCircleIcon aria-hidden="true" className="size-6 text-red-600" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Clear Customer Form
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            This function will clear all of the fields in the entire customer form. This action may not be undone and you may not recover your progress!
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={clearAllFields}
                        className={`inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                      >
                        Clear Form
                      </Button>
                      <Button
                        type="button"
                        data-autofocus
                        onClick={() => setIsClearing(false)}
                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                      >
                        Cancel
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>


            <Dialog open={selectSales} onClose={setSelectSales} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <UserIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle as="h3" className="text-base font-semibold">
                                Select a Sales Rep
                            </DialogTitle>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    In order to complete this form you much first choose a Plant Food Company Sales Rep from the drop down below. This should be the sales rep that directed you to this link!
                                </p>
                            </div>
                            
                            <div className="mt-2 grid grid-cols-2 gap-3">
                                
                                {/*Customer Type*/}
                                <div className={`relative col-span-full mt-3`}>
                                    <label
                                        htmlFor="type"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Sales Rep
                                    </label>
                                    
                                    <Select
                                        type="text"
                                        onChange={handleSelectChange}
                                        className={`block w-full rounded-md ${
                                            darkMode
                                                ? 'bg-darkMainColor text-white outline-neutral-700 outline-2'
                                                : 'bg-white text-gray-900 outline-gray-300 outline-2'
                                        } px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    >
                                        <option value="">Select a Sales Rep</option>
                                        {users
                                            .filter(user => user.accountLevel === "S" || user.accountLevel === "A")
                                            .map(user => (
                                                <option key={user.salesCode} value={JSON.stringify(user)}>
                                                    {user.salesRep}
                                                </option>
                                            ))}
                                    </Select>
                                    
                                </div>
                            
                            </div>
                        
                        
                        </div>
                    </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <Button
                              type="button"
                              onClick={() => setSelectSales(false)}
                              className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                          >
                              Connect Rep
                          </Button>
                          <Link
                              to={`/Customer`}
                              type="button"
                              data-autofocus
                              className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                          >
                            Return Home
                          </Link>
                      </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>


        </div>
    )
};


export default NewCustomer;
