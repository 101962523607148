// AIzaSyDxrbk0CKmZUpJ-5eQGaxHH-I5lYeWrPfc

import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';
import {MapIcon} from "@heroicons/react/24/outline";
import {useDarkMode} from "../../contexts/darkModeContext";

const SmallMap = ({ address }) => {
    const { darkMode } = useDarkMode();
  const [center, setCenter] = useState(null);
  const mapRef = useRef(null);

  const geocodeAddress = async (address) => {
    const apiKey = 'AIzaSyDxrbk0CKmZUpJ-5eQGaxHH-I5lYeWrPfc';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK') {
        return data.results[0].geometry.location;
      } else {
        console.error('Geocoding error:', data.status);
        return null;
      }
    } catch (error) {
      console.error('Error fetching geocode:', error);
      return null;
    }
  };

  useEffect(() => {
    if (address) {
      setCenter(null); // Reset center to force update
      const fetchCoordinates = async () => {
        const coordinates = await geocodeAddress(address);
        if (coordinates) {
          setCenter(coordinates);
          if (mapRef.current) {
            mapRef.current.panTo(coordinates);
          }
        }
      };
      fetchCoordinates();
    }
  }, [address]);

  const handleLoad = (map) => {
    mapRef.current = map;
  };

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  if (!center) {
    return (
        <div className={`w-full h-full flex items-center justify-center`}>
            <div className={`text-center flex flex-col justify-center items-center border-2 rounded-lg border-dashed ${darkMode ? 'border-neutral-700' : ''} px-6 py-2`}>
                <MapIcon className={`size-12`}/>
                <h3 className="mt-2 text-sm font-semibold">Error Loading Map</h3>
                <p className="mt-1 text-sm text-gray-500">Check the Customers Address!</p>
            </div>
        </div>
    );
  }

    return (
        <LoadScriptNext googleMapsApiKey="AIzaSyDxrbk0CKmZUpJ-5eQGaxHH-I5lYeWrPfc">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                options={{
                    mapTypeId: 'satellite', // Set map type here instead of as a separate prop
                    disableDefaultUI: true, // Hides all default UI controls
                }}
                onLoad={handleLoad}
            >
                <Marker position={center}/>
            </GoogleMap>
        </LoadScriptNext>
    );
};

export default SmallMap;