import React, {useState} from 'react';
import SideBar from "./SideBar";
import {Button} from "@headlessui/react";
import {ArrowPathIcon,
} from "@heroicons/react/24/outline";
import {useAuth} from "../contexts/authContext";
import {useDarkMode} from "../contexts/darkModeContext";

const Research = () => {
    // eslint-disable-next-line no-unused-vars
    const { currentUser } = useAuth();
    
    // eslint-disable-next-line no-unused-vars
    const [alertsOn, setAlertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });
    
    const { darkMode } = useDarkMode();
    

    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-screen overflow-y-hidden w-full pt-16 transition-all duration-500 ease-in-out`}>
            
            <SideBar/>
            
            <div className="z-20 animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] h-full mt-3 mb-3 mr-3">
                
                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3">
                    
                    <div className={`text-2xl w-1/6 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row tracking-widest`}>Research
                        {/*Buttons Delete*/}
                        <div className={`flex flex-col items-center ml-3 mt-auto mb-auto`}>
                            <Button className={`rounded-full ${darkMode ? 'bg-darkMainColor text-gray-500' : 'bg-white text-gray-700'} p-1 hover:scale-105 hover:rotate-12 mt-auto mb-auto h-7 w-7 items-center justify-center align-middle`}
                                // onClick={onUpdate}
                            >
                                <ArrowPathIcon className="m-auto"/>
                            </Button>
                        </div>
                    </div>

                    <div className={`text-xl w-5/6 mr-auto ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} flex rounded-md border drop-shadow shadow-gray-900`}>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-1 line-clamp-1">Active:</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-1 line-clamp-1">Pending:</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-1 line-clamp-1">Planned:</div>
                        </div>

                    </div>

                </div>
                

                {/*Orders Body*/}
                <div className={`w-full h-full flex p-2 ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-lg`}>
                    
                    <div className={`w-1/6 border h-full rounded-lg p-2 ${darkMode ? 'border-neutral-700' : ''} mr-2`}>
                        Hello
                    </div>
                    
                    <div className={`w-full border h-full rounded-lg p-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                        Hello
                    </div>
                
                </div>
                
                
                {/*Customers Footer [Buttons]*/}
                <div className="w-full h-fit flex mb-3 mt-3">

                </div>

            </div>


        </div>
    )
};


export default Research;
