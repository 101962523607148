import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {useAuth} from "../../contexts/authContext";
import {
    ArrowDownTrayIcon,
    DocumentIcon,
    ExclamationTriangleIcon,
    EyeSlashIcon,
    PhotoIcon
} from "@heroicons/react/24/outline";
import {categoryColors, getCategoryInfo} from "../GlobalFunctions";
import mainLogo from "../../main-logo.png"
import {Button} from "@headlessui/react";

const ProductExpanded = ({ product }) => {
    
    const { currentUser } = useAuth();
    
    const { darkMode } = useDarkMode();
    
    // Create a dynamic import context
    const requireSDS = require.context("../../SDS", false, /\.pdf$/);
    const requireSell = require.context("../../sellSheets", false, /\.pdf$/);
    const requireImg = require.context("../../images", false, /\.(png|jpg)$/);

    
    // Create a mapping from file names to file paths
    const SDSMapping = requireSDS.keys().reduce((acc, filePath) => {
        // Extract the file name without extension
        const fileName = filePath.replace("./", "").replace(".pdf", "");
        // Add to the mapping
        acc[fileName] = requireSDS(filePath);
        return acc;
    }, {});

    // Create a mapping from file names to file paths
    const SellSheetMapping = requireSell.keys().reduce((acc, filePath) => {
        // Extract the file name without extension
        const fileName = filePath.replace("./", "").replace(".pdf", "");
        // Add to the mapping
        acc[fileName] = requireSell(filePath);
        return acc;
    }, {});
    //
    // // Create a mapping from file names to file paths
    const ImageMapping = requireImg.keys().reduce((acc, filePath) => {
        // Extract the file name without extension
        const fileName = filePath.replace("./", "").replace(/\.(png|jpg)$/, "");
        // Add to the mapping
        acc[fileName] = requireImg(filePath);
        return acc;
    }, {});
    
    const bgColor = categoryColors.find(c => c.category === product?.category)?.color || '';

    const isIPad = /iPad|Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1;
    
    return (
        <div className={`h-[calc(100dvh-9rem)] p-3 relative animate-fadeIn ${darkMode ? 'text-white' : 'text-black'}`}>
            
            <div className="grid grid-cols-3 grid-rows-2 gap-3 relative h-full">
                
                {/*Info*/}
                <div className={`col-start-1 border p-3 rounded-lg ${darkMode ? 'border-neutral-700' : ''}`}>
                    
                    <div className={`h-full`}>
                    
                        {/*Image*/}
                        <div className={`flex items-center justify-center w-full h-48 rounded-lg ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'}`}>
                            {product?.sds ? (
                                <img
                                    src={ImageMapping[product.sds] || mainLogo}
                                    alt="Main"
                                    className="object-cover object-top overflow-hidden"
                                />
                            ) : (
                                <PhotoIcon className="size-12 text-gray-500" />
                            )}
                            
                        </div>
                        
                        <div className={`${bgColor} bg-opacity-50 tracking-widest text-center mt-2 rounded-lg`}>{product?.prices?.[0]?.code}</div>
                    
                        <div className={`h-full`}>
                            {product?.weight ? (
                                <div className={`h-full`}>
                                    
                                    {/*Weight*/}
                                    <div className="flex items-center text-sm justify-between pt-1">
                                        <div>
                                            <div className={`rounded-full tracking-widest`}>Weight</div>
                                        </div>
                                        <div className={`rounded-full tracking-widest`}>{product?.weight} lbs</div>
                                    </div>
                                    
                                    {/*PH*/}
                                    <div className="flex items-center text-sm justify-between pt-1">
                                        <div>
                                            <div className={`rounded-full tracking-widest`}>PH</div>
                                        </div>
                                        <div className={`rounded-full tracking-widest`}>{product?.ph}</div>
                                    </div>
                               
                                </div>
                            ) : (
                                // Skeleton
                                <div role="status" className={`max-w-md rounded-sm shadow-sm`}>
                                    <div className="flex items-center justify-between pt-4">
                                        <div>
                                            <div className={`h-2.5 ${darkMode ? 'bg-neutral-700' : 'bg-neutral-300'} rounded-full w-24 mb-2.5`}></div>
                                            <div className={`w-32 h-2 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} rounded-full`}></div>
                                        </div>
                                        <div className={`h-2.5 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} rounded-full w-12`}></div>
                                    </div>
                                    
                                </div>
                            )}
                        </div>
                    
                    </div>
                    
                </div>
                
                {/*Below*/}
                <div className={`col-start-1 row-start-2 border p-3 rounded-lg ${darkMode ? 'border-neutral-700' : ''}`}>
                    
                    
                    <div className={`h-full`}>
                        
                        {SDSMapping[product?.sds] ? (
                            <div className={`h-full`}>
                                <iframe
                                    src={SDSMapping[product?.sds]}
                                    className="w-full h-full rounded-lg max-h-[calc(100dvh)]"
                                    title="PDF Viewer"
                                />
                                {isIPad && (
                                    <a
                                        href={SDSMapping[product?.sds]}
                                        download
                                        className="absolute top-1 right-1 tracking-widest flex items-center text-xs bg-blue-500 text-white px-2 py-1 rounded-lg shadow-md"
                                    >
                                        <ArrowDownTrayIcon className={`size-4 mr-2`}/>
                                        PDF
                                    </a>
                                )}
                            </div>
                        ) : (
                            // Skeleton
                            <div className={`flex flex-col items-center justify-center w-full h-full rounded-lg ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'}`}>
                                
                                <DocumentIcon className="size-24 text-gray-500" />
                                <div className={`text-gray-500 font-bold tracking-widest text-xl`}>No SDS</div>
                                
                            </div>
                        )}
                        
                    </div>
                    
                    
                </div>
                
                {/*Description*/}
                <div className={`col-span-2 row-span-2 col-start-2 border p-3 rounded-lg ${darkMode ? 'border-neutral-700' : ''}`}>
                    
                    
                    <div className={`h-[calc(100dvh-12.25rem)] overflow-y-scroll scrollbar-hide`}>
                       
                        {/*<div className={``}>{product?.sds}</div>*/}
                        
                        {product?.name ? (
                            <div className={`flex flex-col`}>
                                <div className={`text-lg text-center tracking-widest font-bold`}>{product.name}</div>
                                <div className={`mb-4 text-xs text-center tracking-widest font-bold ${bgColor} rounded-lg bg-opacity-50`}>{product.category}</div>
                            </div>
                        ) : (
                            // Skeleton
                            <div className="w-full h-[calc(100dvh-15.5rem)]">
                                <div className={`h-5 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} rounded-full w-48 mb-6`}></div>
                                <div className={`h-4 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} rounded-full max-w-[480px] mb-2.5`}></div>
                                
                                <div className={`flex flex-col items-center justify-center w-full h-full rounded-lg ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'}`}>
                                
                                    <DocumentIcon className="size-24 text-gray-500"/>
                                    <div className={`text-gray-500 font-bold tracking-widest text-xl`}>No Sell Sheet</div>
                                
                                </div>
                            </div>
                        )}
                        
                        {SellSheetMapping[product?.sds] ? (
                            <div className="relative w-full h-full">
                                <iframe
                                    src={SellSheetMapping[product?.sds]}
                                    className="w-full h-full rounded-lg max-h-[calc(100dvh)]"
                                    title="PDF Viewer"
                                />
                                {isIPad && (
                                    <a
                                        href={SellSheetMapping[product?.sds]}
                                        download
                                        className="absolute top-1 right-1 tracking-widest flex items-center text-xs bg-blue-500 text-white px-2 py-1 rounded-lg shadow-md"
                                    >
                                        <ArrowDownTrayIcon className={`size-4 mr-2`}/>
                                        PDF
                                    </a>
                                )}
                            </div>
                            
                        ) : (
                            <div className={`flex flex-col items-center justify-center w-full h-full rounded-lg ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'}`}>
                                
                                <DocumentIcon className="size-24 text-gray-500"/>
                                <div className={`text-gray-500 font-bold tracking-widest text-xl`}>No Sell Sheet</div>
                            
                            </div>
                        
                        )}
                    
                    </div>
                    
                
                </div>
            
            </div>
            
            
            {!product && (
                <div className="absolute bg-gray-500/5 transition-opacity inset-0 flex items-center justify-center">
                    
                    
                    <div
                        className={`relative transform overflow-hidden rounded-lg  ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}>
                        <div className="sm:flex sm:items-start">
                            <div
                                className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-600/15 sm:mx-0 sm:size-10">
                                <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600"/>
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <div as="h3" className="text-base font-semibold">
                                    No Product Selected
                                </div>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Choose any of the products to the left in the list to view the expanded product
                                        view.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/*<span className="text-gray-500">(Empty)</span>*/}
                </div>
            )}
        
        </div>
    
    )
}

export default ProductExpanded;