import React, {useEffect, useState} from 'react';
import { collection, getDocs } from 'firebase/firestore';


import SideBar from "./SideBar";
import {
    ArrowPathIcon,
    EnvelopeIcon,
    EyeSlashIcon,
    GlobeAmericasIcon,
    IdentificationIcon,
    LockOpenIcon,
    MapIcon,
    MoonIcon,
    PhoneIcon,
    PlusIcon,
    PrinterIcon,
    SunIcon,
    TicketIcon,
    UserIcon,
} from "@heroicons/react/24/outline";
import {useAuth} from "../contexts/authContext";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { storage, db } from '../firebase/firebase';
import {
    Button,
    Dialog,
    DialogBackdrop,
    DialogPanel, DialogTitle,
    Field,
    Input,
    Label,
    Select,
    Switch,
    Textarea
} from "@headlessui/react";
import {useDarkMode} from "../contexts/darkModeContext";
import {XMarkIcon} from "@heroicons/react/16/solid";
import TicketCard from "./Profile/TicketCard";
import Loading from "./Loading";
import {getAuth, sendPasswordResetEmail, updateEmail} from "firebase/auth";
import AccountCard from "./Profile/AccountCard";
import {accountLevelMapping, formatPhoneNumber, pricingMapping, supervisorMapping} from "./GlobalFunctions";

const Profile = () => {

    const { currentUser } = useAuth();

    const {darkMode, toggleDarkMode} = useDarkMode();

    // eslint-disable-next-line no-unused-vars
    const [image, setImage] = useState(null); // To store the selected image file
    // eslint-disable-next-line no-unused-vars
    const [uploading, setUploading] = useState(false); // Upload status
    // eslint-disable-next-line no-unused-vars
    const [imageURL, setImageURL] = useState(''); // To store the uploaded image URL


    const [isPassword, setIsPassword] = useState(false);
    const [isEmail, setIsEmail] = useState(false);

    const [ticket, setTicket] = useState(false);

    const [tickets, setTickets] = useState([]);
    const [closedTickets, setClosedTickets] = useState([]);


    const [category, setCategory] = useState("");
    const [page, setPage] = useState("");
    const [notes, setNotes] = useState("");


    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");


    const [open, setOpen] = useState(false);

    const openVis = () => {
        setOpen(!open);
    }




    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [fullname, setFullname] = useState("");
    const [salesCode, setSalesCode] = useState("");
    const [profileImage, setProfileImage] = useState('');
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [address, setAddress] = useState("");
    const [region, setRegion] = useState("");
    const [supervisor, setSupervisor] = useState("");
    const [accountLevel, setAccountLevel] = useState("");
    

    const togglePassword = () => {
        setIsPassword(!isPassword);
    }

    const toggleEmail = () => {
        setIsEmail(!isEmail);
    }

    const toggleTicket = () => {
        setTicket(!ticket);
    }

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname || "");
                        setLname(userData.lname || "");
                        setSalesCode(userData.salesCode || "");
                        setFullname(userData.fname + " " + userData.lname  || "");
                        setProfileImage(userData.profileImageUrl || "");
                        setEmail(userData.email || "");
                        setPhone(userData.phone || "");
                        setFax(userData.fax || "");
                        setAddress(userData.address || "");
                        setRegion(userData.region || "");
                        setSupervisor(userData.supervisor || "");
                        setAccountLevel(userData.accountLevel || "");
                        setSelectedReps(userData.reps || []);


                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname || "");
                            setLname(userData.lname || "");
                            setSalesCode(userData.salesCode || "");
                            setFullname(userData.fname + " " + userData.lname  || "");
                            setProfileImage(userData.profileImageUrl || "");
                            setEmail(userData.email || "");
                            setPhone(userData.phone || "");
                            setFax(userData.fax || "");
                            setAddress(userData.address || "");
                            setRegion(userData.region || "");
                            setSupervisor(userData.supervisor || "");
                            setAccountLevel(userData.accountLevel || "");
                            setSelectedReps(userData.reps || []);
                            

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchTickets = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`tickets_${currentUser.uid}`);
                    if (storedPendingData) {
                        const pendingData = JSON.parse(storedPendingData);
                        setTickets(pendingData);
                    } else {
                        const pendingCustomers = db.collection("Tickets").doc(currentUser.uid).collection("open");
                        const snapshot = await pendingCustomers.get();

                        if (!snapshot.empty) {
                            const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setTickets(pendingData);
                            localStorage.setItem(`tickets_${currentUser.uid}`, JSON.stringify(pendingData));
                        } else {
                            console.log('Customer document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching customers data:', error);
                }
            }
        }

        const fetchTicketsClosed = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`ticketsClosed_${currentUser.uid}`);
                    if (storedPendingData) {
                        const pendingData = JSON.parse(storedPendingData);
                        setClosedTickets(pendingData);
                    } else {
                        const pendingCustomers = db.collection("Tickets").doc(currentUser.uid).collection("closed");
                        const snapshot = await pendingCustomers.get();

                        if (!snapshot.empty) {
                            const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setClosedTickets(pendingData);
                            localStorage.setItem(`ticketsClosed_${currentUser.uid}`, JSON.stringify(pendingData));
                        } else {
                            console.log('Customer document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching customers data:', error);
                }
            }
        }

        fetchUserName();
        fetchTickets();
        fetchTicketsClosed();
    }, [currentUser]);


    const [updating, setUpdating] = useState(false);

    
    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();
        
        try {
            // Fetch user document from Firestore based on the current user's UID
            const userDoc = await db.collection('Users').doc(currentUser.uid).get();
            if (userDoc.exists) {
                // Get the user's name from the document data
                const userData = userDoc.data();
                setFname(userData.fname || "");
                setLname(userData.lname || "");
                setSalesCode(userData.salesCode || "");
                setFullname(userData.fname + " " + userData.lname  || "");
                setProfileImage(userData.profileImageUrl || "");
                setEmail(userData.email || "");
                setPhone(userData.phone || "");
                setFax(userData.fax || "");
                setAddress(userData.address || "");
                setRegion(userData.region || "");
                setSupervisor(userData.supervisor || "");
                setAccountLevel(userData.accountLevel || "");
                setSelectedReps(userData.reps || []);

                setFullname(userData.fname + " " + userData.lname || "");

                // Save user data to localStorage
                localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
            } else {
                console.log('User document not found');
            }

        } catch (error) {
            console.error("Error updating customers", error);
        }
        
        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        if (alertsOn) {
            alert(`Tickets Updated in ${timeElapsed} secs`);
        }
    };

    
    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        setUploading(true);
        try {
            setImage(file); // Store the image in state if needed
    
            // Create a storage reference
            const storageRef = ref(storage, `profileImages/${currentUser.uid}/${file.name}`);
    
            // Upload the image to Firebase Storage
            const snapshot = await uploadBytes(storageRef, file);
    
            // Get the download URL
            const downloadURL = await getDownloadURL(snapshot.ref);
    
            // Save the download URL to Firestore
            await setDoc(doc(db, 'Users', currentUser.uid), {
                profileImageUrl: downloadURL,
            }, { merge: true });
    
            setImageURL(downloadURL); // Update the state with the new image URL
    
            await onUpdate();
            
            if (alertsOn) {
                alert('Profile image uploaded successfully!');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            if (alertsOn) {
                alert('Error uploading image, please try again.');
            }
        } finally {
            setUploading(false);
        }
    };

    
    
    const onUpdateTickets = async () => {
        setUpdating(true);
        const startTime = Date.now();
        
        try {

            const pendingCustomers = db.collection("Tickets").doc(currentUser.uid).collection("open");
            const snapshot = await pendingCustomers.get();

            if (!snapshot.empty) {
                const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setTickets(pendingData);
                localStorage.setItem(`tickets_${currentUser.uid}`, JSON.stringify(pendingData));
            } else {
                console.log('Customer document not found');
            }

        } catch (error) {
            console.error("Error updating tickets", error);
        }

        try {

            const pendingCustomers = db.collection("Tickets").doc(currentUser.uid).collection("closed");
            const snapshot = await pendingCustomers.get();

            if (!snapshot.empty) {
                const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setClosedTickets(pendingData);
                localStorage.setItem(`ticketsClosed_${currentUser.uid}`, JSON.stringify(pendingData));
            } else {
                console.log('Customer document not found');
            }

        } catch (error) {
            console.error("Error updating tickets", error);
        }
        
        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        if (alertsOn) {
            alert(`Tickets Updated in ${timeElapsed} secs`);
        }
    };


    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update user data in Firestore collection
            await db.collection('Users').doc(currentUser.uid).update({
                fname: fname,
                lname: lname,
                email: email,
                phone: phone,
                fax: fax,
                address: address,
                region: region,
                accountLevel: accountLevel,
                salesCode: salesCode
            });
            console.log('User data updated successfully');
            await onUpdate()

            if (alertsOn) {
                alert('User updated successfully');
            }
        } catch (error) {
            console.error("Error updating user data: ", error);
        }
    };

    const [users, setUsers] = useState([]);
    const [selectedReps, setSelectedReps] = useState([]);


    // Fetch users from Firestore on component mount
    useEffect(() => {
        const fetchUsers = async () => {
            const usersCollection = collection(db, 'Users');
            const userSnapshot = await getDocs(usersCollection);
            const userList = userSnapshot.docs.map(doc => ({
                id: doc.id, // UID from Firestore document ID
                name: `${doc.data().fname} ${doc.data().lname}`, // Combining fname and lname into a single name field
                accountLevel: doc.data().accountLevel,
                email: doc.data().email,
                phone:doc.data().phone,
            }));
            setUsers(userList);
        };
        fetchUsers();
    }, []);


    // Handle selection of a user
    const handleSelectChange = (e) => {
        const selectedUserId = e.target.value;
        const selectedUser = users.find(user => user.id === selectedUserId);

        // Add selected user to selectedReps array if not already selected
        if (selectedUser && !selectedReps.some(rep => rep.id === selectedUserId)) {
            setSelectedReps([...selectedReps, selectedUser]);
        }
    };

    // Handle removing a rep from the selectedReps list
    const handleRemoveRep = (repId) => {
        setSelectedReps(selectedReps.filter(rep => rep.id !== repId));
    };

    // Handle the update of the reps array in Firestore
    const handleUpdateReps = async () => {
        try {
            // Update user data in Firestore collection
            await db.collection('Users').doc(currentUser.uid).update({
                reps: selectedReps.map(rep => ({ id: rep.id, name: rep.name })), // Map selected reps to just their IDs
            });
            console.log('User data updated successfully');
            await onUpdate()

            if (alertsOn) {
                alert('User updated successfully');
            }
        } catch (error) {
            console.error("Error updating user data: ", error);
        }
    };

    const onSubmitMain = async (e) => {
        e.preventDefault();

        const collectionRef = db.collection("Tickets").doc(currentUser.uid).collection("open");

        const parsedFormData = {
            // Ticket Info
            category: category,
            page: page,
            notes: notes,

            // Sales Info
            salesRep: fullname,
            salesCode: salesCode,
            salesID: currentUser.uid,
            date: new Date().toLocaleDateString(),

            status: "open",
        };

        try {
            // Create a new document with a generated ID within the "open" subcollection
            await collectionRef.doc().set(parsedFormData);

            if (alertsOn) {
                alert("Ticket Successfully Submitted"); // Optional: alert for user feedback
            }

            // Clear the form
            setCategory("");
            setPage("");
            setNotes("");

            // Optional actions after submission
            // handleNextTab();
            onUpdateTickets();
            toggleTicket();

        } catch (error) {
            console.error("Error submitting ticket:", error.message);
            // setIsRegistered(false);
        }
    };

    const onSubmitBanner = async (e) => {
        e.preventDefault();

        // Reference to the "banner" document within the "GlobalSettings" collection
        const docRef = db.collection("GlobalSettings").doc("Banner");

        const parsedFormData = {
            title: title,  // Assuming 'title' is state holding the banner title
            body: body,    // Assuming 'body' is state holding the banner body content
            // display: true, // Optional: to control visibility of the banner
            // imageUrl: imageUrl || "", // Optional: image URL if applicable, defaults to empty
        };

        try {
            // Set the banner data in the specified document
            await docRef.set(parsedFormData);

            if (alertsOn) {
                alert("Banner Successfully Updated"); // Optional: alert for user feedback
            }

        } catch (error) {
            console.error("Error updating banner:", error.message);
        }
    };
    
    const handlePasswordReset = async () => {
        const auth = getAuth();
        try {
            await sendPasswordResetEmail(auth, email);
            alert("Password reset email sent! Please check your inbox.");
        } catch (error) {
            alert(`Error: ${error.message}`);
        }
    };
    
    const [newEmail, setNewEmail] = useState("");
    
    const handleEmailUpdate = async (e) => {
        e.preventDefault();
        
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            if (alertsOn) {
                alert("You need to be logged in to update your email.");
            }
            return;
        }

        try {
            await updateEmail(user, newEmail);
            if (alertsOn) {
                alert("Email address updated successfully!");
            }
        } catch (error) {
            if (alertsOn) {
                alert(`Error: ${error.message}`);
            }
        }
    };

    
    const localStorageKey = 'alertsOn';

    const [alertsOn, setAlertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });
    
    // Save settings to local storage when they change
    useEffect(() => {
        localStorage.setItem(
            localStorageKey,
            JSON.stringify({ alertsOn: alertsOn }) // Save as an object
        );
        console.log("Changes Saved");
    }, [alertsOn]);
    
    const localStorageKeyDefault = 'defaultPage'
    
    const [defaultPage, setDefaultPage] = useState(() => {
        const storedSettings = localStorage.getItem('defaultPage');
        return storedSettings ? JSON.parse(storedSettings).defaultPage : '/Home'; // Default to false if null
    });
    
    // Save settings to local storage when they change
    useEffect(() => {
        localStorage.setItem(
            localStorageKeyDefault,
            JSON.stringify({ defaultPage: defaultPage }) // Save as an object
        );
        console.log("Default Page Saved");
    }, [defaultPage]);

    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-[calc(100dvh-4rem)] w-full mt-16 transition-all duration-500 ease-in-out`}>

            {updating && (
                <Loading/>
            )}
            
            <SideBar/>
            
            
            <div className="z-20 flex h-full w-full max-w-[calc(100%-5.8rem)]">
            
                {/*Account Info Side*/}
                <div className={`z-20 animate-slideInLeft w-1/3 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} relative mt-3 mb-3 rounded-lg p-3 overflow-y-auto transition-all duration-500 ease-in-out`}>
    
                    {/*Buttons Update*/}
                    <div className="absolute items-center">
                        <Button className="rounded-full hover:animate-spin mt-auto mb-auto h-6 w-6 items-center justify-center align-middle"
                                onClick={onUpdate}
                        >
                            <ArrowPathIcon className="h-6 text-borderColor hover:text-bgLightGreen m-auto"/>
                        </Button>
                    </div>
    
    
                    <div className="flex flex-col h-full">
    
                        {/*Profile Image / Selector*/}
                        <div className="flex flex-col h-1/3 mb-3 justify-center bg items-center">
                            
                            
                            <div className="mt-6">
                                
                                <div className="relative overflow-hidden rounded-full lg:block">
                                    <img alt="" src={profileImage} className={`relative size-48 rounded-full border ${darkMode ? 'border-neutral-700' : ''}`} />
                                    <label
                                        htmlFor="desktop-user-photo"
                                        className="absolute inset-0 flex size-full items-center justify-center bg-black/75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100"
                                    >
                                        <span>Change</span>
                                        <span className="sr-only"> user photo</span>
                                        <Input
                                            id="desktop-user-photo"
                                            name="user-photo"
                                            type="file"
                                            onChange={handleImageUpload}
                                            accept="image/*"
                                            className="absolute inset-0 size-full cursor-pointer rounded-md border-gray-300 opacity-0"
                                        />
                                    </label>
                                </div>
                                
                            </div>
                            
                        </div>
    
                        {/*Account Info*/}
                        <div className={`flex w-full flex-col h-full border-2 ${darkMode ? 'border-darkBgColor' : ''} rounded-lg p-3`}>
    
                            {/*Name*/}
                            <div className={`text-xl w-full flex flex-col items-center border-b-2 ${darkMode ? 'border-darkBgColor' : ''} mb-3`}>
                                <div className="font-semibold tracking-wider hover:tracking-widest">{fullname || "- - - - -"}</div>
                                <div className="text-xxs tracking-widest">{accountLevelMapping[accountLevel] || "- - - - -"}</div>
                            </div>
    
                            {/*Email*/}
                            <div className="w-full flex flex-row items-center mb-auto">
    
                                <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                    <EnvelopeIcon className="w-5"/>
                                </div>
    
                                <div className="text-sm">{email || "- - - - -"}</div>
    
                                <div className="ml-auto text-sm">Email</div>
                            </div>
    
                            {/*Phone*/}
                            <div className="w-full flex flex-row items-center mb-auto">
    
                                <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                    <PhoneIcon className="w-5"/>
                                </div>
    
                                <div className="text-sm">{formatPhoneNumber(phone) || "- - - - -"}</div>
    
                                <div className="ml-auto text-sm">Phone</div>
                            </div>
    
                            {/*Fax*/}
                            <div className="w-full flex flex-row items-center mb-auto">
    
                                <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                    <PrinterIcon className="w-5"/>
                                </div>
    
                                <div className="text-sm">{formatPhoneNumber(fax) || "- - - - -"}</div>
    
                                <div className="ml-auto text-sm">Fax</div>
                            </div>
    
                            {/*Address*/}
                            <div className="w-full flex flex-row items-center mb-auto">
    
                                <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                    <MapIcon className="w-5"/>
                                </div>
    
                                <div className="text-sm">{address || "- - - - -"}</div>
    
                                <div className="ml-auto text-sm">Address</div>
                            </div>
    
                            {/*Region*/}
                            <div className="w-full flex flex-row items-center mb-auto">
    
                                <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                    <GlobeAmericasIcon className="w-5"/>
                                </div>
    
                                <div className="text-sm">{pricingMapping[region] || "- - - - -"}</div>
    
                                <div className="ml-auto text-sm">Region</div>
                            </div>
    
                            {/*Supervisor*/}
                            <div className="w-full flex flex-row items-center mb-auto">
    
                                <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                    <UserIcon className="w-5"/>
                                </div>
    
                                <div className="text-sm">{supervisorMapping[supervisor] || "- - - - -"}</div>
    
                                <div className="ml-auto text-sm">Supervisor</div>
                            </div>
    
                            {/*Sales Code*/}
                            <div className="w-full flex flex-row items-center">
    
                                <div className={`${darkMode ? 'bg-green-600' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                    <IdentificationIcon className="w-5"/>
                                </div>
    
                                <div className="text-sm">{salesCode || "- - - - -"}</div>
    
                                <div className="ml-auto text-sm">Sales Code</div>
                            </div>
    
                        </div>
    
                    </div>
    
                </div>
    
                {/*Settings*/}
                <div className={`z-20 animate-slideInBottom w-2/3 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} relative mt-3 mb-3 ml-3 p-3 rounded-lg overflow-y-auto hide-scrollbar transition-all duration-500 ease-in-out`}>

                    <div className={`text-xl font-semibold text-center tracking-widest mb-1 border-b-4 border-dotted ${darkMode ? 'border-darkBgColor' : ''}`}>Settings</div>
    
                    {/*Account Info*/}
                    <div className={`w-full border-b-4 border-dotted p-3 ${darkMode ? 'border-darkBgColor' : ''}`}>
                        <div className={`text-lg font-bold tracking-widest`}>Account Info</div>
                        <div className={`text-xs ml-3 text-gray-500 mb-3`}>Update your account data below</div>
    
                        <form onSubmit={onSubmit} method="POST" className={`grid grid-cols-6 gap-x-5 gap-y-8 px-3 py-8 mb-2 border-2 rounded-lg ${darkMode ? 'border-darkBgColor' : ''}`}>
                            
                            {/*F Name*/}
                            <div className={`relative col-span-3`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    First Name
                                </label>
                                <Input
                                    type="text"
                                    value={fname}
                                    onChange={(e) => setFname(e.target.value)}
                                    placeholder={`First Name`}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
                            
                            {/*L Name*/}
                            <div className={`relative col-span-3`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    Last Name
                                </label>
                                <Input
                                    type="text"
                                    required
                                    value={lname}
                                    onChange={(e) => setLname(e.target.value)}
                                    placeholder={`Last Name`}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
                            
                            
                            
                            {/*Phone*/}
                            <div className={`relative col-span-3`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    Phone Number
                                </label>
                                <Input
                                    type="text"
                                    value={formatPhoneNumber(phone)}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder={`First Name`}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
                            
                            {/*Fax*/}
                            <div className={`relative col-span-3`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    Fax Number
                                </label>
                                <Input
                                    type="text"
                                    required
                                    value={fax}
                                    onChange={(e) => setFax(e.target.value)}
                                    placeholder={`Fax Number`}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
    
                            
                            
                            {/*Address*/}
                            <div className={`relative col-span-4`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    Address
                                </label>
                                <Input
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    placeholder={`Address`}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
                            
                            {/*Sales Industry*/}
                            <div className={`relative col-span-2`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    Sales Industry
                                </label>
                                <Select
                                    type="text"
                                    required
                                    value={region}
                                    onChange={(e) => setRegion(e.target.value)}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                >
                                    <option value="AG">Agriculture</option>
                                    <option value="DIST1">Distributor</option>
                                    <option value="DIST2">Distributor 2</option>
                                    <option value="NE">Northeast</option>
                                    <option value="SE">Southeast</option>
                                </Select>
                            </div>
                            
                            
                            
                            {/*Account Level*/}
                            <div className={`relative col-span-2`}>
                                <label
                                    className={`absolute flex z-10 -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    Account Level
                                </label>
                                <Select
                                    type="text"
                                    required
                                    value={accountLevel}
                                    disabled={true}
                                    onChange={(e) => setAccountLevel(e.target.value)}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                >
                                    <option value="S">Salesperson</option>
                                    <option value="CSR">CSR (Customer Service Rep)</option>
                                    <option value="PFC">PFC Fulfillment</option>
                                    <option value="DIST">Distributor</option>
                                    <option value="A">Admin</option>
                                    <option value="D">Developer</option>
                                
                                </Select>
                            </div>
                            
                            {/*Sales Code*/}
                            <div className={`relative col-span-2`}>
                                <label
                                    className={`absolute flex z-10 -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    Sales Code
                                </label>
                                <Input
                                    type="text"
                                    value={salesCode}
                                    disabled={true}
                                    onChange={(e) => setSalesCode(e.target.value)}
                                    placeholder={`Sales Code`}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
                            
                            {/*Supervisor*/}
                            <div className={`relative col-span-2`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    Supervisor
                                </label>
                                <Input
                                    type="text"
                                    value={supervisorMapping[supervisor]}
                                    disabled={true}
                                    onChange={(e) => setSupervisor(e.target.value)}
                                    placeholder={`Address`}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
                            
                            
                            
                            {/*Email Address*/}
                            <div className={`relative col-span-4`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    Email Address
                                </label>
                                <Input
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder={`Email Address`}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
                            
                            {/*BirthDay*/}
                            <div className={`relative col-span-2`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    Birthday
                                </label>
                                <Input
                                    type="date"
                                    disabled={true}
                                    // value={email}
                                    // onChange={(e) => setEmail(e.target.value)}
                                    placeholder={`Email Address`}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
                            
                            
    
                            {/*Save Button*/}
                            <div className={`col-span-full`}>
                                <Button
                                    type="submit"
                                    // disabled={isRegistering}
                                    className="flex w-1/4 ml-auto hover:scale-105 justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Update Account
                                </Button>
                            </div>
                            
                        </form>
    
                    </div>
    
                    {/*General*/}
                    <div className={`w-full border-b-4 border-dotted p-3 ${darkMode ? 'border-darkBgColor' : ''}`}>
                        <div className={`text-lg font-bold tracking-widest`}>General Account Settings</div>
                        <div className={`text-xs ml-3 text-gray-500 mb-3`}>These are general settings for your account</div>
    
                        <div className={`space-y-5 p-3 mb-2 border-2 rounded-lg ${darkMode ? 'border-darkBgColor' : ''}`}>
    
                            {/*DarkMode*/}
                            <div className="w-full flex flex-row items-center">
    
                                <label htmlFor="email" className="block w-1/4 text-sm font-medium leading-6 ">
                                    Dark Mode:
                                </label>
    
                                {/*ToggleSwitch*/}
                                <div className="w-fit ml-10">
                                    <input
                                        type="checkbox"
                                        id="spray"
                                        name="spray"
                                        checked={darkMode}
                                        onChange={toggleDarkMode} // Call the toggle function from context
                                        className="sr-only w-fit"
                                    />
                                    <label htmlFor="spray" className="cursor-pointer">
                                        <div
                                            className={`mr-auto ml-auto w-14 h-8 rounded-full relative ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor drop-shadow shadow-gray-900'}`}>
                                            <div
                                                className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${darkMode ? 'bg-darkMainColor translate-x-full' : 'bg-white'}`}>
                                                {darkMode ? (
                                                    <MoonIcon
                                                        className="w-4 h-full flex-none text-gray-300 mr-auto ml-auto mt-auto mb-auto"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    <SunIcon
                                                        className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </label>
                                </div>
    
    
                            </div>
    
                            {/*Reset Password*/}
                            <div className="w-full flex flex-row items-center">
    
                                <label htmlFor="email" className="block w-1/4 text-sm font-medium leading-6 ">
                                    Password Reset:
                                </label>
    
                                {/*Button*/}
                                <div className="w-1/4 ml-10">
    
                                    <Button
                                        onClick={togglePassword}
                                        className="flex w-full hover:scale-105 ml-auto justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Reset Password
                                    </Button>
    
                                </div>
    
    
                            </div>
    
                            {/*Reset Email*/}
                            <div className="w-full flex flex-row items-center">
    
                                <label htmlFor="email" className="block w-1/4 text-sm font-medium leading-6 ">
                                    Email Reset:
                                </label>
    
                                {/*Button*/}
                                <div className="w-1/4 ml-10">
    
                                    <Button
                                        onClick={toggleEmail}
                                        className="flex ml-auto hover:scale-105 w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Reset Email
                                    </Button>
    
                                </div>
    
    
                            </div>
    
                            {/*Alerts*/}
                            <div className="w-full flex flex-row items-center">
    
                                <label className="block w-1/4 text-sm font-medium leading-6 ">
                                    Alerts:
                                </label>
    
                                {/*ToggleSwitch*/}
                                <Field className="flex w-fit ml-10 col-span-2">
                                    <Switch
                                        checked={alertsOn}
                                        onChange={setAlertsOn}
                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span aria-hidden="true"
                                              className="pointer-events-none absolute size-full rounded-md"/>
                                        <span
                                            aria-hidden="true"
                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                        />
                                        <span
                                            aria-hidden="true"
                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                        />
                                    </Switch>
                                    <Label as="span" className="ml-3 text-sm w-full">
                                        <span className="font-medium">Alert Windows</span>{' '}
                                        <span className="text-gray-500 text-xs">(Popup Windows after every action)</span>
                                    </Label>
                                </Field>
    
    
                            </div>
                            
                            {/*Default Page*/}
                            <div className="w-full flex flex-row items-center">
    
                                <label className="block w-1/4 text-sm font-medium leading-6 ">
                                    Default Page:
                                </label>
    
                                {/*ToggleSwitch*/}
                                <Field className="flex items-center ml-10 col-span-2">
                                    {/*Default Page*/}
                                    <div className={`relative w-fit`}>
                                        <label
                                            className={`absolute flex z-10 -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Account Level
                                        </label>
                                        <Select
                                            value={defaultPage}
                                            onChange={(e) => setDefaultPage(e.target.value)}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        >
                                            <option value="/Home">Home</option>
                                            <option value="/Dashboard">Dashboard</option>
                                            <option value="/Catalog">Catalog</option>
                                            <option value="/Proven">Proven Programs</option>
                                            <option value="/Profile">Profile</option>
                                            <option value="/Guides">Help & Resources</option>
                                        
                                        </Select>
                                    </div>
                                    <Label as="span" className="ml-3 text-sm">
                                        <span className="font-medium">Default Page</span>{' '}
                                        <span className="text-gray-500 text-xs">(This sets the default page that opens when logged in)</span>
                                    </Label>
                                </Field>
    
    
                            </div>
                            
                            
                        </div>
    
    
                    </div>
    
    
                    {/*CSR*/}
                    {["D", "A", "CSR", "PFC"].includes(accountLevel) && (
                        <>
    
                            {/*CSR*/}
                            <div className={`w-full border-b-4 border-dotted p-3 ${darkMode ? 'border-darkBgColor' : ''}`}>
                                <div className={`text-lg font-bold tracking-widest`}>Customer Service Rep</div>
                                <div className={`text-xs ml-3 text-gray-500 mb-3`}>Update your CSR account below to fit your SalesReps</div>
    
                                <form method="POST"
                                      className={`space-y-2 p-3 mb-2 border-2 rounded-lg ${darkMode ? 'border-darkBgColor' : ''}`}>
    
    
                                    <div className="w-full flex flex-row">
    
                                        <div className="w-full">
                                            
                                            <div className="mt-2">
                                                
                                                <div className={`relative`}>
                                                    <label
                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Sales Rep
                                                    </label>
                                                    <Select
                                                        type="text"
                                                        onChange={handleSelectChange}
                                                        className={`block w-1/2 rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-3 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    >
                                                        <option value="">Select a Sales Rep</option>
                                                        {users
                                                          .filter(user => user.accountLevel === "S" || user.accountLevel === "A")
                                                          .map(user => (
                                                            <option key={user.id} value={user.id}>
                                                              {user.name}
                                                            </option>
                                                          ))}
                                                    </Select>
                                                </div>
                                                
                                                
                                                {/* Display selected reps */}
                                                <div className="mt-2 h-56">
                                                    <h3 className="font-medium mb-2 text-sm">Selected Reps:</h3>
                                                    
                                                    
                                                    {selectedReps.length === 0 ? (
                                                        // Display custom dialog if sprays array is empty
                                                        <div
                                                            className="flex w-full h-full justify-center items-center py-10">
                                                            
                                                            <div
                                                                className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-4 py-1 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                                <div
                                                                    className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                                    <EyeSlashIcon className={`w-10 stroke-2 mr-3`}/>
                                                                    <p className="text-md mt-2 line-clamp-1 tracking-widest flex">You
                                                                        Have No Reps Selected</p>
                                                                    <p className="text-sm mt-2 line-clamp-1 tracking-widest flex text-gray-500">You
                                                                        may select your reps from the dropdown above</p>
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    ) : (
                                                        <div className="columns-3 gap-3">
                                                            {selectedReps.map((rep, index) => (
                                                                <div key={index}
                                                                     className={`flex hover:scale-105 w-full items-center py-1.5 border ${darkMode ? 'border-neutral-700' : ''} bg-opacity-50 mb-2 rounded-lg px-2`}>
                                                                    
                                                                    <span className="mr-2 text-sm">{rep.name}</span>
                                                                    
                                                                    <Button
                                                                        onClick={() => handleRemoveRep(rep.id)}
                                                                        className="text-red-500 hover:text-red-700 ml-auto"
                                                                    >
                                                                        <XMarkIcon
                                                                            className="h-5 text hover:text-red-500 hover:animate-pulse hover:scale-110 m-auto"/>
                                                                    </Button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    
                                                    )}
                                                    
                                                    {/*{selectedReps.map((rep, index) => (*/}
                                                    {/*    <div key={index}*/}
                                                    {/*         className={`flex hover:scale-105 w-full animate-zoomIn items-center py-1.5 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor shadow'} bg-opacity-50 mb-2 rounded-lg px-2`}>*/}
                                                    
                                                    {/*        <span*/}
                                                    {/*            className="mr-2 text-gray-500 text-sm">{rep.name}</span>*/}
                                                    
                                                    {/*        <Button*/}
                                                    {/*            onClick={() => handleRemoveRep(rep.id)}*/}
                                                    {/*            className="text-red-500 hover:text-red-700 ml-auto"*/}
                                                    {/*        >*/}
                                                    {/*            <XMarkIcon className="h-5 text hover:text-red-500 hover:animate-pulse hover:scale-110 m-auto"/>*/}
                                                    {/*        </Button>*/}
                                                    
                                                    {/*    </div>*/}
                                                    {/*))}*/}
                                                
                                                
                                                </div>
                                            
                                            
                                            </div>
                                        </div>
                                    
                                    </div>
                                    
                                    
                                    <div className={`w-full items-center flex justify-end`}>
                                        <Button
                                            onClick={handleUpdateReps}
                                            className="flex w-1/4 ml-auto hover:scale-105 justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Update Reps List
                                        </Button>
                                    </div>
                                </form>
                            
                            </div>
                        
                        </>
                    )}
                    
                    {/*Tech Support*/}
                    <div className={`w-full border-b-4 border-dotted p-3 ${darkMode ? 'border-darkBgColor' : ''}`}>
                        <div className={`w-full flex flex-row `}>
                            
                            <div className={`flex flex-row w-3/4 items-center`}>
                                
                                {/*Header*/}
                                <div className={``}>
                                    <div className={`flex flex-row items-center`}>
                                        <div className={`text-lg font-bold tracking-widest mr-5`}>Technical Support
                                            [{open ? closedTickets.length : tickets.length}]
                                        </div>
                                        <Button
                                            className="rounded-full hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle"
                                            onClick={onUpdateTickets}
                                        >
                                            <ArrowPathIcon className="h-6 text-borderColor hover:stroke-2 m-auto"/>
                                        </Button>
                                    </div>
                                    <div className={`text-xs ml-3 text-gray-500 mb-3`}>If you have any issues with the
                                        account or suggestions please submit a ticket!
                                    </div>
                                </div>
    
                                {/*ToggleSwitch*/}
                                <div className="w-fit ml-10">
                                    
                                    <Switch
                                      checked={open}
                                      onChange={openVis}
                                      className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-green-600 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-pendingColor"
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span className={`pointer-events-none text-black relative inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                        
                                            {/*Check*/}
                                            <span
                                                aria-hidden="true"
                                                className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                            >
                                              <svg fill="currentColor" viewBox="0 0 12 12"
                                                   className="size-3">
                                                <path
                                                    d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"/>
                                              </svg>
                                            </span>
                                          
                                            
                                            {/*X Mark*/}
                                            <span
                                                aria-hidden="true"
                                                className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                            >
                                              <svg fill="none" viewBox="0 0 12 12" className="size-3">
                                                <path
                                                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                              </svg>
                                            </span>
                                            
                                            
                                        </span>
                                    </Switch>
                                    
                                </div>
    
                            </div>
    
                            <div className={`flex flex-col w-1/4 mr-3.5 items-center`}>
                                <Button
                                    onClick={toggleTicket}
                                    className="flex w-fit items-center line-clamp-1 ml-auto hover:scale-105 justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <PlusIcon className={`w-6 mr-3 stroke-2`}/>
                                    Add Ticket
                                </Button>
                            </div>
    
                        </div>
    
    
                        {/*Ticket Map*/}
                        <div className={`space-y-5 p-3 mb-2 h-96 overflow-y-auto scrollbar-hide border-2 rounded-lg ${darkMode ? 'border-darkBgColor' : ''}`}>
    
                            {open ? (
                                <div>
                                    {closedTickets.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center py-10">
                                            
                                            <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-4 py-1 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                    <TicketIcon className={`w-10 stroke-2 mr-3`}/>
                                                    <p className="text-md mt-2 line-clamp-1 tracking-widest flex">You Have No Closed Tickets</p>
                                                    <p className="text-sm mt-2 line-clamp-1 tracking-widest flex text-gray-500">You may closed a ticket yourself or wait for a developer to close it for you</p>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        closedTickets.map((ticket, index) => {
                                            return (
                                                <TicketCard
                                                    key={index}
                                                    index={index}
                                                    ticket={ticket}
                                                />
                                            )
                                        })
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {tickets.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center py-10">
                                            
                                            <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-4 py-1 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                    <TicketIcon className={`w-10 stroke-2 mr-3`}/>
                                                    <p className="text-md mt-2 line-clamp-1 tracking-widest flex">You Have No Open Tickets</p>
                                                    <p className="text-sm mt-2 line-clamp-1 tracking-widest flex text-gray-500">You may add a support ticket by pressing the above button!</p>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        tickets.map((ticket, index) => {
                                            return (
                                                <TicketCard
                                                    key={index}
                                                    index={index}
                                                    ticket={ticket}
                                                />
                                            )
                                        })
                                    )}
                                </div>
                            )}
                        
                        
                        </div>
                    
                    
                    </div>
                    
                    {["D", "A",].includes(accountLevel) && (
                        <>
                            
                            {/*Banner Settings*/}
                            <div className={`w-full border-b-4 border-dotted p-3 ${darkMode ? 'border-darkBgColor' : ''}`}>
                                <div className={`text-lg font-bold tracking-widest`}>Home Page Banner</div>
                                <div className={`text-xs ml-3 text-gray-500 mb-3`}>Below you can update the banner that appears on the home screen for every user.</div>
    
                                <form
                                    onSubmit={onSubmitBanner}
                                    method="POST"
                                    className={`space-y-4 grid grid-cols-2 p-3 mb-2 border-2 rounded-lg ${darkMode ? 'border-darkBgColor' : ''}`}>
    
                                     {/*Title*/}
                                    <div className={`relative col-span-1`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Banner Title
                                        </label>
                                        <Input
                                            type="text"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            placeholder={`Type Banner Title`}
                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        />
                                    </div>
                                    
                                    {/*Body*/}
                                    <div className={`relative col-span-2`}>
                                        <label
                                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                        >
                                            Banner Body
                                        </label>
                                        <Textarea
                                            value={body}
                                            onChange={(e) => setBody(e.target.value)}
                                            placeholder={`Type out the body of the banner here`}
                                            className={`block w-full h-36 max-h-64 rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        />
                                    </div>
                                    
                                    {/*Button*/}
                                    <div className={`col-span-full`}>
                                        <Button
                                            type="submit"
                                            // disabled={isRegistering}
                                            className="flex w-1/4 ml-auto hover:scale-105 justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Update Banner
                                        </Button>
                                    </div>
                                </form>
    
                            </div>
    
                        </>
                    )}
                    
                    {["D"].includes(accountLevel) && (
                        <>
    
                            {/*User Account Levels*/}
                            <div className={`w-full border-b-4 border-dotted p-3 ${darkMode ? 'border-darkBgColor' : ''}`}>
                                <div className={`text-lg font-bold tracking-widest`}>User Account Levels [{users.length || 0}]</div>
                                <div className={`text-xs ml-3 text-gray-500 mb-3`}>Update user account levels to give or restrict their access to certain features</div>
    
                                <div className={`space-y-2 p-3 mb-2 border-2 rounded-lg ${darkMode ? 'border-darkBgColor' : ''}`}>
    
                                    {/* Display selected reps */}
                                    <div className="h-96 overflow-y-scroll scrollbar-hide">
                                        
                                        {users.length === 0 ? (
                                            // Display custom dialog if sprays array is empty
                                            <div className="flex w-full h-full justify-center items-center py-10">
    
                                                <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-4 py-1 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                    <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                        <EyeSlashIcon className={`w-10 stroke-2 mr-3`}/>
                                                        <p className="text-md mt-2 line-clamp-1 tracking-widest flex">You Have No Reps Selected</p>
                                                        <p className="text-sm mt-2 line-clamp-1 tracking-widest flex text-gray-500">You may select your reps from the dropdown above</p>
                                                    </div>
                                                </div>
    
                                            </div>
                                        ) : (
                                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                                                {users.map((person, index) => (
                                                    <AccountCard person={person}/>
                                                ))}
                                            </div>
                                        
                                        )}
                                    
                                    </div>
                                
                                
                                </div>
                            
                            </div>
                        
                        </>
                    )}
                
                </div>
            
            </div>
            
            
            
            
            <Dialog open={ticket} onClose={toggleTicket} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <TicketIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle as="h3" className="text-base font-semibold">
                                Submit A Technical Support Ticket
                            </DialogTitle>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    This will send a ticket in with your issue and it will be fixed as soon as possible.
                                    You may be contacted to discus the issue!
                                </p>
                            </div>
                            
                            <form className="grid grid-cols-2 gap-y-4 gap-x-3 mt-6"
                                  onSubmit={onSubmitMain}
                                  method="POST">
                                
                                
                                {/*Category*/}
                                <div className={`relative col-span-1`}>
                                    <label
                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Category
                                    </label>
                                    <Select
                                        type="text"
                                        required
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    >
                                        <option value="">Select Category...</option>
                                        <option value="PERFORMANCE">Performance Issue</option>
                                        <option value="UI_BUG">User Interface Bug</option>
                                        <option value="FEATURE_REQUEST">Feature Request</option>
                                        <option value="NEW_PAGE">New Page Suggestion</option>
                                        <option value="BUG">Other Bug</option>
                                        <option value="OTHER">Other</option>
                                    </Select>
                                </div>
                                
                                {/*Page*/}
                                <div className={`relative col-span-1`}>
                                    <label
                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Affect Page
                                    </label>
                                    <Select
                                        type="text"
                                        required
                                        value={page}
                                        onChange={(e) => setPage(e.target.value)}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    >
                                        <option value="">Select Page...</option>
                                        <option value="Home">Home</option>
                                        <option value="Dashboard">Dashboard</option>
                                        <option value="Catalog">Catalog</option>
                                        <option value="Orders">Current Orders</option>
                                        <option value="Archive">Archived Orders</option>
                                        <option value="Customers">Customer Management</option>
                                        <option value="SalesTeam">Sales Team</option>
                                        <option value="Guides">Help & Resources</option>
                                        <option value="ProgramBuilder">Program Builder</option>
                                        <option value="Messaging">Messaging</option>
                                        <option value="PlantOrders">Plant Orders</option>
                                        <option value="PlantCustomers">Plant Customers</option>
                                        <option value="Profile">Profile / Settings</option>
                                        <option value="SignUp">Sign Up</option>
                                        <option value="Login">Login</option>
                                    </Select>
                                </div>
                                
                                {/*Notes*/}
                                <div className={`relative col-span-2`}>
                                    <label
                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Sales Industry
                                    </label>
                                    <textarea
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                        placeholder={`Explain your issue or suggestion here`}
                                        className={`block w-full h-36 max-h-64 rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    />
                                </div>
                                
                                {/*Buttons*/}
                                <div className="col-span-full mt-4 sm:flex sm:flex-row-reverse">
                                    <Button
                                        type="submit"
                                        // onClick={onSubmitPending}
                                        // disabled={!selectedCustomer}
                                        className={` inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                                    >
                                        Submit Ticket
                                    </Button>
                                    <Button
                                        type="button"
                                        data-autofocus
                                        onClick={toggleTicket}
                                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            
                            
                            </form>
                        
                        
                        </div>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
        
            <Dialog open={isPassword} onClose={setIsPassword} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <LockOpenIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Password Reset
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            For security purposes, we can send a password reset link to the email
                              associated with your account. If you don’t see the email right away,
                              please check your spam or junk folder.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={handlePasswordReset}
                        className={`inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                      >
                        Send Reset Link
                      </Button>
                      <Button
                        type="button"
                        data-autofocus
                        onClick={togglePassword}
                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                      >
                        Cancel
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>

            <Dialog open={isEmail} onClose={toggleEmail} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <EnvelopeIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle as="h3" className="text-base font-semibold">
                                Email Reset
                            </DialogTitle>
                            <div className="mt-2 w-full">
                                <p className="text-sm text-gray-500 w-full">
                                    This will reset the email associated with your account. This email should be an @plantfoodco.com email.
                                </p>
                            </div>
                            
                            <form className="grid grid-cols-2 gap-y-4 gap-x-3 mt-6"
                                  onSubmit={handleEmailUpdate}
                                  method="POST">
                                
                                {/*Notes*/}
                                <div className={`relative col-span-2`}>
                                    <label
                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        New Email
                                    </label>
                                    <Input
                                        value={newEmail}
                                        required
                                        onChange={(e) => setNewEmail(e.target.value)}
                                        placeholder={`Type New Email`}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    />
                                </div>
                                
                                {/*Buttons*/}
                                <div className="col-span-full mt-4 sm:flex sm:flex-row-reverse">
                                    <Button
                                        type="submit"
                                        // onClick={handleEmailUpdate}
                                        // disabled={!selectedCustomer}
                                        className={` inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                                    >
                                        Reset Email
                                    </Button>
                                    <Button
                                        type="button"
                                        data-autofocus
                                        onClick={toggleEmail}
                                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            
                            
                            </form>
                        
                        
                        </div>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
        
        </div>
    )
};


export default Profile;