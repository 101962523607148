import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {Button, Field, Input, Label, Select, Switch, Textarea} from "@headlessui/react";
import {
    ArrowDownCircleIcon, ArrowPathIcon,
    ArrowUpCircleIcon,
    PlusCircleIcon, SquaresPlusIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../../firebase/firebase";
import Loading from "../Loading";
import {categoryColors} from "../GlobalFunctions";

const PricingCard = ({ product }) => {

    const { darkMode } = useDarkMode();
    
    const [name, setName] = useState(product.name || '');
    const [ph, setPh] = useState(parseFloat(product.ph).toFixed(2) || '');
    const [weight, setWeight] = useState(product.weight || '');
    const [category, setCategory] = useState(product.category || '');
    const [sds, setSDS] = useState(product.sds || '');
    const [lock, setLock] = useState(product.lock || false);

    const [isVisible, setIsVisible] = useState(false); // State to track visibility

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving] = useState(false); // Track whether the item is being removed

    // const numberWithCommas = (number) => {
    //     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }

    const [prices, setPrices] = useState(product.prices);
    const [isModified, setIsModified] = useState(false);

    // Function to handle changes in inputs
    const handleInputChange = (index, field, value) => {
        const updatedPrices = prices.map((price, i) =>
            i === index ? { ...price, [field]: value } : price
        );
        setPrices(updatedPrices);
        setIsModified(true);
    };
    

    // Function to handle changes in name
    const handleNameChange = (e) => {
        setName(e.target.value);
        setIsModified(true); // Mark as modified
    };

    // Function to handle changes in ph
    const handlePHChange = (e) => {
        setPh(e.target.value);
        setIsModified(true); // Mark as modified
    };
    
     // Function to handle changes in sds
    const handleSDSChange = (e) => {
        setSDS(e.target.value);
        setIsModified(true); // Mark as modified
    };

    // Function to handle changes in weight
    const handleWeightChange = (e) => {
        setWeight(e.target.value);
        setIsModified(true); // Mark as modified
    };

    // Function to handle changes in weight
    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        setIsModified(true); // Mark as modified
    };
    const [updating, setUpdating] = useState(false);

    // Function to save updated data to Firebase
    const handleSave = async () => {
        setUpdating(true);
        const startTime = Date.now();
        
        try {
            const productDocRef = doc(db, "Products", product.id); // Replace `product.id` with the actual document ID
            await updateDoc(productDocRef, {
                prices,
                name: name,
                ph: parseFloat(ph),
                weight: parseFloat(weight),
                category: category,
                sds: sds,
                lock: lock
            });
            console.log("Product updated successfully!");

            // Update the productUpdated field in all documents in the Users collection
            const usersCollectionRef = collection(db, "Users");
            const usersSnapshot = await getDocs(usersCollectionRef);

            const updatePromises = usersSnapshot.docs.map((userDoc) => {
                const userDocRef = doc(db, "Users", userDoc.id);
                return updateDoc(userDocRef, { productUpdated: true });
            });

            await Promise.all(updatePromises); // Wait for all updates to complete
            console.log("Updated productUpdated field for all users!");

            alert(`${name} Updated successfully!`);

            setIsModified(false);
        } catch (error) {
            console.error("Error updating product or users:", error);
        }
        
        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        alert(`Product Updated in ${timeElapsed} secs`);
    };


    // Add a new price entry to the prices array
    const handleAddPrice = () => {
        const newPrice = {
            um: '',
            code: '',
            ne: '',
            se: '',
            ag: '',
            dist1: '',
            dist2: ''
        };
        setPrices([...prices, newPrice]);
        setIsModified(true);
    };

    // Remove a price entry from the prices array
    const handleRemovePrice = (index) => {
        const updatedPrices = prices.filter((_, i) => i !== index);
        setPrices(updatedPrices);
        setIsModified(true);
    };

    const bgColor = categoryColors.find(c => c.category === category)?.color || '';

    const [showId, setShowId] = useState(false);
    const [clickCount, setClickCount] = useState(0);

    const handleTripleClick = () => {
        setClickCount(prevCount => prevCount + 1);
        setTimeout(() => setClickCount(0), 500); // Reset click count after 500ms

        if (clickCount === 2) { // On the third click
            setShowId(true); // Set showId to true
            setTimeout(() => setShowId(false), 15000); // Reset showId to false after 30 seconds
        }
    };
    
    useEffect(() => {
        if (lock){
            setIsModified(true);
        } else {
            setIsModified(false);
        }
    }, [lock])


    return (
        <div className={`w-full flex flex-col rounded-lg hover:bg-opacity-90 border ${product.PLAIF ? 'border-2 border-blue-500' : darkMode ? 'border-neutral-700' : 'border'} ${darkMode ? 'bg-darkMainColor' : 'bg-white'} p-3 transition-transform duration-500 ease-in-out ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {updating && (
                <Loading/>
            )}
            
            {/*HEADER*/}
            <div className="w-full flex flex-row mb-3">

                <div className="relative w-full grid grid-cols-2">

                    {/*Main*/}
                    <div className="col-span-1 w-full mr-auto flex flex-row items-center h-full mt-auto mb-auto">

                        {/*Category*/}
                        <div className={`w-1/3 p-1 h-8 items-center flex pl-3 ${bgColor} rounded-lg hover:scale-105`} onClick={handleTripleClick}>
                            <Select
                                id="category"
                                className={`rounded-lg text-center block w-full bg-transparent text-white line-clamp-1`}
                                value={category}
                                onChange={handleCategoryChange}
                                // onChange={(event) => {
                                //     const selectedValue = event.target.value;
                                //     setCategory(selectedValue); // Update priceCode state
                                // }}
                            >
                                <option value="">Select Category...</option>
                                <option value="Biostimulants">Biostimulants</option>
                                <option value="Liquid Fertilizer">Liquid Fertilizers</option>
                                <option value="Dry Fertilizer">Dry Fertilizers</option>
                                <option value="Secondary Nutrients">Secondary Nutrients</option>
                                <option value="Micronutrients">Micronutrients</option>
                                <option value="Penetrants & Water Holding Agents">Penetrants & Water Holding Agents</option>
                                <option value="A-Plus® Solubilizing Surfactants">A-Plus® Solubilizing Surfactants</option>
                                <option value="Turfgrass Pigment">Turfgrass Pigment</option>
                                <option value="Water Treatments">Water Treatments</option>
                                <option value="Soil Amendments">Soil Amendments</option>
                                <option value="Fungicides">Fungicides</option>
                                <option value="Equipment">Equipment</option>
                                <option value="Test">Test</option>
                                <option value="Seed">Seed</option>
                            </Select>
                            {/*<div className="flex items-center text-white font-bold justify-center h-full text-sm line-clamp-1">{product.category || "--"}</div>*/}
                        </div>

                        {showId && (
                            <div className="text-xxs ml-3">
                                {product.id}
                            </div>
                        )}

                        {/*Name*/}
                        <div className="justify-center w-2/3 flex flex-col h-full ml-6">
                            <Input
                                className={`rounded-md text-lg tracking-wider line-clamp-1 bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                type="string"
                                placeholder="Product Code"
                                value={name}
                                onChange={handleNameChange}
                            />
                        </div>

                    </div>

                    {/*PH*/}
                    <div className="col-span-1 w-full flex items-center">
                        
                        <div className={`grid grid-cols-3 w-full gap-x-3`}>
                            
                            {/*SDS*/}
                            <div className={`relative col-span-1`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    SDS
                                </label>
                                <Input
                                    type="string"
                                    placeholder="SDS"
                                    value={sds}
                                    onChange={handleSDSChange}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
                            
                            {/*PH*/}
                            <div className={`relative col-span-1`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    PH
                                </label>
                                <Input
                                    type="string"
                                    placeholder="pH"
                                    value={ph}
                                    onChange={handlePHChange}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
                            
                            {/*Weight*/}
                            <div className={`relative col-span-1`}>
                                <label
                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                >
                                    Weight
                                </label>
                                <Input
                                    type="string"
                                    placeholder="lbs per gal"
                                    value={weight}
                                    onChange={handleWeightChange}
                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                />
                            </div>
                            
                        </div>

                    </div>

                    {/*Update Button*/}
                    <div className="absolute top-0 right-0">
                    
                        {isModified && (
                            <div className="ml-6 animate-rubberBand flex items-center">
                                <Button
                                    className="bg-blue-500 flex items-center tracking-widest hover:bg-opacity-50 hover:scale-105 text-white text-sm font-bold py-1 px-4 rounded-lg"
                                    onClick={handleSave}
                                >
                                    <ArrowPathIcon className={`size-4 stroke-2 mr-2`}/>
                                    Update
                                </Button>
                            </div>
                        )}
                    
                    </div>

                    {/*Buttons DropDown*/}
                    {/*<div className="flex flex-col items-center">*/}
                    {/*    <Button*/}
                    {/*        className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"*/}
                    {/*        onClick={toggleVisibility}*/}
                    {/*    >*/}
                    
                    {/*        {isVisible ? (*/}
                    {/*            <ArrowUpCircleIcon*/}
                    {/*                className="h-6 text-gray-500 hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>*/}
                    {/*        ) : (*/}
                    {/*            <ArrowDownCircleIcon*/}
                    {/*                className="h-6 text-gray-500 hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>*/}
                    {/*        )}*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>

            </div>

            {isVisible && (
                <div className={`mb-3`}>
                    
                    <div className={`text-sm rounded-lg p-1 tracking-widest`}>More Fields Coming Soon!</div>
                
                </div>
            )}
            
            {/*Prices Label*/}
            <div className={`flex relative tracking-widest flex-row items-center px-2 py-2`}>
                
                <Button
                    className={`hover:text-green-600 mr-3 hover:scale-105 stroke-2 hover:animate-pulse`}
                    onClick={handleAddPrice}>
                    <PlusCircleIcon className="w-6"/>
                </Button>
                
                <div className={`text-sm`}>Prices [{prices.length}]</div>
                
                
                <Field className="flex items-center ml-6">
                    <Switch
                        checked={lock}
                        onChange={setLock}
                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                    >
                        <span className="sr-only">Use setting</span>
                        <span aria-hidden="true"
                              className="pointer-events-none absolute size-full rounded-md"/>
                        <span
                            aria-hidden="true"
                            className={`pointer-events-none absolute mx-auto h-3 w-5 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                        />
                        <span
                            aria-hidden="true"
                            className="pointer-events-none absolute left-0 inline-block size-4 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                    </Switch>
                  <Label as="span" className={`ml-3 text-sm ${darkMode ? 'text-white' : 'text-black'}`}>
                      <span className="font-medium text-sm">{lock ? "Locked" : ""}</span>
                  </Label>
                </Field>
                
            </div>
            
            {/*Prices*/}
            <div className="w-full flex flex-col">

                {prices.length === 0 ? (
                    // Display custom dialog if sprays array is empty
                    <div className="flex w-full h-full justify-center items-center py-5">
                        
                        <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-24 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                            <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                <SquaresPlusIcon className={`w-6 stroke-1 mr-3`}/>
                                <p className="text-md line-clamp-1 tracking-widest flex">No Pricing Available</p>
                            </div>
                        </div>
                    
                    </div>
                
                ) : (
                    // Display SprayCard components if sprays array is not empty
                    prices.map((price, index) => {
                        return (
                            
                            
                            <div key={index} className={`pt-0.5 animate-zoomIn rounded-lg`}>
                                
                                
                                <div className="flex flex-row w-full items-center p-2">
                                    
                                    {/*Fields Grid*/}
                                    <div className={`grid grid-cols-7 gap-x-2`}>
                                        
                                        {/*U/M*/}
                                        <div className={`relative col-span-1`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                U/M
                                            </label>
                                            <Select
                                                type="text"
                                                name="um"
                                                value={price.um}
                                                onChange={(e) => handleInputChange(index, 'um', e.target.value)}
                                                className={`block w-full rounded-md ${darkMode ? `bg-darkMainColor text-white outline-neutral-700 outline-2` : `bg-white text-gray-900 outline-gray-300`} px-2 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            >
                                                <option value="">Select U/M...</option>
                                                <option value="ACR">Acre</option>
                                                <option value="B12">BAG 12#</option>
                                                <option value="B25">BAG 25#</option>
                                                <option value="B30">BAG 30#</option>
                                                <option value="B40">BAG 40#</option>
                                                <option value="B5">BAG 5#</option>
                                                <option value="B50">BAG 50#</option>
                                                <option value="B51">BAG 51#</option>
                                                <option value="BAG">BAG (Unspecified Weight)</option>
                                                <option value="2.5">CASE (2x2.5)</option>
                                                <option value="CS4">CASE (4X1)</option>
                                                <option value="CAS">CASE</option>
                                                <option value="30">DRUM 30-GAL</option>
                                                <option value="55">DRUM 55-GAL</option>
                                                <option value="DRU">DRUM (Unspecified Volume)</option>
                                                <option value="EAC">EACH</option>
                                                <option value="GAL">GALLON</option>
                                                <option value="TR">TOTE REFILL</option>
                                                <option value="LB">POUND</option>
                                                <option value="OZ">Ounce - Av</option>
                                                <option value="PAI">PAIL 5-GAL</option>
                                                <option value="SAC">Super Sack (B1000)</option>
                                                <option value="SAC2">Super Sack (B2000)</option>
                                                <option value="TON">TON (2,000#)</option>
                                                <option value="275">TOTE 275-GAL</option>
                                            </Select>
                                        </div>
                                        
                                        {/*Product Code*/}
                                        <div className={`relative col-span-1`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                Code
                                            </label>
                                            <Input
                                                name="code"
                                                type="string"
                                                placeholder="Code..."
                                                value={price.code}
                                                onChange={(e) => handleInputChange(index, 'code', e.target.value)}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            />
                                        </div>
                                        
                                        {/*NE Price*/}
                                        <div className={`relative col-span-1`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                NE
                                            </label>
                                            <div className={`flex w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                <div className="text-borderColor text-xs mr-3">$</div>
                                                <Input
                                                    type="number"
                                                    name="ne"
                                                    value={price.ne}
                                                    onChange={(e) => handleInputChange(index, 'ne', e.target.value)}
                                                    placeholder={`NE`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-gray-900'} text-base placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                />
                                                <div className="text-borderColor w-fit text-xs ml-1">USD</div>
                                            </div>
                                        </div>
                                        
                                        {/*SE Price*/}
                                        <div className={`relative col-span-1`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                SE
                                            </label>
                                            <div className={`flex w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                <div className="text-borderColor text-xs mr-3">$</div>
                                                <Input
                                                    type="number"
                                                    name="se"
                                                    value={price.se}
                                                    onChange={(e) => handleInputChange(index, 'se', e.target.value)}
                                                    placeholder={`SE`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-gray-900'} text-base placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                />
                                                <div className="text-borderColor w-fit text-xs ml-1">USD</div>
                                            </div>
                                        </div>
                                        
                                        {/*AG Price*/}
                                        <div className={`relative col-span-1`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                AG
                                            </label>
                                            <div className={`flex w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                <div className="text-borderColor text-xs mr-3">$</div>
                                                <Input
                                                    type="number"
                                                    name="ag"
                                                    value={price.ag}
                                                    onChange={(e) => handleInputChange(index, 'ag', e.target.value)}
                                                    placeholder={`AG`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-gray-900'} text-base placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                />
                                                <div className="text-borderColor w-fit text-xs ml-1">USD</div>
                                            </div>
                                        </div>
                                        
                                        {/*DIST1 Price*/}
                                        <div className={`relative col-span-1`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                DIST 1
                                            </label>
                                            <div className={`flex w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                <div className="text-borderColor text-xs mr-3">$</div>
                                                <Input
                                                    type="number"
                                                    name="dist1"
                                                    value={price.dist1}
                                                    onChange={(e) => handleInputChange(index, 'dist1', e.target.value)}
                                                    placeholder={`DIST 1`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-gray-900'} text-base placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                />
                                                <div className="text-borderColor w-fit text-xs ml-1">USD</div>
                                            </div>
                                        </div>
                                        
                                        {/*DIST2 Price*/}
                                        <div className={`relative col-span-1`}>
                                            <label
                                                className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                                DIST 2
                                            </label>
                                            <div className={`flex w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                <div className="text-borderColor text-xs mr-3">$</div>
                                                <Input
                                                    type="number"
                                                    name="dist2"
                                                    value={price.dist2}
                                                    onChange={(e) => handleInputChange(index, 'dist2', e.target.value)}
                                                    placeholder={`DIST 2`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-gray-900'} text-base placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                />
                                                <div className="text-borderColor w-fit text-xs ml-1">USD</div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    <Button
                                        className="hover:scale-105 hover:animate-pulse text-red-500 w-fit h-fit mt-auto mb-auto rounded-full ml-1"
                                        onClick={() => handleRemovePrice(index)}
                                    >
                                        <XCircleIcon className="w-5"/>
                                    </Button>

                                </div>
                                
                                
                            </div>
                    
                            
                        )
                    })

                )}

            </div>

        </div>
    )
}

export default PricingCard;

