import {collection, doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../firebase/firebase";

const GEOCODE_API_KEY = 'AIzaSyDxrbk0CKmZUpJ-5eQGaxHH-I5lYeWrPfc'; // Store key in .env

// 🔹 Helper to generate a unique ID for each address
const generateAddressHash = (customer) => {
    return `${customer.address1 || ""}, ${customer.city || ""}, ${customer.state || ""} ${customer.zip || ""}`;
};

// 🔥 Fetch geocode from Firestore or Google Maps API
export const getGeocode = async (customer) => {
    if (!customer || !customer.address1 || !customer.city || !customer.state || !customer.zip) {
        console.warn("Skipping customer with missing address:", customer);
        return null;
    }

    const addressString = generateAddressHash(customer);
    const salesCode = customer.salesCode || "UNKNOWN"; // Default if salesCode is missing
    const subcollectionRef = doc(collection(db, "geocodes", salesCode, "customers"), addressString);

    // ✅ Check Firestore cache
    const docSnap = await getDoc(subcollectionRef);
    if (docSnap.exists()) {
        // console.log("Fetched geocode data:", docSnap.data());
        return { address: addressString, location: { lat: docSnap.data().lat, lng: docSnap.data().lng }, ...docSnap.data() };
    }

    // 🌎 Fetch geocode from Google Maps API
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(addressString)}&key=${GEOCODE_API_KEY}`;
    const response = await fetch(geocodeUrl);
    const data = await response.json();

    if (data.status === "OK") {
        const { lat, lng } = data.results[0].geometry.location;

        // ✅ Save valid geocode to Firestore under the appropriate salesCode subcollection
        const geocodeData = {
            name: customer.name || "Unknown",
            customerCode: customer.customerCode ?? "N/A",
            repCode: salesCode,
            address: addressString,
            lat,
            lng
        };

        await setDoc(subcollectionRef, geocodeData);

        // console.log("Saved new geocode in subcollection:", salesCode, addressString);
        return { ...geocodeData, location: { lat, lng } };
    } else {
        // console.error("Geocode failed for:", addressString, data.status);
        return null;
    }
};

// 🔥 Bulk function to get multiple geocoded locations (skips failed ones)
export const getAllGeocodedLocations = async (customers) => {
    const geocodePromises = customers.map((customer) => getGeocode(customer));
    return (await Promise.all(geocodePromises)).filter(Boolean);
};
