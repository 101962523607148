import React, { useEffect, useState } from 'react';
import SideBar from "./SideBar";
import { useDarkMode } from "../contexts/darkModeContext";
import { db } from "../firebase/firebase";
import { useAuth } from "../contexts/authContext";
import MapComponent from "./CustomerMap/MapComponent";
import {Button, Dialog, DialogPanel, DialogTitle, Input, Popover, PopoverButton, PopoverPanel} from "@headlessui/react";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/16/solid";
import {getAllGeocodedLocations} from "./GeocodeUtils";
import {ChevronDoubleRightIcon} from "@heroicons/react/24/outline";
import {salesCodeMapping} from "./GlobalFunctions";

const CustomerMap = () => {
    const { darkMode } = useDarkMode();
    const { currentUser } = useAuth();
    
    const [open, setOpen] = useState(false);

    const [customers, setCustomers] = useState([]);
    // eslint-disable-next-line
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    // eslint-disable-next-line
    const [customerAddresses, setCustomerAddresses] = useState([]);
    const [geocodedAddresses, setGeocodedAddresses] = useState([]); // New state to hold geocoded data

    const countStates = (customers) => {
        const stateCounts = {};
    
        customers.forEach(customer => {
            if (!customer.address || typeof customer.address !== "string") return; // Ensure address exists and is a string
        
                const match = customer.address.match(/,\s*([A-Z]{2})\s+\d{5}/);
                if (match) {
                    const state = match[1];
                    stateCounts[state] = (stateCounts[state] || 0) + 1;
                }
            });
    
        return Object.entries(stateCounts).map(([state, length]) => ({ state, length }));
    };
    
    const countSalesCodes = (customers) => {
        const salesCodeCounts = {};
    
        customers.forEach(customer => {
            if (!customer.repCode || typeof customer.repCode !== "string") return; // Ensure salesCode exists and is a string
            
            const repCode = customer.repCode.trim(); // Trim any whitespace
            salesCodeCounts[repCode] = (salesCodeCounts[repCode] || 0) + 1;
        });
    
        return Object.entries(salesCodeCounts).map(([salesCode, length]) => ({ salesCode, length }));
    };
    
    
    const [stateData, setStateData] = useState([]);
    const [salesCodeData, setSalesCodeData] = useState([]);
    
    const [accountLevel, setAccountLevel] = useState("");
    const [isAccountD, setIsAccountD] = useState(false);

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);
                        setIsAccountD(userData.accountLevel === "D");
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);
                            setIsAccountD(userData.accountLevel === "D");
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
        fetchUserName();
    }, [currentUser]);

    useEffect(() => {
        const storedAddresses = localStorage.getItem(`customerAddresses_${currentUser?.uid}`);
        if (storedAddresses) {
            try {
                setCustomerAddresses(JSON.parse(storedAddresses));
            } catch (error) {
                console.error("Error parsing stored addresses:", error);
                localStorage.removeItem(`customerAddresses_${currentUser?.uid}`);
            }
        }
    }, [currentUser]);

    // Fetch customers from Firestore or local storage
    useEffect(() => {
        const fetchCustomersForAll = async () => {
            try {
                const usersSnapshot = await db.collection("Users")
                    .where("accountLevel", "in", ["S", "A"])
                    .get();

                if (usersSnapshot.empty) {
                    console.warn("No users with account level S or A found.");
                    setCustomers([]);
                    setFilteredCustomers([]);
                    return;
                }

                let allCustomers = [];

                for (const userDoc of usersSnapshot.docs) {
                    const userId = userDoc.id;
                    const customerSnapshot = await db
                        .collection("Customers")
                        .doc(userId)
                        .collection("clients")
                        .get();

                    if (!customerSnapshot.empty) {
                        const userCustomers = customerSnapshot.docs.map(doc => ({
                            id: doc.id,
                            salesID: userId,
                            ...doc.data()
                        }));

                        allCustomers = [...allCustomers, ...userCustomers];
                    }
                }

                setCustomers(allCustomers);
                setFilteredCustomers(allCustomers);
                localStorage.setItem("allActiveCustomers", JSON.stringify(allCustomers));

            } catch (error) {
                console.error("Error fetching all active customers:", error);
            }
        };

        const fetchCustomersActive = async () => {
            if (!currentUser) {
                console.warn("No user is currently logged in. Cannot fetch active customers.");
                return;
            }

            try {
                const storedActiveData = localStorage.getItem(`activeCustomers_${currentUser.uid}`);

                if (storedActiveData) {
                    try {
                        const customersData = JSON.parse(storedActiveData);
                        setCustomers(customersData);
                        setFilteredCustomers(customersData);
                    } catch (jsonError) {
                        console.error("Error parsing stored active customers data:", jsonError);
                        localStorage.removeItem(`activeCustomers_${currentUser.uid}`);
                    }
                } else {
                    const activeCustomersRef = db
                        .collection("Customers")
                        .doc(currentUser.uid)
                        .collection("clients");

                    const snapshot = await activeCustomersRef.get();

                    if (!snapshot.empty) {
                        const activeData = snapshot.docs.map(doc => ({
                            id: doc.id,
                            salesID: currentUser.uid,
                            ...doc.data()
                        }));

                        setCustomers(activeData);
                        setFilteredCustomers(activeData);
                        localStorage.setItem(`activeCustomers_${currentUser.uid}`, JSON.stringify(activeData));
                    } else {
                        console.info("No active customers found for this user.");
                        setCustomers([]);
                        setFilteredCustomers([]);
                    }
                }
            } catch (error) {
                console.error("Error fetching active customers data from Firestore:", error);
            }
        };

        if (accountLevel === "D") {
            fetchCustomersForAll();
        } else {
            fetchCustomersActive();
        }
    }, [currentUser, accountLevel]);

    useEffect(() => {
        if (customers.length > 0) {
            const mappedAddresses = customers
                .filter(customer => customer.address1 && customer.city && customer.state && customer.zip) // Ensure address is valid
                .map(customer => ({
                    name: customer.name,
                    customerCode: customer.customerCode,
                    repCode: customer.salesCode,
                    address: `${customer.address1 || ""}, ${customer.city || ""}, ${customer.state || ""} ${customer.zip || ""}`,
                }));

            setCustomerAddresses(mappedAddresses);
            // setStateData(countSalesCodes(mappedAddresses))
            localStorage.setItem(`customerAddresses_${currentUser?.uid}`, JSON.stringify(mappedAddresses));
        }
    }, [customers, currentUser]);

    // Fetch geocoded locations for customers
    useEffect(() => {
        const fetchGeocodedData = async () => {
            const geocodes = await getAllGeocodedLocations(customers);
            setGeocodedAddresses(geocodes);
            setStateData(countStates(geocodes))
            setSalesCodeData(countSalesCodes(geocodes))
            // console.log(geocodes);
        };
    
        if (customers.length > 0) {
            fetchGeocodedData();
        }
    }, [customers]);

    const [selectedSalesCodes, setSelectedSalesCodes] = useState([]);

    useEffect(() => {
        const uniqueSalesCodes = [...new Set(customers.map(customer => customer.salesCode))];
        setSelectedSalesCodes(uniqueSalesCodes);
    }, [customers]);

    useEffect(() => {
        const filtered = customers.filter(customer => selectedSalesCodes.includes(customer.salesCode));
        setFilteredCustomers(filtered);
    }, [selectedSalesCodes, customers]);

    const handleCheckboxChange = (salesCode) => {
        setSelectedSalesCodes(prevSelected =>
            prevSelected.includes(salesCode)
                ? prevSelected.filter(code => code !== salesCode)
                : [...prevSelected, salesCode]
        );
    };
    
    const [stateCollapse, setStateCollapse] = useState(false);
    const [salesCodeCollapse, setSalesCodeCollapse] = useState(false);
    
    const toggleState = () => {
        setStateCollapse(!stateCollapse);
    };
    
    const toggleSalesCode = () => {
        setSalesCodeCollapse(!salesCodeCollapse);
    };
    

    const address = '38 Hightstown-Cranbury Station Rd, Cranbury, NJ 08512'; // Example address
    
    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-[calc(100dvh-4rem)] w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>

            <div className="z-50 animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] mt-3 rounded-lg mr-3">

                {/*Developer Header*/}
                <div className={`h-12 border flex items-center ${isAccountD ? '' : 'hidden'} ${darkMode ? 'bg-darkMainColor text-white border-neutral-700' : 'bg-white text-black'} mb-3 rounded-lg`}>
                
                    <div className={`grid grid-cols-3 w-full`}>
                        
                        {/*Left*/}
                        <div className={`col-span-1`}>
                            <div className={`ml-3 tracking-widest text-lg flex items-center`}>Customer Map <span className={`text-xxs ml-3 text-gray-500`}>Developer Options</span></div>
                        </div>
                        
                        {/*Middle*/}
                        <div className={`col-span-1 flex items-center justify-center`}>
                            <Popover className="relative hidden">
                                <PopoverButton className="inline-flex items-center gap-x-1 text-sm/6 font-semibold">
                                    <span>Sales Reps Filter</span>
                                    <ChevronDownIcon aria-hidden="true" className="size-5" />
                                </PopoverButton>
                        
                                <PopoverPanel
                                    transition
                                    className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    <div className={`w-56 shrink rounded-xl border ${darkMode ? 'bg-darkMainColor text-white border-neutral-700' : 'bg-white text-black'} p-4 text-sm/6 tracking-widest font-semibold text-gray-900 shadow-lg ring-1 ring-gray-900/5`}>
                                        
                                        {Array.from(new Set(customers.map(customer => customer.salesCode))).map((salesCode) => (
                                            <label key={salesCode} className="flex items-center space-x-2">
                                                <Input
                                                    type="checkbox"
                                                    checked={selectedSalesCodes.includes(salesCode)}
                                                    onChange={() => handleCheckboxChange(salesCode)}
                                                    className="form-checkbox h-4 w-4 text-green-600"
                                                />
                                                <span className="">{salesCode}</span>
                                            </label>
                                        ))}
                                        
                                    </div>
                                </PopoverPanel>
                            </Popover>
                        </div>
                        
                        {/*Right*/}
                        <div className={`col-span-1 flex items-center justify-end`}>
                            <div className={`mr-3 text-sm tracking-widest`}>{customers.length} Customers</div>
                            
                            <Button
                                onClick={() => setOpen(true)}
                                className={`ml-3`}
                            >
                                <ChevronDoubleRightIcon className={`w-6 mr-3 text-gray-500 hover:text-green-600 hover:scale-110`} />
                            </Button>
                            
                        </div>
                        
                    </div>
                
                </div>
                
                {/*Map*/}
                <div className={`flex border ${darkMode ? 'border-neutral-700' : ''} ${isAccountD ? 'h-[calc(100%-5.25rem)]' : 'h-[calc(100%-1.5rem)]'} rounded-lg overflow-y-scroll flex-col`}>
                    <MapComponent
                        key={address}
                        address={address}
                        disabledUI={false}
                        geocodedAddresses={geocodedAddresses.length ? geocodedAddresses : []}
                    />
                </div>

            </div>
            
            
            
            
            
            
            <Dialog open={open} onClose={setOpen} className={`relative z-50 ${darkMode ? 'text-white' : 'text-black'}`}>
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full h-full pl-10 pb-[5.5rem]">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className={`flex flex-col h-full border-2 ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} py-6 shadow-2xl mt-[4.75rem] mr-3 rounded-lg`}>
                                    <div className={`px-4 sm:px-6`}>
                                        <div className="flex items-start justify-between">
                                            <DialogTitle className="text-base font-semibold tracking-widest">Customer Map Analytics</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <Button
                                                    type="button"
                                                    onClick={() => setOpen(false)}
                                                    className="relative rounded-md text-gray-500 hover:text-opacity-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <ChevronDoubleRightIcon aria-hidden="true" className="size-6" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>


                                    {/*Body*/}
                                    <div className="relative mt-6 flex flex-col overflow-y-scroll scrollbar-hide flex-1 px-4 sm:px-6 space-y-6">
                                        
                                        <div className="flex w-full flex-col">
                                            <div className={`flex items-center mb-2`}>
                                                <h2 className="text-lg font-semibold mr-auto tracking-widest">State Distribution [{stateData.length}]</h2>
                                                <Button onClick={toggleState}>
                                                  {stateCollapse ? (
                                                    <ChevronUpIcon className="size-6 text-gray-500" />
                                                  ) : (
                                                    <ChevronDownIcon className="size-6 text-gray-500" />
                                                  )}
                                                </Button>
                                            </div>
                                            <ul className={`space-y-1 divide-y-2 divide-dashed ${darkMode ? 'divide-neutral-700' : ''} ${stateCollapse ? '' : 'h-96 overflow-y-scroll scrollbar-hide'}`}>
                                                    {stateData
                                                      .sort((a, b) => b.length - a.length) // Sort in descending order
                                                      .map(({ state, length }) => (
                                                        <li key={state} className="flex justify-between p-1">
                                                          <span className="font-medium">{state}</span>
                                                          <span>{length} <span className="text-gray-500 text-xs">Customers</span></span>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                        
                                        <div className="flex w-full flex-col">
                                            
                                            
                                            <div className={`flex items-center mb-2`}>
                                                <h2 className="text-lg font-semibold mr-auto tracking-widest">Sales Rep Distribution [{customerAddresses.length}]</h2>
                                                <Button onClick={toggleSalesCode}>
                                                  {salesCodeCollapse ? (
                                                    <ChevronUpIcon className="size-6 text-gray-500" />
                                                  ) : (
                                                    <ChevronDownIcon className="size-6 text-gray-500" />
                                                  )}
                                                </Button>
                                            </div>
                                            
                                            
                                            <ul className={`space-y-1 divide-y-2 divide-dashed ${darkMode ? 'divide-neutral-700' : ''} ${salesCodeCollapse ? '' : 'h-56 overflow-y-scroll scrollbar-hide'}`}>
                                                    {salesCodeData
                                                    .sort((a, b) => b.length - a.length) // Sort in descending order
                                                    .map(({salesCode, length}) => (
                                                        <li key={salesCode} className="flex items-center p-1">
                                                            <span className="text-xs font-medium mr-2">[{salesCode}]</span>
                                                            <span className="mr-auto font-semibold">{salesCodeMapping[salesCode]}</span>
                                                            <span>{length} <span className="text-gray-500 text-xs">Customers</span></span>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    
                                    </div>
                                
                                
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>
        
        
        </div>
    );
};

export default CustomerMap;