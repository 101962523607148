import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";
import {Button, Dialog, DialogPanel, DialogTitle, Input, Select} from "@headlessui/react";
import {ChevronDoubleRightIcon, MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import programsData from "../programs.json";
import ProvenCard from "./Proven/ProvenCard";
import {AgCharts} from "ag-charts-react";
import {useAuth} from "../contexts/authContext";


const Proven = () => {

    const { darkMode } = useDarkMode();
    
    const { currentUser } = useAuth();
    
    const [open, setOpen] = useState(false);

    const [programs] = useState(programsData.programs);
    const [filteredPrograms, setFilteredPrograms] = useState([]);
    const [chartData, setChartData] = useState([]);
    
    useEffect(() => {
        const fetchPrograms = async () => {
            if (currentUser) {
                const programData = programsData.programs;
                
                // Set programs to state
                setFilteredPrograms(programData);
    
                // Group the programs by category
                const groupedData = programData.reduce((acc, prog) => {
                    const { category } = prog;  // Group by 'category'
                    
                    if (!acc[category]) {
                        acc[category] = [];  // Initialize if the category doesn't exist
                    }
                    
                    acc[category].push(prog);  // Add program to the respective category
                    return acc;
                }, {});
                
                 // Map categories to their respective colors
                const categoryColors = {
                    "Greens": "green-600",
                    "Stress": "blue-800",
                    "Aerification": "gray-700",
                    "LDS": "red-800",
                    "Microbial": "purple-800",
                    "GreenUp": "yellow-700",
                    "Grow-In": "green-700",
                    "Bunkers": "teal-900",
                    "Warm Turf": "teal-700",
                    "Fairways": "emerald-700",
                    "Tees": "indigo-800",
                    "Flowers": "pink-700",
                    "Trees": "amber-800",
                };

    
                // Format the data for the chart
                const chartData = Object.keys(groupedData).map((category) => {
                    return {
                        name: category,           // Category name
                        value: groupedData[category].length, // Count of programs in the category
                        color: categoryColors[category] || "gray-500", // Default color if not found
                    };
                });
                
                setChartData(chartData);
                
            }
        };
    
        fetchPrograms();
    }, [currentUser]);
    
    const [searchQuery, setSearchQuery] = useState("");

    const filterProgramsByCatagory = (category) => {
        const filtered = programs.filter(product => category === '' || product.category === category);
        setFilteredPrograms(filtered);
    }

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = programs.filter((product) =>
            product.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredPrograms(filtered);
    }
    
    const categories = [
        { name: "Greens", color: "green-600", key: "Greens" },
        { name: "Stress Reduction", color: "blue-800", key: "Stress Reduction" },
        { name: "Aerification", color: "gray-700", key: "Aerification" },
        { name: "LDS Reduction", color: "red-800", key: "LDS Reduction" },
        { name: "Microbial", color: "purple-800", key: "Microbial" },
        { name: "Spring GreenUp", color: "yellow-700", key: "Spring Greenup" },
        { name: "Grow-In", color: "green-700", key: "Grow-In" },
        { name: "Bunkers", color: "teal-900", key: "Bunkers" },
        { name: "Warm Turf", color: "teal-700", key: "Warm Season Turf" },
        { name: "Fairways", color: "emerald-700", key: "Fairways" },
        { name: "Tees", color: "indigo-800", key: "Tees" },
        { name: "Flowers", color: "pink-700", key: "Flowers" },
        { name: "Trees", color: "amber-800", key: "Trees" }
    ];
    
    
    
    
    
    const [chartBgColor, setChartBgColor] = useState("#efefef");
    const [textColor, setTextColor] = useState("#21b372");
    const [labelColor, setLabelColor] = useState("#ffffff");

    // Function to change colors based on darkMode state
    useEffect(() => {
        if (darkMode) {
            setChartBgColor("#1e1e1e");   // Dark background color for dark mode
            setTextColor("#ffffff"); // Light text color for dark mode
            setLabelColor("#ffffff");
        } else {
            setChartBgColor("#ffffff");   // Light background color for light mode
            setTextColor("#000000"); // Dark text color for light mode
            setLabelColor("#000000");
        }
    }, [darkMode]); // Effect will run every time darkMode changes

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const myTheme = {
        overrides: {
            common: {
                title: {
                    fontSize: 18,
                    color: textColor,
                },
                subtitle: {
                    fontSize: 10,
                },
                axes: {
                    category: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for category axis
                        },
                    },
                    number: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for number axis
                        },
                    },
                },
            },

            donut: {
                series: {
                    innerLabels:{
                        color: labelColor,
                    },
                    cornerRadius: 5,
                    calloutLabel: {
                        color: labelColor,
                    },
                },
                legend: {
                    item:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            shape: "circle",
                        }
                    },
                    pagination:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            color: labelColor,
                        }
                    },
                },
            },


        },
    };
    
    const [options, setOptions] = useState({
        container: document.getElementById("myChart"),
        data: [],
        title: {
            text: "Proven Program Makeup",
        },
        subtitle: {
            text: "Number of Programs",
        },
        series: [
            {
                type: "donut",
                calloutLabelKey: "value",
                angleKey: "value",
                sectorLabelKey: "name",
                innerRadius: 4,
                calloutLabel: {
                    enabled: false,
                },
                sectorLabel: {
                    formatter: ({ datum, sectorLabelKey }) => {
                        return datum[sectorLabelKey];
                    },
                },
                innerRadiusRatio: 0.75,
                innerLabels: [
                    {
                        text: "Total",
                        fontSize: 16,
                        spacing: 10,
                    },
                    {
                        text: `${programs.length}`,
                        fontSize: 18,
                    },
                ],
                tooltip: {
                    renderer: ({ datum, calloutLabelKey, title, sectorLabelKey }) => {
                        return {
                            title,
                            content: `${datum[calloutLabelKey]}`,
                        };
                    },
                },
                sectorSpacing: 3,
            },
        ],
    });
    
    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            theme: myTheme,
            data: chartData,
            background: {
                fill: chartBgColor, // Update background color dynamically
            },
        }));
         // eslint-disable-next-line
    }, [chartData, darkMode, chartBgColor]);
    

    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-[calc(100dvh-4rem)] w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>
            
            {/*Proven Programs*/}
            <div className="z-50 animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] mt-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3">

                    <div className={`text-2xl w-52 ${darkMode ? 'text-white' : 'text-black'} mt-auto mb-auto flex flex-row line-clamp-1 overflow-x-scroll scrollbar-hide`}>Programs <span className={`text-sm ml-3 mt-auto mb-auto font-semibold`}>[{programs.length}]</span></div>

                    {/*Categories*/}
                    <div className={`text-xl border ${darkMode ? 'bg-darkMainColor border-neutral-700 text-white' : 'bg-white border text-black'} w-full flex rounded-md border drop-shadow shadow-gray-900 py-1`}>

                        <Button
                            onClick={() => setOpen(true)}
                            className={`ml-auto`}
                        >
                            <ChevronDoubleRightIcon className={`w-6 mr-3 text-gray-500 hover:text-green-600 hover:scale-110`} />
                        </Button>
                        
                    </div>

                </div>

                {/*Search bar row*/}
                <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>

                    {/*Search Bar*/}
                    <div className="w-3/4 mr-1.5 h-fit text-sm">
                        {/*<div className="text-sm mb-1">Product</div>*/}

                        <div className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>

                            <div className="w-full">

                                <Input
                                    className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                    placeholder="Search programs By Name"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />

                            </div>

                        </div>

                    </div>

                    {/*Category*/}
                    <div className="w-1/4 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            <Select
                                className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                // value={status}
                                onChange={(e) => filterProgramsByCatagory(e.target.value)}
                            >
                                <option value="">Select Category...</option>
                                <option value="Greens">Greens</option>
                                <option value="Stress Reduction">Stress Reduction</option>
                                <option value="Aerification">Aerification</option>
                                <option value="LDS Reduction">LDS Reduction</option>
                                <option value="Microbial">Microbial</option>
                                <option value="Spring Greenup">Spring Greenup</option>
                                <option value="Grow-In">Grow-In</option>
                                <option value="Bunkers">Bunkers</option>
                                <option value="Warm Season Turf">Warm Season Turf</option>
                                <option value="Fairways">Fairways</option>
                                <option value="Tees">Tees</option>
                                <option value="Flowers">Flowers</option>
                                <option value="Trees">Trees</option>
                            </Select>
                        </div>

                    </div>


                </div>

                {/*Proven Body*/}
                <div className="w-full h-full rounded-md">

                    {/*PRODUCT INPUT*/}
                    <div className="w-full flex h-full flex-col">


                        <div className={`w-full h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg`}>

                            {/*CUSTOMERS MAP*/}
                            <div className={`overflow-y-auto scrollbar-hide h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg max-h-[calc(100dvh-11.75rem)] scroll`}>

                                <div>

                                    {filteredPrograms.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center py-10">

                                            <div className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                    <p className="text-xl">No Programs Present</p>
                                                    <p className="text-lg mt-2">Why may this occur?</p>
                                                    <p className="text-xs">1] There appears to be an issue loading programs</p>
                                                    <p className="text-xs">2] There does not seem to be any proven programs that match your search. </p>
                                                    <p className="text-xs">3] There are no proven programs that match your filtering. </p>

                                                </div>
                                            </div>

                                        </div>
                                    ) : (
                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-3 auto-rows-auto">
                                            {filteredPrograms
                                                .sort((a, b) => a.id - b.id) // Ensure items are sorted by ID
                                                .map((program, index) => (
                                                    <ProvenCard
                                                        key={index}
                                                        program={program}/>
                                                ))}
                                        </div>

                                    )}

                                </div>

                            </div>


                        </div>


                    </div>

                </div>


            </div>

            
            <Dialog open={open} onClose={setOpen} className={`relative z-50 ${darkMode ? 'text-white' : 'text-black'}`}>
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full h-full pl-10 pb-[5.5rem]">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className={`flex flex-col h-full border-2 ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} py-6 shadow-2xl mt-[4.75rem] mr-3 rounded-lg`}>
                                    <div className={`px-4 sm:px-6`}>
                                        <div className="flex items-start justify-between">
                                            <DialogTitle className="text-base font-semibold">Proven Program Analytics</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <Button
                                                    type="button"
                                                    onClick={() => setOpen(false)}
                                                    className="relative rounded-md text-gray-500 hover:text-opacity-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <ChevronDoubleRightIcon aria-hidden="true" className="size-6" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>


                                    {/*Body*/}
                                    <div className="relative mt-6 overflow-y-scroll flex-1 px-4 sm:px-6 flex-col space-y-12">
                                        
                                        <div className="flex w-full flex-col">
                                            
                                            <div className={`transition-transform duration-500 ease-in-out`}>
                                                <AgCharts options={options}/>
                                            </div>
                                            
                                            {categories.map(({name, color, key}) => (
                                                <div key={name} className={`flex w-full items-center justify-center h-full border-b-2 border-dashed py-3 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                    
                                                    <div className={`w-1/2 text-center tracking-wider text-sm rounded-md border-${color} border-2 bg-${color} bg-opacity-20 px-2 line-clamp-1`}>
                                                        {name}
                                                    </div>
                                                    
                                                    <div className="w-1/2 text-end text-sm line-clamp-1">
                                                        {programs.filter(program => program.category === key).length}
                                                    </div>
                                                    
                                                </div>
                                            ))}
                                        </div>
                                    
                                    </div>
                                
                                
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>
        
        
        </div>
    )
};


export default Proven;
