import React, {useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {
    ArrowUpTrayIcon,
    EnvelopeIcon, PhoneIcon,
} from "@heroicons/react/24/outline";
import {Button, Select} from "@headlessui/react";
import logo from "../../main-logo.png";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../firebase/firebase";

const AccountCard = ({ person }) => {

    const { darkMode } = useDarkMode();
    
    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });
    
    const [isModified, setIsModify] = useState(false);
    
    const [accountLevel, setAccountLevel] = useState(person.accountLevel || "");
    
    // eslint-disable-next-line no-unused-vars
    const [updating, setUpdating] = useState(false);
    
    const handleSave = async () => {
        setUpdating(true);
        const startTime = Date.now();
        
        try {
            const personDocRef = doc(db, "Users", person.id); // Replace `product.id` with the actual document ID
            await updateDoc(personDocRef, {
                accountLevel: accountLevel, // Add the description field
            });
            
            console.log("person updated successfully!");
            
            if (alertsOn) {
                alert(`${person.name} Level updated successfully!`);
            }

            setIsModify(false);
        } catch (error) {
            console.error("Error updating product or users:", error);
        }
        
        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        
        if (alertsOn) {
            alert(`Person Updated in ${timeElapsed} secs`);
        }
    };

    
    
    return (
        
        <div className={`col-span-1 rounded-lg ${darkMode ? 'bg-darkBgColor/50 text-white' : 'bg-white text-black border'} drop-shadow`}>
            
            
            {isModified && (
                 <Button
                    className={`bg-blue-500/50 border border-blue-500 animate-bounceIn absolute rounded-full w-6 h-6 flex items-center justify-center top-0 right-0 m-1`}
                    onClick={handleSave}
                 >
                    <ArrowUpTrayIcon className={`w-4`}/>
                </Button>
            )}
            
            
            <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-medium">{person.name}</h3>
                        <span className="inline-flex shrink-0 items-center rounded-full bg-green-600/30 border border-green-600 px-1.5 py-0.5 text-xs font-medium">
                          {accountLevel}
                        </span>
                    </div>
                    
                    {/*Account Level*/}
                    <div className={`relative mt-3 w-3/4`}>
                        <label
                            className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkBgColor/50' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                        >
                            Account Level
                        </label>
                        <Select
                            type="text"
                            value={accountLevel}
                            onChange={(e) => {
                                setAccountLevel(e.target.value); // Update the account level
                                setIsModify(true); // Set the boolean to true
                            }}
                            className={`block w-full rounded-md ${darkMode ? 'bg-darkBgColor/50 text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                        >
                            <option value="">Select...</option>
                            <option value="D">Developer</option>
                            <option value="A">Admin</option>
                            <option value="CSR">Customer Service Rep</option>
                            <option value="PFC">PFC Fulfillment</option>
                            <option value="DIST">Distributor</option>
                            <option value="M">Marketing</option>
                            <option value="S">Sales Rep</option>
                        </Select>
                    </div>
                    
                </div>
                <img alt="" src={logo} className="w-10 p-1 object-cover"/>
            </div>
            <div>
                <div className={`-mt-px flex divide-x border-t ${darkMode ? 'border-neutral-700 divide-neutral-700' : 'border-gray-200 divide-gray-200'}`}>
                    <div className="flex w-0 flex-1">
                        <a
                            href={`mailto:${person.email}`}
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold"
                        >
                            <EnvelopeIcon aria-hidden="true" className="text-green-600 size-5"/>
                            Email
                        </a>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                        <a
                            href={`tel:${person.telephone}`}
                            className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold"
                        >
                            <PhoneIcon aria-hidden="true" className="text-green-600 size-5"/>
                            Call
                        </a>
                    </div>
                </div>
            </div>
            
            
        </div>
    
    
    )
}

export default AccountCard;

