import React, { useState } from "react";
import OrderCard from "./OrderCard";
import {ArchiveBoxIcon, CheckCircleIcon, ClipboardDocumentListIcon} from "@heroicons/react/24/outline";
import { Button } from "@headlessui/react";

const OrderSection = ({ status, orders, emptyTitle, emptyDescription, onDelete, darkMode, accountView, onUpdate, products, handleViewOrder, archived }) => {
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(orders.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentOrders = orders.slice(startIndex, startIndex + itemsPerPage);

    return (
        <div className="w-full h-full relative animate-fadeIn">
            {orders.length === 0 ? (
                <div className="flex w-full h-full justify-center items-center py-10">
                    <div
                        className={`flex items-center border-2 border-dashed ${
                            darkMode ? "text-white border-neutral-700" : "text-black bg-neutral-50"
                        } w-fit px-24 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}
                    >
                        <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                            {status === 'A' ? (
                                <ArchiveBoxIcon className="w-10 stroke-2 mr-3 text-green-600" />
                            ) : (
                                <ClipboardDocumentListIcon className="w-10 stroke-2 mr-3 text-gray-600" />
                            )}
                            <p className="text-xl mt-2 line-clamp-1 tracking-widest flex">{emptyTitle}</p>
                            <p className="text-md mt-2 line-clamp-1 tracking-widest flex text-gray-500">{emptyDescription}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {currentOrders.map((order) => (
                        <OrderCard
                            key={order.id}
                            index={order.id}
                            order={order}
                            accountView={accountView}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                            handleViewOrder={handleViewOrder}
                            newProducts={products}
                            archived={archived}
                        />
                    ))}
                    <nav
                        aria-label="Pagination"
                        className={`flex items-center justify-between border-t border-gray-200 ${darkMode ? 'border-neutral-700' : ''} px-4 py-1 sm:px-6`}
                    >
                        <div className="flex items-center space-x-3">
                            <p className="text-sm">
                                Showing <span className="font-medium">{startIndex + 1}</span> to <span
                                className="font-medium">{Math.min(startIndex + itemsPerPage, orders.length)}</span> of{' '}
                                <span className="font-medium">{orders.length}</span> results
                            </p>
                            <select
                                value={itemsPerPage}
                                onChange={(e) => {
                                    setItemsPerPage(Number(e.target.value));
                                    setCurrentPage(1);
                                }}
                                className={`border rounded-lg p-1 ${darkMode ? "bg-darkMainColor border-neutral-700" : ""}`}
                            >
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <Button
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                                className={`px-3 py-1 h-fit rounded-md ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : "bg-green-600 text-white"}`}
                            >
                                Previous
                            </Button>
                            <Button
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                                className={`px-3 py-1 h-fit ml-3 rounded-md ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : "bg-green-600 text-white"}`}
                            >
                                Next
                            </Button>
                        </div>
                    </nav>
                </>
            )}
        </div>
    );
};

export default OrderSection;
