import React, {useEffect, useState} from 'react';
import SideBar from "./SideBar";
import {
    Button,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    Dialog, DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Field,
    Input,
    Label,
    Select,
    Switch,
    Textarea
} from "@headlessui/react";
import {
    ArrowLeftIcon,
    ArrowPathIcon, CheckBadgeIcon,
    CheckIcon, ChevronDoubleRightIcon, CreditCardIcon,
    ExclamationTriangleIcon,
    EyeSlashIcon,
    InformationCircleIcon, LockClosedIcon,
    MagnifyingGlassIcon, PaperAirplaneIcon, PencilIcon, PhoneIcon, PlusIcon,
    SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import ProductInput from "./OrderForm/ProductInput";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import {useDarkMode} from "../contexts/darkModeContext";

import createQuoteTemplate from "./OrderForm/QuoteTemplate";

import pdfMake from "pdfmake/build/pdfmake";
import {collection, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";
import {ChevronUpDownIcon} from "@heroicons/react/16/solid";
import Loading from "./Loading";
import FireworksEffect from "./FireworksEffect";
import {categoryColors, getCategoryInfo} from "./GlobalFunctions";
import pdfIcon from "../pdfIcon.png";

const NewOrder = ({ isEditing, order, close }) => {
    
    const getNextBusinessDate = (daysOut) => {
        let date = new Date();
        let count = 0;
    
        while (count < daysOut) {
            date.setDate(date.getDate() + 1);
            // Check if the day is not Saturday (6) or Sunday (0)
            if (date.getDay() !== 0 && date.getDay() !== 6) {
                count++;
            }
        }
        return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD for input value
    };
    
    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });
    
    const [showFireworks, setShowFireworks] = useState(false)
    
    // eslint-disable-next-line
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');
    const [deliveryDate, setDeliveryDate] = useState(order?.deliveryDate || getNextBusinessDate(5));

    const [callBefore, setCallBefore] = useState(order?.callBefore || false);
    const [earlyOrder, setEarlyOrder] = useState(order?.earlyOrder || false);
    const [prePay, setPrePay] = useState(order?.prePay || false);
    const [ASAP, setASAP] = useState(order?.asap || false);
    
    const [prePayAlert, setPrePayAlert] = useState(false);
    const [EODAlert, setEODAlert] = useState(false);

    const [notes, setNotes] = useState(order?.notes || "");

    const { darkMode } = useDarkMode();

    const { currentUser } = useAuth();
    const [fullname, setFullname] = useState(order?.salesRep || "");
    const [salesCode, setSalesCode] = useState(order?.salesCode || "");
    const [salesEmail, setSalesEmail] = useState(order?.salesEmail || "");
    // const [region, setRegion] = useState("");
    
    const [customerCode, setCustomerCode] = useState(order?.customerCode || "");
    const [customerType, setCustomerType] = useState( 0);
    const [customerPhone, setCustomerPhone] = useState( "");
    
    const [customerUID, setCustomerUID] = useState("");
    const [contractPrices, setContractPrices] = useState([]);
    const [address, setAddress] = useState("");
    const [shippingLocations, setShippingLocations] = useState([]);
    // const [selectedLocation, setSelectedLocation] = useState("");

    const [industry, setIndustry] = useState(order?.customerIndustry || "");
    const [customers, setCustomers] = useState([]);
    const [priceType, setPriceType] = useState(order?.priceType || "");
    const [discount, setDiscount] = useState( 0);

    const [totals, setTotals] = useState([]);
    const [discounTotals, setDiscountTotals] = useState([]);

    const [productInputs, setProductInputs] = useState(order?.products || []);
    
    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
        setOpen(!open);
    };
    
    const [expanded, setExpanded] = useState(true);
    const toggleExpanded = () => {
        setExpanded(!expanded);
    }



    // const [products] = useState(productsData.products);
    // const [filteredProducts, setFilteredProducts] = useState(productsData.products);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProductIds, setSelectedProductIds] = useState([]);




    const [finalProducts, setFinalProducts] = useState([]);

    const [quoteToolTip, setQuoteToolTip] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [showProducts, setShowProducts] = useState(false); // To control product list visibility

    // const [CustomerOrders, setCustomerOrders] = useState([]);
    const [browse, setBrowse] = useState(false);

    const toggleVisibility = () => {
        setBrowse(!browse);
    };

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPlanned, setIsPlanned] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isNoPhone, setIsNoPhone] = useState(false);

    const toggleSubmitting = () => {
        setIsSubmitting(!isSubmitting);
    };
    
    const togglePlanned = () => {
        setIsPlanned(!isPlanned);
    }
    
    const toggleUpdating = () => {
        setIsUpdating(!isUpdating);
    }




    useEffect(() => {
        const fetchProducts = async () => {
            if (currentUser) {
                try {
                    // Fetch the 'productUpdated' field for the current user
                    const userDocRef = doc(db, "Users", currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const { productUpdated } = userData;

                        if (productUpdated) {
                            // If 'productUpdated' is true, fetch products from Firestore
                            console.log("Fetching products from Firestore...");
                            const productsCollection = collection(db, "Products");
                            const snapshot = await getDocs(productsCollection);

                            if (!snapshot.empty) {
                                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                                setProducts(productData);
                                setFilteredProducts(productData);
                                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));

                                // Set 'productUpdated' to false
                                await updateDoc(userDocRef, { productUpdated: false });
                                console.log("Set productUpdated to false after fetching from Firestore.");
                            } else {
                                console.log("No products found in Firestore.");
                            }
                        } else {
                            // If 'productUpdated' is false, load products from localStorage
                            console.log("Loading products from localStorage...");
                            const storedProducts = localStorage.getItem(`products_${currentUser.uid}`);
                            if (storedProducts) {
                                const storedData = JSON.parse(storedProducts);
                                setProducts(storedData);
                                setFilteredProducts(storedData);
                            } else {
                                console.log("No products found in localStorage.");
                            }
                        }
                    } else {
                        console.log("User document does not exist.");
                    }
                } catch (error) {
                    console.error("Error fetching product data:", error);
                }
            }
        };

        fetchProducts();
    }, [currentUser]);
    


    // Filter products by search term
    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        // Filter products by search term
        if (value) {
            setFilteredProducts(
                products.filter((product) =>
                    product.name.toLowerCase().includes(value.toLowerCase())
                )
            );
            setShowProducts(true); // Show the product list while typing
        } else {
            setShowProducts(false); // Hide the product list if the search bar is empty
        }
    };


    // Add a product to the productInputs list and reset the search bar
    // const addProductInput = (product) => {
    //     // if (!selectedProductIds.includes(product.id)) {
    //     //     setProductInputs((prevInputs) => [...prevInputs, product]);
    //     //     setSelectedProductIds([...selectedProductIds, product.id]); // Add the product id to the selected list
    //     // } else {
    //     //     alert(`Product ${product.name} is already added! You may not add multiple of the same product`); // Optional: alert for user feedback
    //     // }
    //
    //     const newKey = productInputs.length + Math.random();
    //     // Add the product with the key field
    //     const productWithKey = { ...product, key: newKey };
    //
    //
    //     setProductInputs((prevInputs) => [...prevInputs, productWithKey]);
    //     setSelectedProductIds([...selectedProductIds, product.id]); // Add the product id to the selected list
    //     setSearchTerm(''); // Clear the search bar
    //     // setFilteredProducts(productsData.products);
    //     setShowProducts(false); // Hide the product list
    // };
    
    const addProductInput = (product = null) => {
        const newKey = productInputs.length + Math.random();
    
        // Create an empty product if none is provided
        const productWithKey = product
            ? { ...product, key: newKey }
            : { id: `temp-${newKey}`, name: "Blank Item", image: "logo", code: "BLANKITEMCODE", quantity: "", price: "", key: newKey, prices: [] }; // Define default empty fields
    
        setProductInputs((prevInputs) => [...prevInputs, productWithKey]);
    
        if (product) {
            setSelectedProductIds([...selectedProductIds, product.id]);
        }
    
        setSearchTerm('');
        setShowProducts(false);
    };

    const handleRemoveProduct = (index) => {
        const removedProductId = productInputs[index]?.id;
        
        setProductInputs((prev) => prev.filter((_, i) => i !== index));
        setSelectedProductIds((prev) => prev.filter((id) => id !== removedProductId));
        setFinalProducts((prev) => prev.filter((_, i) => i !== index));
        console.log(totals);
    };

    
    // Calculate grand total
    const [subTotal, setSubTotal] = useState(0);
    const [actualTotal, setActualTotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);

    
    useEffect(() => {
        
        console.log(finalProducts);
        
        const calculateTotalSum = (finalProducts) => {
            if (!Array.isArray(finalProducts) || finalProducts.length === 0) {
                return 0; // Return 0 if the array is empty or not valid
            }
            
            return finalProducts.reduce((sum, product) => {
                // Check if the product has a valid 'total' value and add it to the sum
                return sum + (product.total ? parseFloat(product.total) : 0);
            }, 0);
        };
        
        const calculateTotalSumSub = (finalProducts) => {
            if (!Array.isArray(finalProducts) || finalProducts.length === 0) {
                return 0; // Return 0 if the array is empty or not valid
            }
            
            return finalProducts.reduce((sum, product) => {
                // Check if the product has a valid 'total' value and add it to the sum
                return sum + (product.actualTotal ? parseFloat(product.actualTotal) : 0);
            }, 0);
        };
        
        const totalActual = calculateTotalSum(finalProducts);
        const totalSubTotal = calculateTotalSumSub(finalProducts);
        
        setActualTotal(totalActual)
        setSubTotal(totalSubTotal);
    }, [totals, discounTotals, finalProducts, customerType, earlyOrder]);
    

    useEffect(() =>{

        let disc = (subTotal - actualTotal).toFixed(2)

        setDiscount(disc);
        setGrandTotal(actualTotal)
        
        console.log(grandTotal);

            // eslint-disable-next-line
    },[priceType, subTotal, actualTotal, discount, customerCode, customerType, earlyOrder]);
    
    // Early Order
    useEffect(() => {
        const EODisc = 5;
        
        if (earlyOrder) {
            setEODAlert(true);
        }
    
        setCustomerType((prevCustomerType) => {
            // If earlyOrder is true, add 8, otherwise remove 8
            return earlyOrder ? prevCustomerType + EODisc : prevCustomerType - EODisc;
        });
    }, [earlyOrder]);
    
    // PrePay Order
    useEffect(() => {
        const preDisc = 3;
    
        if (prePay) {
            setPrePayAlert(true);
        }
        
        setCustomerType((prevCustomerType) => {
            // If earlyOrder is true, add 8, otherwise remove 8
            return prePay ? prevCustomerType + preDisc : prevCustomerType - preDisc;
        });
    }, [prePay]);
    
    
    
    useEffect(() => {
        if(isEditing){
            setCustomerType(order?.discount || 0);
        } else {
            setCustomerType( 0);
        }
        // eslint-disable-next-line
    }, []);

    // fetch time and date
    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();

        // Update time every second
        const interval = setInterval(() => {
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);
        }, 1000);

        return () => clearInterval(interval);
        
    }, []);

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setSalesCode(userData.salesCode);
                        setFullname(userData.fname + " " + userData.lname);
                        setSalesEmail(userData.email);
                        // setRegion(userData.region || "");

                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setSalesCode(userData.salesCode);
                            setSalesEmail(userData.email);
                            // setRegion(userData.region || "");

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchCustomers = async () => {
            if (currentUser) {
                try {
                    const storedActiveData = localStorage.getItem(`activeCustomers_${currentUser.uid}`);
                    if (storedActiveData) {
                        const customersData = JSON.parse(storedActiveData);
                        setCustomers(customersData);
                    } else {
                        const activeCustomers = db.collection("Customers").doc(currentUser.uid).collection("clients");
                        const snapshot = await activeCustomers.get();

                        if (!snapshot.empty) {
                            const activeData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setCustomers(activeData);
                            localStorage.setItem(`activeCustomers_${currentUser.uid}`, JSON.stringify(activeData));
                        } else {
                            console.log('Customer document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching customers data:', error);
                }
            }
        }
        
        // const fetchSharedCustomers = async () => {
        //     if (!currentUser) {
        //         console.warn("No user is currently logged in. Cannot fetch shared customer data.");
        //         return;
        //     }
        //
        //     try {
        //         // Check if customer data is cached
        //         const storedCustomerData = localStorage.getItem(`SharedCustomers_${currentUser.uid}`);
        //
        //         if (storedCustomerData) {
        //             try {
        //                 const parsedCustomers = JSON.parse(storedCustomerData);
        //
        //                 // Append to existing customers instead of replacing
        //                 setCustomers(prevCustomers => [...prevCustomers, ...parsedCustomers]);
        //
        //                 console.log("Successfully added shared customer data:", parsedCustomers);
        //                 return; // Stop here if cached data exists
        //             } catch (jsonError) {
        //                 console.error("Error parsing stored customer data:", jsonError);
        //                 localStorage.removeItem(`SharedCustomers_${currentUser.uid}`); // Clear invalid data
        //             }
        //         }
        //
        //         // Fetch sharedCustomers array from "Users/{currentUser.uid}"
        //         const userRef = doc(db, "Users", currentUser.uid);
        //         const userSnap = await getDoc(userRef);
        //
        //         if (!userSnap.exists()) {
        //             console.info("No shared customers found.");
        //             setCustomers(prevCustomers => [...prevCustomers, ...[]]);
        //             return;
        //         }
        //
        //         const userData = userSnap.data();
        //         const sharedCustomersArray = userData.sharedCustomers || [];
        //
        //         if (sharedCustomersArray.length === 0) {
        //             console.info("No shared customers assigned.");
        //             setCustomers(prevCustomers => [...prevCustomers, ...[]]);
        //             return;
        //         }
        //
        //         // Fetch actual customer data for each entry in sharedCustomersArray
        //         const customerPromises = sharedCustomersArray.map(async ({ sharedBy, customerID }) => {
        //             const customerRef = doc(db, "Customers", sharedBy, "clients", customerID);
        //             const customerSnap = await getDoc(customerRef);
        //
        //             if (customerSnap.exists()) {
        //                 return { id: customerSnap.id, ...customerSnap.data() };
        //             } else {
        //                 console.warn(`Customer ${customerID} not found under ${sharedBy}`);
        //                 return null;
        //             }
        //         });
        //
        //         const fetchedCustomers = (await Promise.all(customerPromises)).filter(cust => cust !== null);
        //
        //         // Store only the fetched customer data in state & cache
        //         setCustomers(prevCustomers => [...prevCustomers, ...fetchedCustomers]);
        //         localStorage.setItem(`SharedCustomers_${currentUser.uid}`, JSON.stringify(fetchedCustomers));
        //
        //     } catch (error) {
        //         console.error("Error fetching shared customers from Firestore:", error);
        //     }
        // };

        if (!isEditing) {
            fetchUserName();
        }
        fetchCustomers();
        // fetchSharedCustomers();
        
    }, [currentUser, isEditing]);

    const [updating, setUpdating] = useState(false);
    

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Products
        try {
            const productsCollection = db.collection("Products");
            const snapshot = await productsCollection.get();

            if (!snapshot.empty) {
                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProducts(productData);
                setFilteredProducts(productData);
                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));
            } else {
                console.log('Product documents not found');
            }

        } catch (error) {
            console.error("Error updating products", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        
        if (alertsOn) {
            alert(`Products Updated in ${timeElapsed} secs`);
        }
    };

    
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const handleSelectChange = (customer) => {
        if (customer) {
            // Set selected customer and related fields
            setSelectedCustomer(customer);
            setIndustry(customer.industry);
            setPriceType(customer.priceType);
            setCustomerCode(customer.customerCode);
            setCustomerPhone(customer.contactPhone);
            setCustomerType(customer.customerType || 0);
            setCustomerUID(customer.id);
            setShippingLocations(customer.ShippingAddresses || []);
            setContractPrices(customer.contractPrices || []);
            setAddress(customer.address1 + " " + customer.city + ", " + customer.state + " " + customer.zip);
        } else {
            // Reset fields if no customer is selected
            setSelectedCustomer(null);
            setIndustry("");
            setPriceType("");
            setCustomerCode("");
            setCustomerPhone("");
            setCustomerType(0);
            setCustomerUID("");
            setShippingLocations([]);
            setContractPrices([]);
            setAddress("");
        }
        setQuery('');
    };

    // Function to handle adding a product's data
    const handleAddFinalProduct = (index, productData) => {
        setFinalProducts(prevProducts => {
            const updatedProducts = [...prevProducts];
            updatedProducts[index] = productData; // Add or update the product data at the correct index
            return updatedProducts;
        });
    };



    const onSubmitPlanned = async () => {
        const collectionRef = db.collection("Customers").doc(currentUser.uid).collection("clients").doc(customerUID).collection("planned");
        const parsedFormData = {

            // Sales Info
            salesRep: fullname,
            salesCode: salesCode,
            status: "planned",
            salesEmail: salesEmail,
            salesUID: currentUser.uid,

            orderDate: "",
            customerCode: customerCode,
            customerIndustry: industry  || "",
            customerName: selectedCustomer.name,
            customerPhone: customerPhone || "",
            customerEmail: selectedCustomer.contactEmail || "",
            customerUID: customerUID || "",
            address: address,
            priceType: priceType,
            
            contactPhone: customerPhone || "",
            contact: selectedCustomer.contact || "",
            billingContact: selectedCustomer.billingContact || "",
            billingPhone: selectedCustomer.billingPhone || "",

            products: finalProducts,

            notes: notes,
            deliveryDate: deliveryDate,
            callBefore: callBefore,
            earlyOrder: earlyOrder,
            prePay: prePay,
            asap: ASAP,

            grandTotal: parseFloat(grandTotal),
            discountTotal: parseFloat(discount),
            discount: parseFloat(customerType),
            subTotal: parseFloat(subTotal),

        };

        try {
            await collectionRef.add(parsedFormData);
            
            localStorage.setItem('newOrder', 'true');
            if (alertsOn) {
                alert(`Order Saved as Planned`); // Optional: alert for user feedback
            }
            
            setIsPlanned(false);
            
            setShowFireworks(true)
            setTimeout(() => setShowFireworks(false), 5000) // Hide fireworks after 3 seconds
            
            await clear();

        } catch (error) {
            console.error('Error registering client:', error.message);
        }
    };

    const onSubmitPending = async () => {
        const collectionRef = db.collection("Customers").doc(currentUser.uid).collection("clients").doc(customerUID).collection("pending");
        const parsedFormData = {

            // Sales Info
            salesRep: fullname,
            salesCode: salesCode,
            status: "pending",
            salesEmail: salesEmail,
            salesUID: currentUser.uid,

            orderDate: date,
            customerCode: customerCode,
            customerIndustry: industry || "",
            customerName: selectedCustomer.name || "",
            customerPhone: customerPhone || "",
            customerEmail: selectedCustomer.contactEmail || "",
            customerUID: customerUID || "",
            address: address,
            priceType: priceType,
            
            contactPhone: customerPhone || "",
            contact: selectedCustomer.contact || "",
            billingContact: selectedCustomer.billingContact || "",
            billingPhone: selectedCustomer.billingPhone || "",

            products: finalProducts,

            submittedTime: Date.now(),

            notes: notes,
            deliveryDate: deliveryDate,
            callBefore: callBefore,
            earlyOrder: earlyOrder,
            prePay: prePay,
            asap: ASAP,

            grandTotal: parseFloat(grandTotal),
            discountTotal: parseFloat(discount),
            discount: parseFloat(customerType),
            subTotal: parseFloat(subTotal),

        };

        try {
            await collectionRef.add(parsedFormData);

            localStorage.setItem('newOrder', 'true');
            
            if (alertsOn) {
                alert(`Order Saved as Planned`);
            }
            setIsSubmitting(false);
            
            setShowFireworks(true);
            setTimeout(() => setShowFireworks(false), 5000) // Hide fireworks after 3 seconds
            
            await clear();

        } catch (error) {
            console.error('Error registering client:', error.message);
        }
    };

    
    
    const onSubmittingUpdating = async () => {
        if (!order?.id) {
            console.error("No order ID provided for editing.");
            return;
        }
    
        console.log("Sales ID:", order?.salesUID);
        console.log("Customer ID:", order?.customerUID);
        console.log("Order ID:", order?.id);
        console.log("Status:", order?.status);
    
        const orderRef = db.collection("Customers")
            .doc(order?.salesUID)
            .collection("clients")
            .doc(order?.customerUID)
            .collection(order?.status)
            .doc(order?.id);
    
        try {
            const orderSnapshot = await orderRef.get();
    
            if (!orderSnapshot.exists) {
                console.error("Order does not exist.");
                return;
            }
    
            const orderData = orderSnapshot.data();
            const updatedOrderData = {
                ...orderData,
                // status: "pending",
                salesRep: fullname || "",
                salesCode: salesCode || "",
                salesEmail: salesEmail || "",
                
                products: finalProducts || [],
                
                notes: notes || "",
                
                deliveryDate: deliveryDate || "",
                callBefore: callBefore || false,
                earlyOrder: earlyOrder || false,
                prePay: prePay || false,
                asap: ASAP || false,
                
                grandTotal: parseFloat(grandTotal) || 0,
                discountTotal: parseFloat(discount) || 0,
                discount: parseFloat(customerType) || 0,
                subTotal: parseFloat(subTotal) || 0,
            };
    
            await orderRef.set(updatedOrderData, { merge: true });
    
            localStorage.setItem("newOrder", "true");
    
            if (alertsOn) {
                alert("Order Updated Successfully");
            }
    
            console.log("Order Updated Successfully!");
            
            setIsUpdating(false);
            setShowFireworks(true);
            setTimeout(() => setShowFireworks(false), 5000); // Hide fireworks after 5 seconds
    
            close();
        } catch (error) {
            console.error("Error updating order:", error.message);
        }
    };
    
    
    
    const clear = async () => {
        setProductInputs([]);
        setTotals([]);
        setFinalProducts([]);
        setCustomerCode("");
        setIndustry("");
        setPriceType("");
        setDeliveryDate(getNextBusinessDate(5))
        setSelectedCustomer(null);
        setDiscount(0);
        setGrandTotal(0);
        setActualTotal(0);
        setSubTotal(0);
        setDiscountTotals([]);
        setCustomerType(0);
        setEarlyOrder(false);
        setCallBefore(false);
        setPrePay(false);
        setASAP(false);
        setNotes("");
        setAddress("");
        setCustomerPhone("");
    }

    const downloadQuotePDF = async () => {
        const data = {
            salesRep: fullname,
            salesCode: salesCode,
            status: "planned",
            salesEmail: salesEmail,
            salesUID: currentUser.uid,

            orderDate: "",
            customerCode: customerCode,
            customerIndustry: industry  || "",
            customerName: selectedCustomer.name,
            customerPhone: customerPhone || "",
            customerEmail: selectedCustomer.contactEmail || "",
            customerUID: customerUID || "",
            address: address,
            priceType: priceType,
            
            contactPhone: customerPhone || "",
            contact: selectedCustomer.contact || "",
            billingContact: selectedCustomer.billingContact || "",
            billingPhone: selectedCustomer.billingPhone || "",

            products: finalProducts,
            
            submittedTime: Date.now(),

            notes: notes,
            deliveryDate: deliveryDate,
            callBefore: callBefore,
            earlyOrder: earlyOrder,
            prePay: prePay,

            grandTotal: parseFloat(grandTotal),
            discountTotal: parseFloat(discount),
            discount: parseFloat(customerType),
            subTotal: parseFloat(subTotal),
        };

        // if (!data) return;

        const docDefinition = createQuoteTemplate(data);
        pdfMake.createPdf(docDefinition).download(`${customerCode}_quote_.pdf`);
    };


    const [query, setQuery] = useState('')

    const filteredCustomers =
        query === ''
            ? customers
            : customers.filter((customer) => {
                return (
                    customer.name.toLowerCase().includes(query.toLowerCase()) ||
                    (customer.customerCode?.toLowerCase()?.includes(query.toLowerCase()) ?? false)
                );
            });
    
    const [isToday, setIsToday] = useState(false);

    const handleDeliveryDateChange = (e) => {
        const selectedDate = e.target.value;
        setDeliveryDate(selectedDate);
    
        // Check if selected date matches today's date
        if (selectedDate === new Date().toISOString().split('T')[0]) {
            setIsToday(true);
        } else {
            setIsToday(false);
        }
    };
    
    
    useEffect(() =>{
        
        if (callBefore && selectedCustomer && !customerPhone) {
            setIsNoPhone(true);
        }
        
    },[callBefore, selectedCustomer]);
    
    
    
    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-[calc(100dvh-4rem)] w-full mt-16 transition-all duration-500 ease-in-out`}>

            {updating && (
                <Loading/>
            )}
            
            {showFireworks && <FireworksEffect show={showFireworks} />}
            
            <SideBar/>
            
            {/*ORDER FORM*/}
            <div className="z-20 flex flex-col h-full w-full max-w-[calc(100%-5.8rem)] pt-3 pb-3">
                
                {/*ORDER FORM HEADER [Sales Rep Info]*/}
                <div className="animate-slideInTop w-full flex mb-3">
                    <div className={`text-2xl w-1/4 line-clamp-1 ${darkMode ? 'text-white' : 'text-black'}`}>{isEditing ? "Edit Order Form" : "New Order Form"}</div>

                    <div className={`text-xl ${darkMode ? 'bg-darkMainColor border border-neutral-700 text-white' : 'bg-white border text-black'} w-3/4 flex rounded-md border drop-shadow shadow-gray-900 transition-all duration-500 ease-in-out`}>

                        {/*Buttons Delete*/}
                        {/*<div className="flex flex-col items-center ml-1.5 mt-auto mb-auto">*/}
                        {/*    <Button className="rounded-full hover:animate-spin mt-auto mb-auto h-6 w-6 items-center justify-center align-middle"*/}
                        {/*            onClick={onUpdate}*/}
                        {/*    >*/}
                        {/*        <ArrowPathIcon className="h-6 text-borderColor hover:text-bgDarkGreen m-auto"/>*/}
                        {/*    </Button>*/}
                        {/*</div>*/}


                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Sales Rep:</div>
                            <div className={`text-sm rounded-md border px-2 line-clamp-1 ${darkMode ? 'border-neutral-700' : ''}`}>{fullname}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Sales Rep Code:</div>
                            <div className={`text-sm rounded-md border px-2 line-clamp-1 ${darkMode ? 'border-neutral-700' : ''}`}>{salesCode}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Date:</div>
                            <div className={`text-sm rounded-md border px-2 line-clamp-1 ${darkMode ? 'border-neutral-700' : ''}`}>{date}</div>
                        </div>

                        {/*Buttons Delete*/}
                        <div className="flex flex-col items-center mr-1.5 mt-auto mb-auto">
                            <Button className="rounded-full hover:animate-pulse mt-auto mb-auto h-6 w-6 items-center justify-center align-middle"
                                    onClick={toggleOpen}
                            >
                                <InformationCircleIcon className="h-6 text-borderColor hover:text-bgDarkGreen m-auto"/>
                            </Button>
                        </div>

                    </div>
                </div>

                {/*ORDER FORM BODY [Order Info]*/}
                <div className={`animate-slideInRight flex flex-col border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white border'} w-full h-full rounded-md drop-shadow shadow-gray-900 p-1.5 overflow-y-scroll scrollbar-hide transition-all duration-500 ease-in-out`}>

                    {/*CUSTOMER DETAILS*/}
                    <div className={`animate-fadeIn w-full flex items-center h-fit flex-row ${darkMode ? 'text-white' : 'text-black'}`}>

                        {/*CUSTOMER*/}
                        <div className="flex flex-col w-1/2 relative p-1.5">
                            
                            <label
                                className={`absolute flex -top-1 z-40 left-3 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                            >
                               Customer
                                <div className={`ml-1`}>
                                    <div className={`flex-none rounded-full p-1 ${selectedCustomer || order?.customerName ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                        <div className={`size-2 rounded-full ${selectedCustomer || order?.customerName ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                    </div>
                                </div>
                            </label>
                            
                            <Combobox
                                as="div"
                                value={selectedCustomer}
                                onChange={handleSelectChange} // Use the updated handler
                            >
                                <div className="relative">
                                    <ComboboxInput
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                        onChange={(event) => setQuery(event.target.value)}
                                        onBlur={() => setQuery('')}
                                        disabled={isEditing}
                                        placeholder={`Select or enter a customer from your list of ${customers.length} available customers`}
                                        displayValue={(customer) => customer?.name || order?.customerName || ""}
                                    />
                                    <ComboboxButton disabled={isEditing} className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2">
                                        <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
                                    </ComboboxButton>

                                    <ComboboxOptions className={`absolute z-10 mt-1 max-h-96 animate-fadeIn focus:outline-none  w-full overflow-auto rounded-lg ${darkMode ? 'bg-neutral-800' : 'bg-white'} py-1 text-base shadow-lg sm:text-sm`}>
                                        {Array.isArray(filteredCustomers) && filteredCustomers.length > 0 ? (
                                            filteredCustomers.map((customer) => (
                                                <ComboboxOption
                                                    key={customer.id}
                                                    value={customer} // Pass the whole customer object as the value
                                                    className={`group relative rounded-full focus:outline-none cursor-default select-none py-2 pl-3 pr-9 ${
                                                        darkMode
                                                            ? 'data-[focus]:bg-darkMainColor data-[focus]:bg-opacity-50'
                                                            : 'data-[focus]:bg-neutral-200'
                                                    }`}
                                                >
                                                    <div className="flex items-center">
                                                        <div className={`rounded-full py-0.5 px-2 ${darkMode ? 'bg-neutral-600' : 'bg-bgColor'}`}>
                                                            {customer.customerCode}
                                                        </div>
                                                        <span className="ml-3 truncate group-data-[selected]:font-semibold">{customer.name}</span>
                                                    </div>

                                                    <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-green-600-600 group-data-[selected]:flex group-data-[focus]:text-white">
                                                        <CheckIcon className="size-5" aria-hidden="true" />
                                                    </span>
                                                </ComboboxOption>
                                            ))
                                        ) : (
                                            <div className={`flex items-center justify-center flex-col p-3`}>
                                                <EyeSlashIcon className={`w-6 stroke-2 text-gray-500`}/>
                                                <div className="px-4 py-2 text-sm text-gray-500">
                                                    No customer found for <span className={`font-bold ${darkMode ? 'text-white' : 'text-black'}`}>'{query}'</span>. Please try again.
                                                </div>
                                            </div>
                                        )}
                                    </ComboboxOptions>
                                </div>
                            </Combobox>
                            
                        </div>

                        {/*INDUSTRY*/}
                        <div className="flex flex-col w-1/6 relative p-1.5">
                            
                            <label
                                className={`absolute flex -top-1 z-40 left-3 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                            >
                               Industry
                                <div className={`ml-1`}>
                                    <div className={`flex-none rounded-full p-1 ${industry ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                        <div className={`size-2 rounded-full ${industry ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                    </div>
                                </div>
                            </label>
                            <Input
                                type="text"
                                required
                                value={industry}
                                disabled={true}
                                // onChange={(e) => setName(e.target.value)}
                                placeholder={`Select Customer`}
                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                            />
                            
                            {/*<div className="text-sm mb-1 line-clamp-1">Industry</div>*/}
                            {/*<Select*/}
                            {/*    className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border'} transition-all duration-500 ease-in-out drop-shadow shadow-gray-900 py-1 px-4 rounded-md`}*/}
                            {/*    disabled={true}*/}
                            {/*    value={industry}*/}
                            {/*>*/}
                            {/*    <option value={industry}>{industryMapping[industry] || "Select..."}</option>*/}
                            {/*    {Object.entries(industryMapping).map(([value, displayName]) => (*/}
                            {/*        <option key={value} value={value}>*/}
                            {/*            {displayName}*/}
                            {/*        </option>*/}
                            {/*    ))}*/}
                            {/*</Select>*/}
                            
                            {/*<div className={`absolute top-6 right-0`}>*/}
                            {/*    <div className={`flex-none rounded-full p-1 ${!industry ? 'bg-red-500/40 animate-heartbeat' : 'bg-emerald-500/30 animate-rubberBand'}`}>*/}
                            {/*        <div className={`size-2 rounded-full ${!industry ? 'bg-red-400' : 'bg-emerald-500'}`} />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                        {/*PRICING TYPE*/}
                        <div className="flex flex-col w-1/6 relative p-1.5">
                            
                            
                            <label
                                className={`absolute flex -top-1 z-40 left-3 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                            >
                                Pricing Type
                                <div className={`ml-1`}>
                                    <div className={`flex-none rounded-full p-1 ${priceType ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                        <div className={`size-2 rounded-full ${priceType ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                    </div>
                                </div>
                            </label>
                            <Input
                                type="text"
                                required
                                value={priceType}
                                disabled={true}
                                // onChange={(e) => setName(e.target.value)}
                                placeholder={`Select Customer`}
                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                            />
                            
                            
                            
                            {/*<div className="text-sm mb-1 line-clamp-1">Pricing Type</div>*/}
                            {/*<Select*/}
                            {/*    className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border'} transition-all duration-500 ease-in-out drop-shadow shadow-gray-900 py-1 px-4 rounded-md`}*/}
                            {/*        value={priceType}*/}
                            {/*        disabled={true}*/}
                            {/*        // onChange={(event) => setPriceType(event.target.value)} // Update priceType on selection change*/}
                            {/*>*/}
                            {/*    <option value={priceType}>{pricingMapping[priceType] || "Select..."}</option>*/}
                            {/*    {Object.entries(pricingMapping).map(([value, displayName]) => (*/}
                            {/*        <option key={value} value={value}>*/}
                            {/*            {displayName}*/}
                            {/*        </option>*/}
                            {/*    ))}*/}
                            {/*</Select>*/}
                            
                            {/* <div className={`absolute top-6 right-0`}>*/}
                            {/*    <div className={`flex-none rounded-full p-1 ${!priceType ? 'bg-red-500/40 animate-heartbeat' : 'bg-emerald-500/30 animate-rubberBand'}`}>*/}
                            {/*        <div className={`size-2 rounded-full ${!priceType ? 'bg-red-400' : 'bg-emerald-500'}`} />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                        {/*CUSTOMER CODE*/}
                        <div className="flex flex-col w-1/6 relative p-1.5">
                            
                            
                            
                            <label
                                className={`absolute flex -top-1 z-40 left-3 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                            >
                                Customer Code
                                <div className={`ml-1`}>
                                    <div className={`flex-none rounded-full p-1 ${customerCode ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                        <div className={`size-2 rounded-full ${customerCode ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                    </div>
                                </div>
                            </label>
                            <Input
                                type="text"
                                required
                                value={customerCode}
                                disabled={true}
                                // onChange={(e) => setName(e.target.value)}
                                placeholder={`Customer Name`}
                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                            />
                            
                            
                            {/*<div className="text-sm mb-1">Customer Code</div>*/}
                            
                            {/*<div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border'} transition-all duration-500 ease-in-out flex flex-row w-full px-4 rounded-lg h-full items-center justify-center`}>*/}
                            {/*    <div className="rounded-md w-full line-clamp-1">{customerCode || "Choose Customer..."}</div>*/}
                            {/*</div>*/}

                            {/* <div className={`absolute top-6 right-0`}>*/}
                            {/*    <div className={`flex-none rounded-full p-1 ${!customerCode ? 'bg-red-500/40 animate-heartbeat' : 'bg-emerald-500/30 animate-rubberBand'}`}>*/}
                            {/*        <div className={`size-2 rounded-full ${!customerCode ? 'bg-red-400' : 'bg-emerald-500'}`} />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                    </div>

                    {/*PRODUCT INPUT*/}
                    <div className={`animate-glideInRight w-full flex ${expanded ? 'h-2/3' : 'h-full'} flex-col mb-1.5`}>
                        
                        {/*PRODUCT SEARCH BAR*/}
                        <div className="w-full flex flex-row">
                            
                            {/*Search Bar*/}
                            <div className={`flex flex-row w-full p-1 rounded-md transition-all duration-500 ease-in-out`}>
                                <div className={`${darkMode ? 'bg-darkMainColor border border-neutral-700' : 'bg-white border'} border-2 w-5/6 rounded-md flex items-center justify-center transition-all duration-500 ease-in-out`}>
                                    
                                    <div className="flex flex-col items-center ml-1.5 mt-auto mb-auto">
                                        <Button
                                            className="rounded-full hover:animate-spin mt-auto mb-auto h-4 w-4 items-center justify-center align-middle"
                                            onClick={onUpdate}
                                        >
                                            <ArrowPathIcon
                                                className="h-4 text-borderColor hover:text-bgDarkGreen m-auto"/>
                                        </Button>
                                    </div>
                                    
                                    <MagnifyingGlassIcon className="text-gray-600 h-5 mr-3 ml-3 stroke-2"/>
                                    <Input
                                        className={`${darkMode ? 'bg-darkMainColor text-white' : 'bg-white'} transition-all duration-500 ease-in-out rounded-md p-1 w-full focus:outline-none`}
                                        placeholder={`Search to add from ${products.length} products`}
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        onFocus={() => setShowProducts(true)}
                                        onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />
                                    
                                    <Button
                                        onClick={() => addProductInput()}
                                        className={`hidden bg-green-600 hover:bg-opacity-50 w-1/6 flex items-center justify-center tracking-widest text-sm h-full text-white rounded-md`}
                                    >
                                        <PlusIcon className={`w-4 stroke-2 mr-3`}/>
                                        Blank Item
                                    </Button>
                                </div>
                                <Button
                                    className={`${selectedCustomer ? 'bg-green-600 hover:bg-opacity-80' : 'bg-gray-400 cursor-not-allowed'} text-sm rounded-md ml-1.5 text-white w-1/6 line-clamp-1`}
                                    onClick={toggleVisibility}
                                    disabled={!selectedCustomer} // Disables if selectedCustomer is null
                                >
                                    Customer Orders
                                </Button>
                            </div>
                            
                            
                            {showProducts && (
                                <ul className={`absolute animate-fadeIn ${darkMode ? 'bg-neutral-800 text-white border border-gray-900' : 'bg-white border'} border rounded-md p-2 w-1/2 max-h-96 overflow-y-auto scrollbar-hide mt-12 z-10 shadow-lg`}>
                                    
                                    {filteredProducts.length > 0 ? (
                                        (() => {
                                            // Sorting filteredProducts before mapping over it
                                            const categoryOrder = categoryColors.map(c => c.category);
                                            filteredProducts.sort((a, b) => categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category));
                                            
                                            return filteredProducts.map((product, index) => {
                                                const {color, shortened} = getCategoryInfo(product.category);
                                                
                                                return (
                                                    <li
                                                        key={product.id}
                                                        className={`cursor-pointer ${product.PLAIF ? 'text-gray-500 bg-opacity-20 bg-gray-500' : ''} tracking-widest p-2 rounded-lg flex items-center ${
                                                            darkMode ? 'hover:bg-darkMainColor' : 'hover:bg-gray-200'
                                                        }`}
                                                        onMouseDown={product.PLAIF ? undefined : () => addProductInput(product)} // Disable if PLAIF is true
                                                    >
                                                        <div className={`flex flex-row items-center w-full`}>
                                                            <div
                                                                className={`text-gray-500 text-xxs mr-3 w-6`}>[{index + 1}]
                                                            </div>
                                                            
                                                            <div className={`mr-3`}>
                                                                <div
                                                                    className={`flex-none w-12 rounded-full p-1 ${color} bg-opacity-40`}>
                                                                    <div
                                                                        className={`text-xxs rounded-full ${color} bg-opacity-80 font-bold px-1 text-center text-white tracking-normal`}>
                                                                        {shortened}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className={`line-clamp-1`}>{product.name}</div>
                                                        </div>
                                                    </li>
                                                );
                                            });
                                        })() // IIFE (Immediately Invoked Function Expression) to sort before rendering
                                    ) : (
                                        <div className={`flex items-center justify-center flex-col p-3`}>
                                            <EyeSlashIcon className={`w-6 stroke-2 text-gray-500`}/>
                                            <div className="px-4 py-2 text-sm text-gray-500">
                                                No product found for{' '}
                                                <span
                                                    className={`font-bold ${darkMode ? 'text-white' : 'text-black'}`}>'{searchTerm}'</span>.
                                                Please try again.
                                            </div>
                                        </div>
                                    )}
                                </ul>
                            )}
                        
                        </div>
                        
                        {/*PRODUCT LABELS*/}
                        <div className={`w-full flex flex-row mb-1 mt-1 font-semibold border-b-2 border-dashed ${darkMode ? ' border-neutral-700' : ''}`}>
                            
                            <div className={`w-full flex flex-row ${darkMode ? 'text-white' : 'text-black'}`}>
                                
                                {/*PRODCUT NAME*/}
                                <div className="flex flex-row w-1/6 mr-3">
                                    <div className="flex flex-col w-full">
                                        <div
                                            className="text-sm">Product{productInputs.length > 1 ? 's' : ''} [{productInputs.length}]
                                        </div>
                                    </div>
                                </div>
                                
                                {/*PRODUCT PRICING/QUANT*/}
                                <div className="flex flex-row w-2/3 mr-3">
                                    
                                    {/*PRICE*/}
                                    <div
                                        className="flex flex-row w-1/3 px-1.5 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-sm">Price per U/M</div>
                                    </div>
                                    
                                    {/*QUANTITY*/}
                                    <div
                                        className="flex flex-row w-1/3 px-6 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-sm">Quantity</div>
                                    </div>
                                    
                                    {/*VOLUME*/}
                                    <div
                                        className="flex flex-row w-1/4 px-3 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-sm">U/M</div>
                                    </div>
                                    
                                    {/*Warehouse*/}
                                    <div
                                        className="flex flex-row w-1/4 px-3 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-sm">WH</div>
                                    </div>
                                    
                                    {/*DISC*/}
                                    <div className="flex flex-row w-1/4 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-sm">Disc</div>
                                    </div>
                                
                                </div>
                                
                                {/*PRODUCT TOTAL*/}
                                <div className="flex flex-row w-1/6 mr-3 justify-end align-middle items-center">
                                    
                                    <div className="ml-auto w-full flex flex-row justify-end">
                                        <div
                                            className="flex flex-row mt-auto mb-auto align-middle justify-end items-center">
                                            <div className="text-sm mr-3">Total</div>
                                        </div>
                                    </div>
                                
                                
                                </div>
                            
                            </div>
                        </div>
                        
                        {/*PRODUCT INPUT MAP*/}
                        <div className={`relative overflow-y-auto pb-2.5 scrollbar-hide h-full ${expanded ? 'max-h-[calc(100vh-30rem)]' : 'max-h-[calc(100vh-23.5rem)]'}`}>
                           
                            {/*Collapse Button*/}
                            <Button
                                className={`absolute z-50 text-sm ${darkMode ? 'bg-darkMainColor text-white border border-neutral-700 shadow' : 'bg-bgColor shadow border text-black'} px-2 rounded-lg bottom-0 right-2`}
                                onClick={toggleExpanded}
                            >
                                {expanded ? (
                                    <div className={`flex items-center`}>
                                        Collapse <ChevronUpDownIcon className={`w-4`}/>
                                    </div>
                                ) : (
                                    <div className={`flex items-center`}>
                                        Expand <ChevronUpDownIcon className={`w-4`}/>
                                    </div>
                                )}
                            </Button>
                           
                            {/*Map*/}
                            <div className={`overflow-y-auto overflow-x-hidden scrollbar-hide h-full ${expanded ? 'max-h-[calc(100vh-30rem)]' : 'max-h-[calc(100vh-24rem)]'} border-b-2 ${darkMode ? 'border-darkBgColor' : ''}`}>
                                {productInputs.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex w-full h-full justify-center items-center">
                                        
                                        <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-neutral-700' : 'text-black bg-neutral-50'} w-fit px-24 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                            <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                <SquaresPlusIcon className={`w-10 stroke-1 mr-3`}/>
                                                <p className="text-xl mt-2 mb-2 line-clamp-1 tracking-widest flex">No Products</p>
                                                <Button onClick={() => setShowProducts(true)}
                                                        disabled={!selectedCustomer}
                                                        className={`${!selectedCustomer ? 'bg-opacity-50' : ''} flex items-center bg-green-600 text-white px-4 py-1.5 rounded-lg hover:bg-opacity-50 text-xs`}
                                                >
                                                    <PlusIcon className={`w-4 mr-2`}/>
                                                    {!selectedCustomer ? 'Select Customer' : 'Add Product'}
                                                </Button>
                                            </div>
                                        </div>
                                    
                                    </div>
                                ) : (
                                    productInputs.map((product, index) => {
                                        const matchedProduct = products.find((p) => p.id === product.id);
                                        return (
                                            <div key={productInputs.id}>
                                                <ProductInput
                                                    key={product.id}
                                                    index={index}
                                                    onRemove={handleRemoveProduct}
                                                    onAdd={handleAddFinalProduct}
                                                    product={matchedProduct}
                                                    priceType={priceType}
                                                    discount={customerType}
                                                    lineItem={product}
                                                    contractPrices={contractPrices}
                                                    region={priceType}
                                                />
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        
                        </div>
                    </div>
                    
                    {/*PRODUCT TOTAL + NOTES + DELIVERY*/}
                    <div className={`animate-slideInBottom w-full h-1/4 mt-auto flex ${expanded ? '' : 'hidden animate-slideOutLeft'}`}>
                        
                        <div className={`w-2/3 h-full mr-auto rounded-lg flex flex-row p-1 ${darkMode ? 'text-white' : 'text-black'}`}>
                            
                            {/*NOTES SECTION*/}
                            <div className="w-1/3 h-full">
                                <Textarea
                                    id=""
                                    name=""
                                    required
                                    autoComplete=""
                                    placeholder="Notes..."
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    className={`block w-full focus:outline-green-600 ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border ring-1 ring-inset ring-green-600'} p-2 h-full rounded-md shadow-gray-900 py-1.5 placeholder:text-gray-400`}
                                />
                            </div>
                            
                            {/*DELIVERY SECTION*/}
                            <div className="w-1/3 ml-3">
                                
                                <label htmlFor="" className="block text-sm font-semibold leading-6">
                                    Delivery Date
                                </label>
                                
                                {/*Delivery Date*/}
                                <Input
                                    id="date-input"
                                    name="date"
                                    type="date"
                                    value={deliveryDate}
                                    onChange={handleDeliveryDateChange}
                                    className={`block w-full px-4 focus:outline-green-600 rounded-md shadow-gray-900 py-1.5
                                        ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border ring-1 ring-inset ring-gray-300'}
                                        ${isToday ? 'border-2 animate-pulse border-red-500 ring-red-500' : ''}`
                                    }
                                />
                                
                                {/*Early Order*/}
                                <div className="flex items-center mt-3">
                                    
                                    <Field className="flex items-center">
                                    <Switch
                                            checked={earlyOrder}
                                            onChange={setEarlyOrder}
                                            className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span aria-hidden="true" className="pointer-events-none absolute size-full rounded-md" />
                                            <span
                                                aria-hidden="true"
                                                className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                            />
                                            <span
                                                aria-hidden="true"
                                                className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                            />
                                        </Switch>
                                        <Label as="span" className="ml-3 text-sm">
                                            <span className="font-medium">Early Order</span>{' '}
                                            <span className="text-gray-500">(Save 5%)</span>
                                        </Label>
                                    </Field>

                                </div>

                                {/*Prepay*/}
                                <div className="flex items-center mt-3">

                                    <Field className="flex items-center">
                                        <Switch
                                            checked={prePay}
                                            onChange={setPrePay}
                                            className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span aria-hidden="true" className="pointer-events-none absolute size-full rounded-md" />
                                            <span
                                                aria-hidden="true"
                                                className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                            />
                                            <span
                                                aria-hidden="true"
                                                className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                            />
                                        </Switch>
                                        <Label as="span" className="ml-3 text-sm">
                                            <span className="font-medium">Prepay Order</span>{' '}
                                            <span className="text-gray-500">(Save 3%)</span>
                                        </Label>
                                    </Field>

                                </div>

                            </div>

                            {/*SHIPPING SECTION*/}
                            <div className="w-1/3 ml-3 mr-3">

                                <label htmlFor="" className="block text-sm font-semibold leading-6">
                                    Shipping Location
                                </label>

                                {/* Shipping Location */}
                                <Select
                                    className={`block w-full px-4 focus:outline-green-600 ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border ring-1 ring-inset ring-gray-300'} rounded-md shadow-gray-900 py-2`}
                                    // value={status}
                                    // onChange={(e) => filterProgramsByCatagory(e.target.value)}
                                >
                                    <option value="">Select Shipping Location...</option>
                                    {Array.isArray(shippingLocations) && shippingLocations.length > 0 ? (
                                        shippingLocations.map((location, index) => (
                                            <option key={index} value={location.name}>
                                                {location.nickName}
                                            </option>
                                        ))
                                    ) : (
                                        <option disabled>No shipping locations available</option>
                                    )}
                                </Select>

                                {/*Call Before*/}
                                <div className="flex items-center mt-3">

                                    <Field className="flex items-center">
                                        <Switch
                                            checked={callBefore}
                                            onChange={setCallBefore}
                                            className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span aria-hidden="true" className="pointer-events-none absolute size-full rounded-md" />
                                            <span
                                                aria-hidden="true"
                                                className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                            />
                                            <span
                                                aria-hidden="true"
                                                className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                            />
                                        </Switch>
                                        <Label as="span" className="ml-3 text-sm">
                                            <span className="font-medium">Call Before Delivery</span>{' '}
                                            {/*<span className="text-gray-500">(Save 10%)</span>*/}
                                        </Label>
                                    </Field>

                                </div>
                                
                                {/*ASAP*/}
                                <div className="flex items-center mt-3">

                                    <Field className="flex items-center">
                                        <Switch
                                            checked={ASAP}
                                            onChange={setASAP}
                                            className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span aria-hidden="true" className="pointer-events-none absolute size-full rounded-md" />
                                            <span
                                                aria-hidden="true"
                                                className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                            />
                                            <span
                                                aria-hidden="true"
                                                className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                            />
                                        </Switch>
                                        <Label as="span" className="ml-3 text-sm">
                                            <span className="font-medium">ASAP Order</span>{' '}
                                            {/*<span className="text-gray-500">(Save 10%)</span>*/}
                                        </Label>
                                    </Field>

                                </div>

                            </div>

                        </div>

                        {/*TOTAL SECTION*/}
                        <div className={`${darkMode ? 'bg-darkAccentColor border border-gray-900 text-white' : 'bg-bgColor border text-black'} drop-shadow w-1/3 ml-auto rounded-lg flex flex-row p-1.5`}>
                            <div className="w-1/2">
                                <div className="h-1/3 flex items-center text-xs">SubTotal:</div>
                                <div className="h-1/3 flex items-center text-xs">Discount (+/- {customerType}%):</div>
                                <div className="h-1/3 flex flex-col">
                                    <div className="flex text-lg">Total:</div>
                                    <div className="flex text-gray-400 text-xxs line-clamp-1">*Before Applicable Taxes & Freight</div>
                                </div>
                            </div>
                            <div className="w-2/3">
                                <div className="h-1/3 flex items-center justify-end text-xs">
                                    <div className="mr-3">$</div>
                                    <div className="mr-3 tracking-wider">{numberWithCommas(subTotal.toFixed(2))}</div>
                                    <div className="text-borderColor">USD</div>
                                </div>
                                <div className="h-1/3 flex items-center justify-end text-xs">
                                    <div className="mr-3">$</div>
                                    <div className="mr-3 tracking-wider">{numberWithCommas(discount)}</div>
                                    <div className="">USD</div>
                                </div>
                                <div className="h-1/3 flex items-center justify-end stext-lg">
                                    <div className="mr-3">$</div>
                                    <div className="mr-3 tracking-wider">{numberWithCommas(grandTotal.toFixed(2))}*</div>
                                    <div className="">USD</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                {/*ORDER FORM FOOTER [Buttons]*/}
                <div className="animate-slideInBottom w-full flex mt-3">
                    
                    {/*Submit*/}
                    <div className={`${isEditing ? 'hidden' : ''} text-xl text-black w-1/2`}>
                        <div className="flex w-full flex-row items-start align-middle justify-start h-full">

                            <Button className={`${!selectedCustomer ? 'bg-opacity-50' : 'hover:scale-105'} ${darkMode ? 'bg-green-600 text-white' : 'bg-green-600 drop-shadow'} hover:bg-opacity-50 rounded-md py-1.5 px-6 text-white text-sm mr-3 transition-all duration-500 ease-in-out`}
                                onClick={toggleSubmitting}
                                disabled={!selectedCustomer}
                            >Submit Order</Button>

                            <Button className={`border ${darkMode ? 'bg-darkMainColor text-white border-neutral-700' : 'bg-white drop-shadow'} hover:scale-105 hover:bg-opacity-50 rounded-md py-1.5 px-6 text-black text-sm mr-3 drop-shadow shadow-gray-900 transition-all duration-500 ease-in-out`}
                                onClick={clear}
                                // onClick={handleClick}
                            >Clear</Button>

                        </div>
                    </div>
                    
                    {/*Planned*/}
                    <div className={`${isEditing ? 'hidden' : ''} text-xl w-1/2`}>
                        <div className="flex w-full flex-row items-end align-middle justify-end h-full">

                            {quoteToolTip && (
                                <div className={`mr-5 border ${darkMode ? 'bg-darkBgColor border-neutral-700 text-white' : 'bg-bgColor text-black'} text-xs rounded p-1`}>
                                    Download Order as a Quote
                                </div>
                            )}
                            
                            <Button
                                className="rounded-full mt-auto mr-3 mb-auto w-fit items-center justify-center align-middle transition-all duration-500 ease-in-out"
                                onClick={downloadQuotePDF}
                                disabled={!selectedCustomer}
                                onMouseEnter={() => setQuoteToolTip(true)}
                                onMouseLeave={() => setQuoteToolTip(false)}
                            >
                                <img
                                    alt=""
                                    src={pdfIcon}
                                    className={`flex-none h-8 ${!selectedCustomer ? 'bg-neutral-700 bg-opacity-50 border rounded-lg border-red-500' : 'hover:animate-heartbeat'}`}
                                />
                                {/*<DocumentArrowDownIcon className={`h-8 text-borderColor ${!selectedCustomer ? 'text-opacity-50' : 'hover:text-green-600 hover:animate-heartbeat'} m-auto transition-all duration-500 ease-in-out`}/>*/}
                            </Button>
                            
                            <Button
                                className={`${!selectedCustomer ? 'bg-opacity-50' : 'hover:scale-105'} ${darkMode ? 'text-white' : 'text-white drop-shadow'} bg-blue-500 hover:bg-opacity-50 rounded-md py-1.5 px-6 text-sm transition-all duration-500 ease-in-out`}
                                onClick={togglePlanned}
                                disabled={!selectedCustomer}
                            >Save Order as Planned</Button>

                        </div>
                    </div>
                    
                    
                    {/*Editing*/}
                    <div className={`${isEditing ? '' : 'hidden'} text-xl w-full`}>
                        <div className="flex w-full flex-row items-center justify-center h-full">

                            {/*{quoteToolTip && (*/}
                            {/*    <div className={`mr-5 border ${darkMode ? 'bg-darkBgColor border-neutral-700 text-white' : 'bg-bgColor text-black'} text-xs rounded p-1`}>*/}
                            {/*        Download Order as a Quote*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            
                            {/*<Button*/}
                            {/*    className="rounded-full mt-auto mr-3 mb-auto w-fit items-center justify-center align-middle transition-all duration-500 ease-in-out"*/}
                            {/*    onClick={downloadQuotePDF}*/}
                            {/*    disabled={!selectedCustomer}*/}
                            {/*    onMouseEnter={() => setQuoteToolTip(true)}*/}
                            {/*    onMouseLeave={() => setQuoteToolTip(false)}*/}
                            {/*>*/}
                            {/*    <img*/}
                            {/*        alt=""*/}
                            {/*        src={pdfIcon}*/}
                            {/*        className={`flex-none h-8 ${!selectedCustomer ? 'bg-neutral-700 bg-opacity-50 border rounded-lg border-red-500' : 'hover:animate-heartbeat'}`}*/}
                            {/*    />*/}
                            {/*    /!*<DocumentArrowDownIcon className={`h-8 text-borderColor ${!selectedCustomer ? 'text-opacity-50' : 'hover:text-green-600 hover:animate-heartbeat'} m-auto transition-all duration-500 ease-in-out`}/>*!/*/}
                            {/*</Button>*/}
                            
                            <Button
                                className={`hover:scale-105 ${darkMode ? 'text-white' : 'text-white drop-shadow'} w-1/2 bg-blue-500 hover:bg-opacity-50 rounded-md py-1.5 px-6 text-sm transition-all duration-500 ease-in-out`}
                                onClick={toggleUpdating}
                                disabled={false}
                            >Update Existing Order</Button>

                        </div>
                    </div>
                    
                    {/*Back*/}
                    <div className={`${isEditing ? '' : 'hidden'} absolute text-xl`}>
                        <div className="flex flex-row items-center justify-center h-full">
                            
                            <Button className={`flex items-center border ${darkMode ? 'bg-darkMainColor text-white border-neutral-700' : 'bg-white drop-shadow'} hover:scale-105 hover:bg-opacity-50 rounded-md py-1.5 px-6 text-black text-sm mr-3 drop-shadow shadow-gray-900 transition-all duration-500 ease-in-out`}
                                onClick={close}
                                disabled={false}
                            >
                                <ArrowLeftIcon className={`w-4 mr-3 stroke-2`}/>
                                Back
                            </Button>

                        </div>
                    </div>
                    
                    
                </div>

            </div>

            {browse && (

                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!browse}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        browse ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-1/2 max-h-full">

                        {/* Modal content */}
                        <div className={`relative h-96 animate-zoomIn ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor text-white' : 'bg-white drop-shadow-lg text-black border'} rounded-lg`}>


                            {/* Modal header */}
                            <div className="flex items-center justify-between p-5 border-b mr-3 ml-3 rounded-t dark:border-gray-600">

                                <div className={`w-full flex flex-col`}>
                                    <h3 className="text-lg w-full font-semibold">
                                        Browse [{selectedCustomer.name || "- - - -"}]'s Orders
                                    </h3>
                                </div>

                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:animate-pulse hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={toggleVisibility}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                </button>
                            </div>



                            <div className={`w-full h-full items-center tracking-widest text-2xl mt-16 absolute flex flex-col`}>Coming Soon</div>



                        </div>

                    </div>
                </div>

            )}

            
            <Dialog open={isSubmitting} onClose={setIsSubmitting} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <PaperAirplaneIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Submit Order to Plant
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Submitting this order is sending it to the Plant for fulfillment.
                              A Submitted order may not be edited so make sure all information is correct.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={onSubmitPending}
                        disabled={!selectedCustomer}
                        className={`${!selectedCustomer ? 'bg-opacity-50' : ''} inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                      >
                        Submit New Order
                      </Button>
                      <Button
                        type="button"
                        data-autofocus
                        onClick={toggleSubmitting}
                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                      >
                        Cancel
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>

            <Dialog open={isPlanned} onClose={setIsPlanned} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-blue-500/15 sm:mx-0 sm:size-10">
                        <PaperAirplaneIcon aria-hidden="true" className="size-6 text-blue-500" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Save Order As Planned
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to save this Order? This order will be saved to your account
                              but not sent to the plant for fulfillment. Your CSR rep will not be able to see this order.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={onSubmitPlanned}
                        disabled={!selectedCustomer}
                        className={`${!selectedCustomer ? 'bg-opacity-50' : ''} inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                      >
                        Save Order as Planned
                      </Button>
                      <Button
                        type="button"
                        data-autofocus
                        onClick={togglePlanned}
                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                      >
                        Cancel
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
            
            <Dialog open={isUpdating} onClose={setIsUpdating} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-blue-500/15 sm:mx-0 sm:size-10">
                        <PencilIcon aria-hidden="true" className="size-6 text-blue-500" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Update Existing Order
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to edit this Order? Editing this order will perminately change the data for this order. If you are a sales Rep once your order is submitted you cannot edit it further.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={onSubmittingUpdating}
                        className={`inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                      >
                        Update Order
                      </Button>
                      <Button
                        type="button"
                        data-autofocus
                        onClick={toggleUpdating}
                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                      >
                        Cancel
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
            
            <Dialog open={isNoPhone} onClose={setIsNoPhone} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-500/15 sm:mx-0 sm:size-10">
                        <PhoneIcon aria-hidden="true" className="size-6 text-green-500" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Missing Phone Number
                        </DialogTitle>
                          <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                  It appears that you would like to add a "Call Before Delivery" option on this order.
                                  IT does not appear to be a phone number on file for this customer. Please add one
                                  below!
                              </p>
                              <div className={`relative mt-4`}>
                                  <label
                                      className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                  >
                                      Customer Phone Number
                                  </label>
                                  <Input
                                      type="text"
                                      value={customerPhone}
                                      onChange={(e) => setCustomerPhone(e.target.value)}
                                      placeholder={`123-456-7890`}
                                      className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300 outline-2'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                  />
                              </div>
                          </div>
                      </div>
                    </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <Button
                              type="button"
                              onClick={() => setIsNoPhone(false)}
                              // onClick={onSubmittingUpdating}
                              className={`inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                          >
                              I Understand
                          </Button>
                          <Button
                              type="button"
                              data-autofocus
                              onClick={() => setIsNoPhone(false)}
                              className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                          >
                              Cancel
                          </Button>
                      </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>

            

            <Dialog open={open} onClose={setOpen} className={`relative z-50 ${darkMode ? 'text-white' : 'text-black'}`}>
                
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/20 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
                  />
                
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full h-full pl-10 py-3">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className={`flex flex-col h-full border-2 ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} shadow-2xl mr-3 rounded-lg`}>
                                    
                                    
                                    <div className={`px-4 sm:px-6 pt-6`}>
                                        <div className="flex items-start justify-between">
                                            <DialogTitle className="text-base font-semibold">New Order Form Reference</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <Button
                                                    type="button"
                                                    onClick={() => setOpen(false)}
                                                    className="relative rounded-md text-gray-500 hover:text-opacity-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <ChevronDoubleRightIcon aria-hidden="true" className="size-6" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>


                                    {/*Body*/}
                                    <div className={`relative mt-5 flex-1 px-4 sm:px-6 flex-col border-t-2 h-[calc(100%-4rem)] overflow-y-scroll scrollbar-hide space-y-12 pt-3 ${darkMode ? 'border-neutral-700': ''}`}>

                                        {/*Green*/}
                                        <div className={`flex flex-row items-center`}>

                                            <div className={`mr-6`}>
                                                <div className={`flex-none rounded-full p-1 bg-emerald-500/30`}>
                                                    <div className={`size-2 rounded-full bg-emerald-500`}/>
                                                </div>
                                            </div>

                                            <div className={``}>
                                                <div className={`font-bold text-emerald-500`}>Green Indicators [Good]</div>
                                                <div className={`text-sm ml-3 text-gray-500`}>These indicators appear in the corner of fields that are correctly filled out and properly formatted. They signify that all necessary information for these fields has been provided, ensuring accurate order processing. On a product input card, their presence confirms that pricing is available for the selected item within the specified industry.</div>
                                            </div>

                                        </div>

                                        {/*Red*/}
                                        <div className={`flex flex-row items-center`}>

                                            <div className={`mr-6`}>
                                                <div className={`flex-none rounded-full p-1 bg-red-500/30 animate-heartbeat`}>
                                                    <div className={`size-2 rounded-full bg-red-400`}/>
                                                </div>
                                            </div>

                                            <div className={``}>
                                                <div className={`font-bold text-red-500`}>Red Indicators [Error]</div>
                                                <div className={`text-sm ml-3 text-gray-500`}>These pulsating indicators appear in the corner of fields that are either incorrectly formatted or left incomplete. They highlight essential fields required for proper order formatting. Additionally, if these indicators are present on a product input card, it signifies that pricing is unavailable for the selected item within the specified industry.</div>
                                            </div>

                                        </div>

                                        {/*Blue*/}
                                        <div className={`flex flex-row items-center`}>

                                            <div className={`mr-6`}>
                                                <div className={`flex-none rounded-full p-1 bg-blue-500/30 animate-heartbeat`}>
                                                    <div className={`size-2 rounded-full bg-blue-500`}/>
                                                </div>
                                            </div>

                                            <div className={``}>
                                                <div className={`font-bold text-blue-500`}>Blue Indicators [Discount]</div>
                                                <div className={`text-sm ml-3 text-gray-500`}>These indicators appear above the total price fields within product input cards. Their presence signifies that a discount has been applied to the corresponding line item in the order.</div>
                                            </div>

                                        </div>

                                        {/*Yellow*/}
                                        <div className={`flex flex-row items-center`}>

                                            <div className={`mr-6`}>
                                                <div className={`flex-none rounded-full p-1 bg-yellow-500/30`}>
                                                    <div className={`size-2 rounded-full bg-yellow-500`}/>
                                                </div>
                                            </div>

                                            <div className={``}>
                                                <div className={`font-bold text-yellow-500`}>Yellow Indicators [Contract]</div>
                                                <div className={`text-sm ml-3 text-gray-500`}>These are indicators appear above the pricing field to indicate that a contract price has been recognized and automatically applied to the order form.</div>
                                            </div>

                                        </div>
                                        
                                        {/*Grey*/}
                                        <div className={`flex flex-row items-center`}>

                                            <div className={`mr-6`}>
                                                <div className={`flex-none rounded-full animate-rubberBand-delay`}>
                                                    <LockClosedIcon className={`size-6 stroke-2 rounded-full text-slate-500`}/>
                                                </div>
                                            </div>

                                            <div className={``}>
                                                <div className={`font-bold text-slate-500`}>Grey Indicators [Locked]</div>
                                                <div className={`text-sm ml-3 text-gray-500`}>These are indicators appear above the pricing field to indicate that this products price is locked and you may not apply any discounts to this price. This is because those products are not PFC products and we do not set the price.</div>
                                            </div>

                                        </div>
                                        
                                        {/*Blue Pencil*/}
                                        <div className={`flex flex-row items-center pb-16`}>

                                            <div className={`mr-6`}>
                                                <div className={`flex-none rounded-full animate-rubberBand-delay`}>
                                                    <PencilIcon className={`size-6 stroke-2 text-blue-500`}/>
                                                </div>
                                            </div>

                                            <div className={``}>
                                                <div className={`font-bold text-blue-500`}>Blue Pencil [Editted]</div>
                                                <div className={`text-sm ml-3 text-gray-500`}>These are indicators appear above the pricing field to indicate that this products price has been physically edited by the Sales Rep. The Sales Rep is authorized to edit a max of 15% off of the "sticker" price however this icon appears so that the CSR back in the office knows that an edited order has been entered. This feature is disabled if any other discount is applied.</div>
                                            </div>

                                        </div>

                                    </div>


                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog open={isToday} onClose={setIsToday} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-600/15 sm:mx-0 sm:size-10">
                        <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Order Date Error
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to set this date as the Delivery Date? You chose the delivery date to be today, This may cause errors in shipping and Freight.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={() => setDeliveryDate("")}
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      >
                        Change Date
                      </Button>
                      <Button
                        type="button"
                        data-autofocus
                        onClick={() => setIsToday(false)}
                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                      >
                        I Understand
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
            
            
            
            
            
            <Dialog open={prePayAlert} onClose={setPrePayAlert} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <CreditCardIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Pre Pay Alert
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            You have chosen this order to be a prepay order. This means that this order
                              will receive an additional 3% discount. This order has to be paid in full by
                              <span className={`font-bold ${darkMode ? 'text-white' : 'text-black'}`}> February 21st, 2025</span> in order to receive this discount.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={() => setPrePayAlert(false)}
                        className={`inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                      >
                          Continue
                      </Button>
                      <Button
                        type="button"
                        data-autofocus
                        onClick={() => {
                            setPrePay(false);
                            setPrePayAlert(false);
                        }}
                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                      >
                        Remove Discount
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
            
            <Dialog open={EODAlert} onClose={setEODAlert} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <CheckBadgeIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Early Order Alert
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            You have chosen and Early Order. This means that this order
                              will receive an additional 5% discount. This order has to be placed by
                              <span className={`font-bold ${darkMode ? 'text-white' : 'text-black'}`}> February 7th, 2025</span> additionally you must take delivery by
                              <span className={`font-bold ${darkMode ? 'text-white' : 'text-black'}`}> Jan 1st</span> -
                              <span className={`font-bold ${darkMode ? 'text-white' : 'text-black'}`}> May 1st</span> in order to receive this discount. Payment must be received by
                              <span className={`font-bold ${darkMode ? 'text-white' : 'text-black'}`}> June 1st</span>.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={() => setEODAlert(false)}
                        className={`inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto`}
                      >
                          Continue
                      </Button>
                      <Button
                        type="button"
                        data-autofocus
                        onClick={() => {
                            setEarlyOrder(false);
                            setEODAlert(false);
                        }}
                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                      >
                        Remove Discount
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>

            
        </div>
    )
};


export default NewOrder;
