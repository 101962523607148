import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, InfoWindow, LoadScriptNext, Marker } from "@react-google-maps/api";
import { MapIcon } from "@heroicons/react/24/outline";
import { useDarkMode } from "../../contexts/darkModeContext";
import { Button } from "@headlessui/react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const MapComponent = ({ geocodedAddresses, disabledUI }) => {
  const [center] = useState({ lat: 37.6872, lng: -97.3301 }); // Default to Wichita, KS
  const mapRef = useRef(null);
  const markerClusterRef = useRef(null);
  const { darkMode } = useDarkMode();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [googleMaps, setGoogleMaps] = useState(null);
  
  const apiKey = "AIzaSyDxrbk0CKmZUpJ-5eQGaxHH-I5lYeWrPfc"; // Store in .env

  
  
  
  useEffect(() => {
    if (!geocodedAddresses || geocodedAddresses.length === 0 || !googleMaps) return;
  
    const validMarkers = geocodedAddresses.filter(
      (marker) => marker.lat && marker.lng && !isNaN(marker.lat) && !isNaN(marker.lng)
    );
  
    console.log("Valid markers:", validMarkers);
  
    if (mapRef.current) {
      // Remove existing clusters if they exist
      if (markerClusterRef.current) {
        markerClusterRef.current.clearMarkers();
      }
  
      // Create markers with click event listeners
      const markers = validMarkers.map((marker) => {
        const newMarker = new googleMaps.maps.Marker({
          position: { lat: marker.lat, lng: marker.lng },
          title: marker.name,
          label: {
            text: marker.repCode,
            color: "white",
            fontSize: "12px",
            fontWeight: "bold",
          },
          icon: {
            path: "M12 2C7.03 2 3 6.03 3 11c0 5.97 9 13 9 13s9-7.03 9-13c0-4.97-4.03-9-9-9z",
            fillColor: "#38a169",
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: "#000",
            scale: 1.5,
            anchor: new googleMaps.maps.Point(12, 24),
            labelOrigin: new googleMaps.maps.Point(12, 10),
          },
        });
  
        // Add click event to show InfoWindow
        newMarker.addListener("click", () => {
          setSelectedMarker(marker); // Store the clicked marker's data
        });
  
        return newMarker;
      });
  
      // Create a new MarkerClusterer instance
      markerClusterRef.current = new MarkerClusterer({ map: mapRef.current, markers });
    }
  }, [geocodedAddresses, googleMaps]);



  const handleLoad = (map) => {
    mapRef.current = map;
    setGoogleMaps(window.google);
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  if (!center) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className={`text-center flex flex-col justify-center items-center border-2 rounded-lg border-dashed ${darkMode ? "border-neutral-700" : ""} px-6 py-2`}>
          <MapIcon className="size-12" />
          <h3 className="mt-2 text-sm font-semibold">Error Loading Map</h3>
          <p className="mt-1 text-sm text-gray-500">Check the Customers Address!</p>
        </div>
      </div>
    );
  }

  return (
    <LoadScriptNext googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={geocodedAddresses?.length > 1 ? 5 : 16}
        options={{ mapTypeId: "hybrid", disableDefaultUI: disabledUI }}
        onLoad={handleLoad}
      >
        {selectedMarker && (
          <InfoWindow position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }} onCloseClick={() => setSelectedMarker(null)}>
            <div className="text-black px-4 flex flex-col items-center justify-center">
              <div className="mb-1 font-bold">{selectedMarker.customerCode}</div>
              <div>{selectedMarker.name}</div>
              <Button className="hidden bg-green-600 text-white px-2 py-1 rounded-lg tracking-widest hover:bg-opacity-80 mt-4">
                AccountView
              </Button>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default MapComponent;