import React, {useEffect, useState} from 'react';
import logo from "../main-logo.png";
import logoFull from "../PFC_Logo_Full.png"
import Header from "./Header";
import {
    BookOpenIcon,
    ClipboardDocumentListIcon,
    HomeIcon,
    UserGroupIcon,
    ArrowRightCircleIcon,
    ArrowLeftCircleIcon,
    UserPlusIcon,
    ArchiveBoxIcon,
    WrenchScrewdriverIcon,
    ChatBubbleLeftEllipsisIcon,
    InboxIcon,
    UserCircleIcon,
    QuestionMarkCircleIcon,
    ChartPieIcon,
    TicketIcon,
    CheckBadgeIcon, FolderOpenIcon, DocumentCurrencyDollarIcon, AcademicCapIcon, MapIcon
} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import {useDarkMode} from "../contexts/darkModeContext";
import {useAuth} from "../contexts/authContext";
import {db} from "../firebase/firebase";
import {Button} from "@headlessui/react";
const SideBar = () => {
    // Get the current pathname

    // eslint-disable-next-line
    const [currentPath, setCurrentPath] = useState(window.location.pathname); // Initialize currentPath

    const { darkMode } = useDarkMode();

    const { currentUser } = useAuth();

    const [accountLevel, setAccountLevel] = useState("");

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);

                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    
    const [collapse, setCollapse] = useState(() => {
        const savedState = localStorage.getItem('collapseState');
        return savedState === null ? false : JSON.parse(savedState);
    });

    const toggleCollapse = () => {
        setCollapse(prevCollapse => {
            const newCollapse = !prevCollapse;
            localStorage.setItem('collapseState', JSON.stringify(newCollapse));
            return newCollapse;
        });
    };

    const accountLevelMapping = {
        D: "Developer",
        A: "Admin",
        CSR: "CSR",
        PFC: "Fulfillment",
        DIST: "Distributor",
        S: "Sales Rep"
    }
    
    const menuItems = [
        { path: "/Home", label: "Home", Icon: HomeIcon, visibleTo: ["D", "CSR", "A", "S", "DIST", "PFC"], disabled: false },
        { path: "/Dashboard", label: "Personal Dashboard", Icon: ChartPieIcon, visibleTo: ["D", "A", "S", "DIST", "PFC"], disabled: false },
        { path: "/Catalog", label: "Catalog", Icon: BookOpenIcon, visibleTo: ["D", "CSR", "A", "S", "DIST", "PFC"], disabled: false },
        { path: "/Proven", label: "Proven Programs", Icon: CheckBadgeIcon, visibleTo: ["D", "CSR", "A", "S", "DIST", "PFC"], disabled: false },
        { path: "/Profile", label: "Profile & Settings", Icon: UserCircleIcon, visibleTo: ["D", "CSR", "A", "S", "DIST", "PFC"], disabled: false },
        
        { path: "/Orders", label: "Current Orders", Icon: ClipboardDocumentListIcon, visibleTo: ["D", "A", "S", "DIST"], disabled: false },
        { path: "/Archive", label: "Archived Orders", Icon: ArchiveBoxIcon, visibleTo: ["D", "A", "S", "DIST"], disabled: true },
        { path: "/Customers", label: "Customer Management", Icon: FolderOpenIcon, visibleTo: ["D", "A", "S", "DIST"], disabled: false },
        { path: "/CustomerMap", label: "Customer Map", Icon: MapIcon, visibleTo: ["D", "A", "S", "DIST"], disabled: false },
        { path: "/SalesTeam", label: "Sales Team", Icon: UserGroupIcon, visibleTo: ["D", "A", "S", "DIST"], disabled: false },
        { path: "/Research", label: "Research & Development", Icon: AcademicCapIcon, visibleTo: ["D", "CSR", "A", "S", "DIST", "PFC"], disabled: true },
        
        { path: "/Guides", label: "Help & Resources", Icon: QuestionMarkCircleIcon, visibleTo: ["D", "CSR", "A", "S", "DIST", "PFC"], disabled: false },
        { path: "", label: "Program Builder", Icon: WrenchScrewdriverIcon, visibleTo: ["D", "A", "S", "DIST"], disabled: true },
        { path: "/Tickets", label: "Support Tickets", Icon: TicketIcon, visibleTo: ["D"], disabled: false },
        { path: "/Pricing", label: "Product Pricing", Icon: DocumentCurrencyDollarIcon, visibleTo: ["D"], disabled: false },
        { path: "", label: "Messaging", Icon: ChatBubbleLeftEllipsisIcon, visibleTo: ["D", "CSR", "A", "S", "DIST", "PFC"], disabled: true },
        
        { path: "/PlantOrders", label: "Rep Orders", Icon: InboxIcon, visibleTo: ["D", "CSR", "PFC"], disabled: false },
        { path: "/PlantCustomers", label: "Rep Customers", Icon: UserPlusIcon, visibleTo: ["D", "CSR", "PFC"], disabled: false },
    ]
    
    
    const renderLink = ({ path, label, Icon, visibleTo, disabled }) => {
        // Check if the current account level has access to this link
        if (!visibleTo.includes(accountLevel)) return null;

        return (
            <Link
                to={disabled ? "#" : path} // Prevent navigation if disabled
                key={label}
                className={`flex items-center p-2 rounded-r-lg hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 hover:border-l-2 hover:border-green-600 mr-auto ml-auto ${
                    currentPath === path
                        ? "bg-green-600/25 border-l-2 border-green-600 bg-opacity-10 shadow-inner shadow-gray-900"
                        : "bg-transparent"
                } ${collapse ? "justify-center" : "w-11/12 mb-1"} ${
                    disabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
            >
                <div className={`w-5 h-5 rounded bg-green-600 drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${
                        collapse ? "" : "mr-2"
                    }`}
                >
                    <Icon className="text-white w-4 flex-none" aria-hidden="true" />
                </div>
                <div className={`text-white text-xs line-clamp-1 ${collapse ? "hidden" : ""}`}>{label}</div>
            </Link>
        );
    };


    return (

        <div className={`flex flex-col justify-between overflow-y-hidden m-3 max-h-[calc(100dvh-4rem)] ${collapse ? 'w-14' : 'w-72 mr-3'}`}>

            <Header/>

            {/*Collapse Button*/}
            <div className="flex flex-row z-50 left-1 mt-1.5 absolute">
                <Button
                    className="justify-center hover:animate-rubberBand items-end text-xl text-white font-bold hover:scale-110 bg-green-600 w-8 h-8 p-0.5 rounded-full"
                    onClick={toggleCollapse}>
                    {collapse ? <ArrowRightCircleIcon/> : <ArrowLeftCircleIcon/>}
                </Button>
            </div>
            
            {/*SideBar*/}
            <div className={`z-40 ${darkMode ? 'bg-darkMainColor border border-neutral-700' : 'bg-bgDarkGreen'} h-full ${collapse ? 'w-14' : 'w-60'} rounded-md shadow-inner shadow-gray-750 max-h-[calc(100dvh-5.5rem)] p-1 transition-all duration-300 ease-in-out`}>

                {/*IMG*/}
                <div className="flex">
                    <div className="flex flex-col items-center justify-between mr-auto ml-auto">
                        <Link to="/Home">
                            {collapse ? <img
                                    alt=""
                                    src={logo}
                                    className="h-14 hover:rotate-2 hover:animate-heartbeat mt-9 w-auto p-0.5"
                                />
                                :
                                <img
                                    alt=""
                                    src={logoFull}
                                    className={`h-14 hover:rotate-2 hover:animate-heartbeat ${darkMode ? '' : 'bg-white'} w-auto mt-6 rounded-lg p-0.5`}
                                />}
                        </Link>

                        <div className="text-xxs text-center text-green-600">{accountLevelMapping[accountLevel] || "- - - - -"}</div>

                    </div>

                </div>

                {/*Links*/}
                <div className="w-full h-full max-h-[calc(100dvh-13rem)] pt-3 overflow-y-scroll overflow-x-hidden hide-scrollbar hover:transform flex flex-col">
                    
                    {/*Main*/}
                    <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-1 mb-3">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <div
                                className={`${darkMode ? 'bg-darkMainColor' : 'bg-bgDarkGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider`}>Main
                            </div>
                        </div>
                        
                        <div className="bg-bgLightGreen w-full rounded-full h-0.5"></div>
                    </div>
                    
                    <>
                        {menuItems.slice(0, 5).map(renderLink)}
                    </>
                    
                    {/*Data*/}
                    {["D", "A", "S", "DIST"].includes(accountLevel) && (
                        <>
                             <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-3 mb-3">
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div
                                        className={`${darkMode ? 'bg-darkMainColor' : 'bg-bgDarkGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider`}>Data
                                    </div>
                                </div>
                                
                                <div className="bg-bgLightGreen w-full rounded-full h-0.5"></div>
                            </div>
                        </>
                    )}
                    
                    <>
                        {menuItems.slice(5, 11).map(renderLink)}
                    </>
                    
                     {/*Plant*/}
                    {["D", "CSR", "PFC"].includes(accountLevel) && (
                        <>
                            <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-3 mb-3">
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div
                                        className={`${darkMode ? 'bg-darkMainColor' : 'bg-bgDarkGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider`}>Plant
                                    </div>
                                </div>
                                
                                <div className="bg-bgLightGreen w-full rounded-full h-0.5"></div>
                            </div>
                        </>
                    )}
                    
                    <>
                        {menuItems.slice(16, 19).map(renderLink)}
                    </>
                    
                    {/*Tools*/}
                    <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-3 mb-3">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <div
                                className={`${darkMode ? 'bg-darkMainColor' : 'bg-bgDarkGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider`}>Tools
                            </div>
                        </div>
                        
                        <div className="bg-bgLightGreen w-full rounded-full h-0.5"></div>
                    </div>
                    
                    <>
                        {menuItems.slice(11, 16).map(renderLink)}
                    </>
                    
                
                </div>
            
            </div>
        
        </div>
    );
};

export default SideBar;

