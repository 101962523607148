import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";
import {
    ArrowDownIcon,
    ArrowPathIcon,
    ArrowUpIcon,
    CheckBadgeIcon,
    NoSymbolIcon,
} from "@heroicons/react/24/outline";
import {Button} from "@headlessui/react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import {ChevronRightIcon} from "@heroicons/react/16/solid";
import logo from "../main-logo.png"

const Performance = () => {

    const { darkMode } = useDarkMode();
    
    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });
    
    const { currentUser } = useAuth();
    
    const [pastOrders, setPastOrders] = useState([]);
    // eslint-disable-next-line
    const [pastCustomers, setPastCustomers] = useState([]);
    
    useEffect(() => {
        
        const fetchNotifications = async () => {
            if (currentUser) {
                try {
                    // Check if notifications data is in localStorage
                    const storedPerformance = localStorage.getItem(`performance_${currentUser.uid}`);

                    if (storedPerformance) {
                        // Parse and set notifications from localStorage
                        const performanceData = JSON.parse(storedPerformance);
                        setPastOrders(performanceData);
                    } else {
                        // Fetch performance from Firestore
                        const performanceRef = collection(db, `Users/${currentUser.uid}/Performance`);
                        const querySnapshot = await getDocs(performanceRef);

                        if (!querySnapshot.empty) {
                            const performanceData = querySnapshot.docs.map((doc) => ({
                                id: doc.id,
                                ...doc.data(),
                            }));

                            // Update state with fetched notifications
                            setPastOrders(performanceData);

                            // Save notifications to localStorage
                            localStorage.setItem(`performance_${currentUser.uid}`, JSON.stringify(performanceData));
                        } else {
                            console.log('No performance data found.');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching performance:', error);
                }
            }
        };
        
        fetchNotifications();
    }, [currentUser]);
    
    // eslint-disable-next-line
    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        try {
            const performanceRef = collection(db, `Users/${currentUser.uid}/Performance`);
            const querySnapshot = await getDocs(performanceRef);

            if (!querySnapshot.empty) {
                const performanceData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Update state with fetched notifications
                setPastOrders(performanceData);

                // Save notifications to localStorage
                localStorage.setItem(`performance_${currentUser.uid}`, JSON.stringify(performanceData));
            } else {
                console.log('No performance data found.');
            }
        } catch (error) {
            console.error("Error fetching performance: ", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) / 1000;
        setUpdating(false);
        if (alertsOn) {
            alert(`Data Updated in ${timeElapsed} secs`);
        }
    };
    
    
    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }
    
    function formatTimestamp(timestamp) {
      const date = new Date(timestamp);
    
      // Extract date components
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
    
      // Extract time components
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const amPm = hours >= 12 ? "PM" : "AM";
    
      // Convert to 12-hour format
      hours = hours % 12 || 12;
    
      // Format into mm/dd/yyyy hh:mm AM/PM
      return `${month}/${day}/${year} ${hours}:${minutes} ${amPm}`;
    }
    
    
    function calculateTimeDifference(timestamp1, timestamp2) {
      // Calculate the absolute difference in milliseconds
      const differenceMs = Math.abs(timestamp1 - timestamp2);
    
      // Calculate days, hours, and minutes
      const days = Math.floor(differenceMs / (24 * 60 * 60 * 1000));
      const hours = Math.floor((differenceMs % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
      const minutes = Math.floor((differenceMs % (60 * 60 * 1000)) / (60 * 1000));
    
      // Build the output string
      let result = "";
      if (days > 0) result += `${days} day${days > 1 ? "s" : ""}, `;
      if (hours > 0 || days > 0) result += `${hours} hour${hours > 1 ? "s" : ""}, `;
      result += `${minutes} minute${minutes > 1 ? "s" : ""}`;
    
      return result;
    }
    
    
    function calculateAverageTimeDifference(dataArray) {
      if (!dataArray || dataArray.length === 0) return "No data provided";
    
      let totalDifferenceMs = 0;
      let validEntriesCount = 0;
    
      // Iterate through the array of objects
      for (const item of dataArray) {
        const { submittedTime, csrTime } = item;
    
        // Ensure both submittedTime and csrTime are numbers
        if (typeof submittedTime === "number" && typeof csrTime === "number") {
          const differenceMs = Math.abs(csrTime - submittedTime);
          totalDifferenceMs += differenceMs;
          validEntriesCount++;
        }
      }
    
      // Handle case where no valid entries exist
      if (validEntriesCount === 0) return "No valid time pairs found";
    
      // Calculate the average in milliseconds
      const averageDifferenceMs = totalDifferenceMs / validEntriesCount;
    
      // Convert to days, hours, and minutes
      const days = Math.floor(averageDifferenceMs / (24 * 60 * 60 * 1000));
      const hours = Math.floor((averageDifferenceMs % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
      const minutes = Math.floor((averageDifferenceMs % (60 * 60 * 1000)) / (60 * 1000));
    
      // Build the average time difference string
      let result = "";
      if (days > 0) result += `${days} d, `;
      if (hours > 0 || days > 0) result += `${hours} h, `;
      result += `${minutes} m`;
    
      return result;
    }
    
    const stats = [
      { name: 'Total Customers', stat: `${pastCustomers.length}`, previousStat: '0', change: '0%', changeType: 'increase' },
      { name: 'Total Orders', stat: `${pastOrders.length}`, previousStat: '0', change: '0%', changeType: 'increase' },
      { name: 'Avg Response Time', stat: `${calculateAverageTimeDifference(pastOrders)}`, previousStat: '0%', change: '0%', changeType: 'increase' },
    ]

    
    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-screen overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>
            
            
            <div className={`w-full z-50 pt-3 pr-3 pb-3`}>
                
                <div className="w-full h-full grid grid-cols-4 grid-rows-6 gap-3">
                    
                    {/* [1] Top Stats*/}
                    <div className={`col-span-4 overflow-y-scroll col-start-1 p-2 ${darkMode ? 'bg-darkMainColor' : 'bg-white'} rounded-lg`}>
                        
                        <div>
                            <dl className="grid grid-cols-1 divide-y divide-gray-500 overflow-hidden md:grid-cols-3 md:divide-x md:divide-y-0">
                                {stats.map((item) => (
                                    <div key={item.name} className="px-4 py-2 sm:p-6">
                                        <dt className="text-base font-normal">{item.name}</dt>
                                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                            <div className="flex items-baseline text-lg font-semibold text-green-600">
                                                {item.stat}
                                                <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span>
                                            </div>
                                            
                                            <div
                                                className={classNames(
                                                    item.changeType === 'increase' ? 'bg-green-600 text-green-800 border-green-600' : 'bg-red-600 text-red-800 border-red-600',
                                                    'bg-opacity-50 inline-flex border-2 items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
                                                )}
                                            >
                                                {item.changeType === 'increase' ? (
                                                    <ArrowUpIcon aria-hidden="true"
                                                                 className="-ml-1 mr-0.5 size-5 shrink-0 self-center text-green-600"/>
                                                ) : (
                                                    <ArrowDownIcon aria-hidden="true"
                                                                   className="-ml-1 mr-0.5 size-5 shrink-0 self-center text-red-600"/>
                                                )}
                                                
                                                <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                                                <span className="text-sm font-medium text-white">{item.change}</span>
                                            </div>
                                        </dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                    
                    <div className={`col-span-2 row-span-2 col-start-1 row-start-2 p-2 ${darkMode ? 'bg-darkMainColor' : 'bg-white'} rounded-lg`}>2</div>
                    
                    {/* [3] Past Orders*/}
                    <div className={`col-span-2 row-span-5 col-start-3 row-start-2 p-2 ${darkMode ? 'bg-darkMainColor' : 'bg-white'} rounded-lg`}>
                        
                        {/*Header*/}
                        <div className="w-full flex flex-row items-center mb-2">
                            <div className={`w-fit mr-auto text-center flex items-center flex-row text-lg tracking-widest`}>
                                <CheckBadgeIcon className={`h-5 mr-3 text-green-600 border-r-2 border-dotted pr-2 ${darkMode ? 'border-borderColor' : ''}`}/> Past Orders
                                
                                
                                {/*Buttons Delete*/}
                                <div className={`flex flex-col items-center ml-1 mt-auto mb-auto`}>
                                    <Button className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle`}
                                        onClick={onUpdate}
                                    >
                                        <ArrowPathIcon className="h-4 text-borderColor m-auto"/>
                                    </Button>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        {/*Body*/}
                        <div className={`w-full h-[94.5%] p-2 overflow-y-scroll relative rounded-lg ${darkMode ? 'border-neutral-700' : ''} border-2`}>
                            
                            {pastOrders.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex w-full h-full justify-center items-center py-10">
                                    
                                    <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-8 py-2 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                        <div className=" rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                            <NoSymbolIcon className={`w-8 stroke-2 mr-3`}/>
                                            <p className="text-md mt-2 line-clamp-1 tracking-widest flex font-semibold">You have no recent orders</p>
                                        </div>
                                    </div>
                                
                                </div>
                            ) : (
                                // Display PFC Update components if sprays array is not empty
                                <div>
                                    {pastOrders.map((order, index) => {
                                        
                                        return (
                                            <div key={index} className={`relative border-b-2 mb-1 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                
                                                <div className={`absolute w-fit`}>
                                                    <div className={`flex-none rounded-full p-1 bg-green-600/30`}>
                                                        <div className={`size-2 rounded-full bg-green-600`}/>
                                                    </div>
                                                </div>
                                                
                                                <div className="px-4 py-2">
                                                    
                                                    <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
                                                        <div className="flex min-w-0 gap-x-4">
                                                            <img
                                                                alt="" src={logo}
                                                                className="size-12 flex-none object-cover rounded-full border border-gray-500"
                                                            />
                                                            
                                                            <div className="min-w-0 flex-auto">
                                                                <p className="text-xs/6 font-semibold">
                                                                    <div>
                                                                        <span className="absolute inset-x-0 -top-px bottom-0"/>
                                                                        CO-{order.orderNum}<span className={`ml-4 text-xs bg-green-600/50 py-0.5 px-2 border border-green-600 rounded-full`}>{order.salesCode}</span>
                                                                    </div>
                                                                </p>
                                                                <p className="mt-1 flex text-xs/5">
                                                                    <div className="relative truncate hover:underline">
                                                                        {calculateTimeDifference(order.submittedTime, order.csrTime)}
                                                                    </div>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex shrink-0 items-center gap-x-4">
                                                            <div className="hidden sm:flex sm:flex-col sm:items-end">
                                                                <p className="text-xs mb-1">Created: <span className={`bg-yellow-600/50 px-2 py-0.5 rounded-full border-yellow-600 border`}>{formatTimestamp(order.submittedTime)}</span></p>
                                                                <p className="text-xs">Submitted: <span className={`bg-blue-600/50 px-2 py-0.5 rounded-full border-blue-600 border`}>{formatTimestamp(order.csrTime)}</span></p>
                                                            </div>
                                                            <a href="/plantOrders">
                                                            <ChevronRightIcon aria-hidden="true" className="size-5 flex-none text-gray-400"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        
                        </div>
                    
                    </div>
                    
                    {/* [4] Past Customers */}
                    <div className={`col-span-2 row-span-3 col-start-1 row-start-4 p-2 ${darkMode ? 'bg-darkMainColor' : 'bg-white'} rounded-lg`}>
                        
                        {/*Header*/}
                        <div className="w-full flex flex-row items-center mb-2">
                            <div className={`w-fit mr-auto text-center flex items-center flex-row text-lg tracking-widest`}>
                                <CheckBadgeIcon className={`h-5 mr-3 text-green-600 border-r-2 border-dotted pr-2 ${darkMode ? 'border-borderColor' : ''}`}/> Past Orders
                                
                                
                                {/*Buttons Delete*/}
                                <div className={`flex flex-col items-center ml-1 mt-auto mb-auto`}>
                                    <Button className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle`}
                                        onClick={onUpdate}
                                    >
                                        <ArrowPathIcon className="h-4 text-borderColor m-auto"/>
                                    </Button>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        {/*Body*/}
                        <div className={`w-full h-[91%] p-2 overflow-y-scroll relative rounded-lg ${darkMode ? 'border-neutral-700' : ''} border-2`}>
                            
                            {pastCustomers.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex w-full h-full justify-center items-center py-10">
                                    
                                    <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-8 py-2 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                        <div className=" rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                            <NoSymbolIcon className={`w-8 stroke-2 mr-3`}/>
                                            <p className="text-md mt-2 line-clamp-1 tracking-widest flex font-semibold">You have no recent customers</p>
                                        </div>
                                    </div>
                                
                                </div>
                            ) : (
                                // Display PFC Update components if sprays array is not empty
                                <div>
                                    {pastCustomers.map((order, index) => {
                                        
                                        return (
                                            <div key={index} className={`relative border-b-2 mb-1 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                
                                                <div className={`absolute w-fit`}>
                                                    <div className={`flex-none rounded-full p-1 bg-green-600/30`}>
                                                        <div className={`size-2 rounded-full bg-green-600`}/>
                                                    </div>
                                                </div>
                                                
                                                <div className="px-4 py-2">
                                                    
                                                    <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
                                                        <div className="flex min-w-0 gap-x-4">
                                                            <img
                                                                alt="" src={logo}
                                                                className="size-12 flex-none object-cover rounded-full border border-gray-500"
                                                            />
                                                            
                                                            <div className="min-w-0 flex-auto">
                                                                <p className="text-xs/6 font-semibold">
                                                                    <div>
                                                                        <span className="absolute inset-x-0 -top-px bottom-0"/>
                                                                        {order.orderNum} | [{order.salesCode}]
                                                                    </div>
                                                                </p>
                                                                <p className="mt-1 flex text-xs/5">
                                                                    <div className="relative truncate hover:underline">
                                                                        {calculateTimeDifference(order.submittedTime, order.csrTime)}
                                                                    </div>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex shrink-0 items-center gap-x-4">
                                                            <div className="hidden sm:flex sm:flex-col sm:items-end">
                                                                <p className="text-xs mb-1">Created: {formatTimestamp(order.submittedTime)}</p>
                                                                <p className="text-xs">Submitted: {formatTimestamp(order.csrTime)}</p>
                                                            </div>
                                                            <a href="/plantOrders">
                                                            <ChevronRightIcon aria-hidden="true" className="size-5 flex-none text-gray-400"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        
                        </div>
                    
                        
                    </div>
                
                </div>
            
            </div>
        
        
        </div>
    )
};


export default Performance;
