import ExcelJS from "exceljs";
import { saveAs } from 'file-saver'
import React from "react";

// Formats number to appear with commas
export const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


// This Exports order data to an Excel spreadsheet
export const downloadExcel = async (ordersArray, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(fileName.replace(".xlsx", ""));

    // Define columns with custom widths
    worksheet.columns = [
        { header: "Code", key: "salesCode", width: 5, bold: true },
        { header: "Sales Rep", key: "salesRep", width: 20 },
        { header: "Sales Rep Email", key: "salesEmail", width: 30 },
        { header: "Customer Code", key: "customerCode", width: 15 },
        { header: "Customer", key: "customer", width: 25 },
        { header: "Order Number", key: "orderNum", width: 10 },
        { header: "Status", key: "status", width: 12 },
        { header: "Order Date", key: "orderDate", width: 15 },
        { header: "SubTotal", key: "subTotal", width: 12 },
        { header: "Discount Rate", key: "discount", width: 12 },
        { header: "Discount Total", key: "discountTotal", width: 12 },
        { header: "GrandTotal", key: "grandTotal", width: 12 },
    ];

    // Add headers (make them bold)
    worksheet.addRow(worksheet.columns.map(col => col.header)).font = { bold: true };

    ordersArray.forEach(order => {
        // Main order row (with grey background)
        const orderRow = worksheet.addRow([
            order.salesCode, order.salesRep, order.salesEmail, order.customerCode,
            order.customerName, order.orderNum || "NA", order.status, order.orderDate,
            order.subTotal || 0, order.discount || 0, order.discountTotal || 0, order.grandTotal || 0
        ]);

        // ✅ Apply light grey background to the **entire** order row
        orderRow.eachCell((cell) => {
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "D3D3D3" } // Light grey
            };
        });

        // Add products from subarray, keeping alignment
        if (order.products && order.products.length > 0) {
            order.products.forEach(product => {
                worksheet.addRow([
                    "", // Skip first column (Sales Code)
                    "", // Skip second column (Sales Rep)
                    product.name,
                    product.price,
                    product.discountAmount || 0,
                    product.quantity,
                    product.selectedVolume,
                    product.warehouse,
                    product.total,
                    product.reasonCode || "----",
                    product.productCode,
                ]);
            });
        }
    });

    // Generate the file as a Blob and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const excelBlob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelBlob, fileName);
};


// This Formats a phone number (---) --- - ----
export const formatPhoneNumber = (phoneNumber) => {
    // Remove any non-digit characters (just in case)
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Check if the input is valid (10 digits)
    if (cleaned.length !== 10) {
        return phoneNumber; // Return the original input if invalid
    }

    // Format the phone number (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber; // Return the original input if not formatted
};


// Formats Time in just hh:mm
export const formatTime = (timestamp) => {
  if (!timestamp) return ""; // Return an empty string for null or undefined values
  // Convert the timestamp to a Date object
  const date = new Date(timestamp);

  // Get hours, minutes, and period (AM/PM)
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Format minutes to always be 2 digits
  const formattedMinutes = minutes.toString().padStart(2, "0");

  // Return the formatted time string
  return `${hours}:${formattedMinutes} ${period}`;
};


// Formats an address with lineBreaks
export const formatAddressWithLineBreaks = (address) => {
    if (!address || address === "---") return "---";
    return address.split(",").map((part, index) => (
        <React.Fragment key={index}>
            {part.trim()}
            <br />
        </React.Fragment>
    ));
};


// Formats a Time stamp in this fashion mm dd yyyy hh:mm am
export function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Define month names
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    
    // Get the month abbreviation
    const month = months[date.getMonth()];

    // Get the day with ordinal suffix
    const day = date.getDate();
    const suffix = (day % 10 === 1 && day !== 11) ? "st" :
                   (day % 10 === 2 && day !== 12) ? "nd" :
                   (day % 10 === 3 && day !== 13) ? "rd" : "th";

    // Get the full year
    const year = date.getFullYear();

    // Format time in 12-hour format
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour format

    // Combine formatted date and time
    return `${month} ${day}${suffix} ${year}, ${hours}:${minutes} ${amPm}`;
}


// Function to download chart as an image
export const download = (chartRef) => {
        if (chartRef.current) {
            chartRef.current.download();
        }
    };


// Function to download chart at fixed size
export const downloadFixedSize = (chartRef) => {
        if (chartRef.current) {
            chartRef.current.download({ width: 600, height: 300 });
        }
    };


// Function to open chart as an image in new tab
export const openImage = (chartRef) => {
        if (chartRef.current) {
            chartRef.current.getImageDataURL({ width: 600, height: 300 }).then((imageDataURL) => {
                const image = new Image();
                image.src = imageDataURL;
                const tab = window.open();
                if (tab) {
                    tab.document.write(image.outerHTML);
                    tab.document.close();
                }
            });
        }
    };


// Function that extracts number from a string
export const extractNumbers = (str) => {
    return str.replace(/\D/g, ''); // \D matches any non-digit character
};


// All of the UM mapping
export const UMMapping = {
    ACR: "Acre",
    B12: "BAG 12#",
    B25: "BAG 25#",
    B30: "BAG 30#",
    B40: "BAG 40#",
    B5: "BAG 5#",
    B50: "BAG 50#",
    B51: "BAG 51#",
    BAG: "BAG (Unspecified Weight)",
    "2.5": "CASE (2x2.5)",
    CS4: "CASE (4X1)",
    CAS: "CASE",
    "30": "DRUM 30-GAL",
    "55": "DRUM 55-GAL",
    DRU: "DRUM (Unspecified Volume)",
    EAC: "EACH",
    GAL: "GALLON",
    TR: "TOTE REFILL",
    LB: "POUND",
    OZ: "Ounce - Av",
    PAI: "PAIL 5-GAL",
    SAC: "Super Sack (B1000)",
    SAC2: "Super Sack (B2000)",
    TON: "TON (2,000#)",
    "275": "TOTE 275-GAL",
};


// Category Colors
export const categoryColors = [
    { category: 'Biostimulants', color: 'bg-bsColor', shortened: 'BS' },
    { category: 'Liquid Fertilizer', color: 'bg-lfColor', shortened: 'LF' },
    { category: 'Dry Fertilizer', color: 'bg-teal-700', shortened: 'DF' },
    { category: 'Secondary Nutrients', color: 'bg-snColor', shortened: 'SN' },
    { category: 'Micronutrients', color: 'bg-mnColor', shortened: 'MN' },
    { category: 'Penetrants & Water Holding Agents', color: 'bg-whColor', shortened: 'PHA' },
    { category: 'A-Plus® Solubilizing Surfactants', color: 'bg-apColor', shortened: 'AP' },
    { category: 'Turfgrass Pigment', color: 'bg-tpColor', shortened: 'TP' },
    { category: 'Water Treatments', color: 'bg-wtColor', shortened: 'WT' },
    { category: 'Soil Amendments', color: 'bg-saColor', shortened: 'SA' },
    { category: 'Fungicides', color: 'bg-fgColor', shortened: 'FG' },
    { category: 'Equipment', color: 'bg-gray-600', shortened: 'EQ' },
    { category: 'Test', color: 'bg-emerald-500', shortened: 'TE' },
    { category: 'Seed', color: 'bg-green-600', shortened: 'SE' },
];


// Function to get colors
export const getCategoryInfo = (category) => {
    const categoryInfo = categoryColors.find(c => c.category === category);
    return categoryInfo ? { color: categoryInfo.color, shortened: categoryInfo.shortened } : { color: '', shortened: '' };
};


// Pricing Levels
export const pricingMapping = {
    AG: "Agriculture",
    DIST1: "Distributor NE/INTL",
    DIST2: "Distributor SE/CAN",
    NE: "North East",
    SE: "South East"
};


// Supervisor Levels
export const supervisorMapping = {
    GP: "Grant Platz",
    JN: "Jordan Nemitz",
    TW: "Tom Weinert",
    TP: "Ted Platz",

}


// Account Levels
export const accountLevelMapping = {
    D: "Developer",
    A: "Admin",
    CSR: "CSR",
    PFC: "PFC Fulfillment",
    DIST: "Distributor",
    S: "Sales Rep",
    M: "Marketing"
}


// State code mapping
export const stateMapping = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming"
};


// ADL Products list
export const ADLProducts = {
    SNCA10SUGARCALC: "ADL",
    BSIMPULSEC: "ADL",
    BSADAMSC: "ADL",
    LF000027C: "ADL",
    PWHYDRATIONAPC: "ADL",
    PWFLOTHRUAPC: "ADL",
    LF082705C: "ADL",
    MNFE66IRONC: "ADL",
    BSHUMIC19C: "ADL",
    BSKELP1C: "ADL",
    MNMN7PHUSIONC: "ADL",
    LF29000050MC: "ADL",
    LF070007MC: "ADL",
    SNCA9S4LIQUAGYPC: "ADL",
    MNFE4KELPIRONC: "ADL",
    SNCA1230CALC: "ADL",
    LF000029C: "ADL",
    MNMICROPACKC: "ADL",
    LF022022DKPC: "ADL",
    PWHYDRATIONACC: "ADL",
    MNSI21SILIKC: "ADL",
    SNCA11CALNITRATEC: "ADL",
    BSMOLASSESC4: "ADL",
    MNFE35HA3C: "ADL",
};



// Mapping of industry values to their display names
export const industryMapping = {
    BOTANICAL: "Botanical Garden",
    DISTRIB: "Product Distributor",
    EMPLOYEE: "PFC-EMPLOYEE",
    FARM: "Agriculture - Farm",
    GOLF: "Golf Course",
    GOVERNMENT: "Government Entity",
    INDUSTRIAL: "Industrial",
    LAWNTREE: "Lawn Care & Tree Care",
    MRSALE: "One Time Purchase Expected",
    MUNICIPAL: "Municipal/ School",
    "Non-Profit": "Non-Profit Account",
    OTHER: "Other",
    SPORTFLD: "Sportsfield"
};

export const salesCodeMapping = {
    CF: "Phillip String",
    CN: "Jason Dowgiewicz",
    DH: "Direct House Account",
    DS: "Gregory Moore",
    FL: "James Rattigan",
    FN: "Christopher Allen",
    FS: "Jordan Nemitz",
    FW: "Cory Agar",
    GC: "Tom Weinert",
    GP: "Grant Platz",
    JC: "Tom Pepe",
    JK: "Jeffery Knapp",
    JM: "Joseph McKenney",
    MF: "Michael Giovanelli",
    NJ: "Richard Neufeld",
    PG: "Michael Janzer",
    RS: "Richard Sweeney",
    SJ: "David St. John",
    WI: "David Conrad",
    MW: "Michael Weinert",
    TP: "Ted Platz",
    SW: "Spencer Wondolowski",
};