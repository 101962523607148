import React, {useEffect, useRef, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";

import { AgCharts } from "ag-charts-react";
import {useAuth} from "../contexts/authContext";
import {
    ArrowDownOnSquareIcon, ArrowDownOnSquareStackIcon,
    ArrowTopRightOnSquareIcon,
    ArrowTrendingDownIcon,
    ArrowTrendingUpIcon, ClipboardDocumentListIcon, CurrencyDollarIcon
} from "@heroicons/react/24/outline";
import {Button, Popover, PopoverButton, PopoverPanel, Switch} from "@headlessui/react";
import {EllipsisVerticalIcon} from "@heroicons/react/16/solid";
import {numberWithCommas, downloadFixedSize, download, openImage} from "./GlobalFunctions";
import DataCard from "./Dashboard/DataCard";


const Dashboard = () => {

    const { darkMode } = useDarkMode();
    const { currentUser } = useAuth();

    const [ordersPending, setOrdersPending] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    
    const [ordersPlanned, setOrdersPlanned] = useState([]);
    const [chartDataPlanned, setChartDataPlanned] = useState([]);
    
    const [ordersActive, setOrdersActive] = useState([]);
    const [chartDataActive, setChartDataActive] = useState([]);
    const [productsDataActive, setProductsDataActive] = useState([]);
    
    const chartRef = useRef(null);
    const chartRefPendingBar = useRef(null);
    const chartRefActiveBar = useRef(null);
    const chartRefActiveDonut = useRef(null);
    
    const [textColor, setTextColor] = useState("#21b372");
    const [lineColor, setLineColor] = useState("#FDDE02");
    const [barColor, setBarColor] = useState("#3e3e42");
    const [bgColor, setBgColor] = useState("#1e1e1e");
    const [labelColor, setLabelColor] = useState("#ffffff");


    useEffect(() => {

        const fetchOrdersActive = async () => {
            if (currentUser) {
                try {
                    const storedActiveData = localStorage.getItem(`activeOrders_${currentUser.uid}`);
                    let activeData = [];

                    if (storedActiveData) {
                        // If data is available in localStorage
                        activeData = JSON.parse(storedActiveData);
                    } else {
                        console.log("No Data Stored")
                    }

                    // Format data for the chart: Extract 'customerCode' and 'grandTotal'
                    const chartData = activeData.map((order, index) => ({
                        index: `Order [${index + 1}]`,
                        customerCode: order.customerCode || 'Unknown',  // Fallback if customerCode is missing
                        grandTotal: parseFloat(order.grandTotal) || 0,  // Fallback if grandTotal is missing
                    }));

                    // Set the chart data
                    setChartDataActive(chartData);

                    // Set pending orders data
                    setOrdersActive(activeData);
                    
                    
                    
                    
                    
                    
                    const productCounts = activeData
                        .flatMap(order => order.products) // Flatten all product arrays into one
                        .reduce((acc, product) => {
                            if (acc[product.name]) {
                                acc[product.name].count += 1; // Increment count if the product exists
                                acc[product.name].totalPrice += parseFloat(product.total); // Sum up total price
                                
                                // Increment UM count
                                if (acc[product.name].umCounts[product.selectedVolume]) {
                                    acc[product.name].umCounts[product.selectedVolume] += 1;
                                } else {
                                    acc[product.name].umCounts[product.selectedVolume] = 1;
                                }
                            } else {
                                acc[product.name] = {
                                    count: 1,
                                    totalPrice: parseFloat(product.total),
                                    umCounts: { [product.selectedVolume]: 1 } // Initialize UM count
                                };
                            }
                            return acc;
                        }, {});
                    
                    // Convert the object into an array formatted for the chart
                    const productData = Object.entries(productCounts).map(([name, { count, totalPrice, umCounts }]) => ({
                        name,
                        value: count,
                        totalPrice: parseFloat(totalPrice.toFixed(2)), // Round to 2 decimal places
                        umCounts: Object.entries(umCounts).map(([um, count]) => ({ um, count })) // Convert UM counts to an array
                    }));
                    
                    console.log(productData);
                    setProductsDataActive(productData);
                    
                    
                    
                    
                    
                    

                    console.log(chartData);

                } catch (error) {
                    console.error('Error fetching active orders data:', error);
                }
            }
        };

        const fetchOrdersPending = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`pendingOrders_${currentUser.uid}`);
                    let pendingData = [];

                    if (storedPendingData) {
                        // If data is available in localStorage
                        pendingData = JSON.parse(storedPendingData);
                    } else {
                       console.log("No Data Stored");
                    }

                    // Format data for the chart: Extract 'customerCode' and 'grandTotal'
                    const chartData = pendingData.map((order, index) => ({
                        index: `Order [${index + 1}]`,
                        customerCode: order.customerCode || 'Unknown',  // Fallback if customerCode is missing
                        grandTotal: parseFloat(order.grandTotal) || 0,  // Fallback if grandTotal is missing
                    }));

                    // Set the chart data
                    setChartData(chartData);

                    // Set pending orders data
                    setOrdersPending(pendingData);
                    
                    
                    
                    
                    const productCounts = pendingData
                        .flatMap(order => order.products) // Flatten all product arrays into one
                        .reduce((acc, product) => {
                            if (acc[product.name]) {
                                acc[product.name].count += 1; // Increment count if the product exists
                                acc[product.name].totalPrice += parseFloat(product.total); // Sum up total price
                                
                                // Increment UM count
                                if (acc[product.name].umCounts[product.selectedVolume]) {
                                    acc[product.name].umCounts[product.selectedVolume] += 1;
                                } else {
                                    acc[product.name].umCounts[product.selectedVolume] = 1;
                                }
                            } else {
                                acc[product.name] = {
                                    count: 1,
                                    totalPrice: parseFloat(product.total),
                                    umCounts: { [product.selectedVolume]: 1 } // Initialize UM count
                                };
                            }
                            return acc;
                        }, {});
                    
                    // Convert the object into an array formatted for the chart
                    const productData = Object.entries(productCounts).map(([name, { count, totalPrice, umCounts }]) => ({
                        name,
                        value: count,
                        totalPrice: parseFloat(totalPrice.toFixed(2)), // Round to 2 decimal places
                        umCounts: Object.entries(umCounts).map(([um, count]) => ({ um, count })) // Convert UM counts to an array
                    }));

                    
                    console.log(productData);
                    setProductsData(productData);
                    
                    
                    

                    console.log(chartData);

                } catch (error) {
                    console.error('Error fetching pending orders data:', error);
                }
            }
        };

        const fetchOrdersPlanned = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`plannedOrders_${currentUser.uid}`);
                    let pendingData = [];

                    if (storedPendingData) {
                        // If data is available in localStorage
                        pendingData = JSON.parse(storedPendingData);
                    } else {
                        console.log("No Data Stored");
                    }

                    // Format data for the chart: Extract 'customerCode' and 'grandTotal'
                    const chartData = pendingData.map((order, index) => ({
                        index: `Order [${index + 1}]`,
                        customerCode: order.customerCode || 'Unknown',  // Fallback if customerCode is missing
                        grandTotal: order.grandTotal || 0,  // Fallback if grandTotal is missing
                    }));

                    // Set the chart data
                    setChartDataPlanned(chartData);

                    // Set pending orders data
                    setOrdersPlanned(pendingData);

                    console.log(chartData);

                } catch (error) {
                    console.error('Error fetching planned orders data:', error);
                }
            }
        };

        fetchOrdersActive();
        fetchOrdersPending();
        fetchOrdersPlanned();

    }, [currentUser]);

    // Function to change colors based on darkMode state
    useEffect(() => {
        if (darkMode) {
            setTextColor("#21b372"); // Light text color for dark mode
            setBarColor("#292929");
            setLineColor("#FDDE02");
            setBgColor("#1e1e1e");   // Dark background color for dark mode
            setLabelColor("#ffffff");

        } else {
            setTextColor("#000000"); // Dark text color for light mode
            setBarColor("#efefef");
            setLineColor("#21b372");
            setBgColor("#ffffff");   // Light background color for light mode
            setLabelColor("#000000");
        }
    }, [darkMode]); // Effect will run every time darkMode changes

    const myTheme = {
        palette: {
            fills: [barColor, "#21B372", "#FDDE02", "#F76700", "#D30018"],
        },
        overrides: {
            common: {
                title: {
                    fontSize: 18,
                    color: textColor,
                },
                subtitle: {
                    fontSize: 10,
                },
                axes: {
                    category: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for category axis
                        },
                    },
                    number: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for number axis
                        },
                    },
                },
            },
            bar: {
                series: {
                    // label: {
                    //     enabled: true,
                    //     color: textColor,
                    // },
                    cornerRadius: 10,
                },
            },
            line: {
                series: {
                    label: {
                        enabled: true,
                        color: lineColor,
                    },
                    stroke: lineColor,
                    circle: lineColor,
                    strokeWidth: 2,
                },
            },

        },
    };
    
    // Chart Options: Control & configure the chart

    const [orderOptions, setOrderOptions] = useState({
        // theme: myTheme,
        title: { text: 'Pending Orders' },
        subtitle: { text: 'Data from 2024' },
        data: [],
        series: [
            {
                type: 'bar',
                xKey: 'index',
                yKey: 'grandTotal',
                yName: 'Grand Total',
            },
            {
                type: 'line',
                xKey: 'index',
                yKey: 'grandTotal',
                yName: 'Grand Total',
                label: {
                    formatter: (params) => {
                        return "$ " + numberWithCommas(params.value.toLocaleString());
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: {
                    // rotation: 45,
                },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    formatter: (params) => {
                        return '$' + params.value.toLocaleString();
                    },
                },
            },
        ],
        legend: {
            position: 'bottom',
        },
    });
    
    const [activeOptions, setActiveOptions] = useState({
        // theme: myTheme,
        title: { text: 'Active Orders' },
        subtitle: { text: 'Data from 2024' },
        data: [],
        series: [
            {
                type: 'bar',
                xKey: 'index',
                yKey: 'grandTotal',
                yName: 'Grand Total',
            },
            {
                type: 'line',
                xKey: 'index',
                yKey: 'grandTotal',
                yName: 'Grand Total',
                label: {
                    formatter: (params) => {
                        return "$ " + numberWithCommas(params.value.toLocaleString());
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: {
                    // rotation: 45,
                },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    formatter: (params) => {
                        return '$' + params.value.toLocaleString();
                    },
                },
            },
        ],
        legend: {
            position: 'bottom',
        },
    });


    // Update chart options whenever chartData is updated
    // Bar Charts
    useEffect(() => {
        setActiveOptions((prevOptions) => ({
            ...prevOptions,
            data: chartDataActive,
            theme: myTheme,
            background: {
                fill: bgColor, // Update background color dynamically
            },
        }));

        setOrderOptions((prevOptions) => ({
            ...prevOptions,
            data: chartData,
            theme: myTheme,
            background: {
                fill: bgColor, // Update background color dynamically
            },
        }));
        // eslint-disable-next-line
        }, [chartData, chartDataPlanned, chartDataActive, darkMode, bgColor, textColor]);

    

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const productsTheme = {
        overrides: {
            common: {
                title: {
                    fontSize: 18,
                    color: textColor,
                },
                subtitle: {
                    fontSize: 10,
                },
                axes: {
                    category: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for category axis
                        },
                    },
                    number: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for number axis
                        },
                    },
                },
            },

            donut: {
                series: {
                    innerLabels:{
                        color: labelColor,
                    },
                    cornerRadius: 5,
                    calloutLabel: {
                        color: labelColor,
                    },
                },
                legend: {
                    item:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            shape: "circle",
                        }
                    },
                    pagination:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            color: labelColor,
                        }
                    },
                },
            },


        },
    };
    
    
    const [pendingToggle, setPendingToggle] = useState(false);
    const [productOptions, setProductOptions] = useState({})
    
    const [activeToggle, setActiveToggle] = useState(false);
    const [productOptionsActive, setProductsOptionsActive] = useState({});

    // Function to update chart options
    const updateActiveChartOptions = () => {
        setProductsOptionsActive({
            container: document.getElementById("myChart"),
            data: [],
            title: {
                text: "Active Product Tracking",
            },
            subtitle: {
                text: activeToggle ? "Sorted by total price" : "Sorted by number of occurrences",
            },
            series: [
                {
                    type: "donut",
                    calloutLabelKey: activeToggle ? "totalPrice" : "value",
                    angleKey: activeToggle ? "totalPrice" : "value",
                    sectorLabelKey: "name",
                    innerRadius: 4,
                    calloutLabel: {
                        enabled: false,
                    },
                    sectorLabel: {
                        formatter: ({ datum, sectorLabelKey }) => {
                            const value = datum[sectorLabelKey];
                            return numberWithCommas(value);
                        },
                    },
                    innerRadiusRatio: 0.8,
                    innerLabels: [
                        {
                            text: "Total:",
                            fontSize: 16,
                            spacing: 10,
                        },
                        {
                            text: `0`,
                            fontSize: 14,
                        },
                    ],
                    tooltip: {
                        renderer: ({ datum, calloutLabelKey, title, sectorLabelKey }) => {
                            return {
                                title,
                                content: `${numberWithCommas(datum[sectorLabelKey])}: ${numberWithCommas(datum[calloutLabelKey])} ${activeToggle ? "USD" : "Occurrences"}`,
                            };
                        },
                    },
                    sectorSpacing: 3,
                },
            ],
        });
    };
    
    // Update the chart whenever `useTotalPrice` changes
    useEffect(() => {
        updateActiveChartOptions();
         // eslint-disable-next-line
    }, [activeToggle]);
    
    
    // Function to update chart options
    const updatePendingChartOptions = () => {
        setProductOptions({
            container: document.getElementById("myChart"),
            data: [],
            title: {
                text: "Pending Product Tracking",
            },
            subtitle: {
                text: pendingToggle ? "Sorted by total price" : "Sorted by number of occurrences",
            },
            series: [
                {
                    type: "donut",
                    calloutLabelKey: pendingToggle ? "totalPrice" : "value",
                    angleKey: pendingToggle ? "totalPrice" : "value",
                    sectorLabelKey: "name",
                    innerRadius: 4,
                    calloutLabel: {
                        enabled: false,
                    },
                    sectorLabel: {
                        formatter: ({ datum, sectorLabelKey }) => {
                            const value = datum[sectorLabelKey];
                            return numberWithCommas(value);
                        },
                    },
                    innerRadiusRatio: 0.8,
                    innerLabels: [
                        {
                            text: "Total:",
                            fontSize: 16,
                            spacing: 10,
                        },
                        {
                            text: `0`,
                            fontSize: 14,
                        },
                    ],
                    tooltip: {
                        renderer: ({ datum, calloutLabelKey, title, sectorLabelKey }) => {
                            return {
                                title,
                                content: `${numberWithCommas(datum[sectorLabelKey])}: ${numberWithCommas(datum[calloutLabelKey])} ${pendingToggle ? "USD" : "Occurrences"}`,
                            };
                        },
                    },
                    sectorSpacing: 3,
                },
            ],
        });
    };
    
    // Update the chart whenever `useTotalPrice` changes
    useEffect(() => {
        updatePendingChartOptions();
         // eslint-disable-next-line
    }, [pendingToggle]);
    

    // Update chart options when productsData changes
    // Donuts Charts
    useEffect(() => {
        setProductOptions((prevOptions) => ({
            ...prevOptions,
            theme: productsTheme,
            data: productsData,
            background: {
                fill: bgColor, // Update background color dynamically
            },
            series: prevOptions.series.map(series => ({
                ...series,
                innerLabels: [
                    {
                        text: "Total:",
                        fontSize: 16,
                        spacing: 10,
                    },
                    {
                        text: `${productsData.length} Products`, // Update inner label with totalSum
                        fontSize: 14,
                    },
                ],
            })),
        }));
        
        setProductsOptionsActive((prevOptionsActive) => ({
            ...prevOptionsActive,
            theme: productsTheme,
            data: productsDataActive,
            background: {
                fill: bgColor, // Update background color dynamically
            },
            series: prevOptionsActive.series.map(series => ({
                ...series,
                innerLabels: [
                    {
                        text: "Total:",
                        fontSize: 16,
                        spacing: 10,
                    },
                    {
                        text: `${productsDataActive.length} Products`, // Update inner label with totalSum
                        fontSize: 14,
                    },
                ],
            })),
        }));
        // eslint-disable-next-line
    }, [productsData, activeToggle, pendingToggle, darkMode, bgColor, productsDataActive]);
    
    
    // Function to calculate the total grand total from pending orders
    const calculateTotalGrandTotal = (orders) => {
        return orders.reduce((total, order) => {
            return (total + (order.grandTotal || 0)); // Add 0 for undefined grandTotal
        }, 0);
    };

    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-[calc(100dvh-4rem)] w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>
            
            <div className="z-50 animate-fadeIn flex flex-col w-full max-w-[calc(100%-5.8rem)] mt-3 mr-3">
                
                
                {/*Header*/}
                <div className="w-full h-fit flex mr-3 text-sm">
                    
                    <div className={`w-1/3 mr-3 p-3 rounded-lg drop-shadow flex flex-row items-center justify-center border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                        <ArrowTrendingUpIcon className={`hover:animate-pulse h-12 ${darkMode ? 'bg-orderColor text-black' : 'bg-orderColor text-black'} p-3 rounded-lg`}/>
                        <div className={`ml-5 line-clamp-1 ${darkMode ? 'text-green-400' : 'text-black'} rounded-lg tracking-widest font-semibold`}>Active</div>
                        <div className={`ml-auto text-xs bg-orderColor/30 border border-orderColor px-1 rounded-md mr-2`}>{ordersActive.length}</div>
                        <div className={`line-clamp-1 text-xs ${darkMode ? 'bg-darkBgColor bg-opacity-80 text-white' : 'bg-bgColor text-black'} px-4 py-1 rounded-lg tracking-wider`}>$ {numberWithCommas(calculateTotalGrandTotal(ordersActive).toFixed(2)) || "- - - - - -"} USD</div>
                    </div>
                    
                    <div className={`w-1/3 mr-3 p-3 rounded-lg drop-shadow flex flex-row items-center border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                        <ArrowTrendingDownIcon className={`h-12 ${darkMode ? 'bg-pendingColor text-black' : 'bg-pendingColor text-black'} p-3 rounded-lg`}/>
                        <div className={`ml-5 line-clamp-1 ${darkMode ? 'text-green-400' : 'text-black'} rounded-lg tracking-widest font-semibold`}>Pending</div>
                        <div className={`ml-auto text-xs bg-pendingColor/30 border border-pendingColor px-1 rounded-md mr-2`}>{ordersPending.length}</div>
                        <div className={`line-clamp-1 text-xs ${darkMode ? 'bg-darkBgColor bg-opacity-80 text-white' : 'bg-bgColor text-black'} px-4 py-1 rounded-lg tracking-wider`}>$ {numberWithCommas(calculateTotalGrandTotal(ordersPending).toFixed(2)) || "- - - - - -"} USD</div>
                    </div>
                    
                    <div className={`w-1/3 p-3 rounded-lg drop-shadow flex flex-row items-center border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                        <ArrowTrendingUpIcon className={`h-12 ${darkMode ? 'bg-plannedColor text-black' : 'bg-plannedColor text-black'} p-3 rounded-lg`}/>
                        <div className={`ml-5 line-clamp-1 ${darkMode ? ' text-green-400' : 'text-black'} rounded-lg tracking-widest font-semibold`}>Planned</div>
                        <div className={`ml-auto text-xs bg-plannedColor/30 border border-plannedColor px-1 rounded-md mr-2`}>{ordersPlanned.length}</div>
                        <div className={`line-clamp-1 text-xs ${darkMode ? 'bg-darkBgColor bg-opacity-80 text-white' : 'bg-bgColor text-black'} px-4 py-1 rounded-lg tracking-wider`}>$ {numberWithCommas(calculateTotalGrandTotal(ordersPlanned).toFixed(2)) || "- - - - - -"} USD</div>
                    </div>
                
                </div>
                
                
                
                {/*Grid Body*/}
                <div className={`w-full mt-3 mr-3 rounded-lg h-full max-h-[calc(100dvh-10.75rem)] overflow-y-scroll scrollbar-hide`}>
                    
                    
                    <div className="h-[100vh] grid grid-cols-5 grid-rows-2 gap-3">
                        
                        {/*Top Left Active! Pending Donut*/}
                        <div className={`col-span-2 w-full col-start-1`}>
                            
                            <div className={`grid grid-cols-3 h-full overflow-hidden gap-x-1.5`}>
                                
                                <div className={`col-span-1 p-1 space-y-2 overflow-y-scroll scrollbar-hide rounded-lg border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                                
                                    {Array.isArray(productsDataActive) && (
                                         productsDataActive.map((data, index) => (
                                            <DataCard
                                                index={index}
                                                data={data}
                                            />
                                         ))
                                    )}
                                
                                </div>
                                
                                <div className={`col-span-2 h-full rounded-lg border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                                    
                                    <div className={`flex items-center`}>
                                        <Switch
                                            checked={activeToggle}
                                            onChange={setActiveToggle}
                                            className="group relative inline-flex ml-2 items-center h-6 w-11 shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out"
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                aria-hidden="true"
                                                className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                            />
                                            <span className={`pointer-events-none relative inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-4`}>
                                            
                                                {/*false*/}
                                                <span
                                                    aria-hidden="true"
                                                    className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                >
                                                <ClipboardDocumentListIcon className={`text-green-600 size-3.5 stroke-2`}/>
                                            </span>
                                                
                                                {/*true*/}
                                                <span
                                                    aria-hidden="true"
                                                    className="absolute inset-0 right-1 flex size-full items-center justify-center text-green-600 opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                >
                                                <CurrencyDollarIcon className={`text-green-600 size-3.5 stroke-2`}/>
                                            </span>
                                            
                                            </span>
                                        
                                        </Switch>
                                        
                                        <Popover className="relative w-full flex justify-end">
                                            <PopoverButton className={`inline-flex items-center gap-x-1 text-sm/6 font-semibold rounded-full m-1 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'}`}>
                                                <EllipsisVerticalIcon aria-hidden="true" className={`size-5 m-1`} />
                                            </PopoverButton>
                                    
                                            <PopoverPanel
                                                transition
                                                className="absolute right-0 z-50 mt-9 flex w-screen max-w-min px-2 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                                            >
                                                <div className={`w-56 shrink rounded-xl border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-bgColor'} p-1 text-sm/6 font-semibold shadow-lg ring-1 ring-gray-900/5`}>
                                                    
                                                    <div className="p-3 w-full grid grid-cols-1 gap-3">
                                                        <Button
                                                            className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                            onClick={() => download(chartRefActiveDonut)}>
                                                            <ArrowDownOnSquareIcon className={`w-4 mr-auto`}/>
                                                            Download IMG
                                                        </Button>
                                                        <Button
                                                            className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                                onClick={() => downloadFixedSize(chartRefActiveDonut)}>
                                                            <ArrowDownOnSquareStackIcon className={`w-4 mr-auto`}/>
                                                            Smaller Size
                                                        </Button>
                                                        <Button
                                                            className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                            onClick={() => openImage(chartRefActiveDonut)}>
                                                            <ArrowTopRightOnSquareIcon className={`w-4 mr-auto`}/>
                                                            New Window
                                                        </Button>
                                                    </div>
                                                    
                                                </div>
                                            </PopoverPanel>
                                        </Popover>
                                    </div>
                            
                                    
                                    <div style={{display: 'grid', width: '100%', height: '90%'}}>
                                        <AgCharts ref={chartRefActiveDonut} options={productOptionsActive}/>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        {/*Top Right Active! Bar*/}
                        <div className={`col-span-3 col-start-3 rounded-lg border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                            
                            <Popover className="relative w-full flex justify-end">
                                <PopoverButton className={`inline-flex items-center gap-x-1 text-sm/6 font-semibold rounded-full m-1 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'}`}>
                                    <EllipsisVerticalIcon aria-hidden="true" className={`size-5 m-1`} />
                                </PopoverButton>
                        
                                <PopoverPanel
                                    transition
                                    className="absolute right-0 z-50 mt-9 flex w-screen max-w-min px-2 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    <div className={`w-56 shrink rounded-xl border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-bgColor'} p-1 text-sm/6 font-semibold shadow-lg ring-1 ring-gray-900/5`}>
                                        
                                        <div className="p-3 w-full grid grid-cols-1 gap-3">
                                            <Button
                                                className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                onClick={() => download(chartRefActiveBar)}>
                                                <ArrowDownOnSquareIcon className={`w-4 mr-auto`}/>
                                                Download IMG
                                            </Button>
                                            <Button
                                                className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                    onClick={() => downloadFixedSize(chartRefActiveBar)}>
                                                <ArrowDownOnSquareStackIcon className={`w-4 mr-auto`}/>
                                                Smaller Size
                                            </Button>
                                            <Button
                                                className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                onClick={() => openImage(chartRefActiveBar)}>
                                                <ArrowTopRightOnSquareIcon className={`w-4 mr-auto`}/>
                                                New Window
                                            </Button>
                                        </div>
                                        
                                    </div>
                                </PopoverPanel>
                            </Popover>
                            
                            <div style={{display: 'grid', width: '100%', height: '90%'}}>
                                <AgCharts ref={chartRefActiveBar} options={activeOptions}/>
                            </div>
                        </div>
                        
                        {/*Bottom Left Pending! Bar*/}
                        <div className={`col-span-3 col-start-1 row-start-2 rounded-lg border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                        
                            <Popover className="relative w-full flex justify-end">
                                <PopoverButton className={`inline-flex items-center gap-x-1 text-sm/6 font-semibold rounded-full m-1 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'}`}>
                                    <EllipsisVerticalIcon aria-hidden="true" className={`size-5 m-1`} />
                                </PopoverButton>
                        
                                <PopoverPanel
                                    transition
                                    className="absolute right-0 z-50 mt-9 flex w-screen max-w-min px-2 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    <div className={`w-56 shrink rounded-xl border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-bgColor'} p-1 text-sm/6 font-semibold shadow-lg ring-1 ring-gray-900/5`}>
                                        
                                        <div className="p-3 w-full grid grid-cols-1 gap-3">
                                            <Button
                                                className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                onClick={() => download(chartRefPendingBar)}>
                                                <ArrowDownOnSquareIcon className={`w-4 mr-auto`}/>
                                                Download IMG
                                            </Button>
                                            <Button
                                                className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                    onClick={() => downloadFixedSize(chartRefPendingBar)}>
                                                <ArrowDownOnSquareStackIcon className={`w-4 mr-auto`}/>
                                                Smaller Size
                                            </Button>
                                            <Button
                                                className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                onClick={() => openImage(chartRefPendingBar)}>
                                                <ArrowTopRightOnSquareIcon className={`w-4 mr-auto`}/>
                                                New Window
                                            </Button>
                                        </div>
                                        
                                    </div>
                                </PopoverPanel>
                            </Popover>
                            
                            <div style={{display: 'grid', width: '100%', height: '90%'}}>
                                <AgCharts ref={chartRefPendingBar} options={orderOptions}/>
                            </div>
                            
                        </div>
                        
                        {/*Bottom Right Pending Donut*/}
                        <div className={`col-span-2 col-start-4 row-start-2`}>
                            
                            
                            <div className={`grid grid-cols-3 h-full overflow-y-hidden gap-x-1.5`}>
                                
                                <div className={`col-span-1 p-1 space-y-2 overflow-scroll scrollbar-hide rounded-lg border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                                    
                                    {Array.isArray(productsData) && (
                                        productsData.map((data, index) => (
                                            <DataCard
                                                index={index}
                                                data={data}
                                            />
                                        ))
                                    )}
                                
                                </div>
                                
                                <div className={`col-span-2 h-full rounded-lg border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'}`}>
                                    
                                    <div className={`flex items-center`}>
                                        
                                        <Switch
                                            checked={pendingToggle}
                                            onChange={setPendingToggle}
                                            className="group relative inline-flex ml-2 items-center h-6 w-11 shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out"
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                aria-hidden="true"
                                                className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                            />
                                            <span className={`pointer-events-none relative inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-4`}>
                                            
                                                {/*false*/}
                                                <span
                                                    aria-hidden="true"
                                                    className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                >
                                                <ClipboardDocumentListIcon className={`text-green-600 size-3.5 stroke-2`}/>
                                            </span>
                                                
                                                {/*true*/}
                                                <span
                                                    aria-hidden="true"
                                                    className="absolute inset-0 right-1 flex size-full items-center justify-center text-green-600 opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                >
                                                <CurrencyDollarIcon className={`text-green-600 size-3.5 stroke-2`}/>
                                            </span>
                                            
                                            </span>
                                        
                                        </Switch>
                                        
                                        <Popover className="relative w-full flex justify-end">
                                            <PopoverButton
                                                className={`inline-flex items-center gap-x-1 text-sm/6 font-semibold rounded-full m-1 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'}`}>
                                                <EllipsisVerticalIcon aria-hidden="true" className={`size-5 m-1`}/>
                                            </PopoverButton>
                                            
                                            <PopoverPanel
                                                transition
                                                className="absolute right-0 z-50 mt-9 flex w-screen max-w-min px-2 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                                            >
                                                <div
                                                    className={`w-56 shrink rounded-xl border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-bgColor'} p-1 text-sm/6 font-semibold shadow-lg ring-1 ring-gray-900/5`}>
                                                    
                                                    <div className="p-3 w-full grid grid-cols-1 gap-3">
                                                        <Button
                                                            className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                            onClick={() => download(chartRef)}>
                                                            <ArrowDownOnSquareIcon className={`w-4 mr-auto`}/>
                                                            Download IMG
                                                        </Button>
                                                        <Button
                                                            className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                            onClick={() => downloadFixedSize(chartRef)}>
                                                            <ArrowDownOnSquareStackIcon className={`w-4 mr-auto`}/>
                                                            Smaller Size
                                                        </Button>
                                                        <Button
                                                            className={`flex items-center text-xs tracking-widest hover:scale-105 col-span-1 border ${darkMode ? 'bg-darkBgColor border-neutral-700' : 'bg-bgColor shadow-sm'} w-full px-2 py-0.5 rounded-lg ml-auto mr-auto`}
                                                            onClick={() => openImage(chartRef)}>
                                                            <ArrowTopRightOnSquareIcon className={`w-4 mr-auto`}/>
                                                            New Window
                                                        </Button>
                                                    </div>
                                                
                                                </div>
                                            </PopoverPanel>
                                        </Popover>

                                    </div>
                                    
                                    <div style={{display: 'grid', width: '100%', height: '90%'}}>
                                        <AgCharts ref={chartRef} options={productOptions}/>
                                    </div>
                                </div>
                            
                            </div>
                            
                        
                        </div>
                    
                    </div>
                
                </div>
            
            
            </div>
        
        </div>
    )
};


export default Dashboard;
