import React, {useEffect, useState} from 'react';
import {collection, deleteDoc, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";
import SideBar from "./SideBar";
import {Button, Field, Input, Label, Popover, PopoverButton, PopoverPanel, Select, Switch} from "@headlessui/react";
import {
    ArrowDownIcon,
    ArrowPathIcon, ArrowUpIcon, LinkSlashIcon,
    MagnifyingGlassIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import {useDarkMode} from "../contexts/darkModeContext";
import {ChevronDownIcon} from "@heroicons/react/16/solid";
import course from "../course.jpg";
import fullLogo from "../Plantfood Logo.png"
import Loading from "./Loading";
import OrderSection from "./Orders/OrderSection";
import {downloadExcel, numberWithCommas} from "./GlobalFunctions";
import notifySound from "../notification.mp3";
import { ReactComponent as ExcelSVG } from "../excel-file-type.svg"
import NewOrder from "./NewOrder";
import OrderSearchInput from "./Orders/OrderSearchInput";




const PlantOrders = () => {
    const { currentUser } = useAuth();
    
    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });

    const [status, setStatus] = useState("P");

    const { darkMode } = useDarkMode();

    const [allOrders, setAllOrders] = useState([]);
    const [filteredAllOrders, setFilteredAllOrders] = useState([]);

    const [allPending, setAllPending] = useState([]);
    const [fileredAllPending, setFilteredAllPending] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [reps, setReps] = useState([]);

    // Handle dropdown change
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };
    

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setReps(userData.reps || []);
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setReps(userData.reps || []);
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    useEffect(() => {

        const fetchAllUsersPendingData = async () => {
            try {
                if (!reps || reps.length === 0) {
                    console.log("Reps is null or empty. Returning an empty array.");
                    setAllPending([]);
                    setFilteredAllPending([]);
                    return;
                }
        
                const storedPendingData = localStorage.getItem(`allPendingOrders_${currentUser.uid}`);
                if (storedPendingData) {
                    const pendingData = JSON.parse(storedPendingData);
                    // Sort in descending order before setting state
                    pendingData.sort((a, b) => new Date(b.submittedTime) - new Date(a.submittedTime));
                    setAllPending(pendingData);
                    setFilteredAllPending(pendingData);
                } else {
                    const repsIds = reps.map((rep) => rep.id);
                    let allPendingData = [];
        
                    const userPromises = repsIds.map(async (userId) => {
                        const clientsCollectionRef = collection(db, `Customers/${userId}/clients`);
                        const clientsSnapshot = await getDocs(clientsCollectionRef);
        
                        const pendingPromises = clientsSnapshot.docs.map(async (clientDoc) => {
                            const clientId = clientDoc.id;
                            const pendingCollectionRef = collection(db, `Customers/${userId}/clients/${clientId}/pending`);
                            const pendingSnapshot = await getDocs(pendingCollectionRef);
        
                            pendingSnapshot.forEach((pendingDoc) => {
                                allPendingData.push({
                                    userId,
                                    clientId,
                                    ...pendingDoc.data(),
                                    id: pendingDoc.id,
                                });
                            });
                        });
        
                        await Promise.all(pendingPromises);
                    });
        
                    await Promise.all(userPromises);
        
                    if (allPendingData.length > 0) {
                        // Sort in descending order before setting state
                        allPendingData.sort((a, b) => new Date(b.submittedTime) - new Date(a.submittedTime));
                        setAllPending(allPendingData);
                        setFilteredAllPending(allPendingData);
                        localStorage.setItem(`allPendingOrders_${currentUser.uid}`, JSON.stringify(allPendingData));
                    } else {
                        setAllPending([]);
                        setFilteredAllPending([]);
                        localStorage.removeItem(`allPendingOrders_${currentUser.uid}`);
                        console.log('No pending orders found, clearing data.');
                    }
                }
            } catch (error) {
                console.error("Error fetching pending data for all users: ", error);
            }
        };

        const fetchAllUsersActiveData = async () => {
            try {
                if (!reps || reps.length === 0) {
                    console.log("Reps is null or empty. Returning an empty array.");
                    setAllOrders([]);
                    setFilteredAllOrders([]);
                    return;
                }
        
                const storedAllData = localStorage.getItem(`allOrders_${currentUser.uid}`);
                if (storedAllData) {
                    const activeData = JSON.parse(storedAllData);
                    // Sort in descending order before setting state
                    activeData.sort((a, b) => new Date(b.submittedTime) - new Date(a.submittedTime));
                    setAllOrders(activeData);
                    setFilteredAllOrders(activeData);
                } else {
                    const repsIds = reps.map((rep) => rep.id);
                    let allActiveData = [];
        
                    const userPromises = repsIds.map(async (userId) => {
                        const clientsCollectionRef = collection(db, `Customers/${userId}/clients`);
                        const clientsSnapshot = await getDocs(clientsCollectionRef);
        
                        const activePromises = clientsSnapshot.docs.map(async (clientDoc) => {
                            const clientId = clientDoc.id;
                            const activeCollectionRef = collection(db, `Customers/${userId}/clients/${clientId}/active`);
                            const activeSnapshot = await getDocs(activeCollectionRef);
        
                            activeSnapshot.forEach((activeDoc) => {
                                allActiveData.push({
                                    userId,
                                    clientId,
                                    ...activeDoc.data(),
                                    id: activeDoc.id,
                                });
                            });
                        });
        
                        await Promise.all(activePromises);
                    });
        
                    await Promise.all(userPromises);
        
                    if (allActiveData.length > 0) {
                        // Sort in descending order before setting state
                        allActiveData.sort((a, b) => new Date(b.submittedTime) - new Date(a.submittedTime));
                        setAllOrders(allActiveData);
                        setFilteredAllOrders(allActiveData);
                        localStorage.setItem(`allOrders_${currentUser.uid}`, JSON.stringify(allActiveData));
                    } else {
                        setAllOrders([]);
                        setFilteredAllOrders([]);
                        localStorage.removeItem(`allOrders_${currentUser.uid}`);
                        console.log('No active orders found, clearing data.');
                    }
                }
            } catch (error) {
                console.error("Error fetching active data for all users: ", error);
            }
        };

        fetchAllUsersPendingData();
        fetchAllUsersActiveData();

    }, [currentUser, reps]);


    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // User Data
        try {

            // Fetch user document from Firestore based on the current user's UID
            const userDoc = await db.collection('Users').doc(currentUser.uid).get();
            if (userDoc.exists) {
                // Get the user's name from the document data
                const userData = userDoc.data();
                setReps(userData.reps);

                // Save user data to localStorage
                localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
            } else {
                console.log('User document not found');
            }

        } catch (error) {
            console.error("Error fetching all pending orders", error);
        }

        // Pending
        try {
            // Extract user IDs from the reps array
            const repsIds = reps.map((rep) => rep.id);
            console.log(repsIds);
            console.log(reps);

            let allPendingData = [];

            // Iterate over the allowed user IDs in repsIds
            const userPromises = repsIds.map(async (userId) => {
                // Reference to the client's collection for this user
                const clientsCollectionRef = collection(db, `Customers/${userId}/clients`);
                const clientsSnapshot = await getDocs(clientsCollectionRef);

                // Create an array of promises for fetching pending collections for each client
                const pendingPromises = clientsSnapshot.docs.map(async (clientDoc) => {
                    const clientId = clientDoc.id;

                    // Reference to the pending subcollection for the client
                    const pendingCollectionRef = collection(db, `Customers/${userId}/clients/${clientId}/pending`);
                    const pendingSnapshot = await getDocs(pendingCollectionRef);

                    // Push pending data to the allPendingData array
                    pendingSnapshot.forEach((pendingDoc) => {
                        allPendingData.push({
                            userId,    // Add user ID
                            clientId,  // Add client ID
                            ...pendingDoc.data(),
                            id: pendingDoc.id, // Add document ID
                        });
                    });
                });

                // Run all pending fetches for each client in parallel
                await Promise.all(pendingPromises);
            });

            // Run all user-level fetches in parallel
            await Promise.all(userPromises);

            // Handle empty data scenario
            if (allPendingData.length > 0) {
                setAllPending(allPendingData);
                setFilteredAllPending(allPendingData);
                localStorage.setItem(`allPendingOrders_${currentUser.uid}`, JSON.stringify(allPendingData));
            } else {
                // Clear the state and localStorage when no data is found
                setAllPending([]);
                setFilteredAllPending([]);
                localStorage.removeItem(`allPendingOrders_${currentUser.uid}`);
                console.log('No pending orders found, clearing data.');
            }

        } catch (error) {
            console.error("Error fetching all pending orders", error);
        }

        // Active
        try {
            // Extract user IDs from the reps array
            const repsIds = reps.map((rep) => rep.id);

            let allActiveData = [];

            // Iterate over the allowed user IDs in repsIds
            const userPromises = repsIds.map(async (userId) => {
                // Reference to the client's collection for this user
                const clientsCollectionRef = collection(db, `Customers/${userId}/clients`);
                const clientsSnapshot = await getDocs(clientsCollectionRef);

                // Create an array of promises for fetching active collections for each client
                const activePromises = clientsSnapshot.docs.map(async (clientDoc) => {
                    const clientId = clientDoc.id;

                    // Reference to the active subcollection for the client
                    const activeCollectionRef = collection(db, `Customers/${userId}/clients/${clientId}/active`);
                    const activeSnapshot = await getDocs(activeCollectionRef);

                    // Push active data to the allActiveData array
                    activeSnapshot.forEach((activeDoc) => {
                        allActiveData.push({
                            userId,    // Add user ID
                            clientId,  // Add client ID
                            ...activeDoc.data(),
                            id: activeDoc.id, // Add document ID
                        });
                    });
                });

                // Run all active fetches for each client in parallel
                await Promise.all(activePromises);
            });

            // Run all user-level fetches in parallel
            await Promise.all(userPromises);

            // Handle empty data scenario
            if (allActiveData.length > 0) {
                allActiveData.sort((a, b) => new Date(b.submittedTime) - new Date(a.submittedTime));
                setAllOrders(allActiveData);
                setFilteredAllOrders(allActiveData);
                localStorage.setItem(`allOrders_${currentUser.uid}`, JSON.stringify(allActiveData));
            } else {
                // Clear the state and localStorage when no data is found
                setAllOrders([]);
                setFilteredAllOrders([]);
                localStorage.removeItem(`allOrders_${currentUser.uid}`);
                console.log('No active orders found, clearing data.');
            }


        } catch (error) {
            console.error("Error fetching active data for all users: ", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        
        if (alertsOn){
            alert(`Orders Updated in ${timeElapsed} secs`)
        }
        
        // if (allPending.length === 0) {
        //     const ctx = new (window.AudioContext || window.webkitAudioContext)();
        //     const oscillator = ctx.createOscillator();
        //     const gainNode = ctx.createGain();
        //
        //     oscillator.type = "sawtooth"; // Can be "square", "sawtooth", or "triangle"
        //     oscillator.frequency.setValueAtTime(440, ctx.currentTime); // A4 note (chime sound)
        //     gainNode.gain.setValueAtTime(0.1, ctx.currentTime); // Adjust volume
        //
        //     oscillator.connect(gainNode);
        //     gainNode.connect(ctx.destination);
        //
        //     oscillator.start();
        //     oscillator.stop(ctx.currentTime + 0.5); // Play for 0.5 seconds
        // }
        
        // Play a chime sound if allPending is not empty
        if (allPending.length > 0 && alertSound) {
            const audio = new Audio(notifySound); // Ensure you have an audio file in the correct path
            audio.play().catch((error) => console.error("Error playing audio:", error));
        }
        
    };







    const handlePendingSearch = (query) => {
        setSearchQuery(query);
        
        const lowerCaseQuery = query.toLowerCase();

        const filtered = allPending.filter((customer) =>
            customer.customerName.toLowerCase().includes(lowerCaseQuery) ||
            customer.customerCode.toLowerCase().includes(lowerCaseQuery)
            // customer.customerPhone.includes(lowerCaseQuery) ||
            // customer.salesCode.toString().includes(lowerCaseQuery) // If orderNumber is a number, convert it to a string
        );

        setFilteredAllPending(filtered);
    }

    const handleActiveSearch = (query) => {
        setSearchQuery(query);
        
        const lowerCaseQuery = query.toLowerCase();

        const filtered = allOrders.filter((customer) =>
            customer.customerName.toLowerCase().includes(lowerCaseQuery) ||
            customer.customerCode.toLowerCase().includes(lowerCaseQuery) ||
            // customer.customerPhone.includes(lowerCaseQuery) ||
            customer.orderERP.toString().includes(lowerCaseQuery) // If orderNumber is a number, convert it to a string
        );
        
        setFilteredAllOrders(filtered);
    }


    const filterOrdersByIndustry = (industry) => {
        const filtered = allOrders.filter(customer => industry === '' || customer.customerIndustry === industry);
        setFilteredAllOrders(filtered);
    };

    const filterPendingOrdersByIndustry = (industry) => {
        const filtered = allPending.filter(customer => industry === '' || customer.customerIndustry === industry);
        setFilteredAllPending(filtered);
    };


    const filterOrdersByRep = (rep) => {
        const filtered = allOrders.filter(customer => rep === '' || customer.salesRep === rep);
        setFilteredAllOrders(filtered);
    };

    const filterPendingOrdersByRep = (rep) => {
        const filtered = allPending.filter(customer => rep === '' || customer.salesRep === rep);
        setFilteredAllPending(filtered);
    };


    const filtering = (e) => {
        if(status === "A"){
            filterOrdersByIndustry(e.target.value);
        }
        if (status === "P"){
            filterPendingOrdersByIndustry(e.target.value);
        }
    }

    const filteringRep = (e) => {
        if(status === "A"){
            filterOrdersByRep(e.target.value);
        }
        if (status === "P"){
            filterPendingOrdersByRep(e.target.value);
        }
    }



    // Function to calculate the total grand total from pending orders
    const calculateTotalGrandTotal = (orders) => {
        return orders.reduce((total, order) => {
            return total + (parseFloat(order.grandTotal) || 0); // Add 0 for undefined grandTotal
        }, 0);
    };


    const activeGrandTotal = calculateTotalGrandTotal(filteredAllOrders);
    const pendingGrandTotal = calculateTotalGrandTotal(fileredAllPending);

    const [count, setCount] = useState(0);

    useEffect(() => {

        if (status === "A"){
            setCount(filteredAllOrders.length);
        }
        if (status === "P"){
            setCount(fileredAllPending.length);
        }


    }, [status, filteredAllOrders, fileredAllPending]);

    const [AscDesc, setAscDesc] = useState(true);
    const [AlphaAscDesc, setAlphaAscDesc] = useState(true);
    const [DateAscDesc, setDateAscDesc] = useState(false); // Default to descending order
    // const [DateAscDesc, setDateAscDesc] = useState(true);

    const toggleAscDesc = () => {
        // Toggle AscDesc and sort the orders array
        setAscDesc((prev) => !prev);

        if(status === "A"){
            const sortedOrders = [...filteredAllOrders].sort((a, b) => {
                return AscDesc
                    ? a.grandTotal - b.grandTotal  // Ascending
                    : b.grandTotal - a.grandTotal; // Descending
            });
            setFilteredAllOrders(sortedOrders);
        }
        if (status === "P"){
            const sortedOrders = [...fileredAllPending].sort((a, b) => {
                return AscDesc
                    ? a.grandTotal - b.grandTotal  // Ascending
                    : b.grandTotal - a.grandTotal; // Descending
            });
            setFilteredAllPending(sortedOrders);
        }
    };
    
    const toggleAlphaAscDesc = () => {
        setAlphaAscDesc((prev) => !prev);
    
        if (status === "A") {
            const sortedOrders = [...filteredAllOrders].sort((a, b) => {
                return AlphaAscDesc
                    ? a.customerCode.localeCompare(b.customerCode)  // Ascending
                    : b.customerCode.localeCompare(a.customerCode); // Descending
            });
            setFilteredAllOrders(sortedOrders);
        }
        
        if (status === "P") {
            const sortedPending = [...fileredAllPending].sort((a, b) => {
                return AlphaAscDesc
                    ? a.customerCode.localeCompare(b.customerCode)  // Ascending
                    : b.customerCode.localeCompare(a.customerCode); // Descending
            });
            setFilteredAllPending(sortedPending);
        }
    };
    
    const toggleDateAscDesc = () => {
        setDateAscDesc((prev) => {
            const newOrder = !prev;
            
            if (status === "A") {
                const sortedOrders = [...filteredAllOrders].sort((a, b) => {
                    return newOrder
                        ? new Date(a.orderDate) - new Date(b.orderDate)  // Ascending
                        : new Date(b.orderDate) - new Date(a.orderDate); // Descending
                });
                setFilteredAllOrders(sortedOrders);
            }
    
            if (status === "P") {
                const sortedOrders = [...fileredAllPending].sort((a, b) => {
                    return newOrder
                        ? new Date(a.orderDate) - new Date(b.orderDate)  // Ascending
                        : new Date(b.orderDate) - new Date(a.orderDate); // Descending
                });
                setFilteredAllPending(sortedOrders);
            }
    
            return newOrder;
        });
    };
    
    const statusMap = {
        A: {
            orders: filteredAllOrders,
            emptyTitle: "Your Reps Have No Active Orders",
            emptyDescription: "You have to upload your reps pending orders to the ERP",
        },
        P: {
            orders: fileredAllPending,
            emptyTitle: "Your Reps Have No Pending Orders",
            emptyDescription: "You have to wait for your Reps to place orders in the field",
        },
    };

    const currentStatus = statusMap[status] || {};
    
    
    const localStorageKey = 'updateSettings';

    const [automaticUpdate, setAutomaticUpdate] = useState(() => {
        const storedSettings = localStorage.getItem(localStorageKey);
        return storedSettings ? JSON.parse(storedSettings).automaticUpdate : true;
    });
    
    const localStorageKeyAlert = 'alertSound';

    const [alertSound, setAlertSound] = useState(() => {
        const storedSettings = localStorage.getItem(localStorageKeyAlert);
        return storedSettings ? JSON.parse(storedSettings).alertSound : true;
    });
    
    const [updateInterval, setUpdateInterval] = useState(10);
    
    // const [updateInterval, setUpdateInterval] = useState(() => {
    //     const storedSettings = localStorage.getItem(localStorageKey);
    //     return storedSettings ? JSON.parse(storedSettings).updateInterval : 0;
    // });
    
    // Save settings to local storage when they change
    useEffect(() => {
        localStorage.setItem(
            localStorageKey,
            JSON.stringify({ automaticUpdate, updateInterval })
        );
        console.log("Changes Saved");
    }, [automaticUpdate, updateInterval]);
    
    useEffect(() => {
        localStorage.setItem(
            localStorageKeyAlert,
            JSON.stringify({ alertSound })
        );
        console.log("Changes Saved");
    }, [alertSound]);

    const [timeLeft, setTimeLeft] = useState(updateInterval * 60); // Initial countdown in seconds
    
    useEffect(() => {
        let intervalId;
        let countdownId;
    
        if (automaticUpdate && updateInterval > 0) {
            setTimeLeft(updateInterval * 60); // Reset countdown to full interval in seconds
    
            // Start the update interval
            intervalId = setInterval(() => {
                onUpdate();
                setTimeLeft(updateInterval * 60); // Reset countdown after update
            }, updateInterval * 60 * 1000);
    
            // Start the countdown timer to update every second
            countdownId = setInterval(() => {
                setTimeLeft((prev) => (prev > 0 ? prev - 1 : updateInterval * 60));
            }, 1000);
        }
    
        return () => {
            clearInterval(intervalId);
            clearInterval(countdownId); // Clean up countdown timer
        };
        // eslint-disable-next-line
    }, [automaticUpdate, updateInterval]);
    
    // Convert seconds to minutes and seconds for display
    const formatTimeLeft = () => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    
    const handleDeleteOrder = async (clientId, orderId) => {
        // Check if clientId and orderId are not empty
        if (!clientId || !orderId) {
            console.warn("Client ID or Order ID is missing. Delete operation aborted.");
            return;
        }

        try {
            const orderRef = doc(db, `Customers/${currentUser.uid}/clients/${clientId}/pending`, orderId);
            await deleteDoc(orderRef);
            console.log(`Order with ID ${orderId} for client ${clientId} deleted successfully`);

            // Update the state to remove the deleted order
            setFilteredAllPending((prevOrders) =>
                prevOrders.filter((order) => !(order.clientId === clientId && order.id === orderId))
            );

            await onUpdate();
        } catch (error) {
            console.error("Error deleting order:", error);
        }
    };
    

    const downloadMapping = () => {
        if (status === "A") {
            downloadExcel(allOrders, "AllActiveOrders.xlsx");
        }
        if (status === "P") {
            downloadExcel(allPending, "AllPendingOrders.xlsx");
        }
    };
    
    
    const [products, setProducts] = useState([]);
    
    useEffect(() => {
        const fetchProducts = async () => {
            if (currentUser) {
                try {
                    // Fetch the 'productUpdated' field for the current user
                    const userDocRef = doc(db, "Users", currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const { productUpdated } = userData;

                        if (productUpdated) {
                            // If 'productUpdated' is true, fetch products from Firestore
                            console.log("Fetching products from Firestore...");
                            const productsCollection = collection(db, "Products");
                            const snapshot = await getDocs(productsCollection);

                            if (!snapshot.empty) {
                                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                                setProducts(productData);
                                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));

                                // Set 'productUpdated' to false
                                await updateDoc(userDocRef, { productUpdated: false });
                                console.log("Set productUpdated to false after fetching from Firestore.");
                            } else {
                                console.log("No products found in Firestore.");
                            }
                        } else {
                            // If 'productUpdated' is false, load products from localStorage
                            console.log("Loading products from localStorage...");
                            const storedProducts = localStorage.getItem(`products_${currentUser.uid}`);
                            if (storedProducts) {
                                const storedData = JSON.parse(storedProducts);
                                setProducts(storedData);
                            } else {
                                console.log("No products found in localStorage.");
                            }
                        }
                    } else {
                        console.log("User document does not exist.");
                    }
                } catch (error) {
                    console.error("Error fetching product data:", error);
                }
            }
        };

        fetchProducts();
    }, [currentUser]);
    
    
    
    
    
    
    const [orderForm, setOrderForm] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    
    // Function to handle both accountView and selected customer
    const handleViewOrder = (order) => {
        setOrderForm(true);
        setSelectedOrder(order);
    };
    
    
    
    
    return (
        
        <>
            {orderForm ? (
                <NewOrder
                    order={selectedOrder}
                    isEditing={true}
                    close={() => setOrderForm(false)}
                />
            ) : (
                <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-[calc(100dvh-4rem)] overflow-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>
        
                    {updating && (
                        <Loading/>
                    )}
                    
                    <SideBar/>
                    
                    <div className="z-20 animate-fadeIn flex flex-col relative w-full max-w-[calc(100%-6rem)] h-full mt-3 mb-3 mr-3">
        
                        {/*Customers Header*/}
                        <div className="w-full h-fit flex mb-3">
                            <div className={`text-2xl w-1/4 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row line-clamp-1`}>
                                <div className={`line-clamp-1`}>Rep Orders</div>
                                {/*Buttons Delete*/}
                                <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                                    <Button className={`rounded-full border ${darkMode ? 'bg-darkMainColor border-neutral-700 text-gray-500' : 'bg-white text-gray-700'} p-1 hover:scale-105 hover:rotate-12 mt-auto mb-auto h-7 w-7 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                        onClick={onUpdate}
                                    >
                                        <ArrowPathIcon className="m-auto"/>
                                    </Button>
                                </div>
                            </div>
        
                            <div className={`text-xl z-50 mr-1.5 relative ${darkMode ? 'bg-darkMainColor border border-neutral-700 text-white' : 'bg-white border text-black'} w-4/6 flex rounded-md border drop-shadow shadow-gray-900`}>
        
                                <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                                    <div className="font-semibold text-sm mr-3 line-clamp-1">Active:</div>
                                    <div className="text-sm rounded-md bg-orderColor bg-opacity-20 border-2 border-orderColor px-2 line-clamp-1">$ {numberWithCommas(activeGrandTotal.toFixed(2))}</div>
                                    <div className="text-xs rounded-md bg-orderColor bg-opacity-20 border-2 border-orderColor px-1 ml-1 line-clamp-1">{filteredAllOrders.length || 0}</div>
                                </div>
        
                                <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                                    <div className="font-semibold text-sm mr-3 line-clamp-1">Pending:</div>
                                    <div className="text-sm rounded-md bg-pendingColor bg-opacity-20 border-2 border-pendingColor px-2 line-clamp-1">$ {numberWithCommas(pendingGrandTotal.toFixed(2))}</div>
                                    <div className="text-xs rounded-md bg-pendingColor bg-opacity-20 border-2 border-pendingColor px-1 ml-1 line-clamp-1">{fileredAllPending.length || 0}</div>
                                </div>
        
                                <Popover className="w-full h-full absolute z-50">
                                    <div className="w-full h-full">
                                        <div className="w-full mx-auto h-full flex items-center px-6 lg:px-8">
                                            <PopoverButton className="absolute right-0 inline-flex mr-3 items-center gap-x-1 text-xs font-semibold">
                                                Reps
                                                <ChevronDownIcon aria-hidden="true" className="size-5" />
                                            </PopoverButton>
                                        </div>
                                    </div>
        
                                    <PopoverPanel
                                        transition
                                        className={`relative top-0 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} mt-3 rounded-lg shadow-xl transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in`}
                                    >
                                        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 px-6 py-10 lg:grid-cols-2 lg:px-8">
        
                                            {/*Column 1*/}
                                            <div className="grid grid-cols-1 gap-x-6 sm:gap-x-8">
                                                <div className={`w-full`}>
                                                    <h3 className="text-sm/6 font-medium">Sales Reps List</h3>
                                                    <h1 className="text-xs ml-3 text-gray-500 font-medium">These are the Sales
                                                        Reps you are Currently Servicing</h1>
                                                    <div className="mt-6 flow-root">
                                                        <div className="-my-2">
        
                                                            {reps.length === 0 ? (
                                                                // Display custom dialog if sprays array is empty
                                                                <div className="flex w-full h-full justify-center items-center py-2">
        
                                                                    <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-4 py-1 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                                        <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                                            <LinkSlashIcon className={`w-4 stroke-2 mr-3`}/>
                                                                            <p className="text-xs mt-2 line-clamp-1 tracking-widest flex">You Have No Reps</p>
                                                                            <p className="text-xxs mt-2 line-clamp-1 tracking-widest flex text-gray-500">You may select reps in the profile and settings page</p>
                                                                            
                                                                            <a className={`text-xs bg-green-600 px-2 py-0.5 rounded-lg mt-2 hover:bg-opacity-50`}
                                                                                href={`/Profile`}
                                                                            >
                                                                                Go To Settings
                                                                            </a>
                                                                        </div>
                                                                    </div>
        
                                                                </div>
                                                            ) : (
                                                                // Display SprayCard components if sprays array is not empty
                                                                <div className="grid grid-cols-2 gap-2 ml-6">
                                                                    {reps.map((item, index) => (
                                                                        <div key={item.name}
                                                                             className="py-1 text-sm font-semibold">
                                                                            [{index + 1}] {item.name}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            {/*Column 2*/}
                                            <div className="grid grid-cols-1 w-full sm:gap-4 lg:grid-cols-2">
                                                
                                                {/*Automatic Updates*/}
                                                <Field className="flex w-full col-span-2">
                                                <Switch
                                                        checked={automaticUpdate}
                                                        onChange={setAutomaticUpdate}
                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span aria-hidden="true"
                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                        <span
                                                            aria-hidden="true"
                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                        />
                                                        <span
                                                            aria-hidden="true"
                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                        />
                                                    </Switch>
                                                    <Label as="span" className="ml-3 text-sm w-full">
                                                        <span className="font-medium">Automatic Updates</span>{' '}
                                                        <span className="text-gray-500 hidden text-xs">(Specify Time)</span>
                                                    </Label>
                                                </Field>
                                                
                                                {/*Alert Sound*/}
                                                <Field className="flex w-full col-span-2">
                                                    <Switch
                                                        checked={alertSound}
                                                        onChange={setAlertSound}
                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span aria-hidden="true"
                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                        <span
                                                            aria-hidden="true"
                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                        />
                                                        <span
                                                            aria-hidden="true"
                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                        />
                                                    </Switch>
                                                    <Label as="span" className="ml-3 text-sm w-full">
                                                        <span className="font-medium">Alert Chime</span>{' '}
                                                        <span className="text-gray-500 text-xs">(Only if pending orders are present)</span>
                                                    </Label>
                                                </Field>
                                                
                                                {/*Time*/}
                                                <Field className="flex w-full col-span-2 relative items-center">
                                                    <label
                                                        htmlFor="name"
                                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Time
                                                    </label>
                                                    <Input
                                                        id="name"
                                                        name="name"
                                                        type="number"
                                                        placeholder="0 mins"
                                                        value={updateInterval}
                                                        disabled={true}
                                                        onChange={(e) => setUpdateInterval(Number(e.target.value))}
                                                        className={`block w-1/2 rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                    />
                                                    <Label as="span" className="ml-3 text-sm w-full">
                                                        <span className="font-medium">Time</span>{' '}
                                                        <span className="text-gray-500 text-xs">({updateInterval} mins)</span>
                                                    </Label>
                                                </Field>
                                                
                                                {/*CountDown*/}
                                                <Field className="flex w-full col-span-2 relative items-center">
                                                    <label
                                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                    >
                                                        Remaining Time
                                                    </label>
                                                    <div className={`block w-1/2 rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                        {formatTimeLeft()}
                                                    </div>
                                                    <Label as="span" className="ml-3 text-sm w-full">
                                                        <span className="font-medium">Next Refresh</span>{' '}
                                                    </Label>
                                                </Field>
                                                
                                                
                                                <div className={`w-full h-full hidden items-center justify-center flex rounded-lg isolate overflow-hidden`}>
                                                    {/* Background Course Image */}
                                                    <img
                                                        alt=""
                                                        src={course}
                                                        className={`absolute border ${darkMode ? 'border-borderColor' : 'drop-shadow'} rounded-lg inset-0 -z-10 h-full w-full object-cover object-right md:object-center`}
                                                    />
                                                    
                                                    {/* Centered Logo */}
                                                    <div className="absolute inset-0 flex items-center justify-center">
                                                        <img
                                                            alt="Logo"
                                                            src={fullLogo}
                                                            className="p-16 object-contain"
                                                        />
                                                    </div>
                                                </div>
                                            
                                            
                                            </div>
                                        
                                        
                                        </div>
                                    </PopoverPanel>
                                </Popover>
                            
                            </div>
                            
                            <div className="w-1/6 flex items-center text-black ml-1.5">
                                <Button className="bg-green-600 h-fit w-full hover:scale-105 tracking-widest hover:bg-opacity-50 rounded-md py-1 px-6 text-white text-sm flex flex-row align-middle justify-center items-center"
                                    onClick={downloadMapping}
                                    // disabled={true}
                                >
                                    <ExcelSVG className={`w-8 h-6 hover:stroke-green-600 stroke-white fill-white`} />
                                    <div className="w-full line-clamp-1">Download Excel</div>
                                </Button>
                            </div>
        
        
                        </div>
        
                        {/*Search bar row*/}
                        <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>
        
                            {/*Search Bar*/}
                            <div className="w-1/2 mr-1.5 h-fit">
                                <div className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                                    
                                    <OrderSearchInput
                                        status={status}
                                        searchQuery={searchQuery}
                                        setSearchQuery={setSearchQuery}
                                        onSearch={
                                            status === "A" ? handleActiveSearch :
                                            status === "P" ? handlePendingSearch :
                                            () => {}
                                        }
                                        darkMode={darkMode}
                                    />
                                    
                                </div>
        
                            </div>
        
                            {/*Status*/}
                            <div className="w-1/6 mr-1.5 ml-1.5 h-full">
        
                                <div className="flex flex-col w-full mr-3 h-full">
                                    {/*<div className="text-sm mb-1">Status</div>*/}
                                    <Select
                                        className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                        value={status}
                                        onChange={handleStatusChange}
                                    >
                                        <option value="A">Active</option>
                                        <option value="P">Pending</option>
                                    </Select>
                                </div>
        
                            </div>
                            
                            {/*Pricing Type*/}
                            <div className="w-1/6 mr-1.5 ml-1.5 h-full">
        
                                <div className="flex flex-col w-full mr-3 h-full">
                                    {/*<div className="text-sm mb-1">Pricing Type</div>*/}
                                    <Select
                                        className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                        onChange={filteringRep}
                                    >
                                        <option value="">Select Sales Rep...</option>
                                        
                                        {reps.map((rep, index) => {
                                            const label = rep.name; // Get label from mapping object
                                            return label ? (
                                                <option key={index} value={rep.name}>
                                                    {label}
                                                </option>
                                            ) : null; // Exclude options with no matching label
                                        })}
                                    </Select>
                                </div>
        
                            </div>
        
                            {/*Industry*/}
                            <div className="w-1/6 ml-1.5 h-full">
        
                                <div className="flex flex-col w-full mr-3 h-full">
                                    {/*<div className="text-sm mb-1">Industry</div>*/}
        
                                    <Select
                                        className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                        onChange={filtering}
                                    >
                                        <option value="">Select Industry...</option>
                                        <option value="BOTANICAL">Botanical Garden</option>
                                        <option value="DISTRIB">Product Distributor</option>
                                        <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                        <option value="FARM">Agriculture - Farm</option>
                                        <option value="GOLF">Golf Course</option>
                                        <option value="GOVERNMENT">Government Entity</option>
                                        <option value="INDUSTRIAL">Industrial</option>
                                        <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                        <option value="MRSALE">One Time Purchase Expected</option>
                                        <option value="MUNICIPAL">Municipal/ School</option>
                                        <option value="Non-Profit">Non-Profit Account</option>
                                        <option value="OTHER">Other</option>
                                        <option value="SPORTFLD">Sportsfield</option>
                                    </Select>
                                </div>
        
                            </div>
        
        
                        </div>
        
                        {/*Orders Body*/}
                        <div className="w-full h-full rounded-md mb-6">
        
                            {/*Orders Maps*/}
                            <div className="w-full flex h-full flex-col">
        
        
                                <div className={`w-full h-full border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} rounded-lg`}>
        
                                    {/*HEADER*/}
                                    <div className="w-full flex flex-row pl-3 pr-3">
        
                                        <div className={`w-full flex flex-row border-b-2 ${darkMode ? 'border-darkBgColor' : ''}`}>
        
                                            {/*Buttons Delete*/}
                                            <div className="flex flex-col items-center mr-3">
                                                <div className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                                    {/*<TrashIcon className="h-6 text-transparent m-auto"/>*/}
                                                    <div className="justify-center flex flex-col items-center h-full">
                                                        <div className="text-sm font-semibold">[{count}]</div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            {/*Sales Name*/}
                                            <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">
        
                                            <div className="justify-center flex flex-col h-full">
                                                    <div className="text-sm font-semibold">Sales Rep</div>
                                                </div>
        
                                            </div>
        
                                            {/*Customer*/}
                                            <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">
        
                                                <div className="justify-center flex items-center h-full">
                                                    <div className="text-sm font-semibold">Customer</div>
                                                    <Button
                                                        className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                                        onClick={toggleAlphaAscDesc}
                                                    >
        
                                                        {AscDesc ? (
                                                            <ArrowUpIcon
                                                                className="h-4 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                                                        ) : (
                                                            <ArrowDownIcon
                                                                className="h-4 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                                                        )}
                                                    </Button>
                                                </div>
        
        
                                            </div>
        
                                            <div className="flex w-1/6 items-center justify-center h-full text-sm font-semibold">ERP</div>
        
                                            {/*Order Sorting*/}
                                            <div className="w-1/2 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">
        
                                                {/*Status*/}
                                                <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">
        
                                                    <div className="w-fit p-1 h-8 rounded-full flex flex-row">
                                                        <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Status</div>
                                                    </div>
        
                                                </div>
        
                                                <div className="w-1/3 p-1 h-8">
                                                    {status === "Planned" ? (
                                                        <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Submit to Plant</div>
                                                    ) : (
                                                        <div className={`flex items-center justify-center h-full`}>
                                                            <div
                                                                className="flex items-center h-full text-sm font-semibold">Dates
                                                            </div>
                                                            <Button
                                                                className="rounded-full mt-auto mb-auto h-full w-8 items-center justify-center align-middle"
                                                                onClick={toggleDateAscDesc}
                                                            >
        
                                                                {DateAscDesc ? (
                                                                    <ArrowDownIcon
                                                                        className="h-4 stroke-2 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                                                                ) : (
                                                                    <ArrowUpIcon
                                                                        className="h-4 stroke-2 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                                                                )}
                                                            </Button>
                                                        </div>)}
                                                </div>
        
                                                {/*Order Sorting*/}
                                                <div className="w-1/3 h-8 items-center justify-center flex">
                                                    <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Order Total</div>
                                                    <Button
                                                        className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                                        onClick={toggleAscDesc}
                                                    >
        
                                                        {AscDesc ? (
                                                            <ArrowDownIcon
                                                                className="h-4 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                                                        ) : (
                                                            <ArrowUpIcon
                                                                className="h-4 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                                                        )}
                                                    </Button>
                                                </div>
        
        
                                            </div>
        
        
                                            {/*Buttons DropDown*/}
                                            <div className="flex flex-col items-center ml-3">
                                                <div className=" mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                                    <TrashIcon className="h-6 text-transparent m-auto"/>
                                                </div>
                                            </div>
        
        
                                        </div>
        
                                    </div>
        
                                    {/*Orders MAP*/}
                                    <div className={`overflow-y-auto h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} p-1 rounded-lg max-h-[calc(100dvh-14.5rem)] scrollbar-hide`}>
                                        
                                        <OrderSection
                                            status={status}
                                            orders={currentStatus.orders || []}
                                            emptyTitle={currentStatus.emptyTitle}
                                            emptyDescription={currentStatus.emptyDescription}
                                            darkMode={darkMode}
                                            onUpdate={onUpdate}
                                            products={products}
                                            handleViewOrder={handleViewOrder}
                                            onDelete={(customerUID, id) => {
                                                if (status === "P") handleDeleteOrder(customerUID, id);
                                            }}
                                        />
        
                                    </div>
        
        
                                </div>
        
        
                            </div>
        
                        </div>
        
                    </div>
        
        
                </div>
           )}
        </>
        

    )
};


export default PlantOrders;
