import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";
import {
    Button,
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle, Field,
    Input, Label,
    Select, Switch,
    Textarea
} from "@headlessui/react";
import {
    ArrowDownTrayIcon,
    ArrowPathIcon, DocumentCurrencyDollarIcon,
    MagnifyingGlassIcon,
    PlusCircleIcon, PlusIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import PricingCard from "./Pricing/PricingCard";

import { utils as xlsxUtils, write as writeXlsx } from 'xlsx'
import { saveAs } from 'file-saver'
import Loading from "./Loading";
import { ReactComponent as ExcelSVG } from "../excel-file-type.svg"
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {categoryColors, numberWithCommas} from "./GlobalFunctions";

const Pricing = () => {

    const { darkMode } = useDarkMode();
    const { currentUser } = useAuth();
    
    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            if (currentUser) {
                try {
                    const storedProducts = localStorage.getItem(`products_${currentUser.uid}`);
                    if (storedProducts) {
                        const storedData = JSON.parse(storedProducts);
                        setProducts(storedData);
                        setFilteredProducts(storedData);
                    } else {

                        const productsCollection = db.collection("Products");
                        const snapshot = await productsCollection.get();

                        if (!snapshot.empty) {
                            const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setProducts(productData);
                            setFilteredProducts(productData);
                            localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));
                        } else {
                            console.log('Product documents not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching products data:', error);
                }
            }
        };

        fetchProducts();
    }, [currentUser]);

    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Pending
        try {
            const productsCollection = db.collection("Products");
            const snapshot = await productsCollection.get();

            if (!snapshot.empty) {
                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProducts(productData);
                setFilteredProducts(productData);
                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));
            } else {
                console.log('Product documents not found');
            }

        } catch (error) {
            console.error("Error updating products", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        
        if (alertsOn) {
            alert(`Products Updated in ${timeElapsed} secs`);
        }
    };


    const [searchQuery, setSearchQuery] = useState("");

    const [adding, setAdding] = useState(false);

    // Function to toggle visibility
    const toggleVisibility = () => {
        setAdding(!adding);
    };

    const filterProductsByCatagory = (catagory) => {
        const filtered = products.filter(product => catagory === '' || product.category === catagory);
        setFilteredProducts(filtered);
    }

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = products.filter((product) =>
            product.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredProducts(filtered);
    }



    const [prices, setPrices] = useState([]);

    const handleAddPrice = () => {
        setPrices([...prices, { um: '', code: '', ne: '', se: '', ag: '', dist1: '', dist2: '' }]);
    };

    const handleRemovePrice = (index) => {
        const newLocations = prices.filter((_, i) => i !== index);
        setPrices(newLocations);
    };

    // Updated handleInputChange
    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        const newPrices = [...prices];
        newPrices[index][name] = value;
        setPrices(newPrices);
    };



    const [name, setName] = useState("");
    const [category, setCategory] = useState("");
    const [sds, setSds] = useState("");

    const [ph, setPh] = useState(0);
    const [weight, setWeight] = useState(0);
    
    const [AG, setAG] = useState(false);
    const [PLAIF, setPLAIF] = useState(false);
    const [INT, setINT] = useState(false);
    const [country, setCountry] = useState("");

    const onSubmitMain = async (e) => {
        e.preventDefault();

        const collectionRef = db.collection("Products");

        const parsedFormData = {

            // Sales Info
            name: name,
            category: category,
            ph: parseFloat(ph),
            weight: parseFloat(weight),
            sds: sds,
            
            AG: AG,
            PLAIF: PLAIF,

            updated: Date.toLocaleString(),

            prices: prices,
        };

        try {
            await collectionRef.add(parsedFormData);
            
            if (alertsOn) {
                alert(`Product Added to the Cloud`); // Optional: alert for user feedback
            }
            
            // Update the productUpdated field in all documents in the Users collection
            const usersCollectionRef = collection(db, "Users");
            const usersSnapshot = await getDocs(usersCollectionRef);

            const updatePromises = usersSnapshot.docs.map((userDoc) => {
                const userDocRef = doc(db, "Users", userDoc.id);
                return updateDoc(userDocRef, { productUpdated: true });
            });
            
            await Promise.all(updatePromises); // Wait for all updates to complete
            console.log("Updated productUpdated field for all users!");

            await onUpdate();

            setName("");
            setCategory("");
            setPh(0);
            setWeight(0);

            setPrices([]);

            // onUpdate();

        } catch (error) {
            console.error('Error registering product:', error.message);
            // setIsRegistered(false);
        }
    };
    

    const bgColor = categoryColors.find(c => c.category === category)?.color || '';
    

    const downloadAsExcel = () => {
        const columnHeaders = [
            'Code',
            'Item Name',
            'U/M',
            'Unit Price 1 (NE)',
            'Unit Price 1 (SE)',
            'Unit Price 3 (AG)',
            'Unit Price 4 (DIST 1)',
            'Unit Price 5 (DIST 2)',
        ];

        // Map through products to extract product details
        const worksheetData = [
            columnHeaders,
            ...products.flatMap(order =>
                order.prices.map(price => [
                    price.code || '',
                    order.name || '',
                    price.um || '',
                    numberWithCommas(price.ne) || 0,
                    numberWithCommas(price.se) || 0,
                    numberWithCommas(price.ag) || 0,
                    numberWithCommas(price.dist1) || 0,
                    numberWithCommas(price.dist2) || 0
                ])
            )
        ];

        // Generate Excel worksheet
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);

        // Set custom column widths (double the default width)
        worksheet['!cols'] = [
            { wch: 20 }, // Item
            { wch: 40 }, // Item Description
            { wch: 10 }, // U/M
            { wch: 20 }, // Unit Price 1 (NE)
            { wch: 20 }, // Unit Price 1 (SE)
            { wch: 20 }, // Unit Price 3 (AG)
            { wch: 20 }, // Unit Price 4 (DIST 1)
            { wch: 20 }, // Unit Price 5 (DIST 2)
        ];

        // Create workbook and append worksheet
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Product Pricing');

        // Get current date in yyyymmdd format
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const dd = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${yyyy}${mm}${dd}`;

        // Convert workbook to buffer and create Blob
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file with the dynamic filename
        saveAs(excelBlob, `ItemPricingExport${formattedDate}.xlsx`);
    };
    
    // eslint-disable-next-line
    const downloadAsExcelSHORT = () => {
        const columnHeaders = ['Product Name',];
        const worksheetData = [columnHeaders, ...products.map(product => [product.name])];
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Active Products');
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'ActiveProducts.xlsx');
    };

    
    const downloadJSON = () => {
        const dataStr = JSON.stringify(products, null, 2); // Convert data to JSON string
        const blob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `products.json`; // Use a unique name for each file
        link.click();

        URL.revokeObjectURL(url); // Clean up the URL object
    };


    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    
    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentProducts = filteredProducts.slice(startIndex, startIndex + itemsPerPage);
    

    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-[calc(100dvh-4rem)] w-full mt-16 transition-all duration-500 ease-in-out`}>

            {updating && (
                <Loading/>
            )}
            
            <SideBar/>
            
            
            
            
            <div className="z-20 animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] mt-3 mr-3">

                {/*Pricing Header*/}
                <div className="w-full h-fit flex mb-3">

                    <div className={`text-2xl w-1/3 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row`}>Product Pricing
                        {/*Buttons Delete*/}
                        <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                            <Button className={`rounded-full ${darkMode ? 'bg-darkMainColor text-gray-500' : 'bg-white text-gray-700'} p-1 hover:scale-105 hover:rotate-12 mt-auto mb-auto h-7 w-7 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                onClick={onUpdate}
                            >
                                <ArrowPathIcon className="m-auto"/>
                            </Button>
                        </div>
                    </div>

                    <div className={`text-xl mr-1.5 border ${darkMode ? 'bg-darkMainColor border-neutral-700 text-white' : 'bg-white border text-black'} w-5/6 flex rounded-md border drop-shadow shadow-gray-900`}>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Products:</div>
                            <div className={`text-sm rounded-md border ${darkMode ? 'border-neutral-700' : ''} px-2 line-clamp-1`}>{products.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Filtered Products:</div>
                            <div className={`text-sm rounded-md border ${darkMode ? 'border-neutral-700' : ''} px-2 line-clamp-1`}>{filteredProducts.length}</div>
                        </div>

                        {/*Excel*/}
                        <div className="w-fit h-full text-sm">

                            <div className="flex flex-row items-center justify-center h-full mr-3">
                                {/*<div className="text-sm mb-1">Category</div>*/}
                                <Button
                                    className="w-10 h-10 rounded-full hover:scale-105 hover:text-green-600 py-1.5 text-sm flex flex-row align-middle justify-center items-center"
                                    onClick={downloadAsExcel}
                                >
                                    <ExcelSVG className={`w-6 hover:stroke-green-600 ${darkMode ? 'stroke-white fill-white' : 'stroke-black fill-black'}`} />
                                </Button>
                                
                                <Button
                                    className="w-10 h-10 rounded-full hover:scale-105 hover:text-green-600 py-1.5 text-sm flex flex-row align-middle justify-center items-center"
                                    onClick={downloadJSON}
                                >
                                    <ArrowDownTrayIcon className="w-6"/>
                                </Button>
                            </div>

                        </div>

                    </div>

                    <div className="w-1/6 text-black ml-1.5">
                        <Button
                            className="bg-green-600 h-full w-full hover:scale-105 hover:bg-opacity-80 rounded-md py-1.5 px-6 text-white text-sm mr-3 flex flex-row align-middle justify-center items-center"
                            onClick={toggleVisibility}
                        >
                            <PlusCircleIcon className="w-6"/>
                            <div className="w-full line-clamp-1">Add Product</div>
                        </Button>
                    </div>

                </div>

                {/*Search bar row*/}
                <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'} space-x-3`}>
                    

                    {/*Search Bar*/}
                    <div className="w-3/4 h-fit text-sm">
                        {/*<div className="text-sm mb-1">Product</div>*/}

                        <div
                            className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                        <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>

                            <div className="w-full">

                                <Input
                                    className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                    placeholder="Search Products By Name"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    // onFocus={() => setShowProducts(true)}
                                    // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                />

                            </div>

                        </div>

                    </div>

                    {/*Category*/}
                    <div className="w-1/4 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Category</div>*/}
                            <Select
                                className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                // value={status}
                                onChange={(e) => filterProductsByCatagory(e.target.value)}
                            >
                                <option value="">Select Category...</option>
                                <option value="Biostimulants">Biostimulants</option>
                                <option value="Liquid Fertilizer">Liquid Fertilizers</option>
                                <option value="Dry Fertilizer">Dry Fertilizers</option>
                                <option value="Secondary Nutrients">Secondary Nutrients</option>
                                <option value="Micronutrients">Micronutrients</option>
                                <option value="Penetrants & Water Holding Agents">Penetrants & Water Holding Agents</option>
                                <option value="A-Plus® Solubilizing Surfactants">A-Plus® Solubilizing Surfactants</option>
                                <option value="Turfgrass Pigment">Turfgrass Pigment</option>
                                <option value="Water Treatments">Water Treatments</option>
                                <option value="Soil Amendments">Soil Amendments</option>
                                <option value="Fungicides">Fungicides</option>
                                <option value="Equipment">Equipment</option>
                                <option value="Test">Test</option>

                            </Select>
                        </div>

                    </div>


                </div>

                {/*Pricing Body*/}
                <div className="w-full h-full rounded-md">

                    {/*PRODUCT INPUT*/}
                    <div className="w-full flex h-full flex-col">


                        <div className={`w-full h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg`}>

                            {/*CUSTOMERS MAP*/}
                            <div className={`relative overflow-y-auto h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg max-h-[calc(100dvh-11.75rem)] scrollbar-hide`}>

                                <div>
                                    
                                    {filteredProducts.length === 0 ? (
                                        <div className="absolute flex w-full h-full justify-center items-center">
                                            <div className={`flex items-center border-2 border-dashed ${darkMode ? "text-white border-borderColor" : "text-black bg-neutral-50"} w-fit px-24 py-6 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                <div className="text-center flex items-center flex-col">
                                                    <DocumentCurrencyDollarIcon className={`size-12 stroke-1 text-white`} />
                                                    <h3 className="mt-2 text-sm font-semibold">No Products</h3>
                                                    <p className="mt-1 text-sm">Get started by creating a new product.</p>
                                                    <div className="mt-6">
                                                        <Button
                                                            type="button"
                                                            onClick={toggleVisibility}
                                                            className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50"
                                                        >
                                                            <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5" />
                                                            New Product
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-3 auto-rows-auto">
                                                {currentProducts.map((product, index) => (
                                                    <PricingCard index={index} key={product.id} product={product} />
                                                ))}
                                            </div>
                                            <nav
                                                aria-label="Pagination"
                                                className={`flex items-center justify-between border rounded-lg ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white border-gray-200'} mt-3 px-4 py-1 sm:px-6`}
                                            >
                                                <div className="flex items-center space-x-3">
                                                    <p className="text-sm">
                                                        Showing <span className="font-medium">{startIndex + 1}</span> to <span
                                                        className="font-medium">{Math.min(startIndex + itemsPerPage, filteredProducts.length)}</span> of{' '}
                                                        <span className="font-medium">{filteredProducts.length}</span> results
                                                    </p>
                                                    <select
                                                        value={itemsPerPage}
                                                        onChange={(e) => {
                                                            setItemsPerPage(Number(e.target.value));
                                                            setCurrentPage(1);
                                                        }}
                                                        className={`border rounded-lg p-1 ${darkMode ? "bg-darkMainColor border-neutral-700" : ""}`}
                                                    >
                                                        <option value={25}>25</option>
                                                        <option value={50}>50</option>
                                                        <option value={75}>75</option>
                                                        <option value={100}>100</option>
                                                    </select>
                                                </div>
                                                <div className="flex flex-1 justify-between sm:justify-end">
                                                    <Button
                                                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                                        disabled={currentPage === 1}
                                                        className={`px-3 py-1 h-fit rounded-md ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : "bg-green-600 text-white"}`}
                                                    >
                                                        Previous
                                                    </Button>
                                                    <Button
                                                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                                        disabled={currentPage === totalPages}
                                                        className={`px-3 py-1 h-fit ml-3 rounded-md ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : "bg-green-600 text-white"}`}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </nav>
                                        </>
                                    )}
                                
                                </div>
                            
                            </div>
                        
                        
                        </div>
                    
                    
                    </div>
                
                </div>
            
            
            </div>
            
            
            
            
            <Dialog open={adding} onClose={setAdding} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-7xl h-[40rem] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            {/*Body*/}
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                    <DocumentCurrencyDollarIcon aria-hidden="true" className="size-6 text-green-600"/>
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base flex items-center font-semibold">
                                        Add a New Product
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            When adding in a new product you have to add in the Name and Category for the system to recognize it. Also to add in pricing and codes you must below add in a "Product Price" which will allow you to select U/M add in the product code and the 5 pricing types.
                                        </p>
                                    </div>
                                    
                                    
                                     {/* Modal body */}
                                    <form className="mt-5 grid grid-cols-4 gap-x-4 gap-y-4"
                                          onSubmit={onSubmitMain}
                                          method="POST">
            
                                            {/*Product Name*/}
                                            <div className={`relative col-span-2`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Product Name
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${name ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${name ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Input
                                                    type="text"
                                                    required
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    placeholder={`Type Product Name`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            {/*Category*/}
                                            <div className={`relative col-span-2`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Product Category
                                                    <div className={`ml-1`}>
                                                        <div className={`flex-none rounded-full p-1 ${category ? 'bg-emerald-500/30' : 'bg-red-500/30'}`}>
                                                            <div className={`size-2 rounded-full ${category ? 'bg-emerald-500' : 'bg-red-500 animate-pulse'}`}/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <Select
                                                    type="text"
                                                    required
                                                    value={category}
                                                    onChange={(e) => setCategory(e.target.value)}
                                                    className={`block w-full rounded-md ${darkMode ? `${bgColor} bg-darkMainColor text-white outline-neutral-700 outline-2` : `${bgColor} bg-white text-gray-900 outline-gray-300`} px-2 py-2.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                >
                                                    <option value="">Select Category...</option>
                                                    <option value="Biostimulants">Biostimulants</option>
                                                    <option value="Liquid Fertilizer">Liquid Fertilizers</option>
                                                    <option value="Dry Fertilizer">Dry Fertilizers</option>
                                                    <option value="Secondary Nutrients">Secondary Nutrients</option>
                                                    <option value="Micronutrients">Micronutrients</option>
                                                    <option value="Penetrants & Water Holding Agents">Penetrants & Water Holding Agents</option>
                                                    <option value="A-Plus® Solubilizing Surfactants">A-Plus® Solubilizing Surfactants</option>
                                                    <option value="Turfgrass Pigment">Turfgrass Pigment</option>
                                                    <option value="Water Treatments">Water Treatments</option>
                                                    <option value="Soil Amendments">Soil Amendments</option>
                                                    <option value="Fungicides">Fungicides</option>
                                                    <option value="Equipment">Equipment</option>
                                                    <option value="Test">Test</option>
                                                    <option value="Seed">Seed</option>
                                                    <option value="MISC">Misc.</option>
                                                </Select>
                                            </div>
                                            
                                        
                                            {/*Product PH*/}
                                            <div className={`relative col-span-1`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Product PH
                                                </label>
                                                <Input
                                                    type="number"
                                                    value={ph}
                                                    onChange={(e) => setPh(e.target.value)}
                                                    placeholder={`Type Product PH...`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                            
                                            {/*Product Weight*/}
                                            <div className={`relative col-span-1`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    Product Weight
                                                </label>
                                                <Input
                                                    type="number"
                                                    value={weight}
                                                    onChange={(e) => setWeight(e.target.value)}
                                                    placeholder={`Type Product Weight...`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                        
                                            {/*Product SDS*/}
                                            <div className={`relative col-span-1`}>
                                                <label
                                                    className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                >
                                                    SDS
                                                </label>
                                                <Input
                                                    type="string"
                                                    value={sds}
                                                    onChange={(e) => setSds(e.target.value)}
                                                    placeholder={`Type Product SDS...`}
                                                    className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                />
                                            </div>
                                        
                                            {/*SDS Label*/}
                                            <div className={`relative col-span-1`}>
                                                <div className={`text-xs text-gray-500`}>SDS is the unique identifier code so search the system for IMG's SDS's and Sell Sheets</div>
                                            </div>
            
            
                                            {/*AG*/}
                                            <div className={`relative col-span-2`}>
                                                {/*Automatic Updates*/}
                                                <Field className="flex items-center w-full col-span-2">
                                                    <Switch
                                                        checked={AG}
                                                        onChange={setAG}
                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span aria-hidden="true"
                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                        <span
                                                            aria-hidden="true"
                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                        />
                                                        <span
                                                            aria-hidden="true"
                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                        />
                                                    </Switch>
                                                    <Label as="span" className="ml-3 text-sm w-full">
                                                        <span className="font-medium">AG only Product</span>{' '}
                                                        <span className="text-gray-500 text-xs">(Products with -AG at end of Code)</span>
                                                    </Label>
                                                </Field>
                                            </div>
                                            
                                            {/*PLAIF*/}
                                            <div className={`relative col-span-2`}>
                                                {/*Automatic Updates*/}
                                                <Field className="flex items-center w-full col-span-2">
                                                    <Switch
                                                        checked={PLAIF}
                                                        onChange={setPLAIF}
                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span aria-hidden="true"
                                                              className="pointer-events-none absolute size-full rounded-md"/>
                                                        <span
                                                            aria-hidden="true"
                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                        />
                                                        <span
                                                            aria-hidden="true"
                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                        />
                                                    </Switch>
                                                    <Label as="span" className="ml-3 text-sm w-full">
                                                        <span className="font-medium">PLAIF only Product</span>{' '}
                                                        <span className="text-gray-500 text-xs">(Products with -PLAIF at end of Code)</span>
                                                    </Label>
                                                </Field>
                                            </div>
                                        
                                        
                                            
                                        
                                            {/*Prices*/}
                                            <div className="col-span-full grid gap-3 mb-3 grid-cols-1">
            
                                                {/**/}
                                                <div className="col-span-2 sm:col-span-1">
            
                                                    {/*Prices Header*/}
                                                    <div className={`flex flex-row items-center`}>
                                                        <label
                                                            htmlFor="name"
                                                            className="block text-sm font-medium tracking-widest"
                                                        >
                                                            Product Prices [{prices.length}]
                                                        </label>
            
                                                        <Button
                                                            className="w-fit rounded-full ml-5 hover:text-green-400 hover:scale-105 hover:animate-pulse"
                                                            onClick={handleAddPrice}
                                                        >
                                                            <PlusCircleIcon className="w-8"></PlusCircleIcon>
                                                        </Button>
                                                    </div>
                                                    
                                                    {/*Prices Maps*/}
                                                    <div className={`mt-2 h-56 border-2 space-y-2 rounded-lg ${darkMode ? 'border-neutral-700' : ''} overflow-y-scroll scrollbar-hide`}>
            
                                                        
                                                        {prices.length === 0 ? (
                                        
                                                            <div className="flex w-full h-full justify-center items-center">
                                                                <div className={`flex items-center border-2 border-dashed ${darkMode ? "text-white border-neutral-700" : "text-black bg-neutral-50"} w-fit px-24 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                                    <div className="text-center flex items-center flex-col">
                                                                        <DocumentCurrencyDollarIcon className={`size-8 stroke-1 text-white`}/>
                                                                        <h3 className="mt-2 text-xs font-semibold">No Prices</h3>
                                                                        <div className="mt-1">
                                                                            <Button
                                                                                type="button"
                                                                                onClick={handleAddPrice}
                                                                                className="inline-flex items-center rounded-md bg-green-600 px-3 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                            >
                                                                                <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-4"/>
                                                                                New Price
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 auto-rows-auto">
                                                                {prices.map((price, index) => (
                    
                                                                    // Pricing Card
                                                                    <div key={index} className={`pt-0.5 animate-zoomIn rounded-lg`}>
                                                                        
                                                                        
                                                                        <div className="flex flex-row w-full items-center p-2">
                                                                            <Button
                                                                                className="hover:scale-105 hover:animate-pulse text-red-500 w-fit h-fit mt-auto mb-auto rounded-full mr-1"
                                                                                onClick={() => handleRemovePrice(index)}
                                                                            >
                                                                                <XCircleIcon className="w-5"/>
                                                                            </Button>
                    
                                                                            {/*Fields Grid*/}
                                                                            <div className={`grid grid-cols-7 gap-x-2`}>
                                                                                
                                                                                {/*U/M*/}
                                                                                <div className={`relative col-span-1`}>
                                                                                    <label
                                                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                                                    >
                                                                                        U/M
                                                                                    </label>
                                                                                    <Select
                                                                                        type="text"
                                                                                        name="um"
                                                                                        value={price.um}
                                                                                        onChange={(e) => handleInputChange(index, e)}
                                                                                        className={`block w-full rounded-md ${darkMode ? `${bgColor} bg-darkMainColor text-white outline-neutral-700 outline-2` : `${bgColor} bg-white text-gray-900 outline-gray-300`} px-2 py-1.5 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                                                    >
                                                                                        <option value="">Select U/M...</option>
                                                                                        <option value="ACR">Acre</option>
                                                                                        <option value="B12">BAG 12#</option>
                                                                                        <option value="B25">BAG 25#</option>
                                                                                        <option value="B30">BAG 30#</option>
                                                                                        <option value="B40">BAG 40#</option>
                                                                                        <option value="B5">BAG 5#</option>
                                                                                        <option value="B50">BAG 50#</option>
                                                                                        <option value="B51">BAG 51#</option>
                                                                                        <option value="BAG">BAG (Unspecified Weight)</option>
                                                                                        <option value="2.5">CASE (2x2.5)</option>
                                                                                        <option value="CS4">CASE (4X1)</option>
                                                                                        <option value="CAS">CASE</option>
                                                                                        <option value="30">DRUM 30-GAL</option>
                                                                                        <option value="55">DRUM 55-GAL</option>
                                                                                        <option value="DRU">DRUM (Unspecified Volume)</option>
                                                                                        <option value="EAC">EACH</option>
                                                                                        <option value="GAL">GALLON</option>
                                                                                        <option value="TR">TOTE REFILL</option>
                                                                                        <option value="LB">POUND</option>
                                                                                        <option value="OZ">Ounce - Av</option>
                                                                                        <option value="PAI">PAIL 5-GAL</option>
                                                                                        <option value="SAC">Super Sack (B1000)</option>
                                                                                        <option value="SAC2">Super Sack (B2000)</option>
                                                                                        <option value="TON">TON (2,000#)</option>
                                                                                        <option value="275">TOTE 275-GAL</option>
                                                                                    </Select>
                                                                                </div>
                                                                                
                                                                                {/*Product Code*/}
                                                                                <div className={`relative col-span-1`}>
                                                                                    <label
                                                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                                                    >
                                                                                        Code
                                                                                    </label>
                                                                                    <Input
                                                                                        name="code"
                                                                                        type="string"
                                                                                        placeholder="Code..."
                                                                                        value={price.code}
                                                                                        onChange={(e) => handleInputChange(index, e)}
                                                                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                                                                    />
                                                                                </div>
                                                                                
                                                                                {/*NE Price*/}
                                                                                <div className={`relative col-span-1`}>
                                                                                    <label
                                                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                                                    >
                                                                                        NE
                                                                                    </label>
                                                                                    <div className={`flex w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                                                        <div className="text-borderColor text-xs mr-3">$</div>
                                                                                        <Input
                                                                                            type="number"
                                                                                            name="ne"
                                                                                            value={price.ne}
                                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                                            placeholder={`NE`}
                                                                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-gray-900'} text-base placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                                                        />
                                                                                        <div className="text-borderColor w-fit text-xs ml-1">USD</div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                {/*SE Price*/}
                                                                                <div className={`relative col-span-1`}>
                                                                                    <label
                                                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                                                    >
                                                                                        SE
                                                                                    </label>
                                                                                    <div className={`flex w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                                                        <div className="text-borderColor text-xs mr-3">$</div>
                                                                                        <Input
                                                                                            type="number"
                                                                                            name="se"
                                                                                            value={price.se}
                                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                                            placeholder={`SE`}
                                                                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-gray-900'} text-base placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                                                        />
                                                                                        <div className="text-borderColor w-fit text-xs ml-1">USD</div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                {/*AG Price*/}
                                                                                <div className={`relative col-span-1`}>
                                                                                    <label
                                                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                                                    >
                                                                                        AG
                                                                                    </label>
                                                                                    <div className={`flex w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                                                        <div className="text-borderColor text-xs mr-3">$</div>
                                                                                        <Input
                                                                                            type="number"
                                                                                            name="ag"
                                                                                            value={price.ag}
                                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                                            placeholder={`AG`}
                                                                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-gray-900'} text-base placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                                                        />
                                                                                        <div className="text-borderColor w-fit text-xs ml-1">USD</div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                {/*DIST1 Price*/}
                                                                                <div className={`relative col-span-1`}>
                                                                                    <label
                                                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                                                    >
                                                                                        DIST 1
                                                                                    </label>
                                                                                    <div className={`flex w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                                                        <div className="text-borderColor text-xs mr-3">$</div>
                                                                                        <Input
                                                                                            type="number"
                                                                                            name="dist1"
                                                                                            value={price.dist1}
                                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                                            placeholder={`DIST 1`}
                                                                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-gray-900'} text-base placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                                                        />
                                                                                        <div className="text-borderColor w-fit text-xs ml-1">USD</div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                {/*DIST2 Price*/}
                                                                                <div className={`relative col-span-1`}>
                                                                                    <label
                                                                                        className={`absolute flex -top-2 left-2 rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                                                    >
                                                                                        DIST 2
                                                                                    </label>
                                                                                    <div className={`flex w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-neutral-700 outline-2' : 'bg-white text-gray-900 outline-gray-300'} px-2 py-1 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}>
                                                                                        <div className="text-borderColor text-xs mr-3">$</div>
                                                                                        <Input
                                                                                            type="number"
                                                                                            name="dist2"
                                                                                            value={price.dist2}
                                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                                            placeholder={`DIST 2`}
                                                                                            className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-gray-900'} text-base placeholder:text-gray-400 focus:outline-none sm:text-sm/6`}
                                                                                        />
                                                                                        <div className="text-borderColor w-fit text-xs ml-1">USD</div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </div>
                    
                                                                        </div>
                                                                        
                                                                        
                                                                        
                                                                        
                                                                        
                                                                        
                                                                        
                                                                    </div>
                    
                                                                ))}
                                                            </div>
                                                        )}
            
                                                    </div>
            
                                                </div>
            
            
                                            </div>
            
                                            
                                            {/*Buttons*/}
                                            <div className="col-span-full mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                <Button
                                                    type="submit"
                                                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                                                >
                                                    Add Product
                                                </Button>
                                                <Button
                                                    type="button"
                                                    data-autofocus
                                                    onClick={() => setAdding(false)}
                                                    className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            
            
                                    </form>
                                    
                                </div>
                            </div>
                            
                        
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>



        </div>
    )
};


export default Pricing;
