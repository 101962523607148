import React, {useState} from 'react';
import logo from "../PFC46.png";
import course from "../NCFP.JPG"
import tractor from "../TractorTruck.jpeg"
import lyme from "../lymeTrucks.jpg"
import catalog from "../Catalog.JPG";
import trucks from "../PFCTrucks.JPG";
import spray from "../spray.JPG";
import warehouse from "../PFCWarehouse.JPG"
import {Link} from "react-router-dom";

const LandingCustomers = () => {
    
    return (
        
        <div className="bg-white h-dvh overflow-hidden">
            
            <main className={`h-dvh bg-black`}>
                <div className="relative isolate">
                    
                    
                    <svg
                        aria-hidden="true"
                        className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
                    >
                        <defs>
                            <pattern
                                x="50%"
                                y={-1}
                                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                                width={200}
                                height={200}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none"/>
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" width="100%" height="100%"
                              strokeWidth={0}/>
                    </svg>
                    <div
                        aria-hidden="true"
                        className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                            }}
                            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-green-600 to-green-600 opacity-30"
                        />
                    </div>
                    
                    <img
                        alt=""
                        src={warehouse}
                        className="absolute inset-0 -z-10 size-full opacity-40 object-cover"
                    />
                    
                    
                    <div className="overflow-hidden">
                        <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
                            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                                
                                
                                <div className="relative bg-white/5 rounded-lg backdrop-blur-sm p-2 w-full lg:max-w-xl lg:shrink-0 xl:max-w-2xl">
                                    <h1 className="text-pretty text-5xl font-semibold tracking-tight text-white sm:text-7xl">
                                        Growing Stronger Together
                                    </h1>
                                    <p className="mt-8 text-pretty text-lg font-medium text-white sm:max-w-md sm:text-xl/8 lg:max-w-none">
                                        Join a trusted network of professionals in golf course management, agriculture, lawn care, and tree care.
                                        Our premium fertilizer solutions are designed to help you cultivate success, season after season.
                                        Sign up today and take the first step toward healthier, greener results.
                                    </p>
                                    <div className="mt-10 flex items-center gap-x-6">
                                        <Link
                                            to={'/NewCustomer'}
                                            className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50"
                                        >
                                            Get started
                                        </Link>
                                        <Link to={'/'} className="text-sm/6 font-semibold text-white">
                                            Sales Rep <span aria-hidden="true">→</span>
                                        </Link>
                                    </div>
                                    
                                    <img
                                        alt=""
                                        src={logo}
                                        className="h-24 absolute bottom-1 right-1"
                                    />
                                    
                                </div>
                                
                                {/*Images*/}
                                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                                    <div
                                        className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                                        <div className="relative">
                                            <img
                                                alt=""
                                                src={course}
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div
                                                className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"/>
                                        </div>
                                    </div>
                                    <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                                        <div className="relative">
                                            <img
                                                alt=""
                                                src={tractor}
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div
                                                className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"/>
                                        </div>
                                        <div className="relative">
                                            <img
                                                alt=""
                                                src={trucks}
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div
                                                className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"/>
                                        </div>
                                    </div>
                                    <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                                        <div className="relative">
                                            <img
                                                alt=""
                                                src={catalog}
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div
                                                className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"/>
                                        </div>
                                        <div className="relative">
                                            <img
                                                alt=""
                                                src={spray}
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div
                                                className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </main>
        </div>
    
    )
};


export default LandingCustomers;
