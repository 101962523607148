import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";

import {useAuth} from "../contexts/authContext";
import {collection, deleteDoc, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../firebase/firebase";
import course from "../course.jpg";
import fullLogo from "../Plantfood Logo.png"
import './Calendar/CustomCalender.scss'
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from 'moment'
import {
    ArrowPathIcon,
    BellAlertIcon,
    BellSlashIcon,
    CalendarDateRangeIcon,
    CheckBadgeIcon,
    CheckCircleIcon,
    CheckIcon,
    ClockIcon, CurrencyDollarIcon,
    NoSymbolIcon,
    PlusCircleIcon, TrashIcon,
    UserPlusIcon,
} from "@heroicons/react/24/outline";
import {
    Button,
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Input,
    Select,
    Textarea,
    Transition
} from "@headlessui/react";
import {BellIcon, ChevronDownIcon} from "@heroicons/react/16/solid";
import Loading from "./Loading";
const localizer = momentLocalizer(moment)



const Home = () => {

    const { darkMode } = useDarkMode();
    
    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });
    
    const [show, setShow] = useState(true)

    const { currentUser } = useAuth();
    
    const [accountLevel, setAccountLevel] = useState("");

    const [notifications, setNotifications] = useState([]);
    
    // eslint-disable-next-line no-unused-vars
    const [pfcUpdates, setPfcUpdates] = useState([]);
    const [updateTitle, setUpdateTitle] = useState("");
    const [updateBody, setUpdateBody] = useState("");
    
    const [events, setEvents] = useState([]);
    const [rawEvents, setRawEvents] = useState([]);

    const [banner, setBanner] = useState(null);

    const [ordersActive, setOrdersActive] = useState([]);
    const [ordersPending, setOrdersPending] = useState([]);
    const [customersActive, setCustomersActive] = useState([]);
    const [customersPending, setCustomersPending] = useState([]);
    
    
    const [eventName, setEventName] = useState("");
    const [eventType, setEventType] = useState("");
    const [eventDate, setEventDate] = useState("");
    
    const [addUpdate, setAddUpdate] = useState(false);
    
    const toggleAddUpdate = () => {
        setAddUpdate(!addUpdate);
    };




    useEffect(() => {
        const fetchBanner = async () => {
            const bannerDoc = doc(db, 'GlobalSettings', 'Banner');
            const docSnap = await getDoc(bannerDoc);

            if (docSnap.exists()) {
                setBanner(docSnap.data());
            } else {
                console.log("No banner found!");
            }
        };
        
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
        
        const fetchProductsUpdated = async () => {
            if (currentUser) {
                try {
                    const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                    if (userDoc.exists) {
                        // Get the user's name from the document data
                        const userData = userDoc.data();
                        setShow(userData.productUpdated || false);

                        // Save user data to localStorage
                        localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                    } else {
                        console.log('User document not found');
                    }
                    
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
        
        const fetchNotifications = async () => {
            if (currentUser) {
                try {
                    // Check if notifications data is in localStorage
                    const storedNotifications = localStorage.getItem(`notifications_${currentUser.uid}`);

                    if (storedNotifications) {
                        // Parse and set notifications from localStorage
                        const notificationsData = JSON.parse(storedNotifications);
                        setNotifications(notificationsData);
                    } else {
                        // Fetch notifications from Firestore
                        const notificationsRef = collection(db, `Users/${currentUser.uid}/Notifications`);
                        const querySnapshot = await getDocs(notificationsRef);

                        if (!querySnapshot.empty) {
                            const notificationsData = querySnapshot.docs.map((doc) => ({
                                id: doc.id,
                                ...doc.data(),
                            }));

                            // Update state with fetched notifications
                            setNotifications(notificationsData);

                            // Save notifications to localStorage
                            localStorage.setItem(`notifications_${currentUser.uid}`, JSON.stringify(notificationsData));
                        } else {
                            console.log('No notifications found.');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching notifications:', error);
                }
            }
        };
        
        const fetchEvents = async () => {
            if (currentUser) {
                try {
                    // Check if notifications data is in localStorage
                    const storedEvents = localStorage.getItem(`events_${currentUser.uid}`);

                    if (storedEvents) {
                        // Parse and set notifications from localStorage
                        const eventsData = JSON.parse(storedEvents);
                        
                        const rawEvents = eventsData.map(doc => {
                            const eventDate = new Date(`${doc.date}T00:00:00`); // Ensures it's treated as local time
                            return {
                                title: doc.name,
                                start: eventDate.getTime() ? eventDate : null, // Fallback to null if date is invalid
                                end: eventDate.getTime() ? eventDate : null,
                                type: doc.type,
                            };
                        });
                        
                        console.log(rawEvents);
                        
                        setRawEvents(rawEvents);
                        setEvents(eventsData);
                    } else {
                        // Fetch notifications from Firestore
                        const eventRef = collection(db, `Users/${currentUser.uid}/Events`);
                        const querySnapshot = await getDocs(eventRef);

                        if (!querySnapshot.empty) {
                            const eventsData = querySnapshot.docs.map((doc) => ({
                                id: doc.id,
                                ...doc.data(),
                            }));
                            
                            const rawEvents = eventsData.map(doc => {
                                const eventDate = new Date(`${doc.date}T00:00:00`); // Ensures it's treated as local time
                                return {
                                    title: doc.name,
                                    start: eventDate.getTime() ? eventDate : null, // Fallback to null if date is invalid
                                    end: eventDate.getTime() ? eventDate : null,
                                    type: doc.type,
                                };
                            });
                            
                            setRawEvents(rawEvents);

                            // Update state with fetched notifications
                            setEvents(eventsData);

                            // Save notifications to localStorage
                            localStorage.setItem(`events_${currentUser.uid}`, JSON.stringify(eventsData));
                        } else {
                            console.log('No events found.');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching events:', error);
                }
            }
        };
        
        const fetchOrdersActive = async () => {
            if (currentUser) {
                try {
                    const storedActiveData = localStorage.getItem(`activeOrders_${currentUser.uid}`);
                    let activeData = [];

                    if (storedActiveData) {
                        // If data is available in localStorage
                        activeData = JSON.parse(storedActiveData);
                    } else {
                        console.log("No Data Stored")
                    }

                    // Set pending orders data
                    setOrdersActive(activeData);

                } catch (error) {
                    console.error('Error fetching active orders data:', error);
                }
            }
        };

        const fetchOrdersPending = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`pendingOrders_${currentUser.uid}`);
                    let pendingData = [];

                    if (storedPendingData) {
                        // If data is available in localStorage
                        pendingData = JSON.parse(storedPendingData);
                    } else {
                        console.log("No Data Stored");
                    }

                    // Set pending orders data
                    setOrdersPending(pendingData);

                } catch (error) {
                    console.error('Error fetching pending orders data:', error);
                }
            }
        };

        const fetchCustomersActive = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`activeCustomers_${currentUser.uid}`);
                    let pendingData = [];

                    if (storedPendingData) {
                        // If data is available in localStorage
                        pendingData = JSON.parse(storedPendingData);
                    } else {
                        console.log("No Data Stored");
                    }

                    // Set pending orders data
                    setCustomersActive(pendingData);

                } catch (error) {
                    console.error('Error fetching pending customers data:', error);
                }
            }
        };

        const fetchCustomersPending = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`pendingCustomers_${currentUser.uid}`);
                    let pendingData = [];

                    if (storedPendingData) {
                        // If data is available in localStorage
                        pendingData = JSON.parse(storedPendingData);
                    } else {
                        console.log("No Data Stored");
                    }

                    // Set pending orders data
                    setCustomersPending(pendingData);

                } catch (error) {
                    console.error('Error fetching pending customers data:', error);
                }
            }
        };
        
        const fetchUpdates = async () => {
            if (currentUser) {
                try {
                    // Fetch the 'productUpdated' field for the current user
                    const userDocRef = doc(db, "Users", currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const { pfcUpdate } = userData;

                        if (pfcUpdate) {
                            // If 'productUpdated' is true, fetch products from Firestore
                            console.log("Fetching products from Firestore...");
                            const productsCollection = collection(db, "Updates");
                            const snapshot = await getDocs(productsCollection);

                            if (!snapshot.empty) {
                                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                                setPfcUpdates(productData);
                                localStorage.setItem(`updates_${currentUser.uid}`, JSON.stringify(productData));

                                // Set 'productUpdated' to false
                                await updateDoc(userDocRef, { pfcUpdate: false });
                                console.log("Set pfcUpdated to false after fetching from Firestore.");
                            } else {
                                console.log("No updates found in Firestore.");
                            }
                        } else {
                            // If 'productUpdated' is false, load products from localStorage
                            console.log("Loading products from localStorage...");
                            const storedProducts = localStorage.getItem(`updates_${currentUser.uid}`);
                            if (storedProducts) {
                                const storedData = JSON.parse(storedProducts);
                                setPfcUpdates(storedData);
                            } else {
                                console.log("No updates found in localStorage.");
                            }
                        }
                    } else {
                        console.log("User document does not exist.");
                    }
                } catch (error) {
                    console.error("Error fetching update data:", error);
                }
            }
        };

        
        fetchProductsUpdated();

        fetchOrdersActive();
        fetchOrdersPending();
        fetchCustomersActive();
        fetchCustomersPending();
        fetchNotifications();
        fetchEvents();
        fetchUserName();
        fetchBanner();
        
        fetchUpdates();
    }, [currentUser]);


    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        try {
            // Notifications
            const notificationsRef = collection(db, `Users/${currentUser.uid}/Notifications`);
            const querySnapshot = await getDocs(notificationsRef);

            if (!querySnapshot.empty) {
                const notificationsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Update state with fetched notifications
                setNotifications(notificationsData);

                // Save notifications to localStorage
                localStorage.setItem(`notifications_${currentUser.uid}`, JSON.stringify(notificationsData));
            } else {
                console.log('No notifications found.');

                // Clear state and local storage
                setNotifications([]);
                localStorage.removeItem(`notifications_${currentUser.uid}`);
            }
        } catch (error) {
            console.error("Error fetching notifications: ", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) / 1000;
        setUpdating(false);
        if (alertsOn) {
            alert(`Notifications Updated in ${timeElapsed} secs`);
        }
    };
    
    const onUpdateEvents = async () => {
        setUpdating(true);
        const startTime = Date.now();

        try {
            // Fetch notifications from Firestore
            const eventRef = collection(db, `Users/${currentUser.uid}/Events`);
            const querySnapshot = await getDocs(eventRef);

            if (!querySnapshot.empty) {
                const eventsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                const rawEvents = eventsData.map(doc => {
                    const eventDate = new Date(`${doc.date}T00:00:00`); // Ensures it's treated as local time
                    return {
                        title: doc.name,
                        start: eventDate.getTime() ? eventDate : null, // Fallback to null if date is invalid
                        end: eventDate.getTime() ? eventDate : null,
                        type: doc.type,
                    };
                });
                
                setRawEvents(rawEvents);
                // Update state with fetched notifications
                setEvents(eventsData);

                // Save notifications to localStorage
                localStorage.setItem(`events_${currentUser.uid}`, JSON.stringify(eventsData));
            } else {
                console.log('No events found.');

                // Clear state and local storage
                setEvents([]);
                localStorage.removeItem(`events_${currentUser.uid}`);
            }
        } catch (error) {
            console.error("Error fetching notifications: ", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) / 1000;
        setUpdating(false);
        if (alertsOn) {
            alert(`Events Updated in ${timeElapsed} secs`);
        }
    };

    const onUpdatePFC = async () => {
        setUpdating(true);
        const startTime = Date.now();

        try {
            // If 'productUpdated' is true, fetch products from Firestore
            console.log("Fetching products from Firestore...");
            const productsCollection = collection(db, "Updates");
            const snapshot = await getDocs(productsCollection);

            if (!snapshot.empty) {
                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPfcUpdates(productData);
                localStorage.setItem(`updates_${currentUser.uid}`, JSON.stringify(productData));

                // Set 'productUpdated' to false
                console.log("Set pfcUpdated to false after fetching from Firestore.");
            } else {
                console.log("No updates found in Firestore.");
            }
        } catch (error) {
            console.error("Error fetching notifications: ", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) / 1000;
        setUpdating(false);
        if (alertsOn) {
            alert(`PFC Updated Updated in ${timeElapsed} secs`);
        }
    };


    const deleteSingleNotification = async (notificationID) => {
        try {
            const notificationDocRef = doc(db, `Users/${currentUser.uid}/Notifications`, notificationID);
            await deleteDoc(notificationDocRef);
            onUpdate();
            console.log(`Notification with ID ${notificationID} deleted successfully.`);
            if (alertsOn) {
                alert("Notification deleted successfully.");
            }
        } catch (error) {
            console.error("Error deleting notification:", error);
            if (alertsOn) {
                alert("Failed to delete notification.");
            }
        }
    };

    const deleteAllNotifications = async () => {
        try {
            const notificationsCollectionRef = collection(db, `Users/${currentUser.uid}/Notifications`);
            const snapshot = await getDocs(notificationsCollectionRef);

            const deletePromises = snapshot.docs.map((doc) => deleteDoc(doc.ref));
            await Promise.all(deletePromises);
            onUpdate()

            console.log("All notifications deleted successfully.");
            if (alertsOn) {
                alert("All notifications deleted successfully.");
            }
        } catch (error) {
            console.error("Error deleting all notifications:", error);
            if (alertsOn) {
                alert("Failed to delete all notifications.");
            }
        }
    };

    const projects = [
        { name: 'Active Orders', initials: 'AO', href: '/Orders', members: ordersActive.length, label:"Orders in ERP", bgColor: 'bg-green-600/30 border-2 border-green-600' },
        { name: 'Pending Orders', initials: 'PO', href: '/Orders', members: ordersPending.length, label:"Orders waiting for CSR response", bgColor: 'bg-yellow-600/30 border-2 border-yellow-600' },
        { name: 'Active Customers', initials: 'AC', href: '/Customers', members: customersActive.length, label:"Customers in ERP", bgColor: 'bg-green-600/30 border-2 border-green-600' },
        { name: 'Pending Customers', initials: 'PC', href: '/Customers', members: customersPending.length, label:"Customers waiting for CSR response", bgColor: 'bg-yellow-600/30 border-2 border-yellow-600' },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const notificationTypes = {
        A: {
            title: "Order Moved to Active",
            description: "Moved to active at:",
            icon: <CheckCircleIcon className="h-6 w-6 text-green-500" />, // Example icon
        },
        B: {
            title: "Order Moved to Pending",
            description: "Moved to pending at:",
            icon: <ClockIcon className="h-6 w-6 text-yellow-500" />, // Example icon
        },
        C: {
            title: "Customer Moved to Active",
            description: "Moved to active at:",
            icon: <UserPlusIcon className="h-6 w-6 text-yellow-500" />, // Example icon
        },
        // Add more types as needed
    };

    const handleDelete = (notificationID) => {
        deleteSingleNotification(notificationID);
    };
    
    const onSubmitMain = async (e) => {
        e.preventDefault();

        const collectionRef = db.collection("Users").doc(currentUser.uid).collection("Events");

        const parsedFormData = {
            name: eventName,
            type: eventType,
            date: eventDate,
        };

        try {
            await collectionRef.add(parsedFormData);
            // handleNextTab();
            if (alertsOn) {
                alert(`Event Added to the Cloud`); // Optional: alert for user feedback
            }
            onUpdateEvents();

        } catch (error) {
            console.error('Error registering event:', error.message);
            // setIsRegistered(false);
        }
    };
    
    const clear = async () =>{
        setEventName("");
        setEventType("");
        setEventDate("");
    }
    
    const typeColors = {
        'Site Visit': '#38a169', // green-500
        'Product Demo': '#3b82f6', // blue-500
        'Order Follow-Up': '#f59e0b', // yellow-500
        'Inventory Check': '#ef4444', // red-400
        'Customer Training': '#8b5cf6', // purple-500
        'New Product Launch': '#ec4899', // pink-500
        'Annual Review': '#4f46e5', // indigo-600
        'Contract Renewal': '#fb923c', // orange-500
        'Technical Support Call': '#14b8a6', // teal-500
        'Trade Show Visit': '#4b5563', // gray-600
    };
    
    const typeShortened = {
        'Site Visit': 'SV',
        'Product Demo': 'PD',
        'Order Follow-Up': 'OF',
        'Inventory Check': 'IC',
        'Customer Training': 'CT',
        'New Product Launch': 'NP',
        'Annual Review': 'AR',
        'Contract Renewal': 'CR',
        'Technical Support Call': 'TS',
        'Trade Show Visit': 'TV',
    };
    
    
    
    const eventPropGetter = (event) => {
    
        const backgroundColor = typeColors[event.type] || '#999999'; // Default color if type is not listed
    
        return { style: { backgroundColor } };
    };
    
    const onSubmitUpdate = async () => {

        const collectionRef = db.collection("Updates");

        const parsedFormData = {
            title: updateTitle,
            body: updateBody,
            time: Date.now(),
        };

        try {
            await collectionRef.add(parsedFormData);
            if (alertsOn) {
                alert(`Update Added to the Cloud`); // Optional: alert for user feedback
            }
            
            // Update the productUpdated field in all documents in the Users collection
            const usersCollectionRef = collection(db, "Users");
            const usersSnapshot = await getDocs(usersCollectionRef);

            const updatePromises = usersSnapshot.docs.map((userDoc) => {
                const userDocRef = doc(db, "Users", userDoc.id);
                return updateDoc(userDocRef, { pfcUpdate: true });
            });
            
            await Promise.all(updatePromises); // Wait for all updates to complete
            console.log("Updated pfcUpdate field for all users!");

            await onUpdate();

            setUpdateTitle("");
            setUpdateBody("");

        } catch (error) {
            console.error('Error registering update:', error.message);
            // setIsRegistered(false);
        }
    };
    
    const deleteSingleUpdate = async (updateID) => {
        try {
            const updateDocRef = doc(db, `Updates`, updateID);
            await deleteDoc(updateDocRef);
            onUpdate();
            console.log(`Notification with ID ${updateID} deleted successfully.`);
            if (alertsOn) {
                alert("Notification deleted successfully.");
            }
        } catch (error) {
            console.error("Error deleting notification:", error);
            if (alertsOn) {
                alert("Failed to delete notification.");
            }
        }
    };
    
    
    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
    
        // Define month names
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        
        // Get the month abbreviation
        const month = months[date.getMonth()];
    
        // Get the day with ordinal suffix
        const day = date.getDate();
        const suffix = (day % 10 === 1 && day !== 11) ? "st" :
                       (day % 10 === 2 && day !== 12) ? "nd" :
                       (day % 10 === 3 && day !== 13) ? "rd" : "th";
    
        // Get the full year
        const year = date.getFullYear();
    
        // Format time in 12-hour format
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const amPm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12; // Convert 24-hour time to 12-hour format
    
        // Combine formatted date and time
        return `${month} ${day}${suffix} ${year}, ${hours}:${minutes} ${amPm}`;
    }



    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-[calc(100dvh-4rem)] overflow-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>
            
            {updating && (
                <Loading/>
            )}
            
            <SideBar/>
            
            <div className="z-20 animate-fadeIn flex flex-col h-full w-full max-w-[calc(100%-6rem)] mt-3 mr-3 overflow-y-auto rounded-lg max-h-[calc(100dvh-5.5rem)] hide-scrollbar">
                
                {/*Banner*/}
                <div className={`relative animate-slideInBottom min-h-32 mb-3 ${darkMode ? '' : 'drop-shadow'} items-center flex rounded-lg isolate overflow-hidden`}>
                    <img
                        alt=""
                        src={course}
                        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                    />
                    <div className={`flex flex-row w-full`}>
                        <div
                            className="mx-auto w-2/3 ml-6 h-24 overflow-y-auto hide-scrollbar px-6 py-2 bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10">
                            <div className="mx-auto lg:mx-0">
                                <h2 className="text-lg font-bold text-green-600 tracking-widest sm:text-7xl">{banner ? banner.title : "No banner available"}</h2>
                                <p className="w-full text-pretty text-xs font-medium text-white">{banner ? banner.body : "No banner available"}</p>
                            </div>
                        </div>
                        
                        
                        <div className="justify-center items-center mx-auto w-1/3 mt-auto mb-auto ml-6 mr-6">
                            
                            <img
                                alt=""
                                src={fullLogo}
                                className="w-full mt-auto mb-auto"
                            />
                        
                        </div>
                    </div>
                </div>
                
                {/*Data Header*/}
                {["D", "A", "S", "DIST"].includes(accountLevel) && (
                    <>
                        <div>
                            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                            <ul role="list"
                                className="mb-3 grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-3 lg:grid-cols-4 animate-slideInBottom">
                                {projects.map((project) => (
                                    <li key={project.name} className="col-span-1 flex rounded-md shadow-sm">
                                        <div
                                            className={classNames(
                                                project.bgColor,
                                                'flex w-16 shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white',
                                            )}
                                        >
                                            {project.members}
                                        </div>
                                        <div
                                            className={`flex flex-1 items-center justify-between truncate rounded-r-md border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white border-gray-200 border-b border-r border-t'}`}>
                                            <div className="flex-1 truncate px-4 py-2 text-sm">
                                                <a href={project.href}
                                                   className="font-medium hover:text-gray-600">
                                                    {project.name}
                                                </a>
                                                <p className="text-gray-500">{project.label}</p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                )}
                
                {/*Grid Layout Hidden*/}
                <div className="hidden grid grid-cols-5 grid-rows-5 gap-3">
                    
                    {/*Calender Form*/}
                    <div className={`row-span-3 h-[30rem] py-2 px-4 overflow-y-scroll scrollbar-hide col-start-1 row-start-1 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} rounded-lg`}>
                        <form onSubmit={onSubmitMain} method="POST">
                            <div className="border-b border-gray-900/10">
                                <h2 className="text-base/7 text-center font-semibold">Calender Form</h2>
                                <p className="mt-1 text-sm/6 text-center text-gray-500">- - - Add custom events - -
                                    -</p>
                                
                                <div className="mt-4 grid grid-cols-1 gap-y-2 sm:grid-cols-6">
                                    
                                    <div className="sm:col-span-5">
                                        <label htmlFor="last-name" className="block text-sm/6 font-medium">
                                            Event name
                                        </label>
                                        <div className="mt-2">
                                            <Input
                                                id="last-name"
                                                name="last-name"
                                                type="text"
                                                placeholder={`Event Name`}
                                                value={eventName}
                                                onChange={(e) => setEventName(e.target.value)}
                                                autoComplete="family-name"
                                                className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="sm:col-span-3">
                                        <label htmlFor="country" className="block text-sm/6 font-medium">
                                            Category
                                        </label>
                                        <div className="mt-2 grid grid-cols-1">
                                            <Select
                                                id="country"
                                                name="country"
                                                autoComplete="country-name"
                                                value={eventType}
                                                onChange={(e) => setEventType(e.target.value)}
                                                className={`col-start-1 row-start-1 w-full appearance-none rounded-md ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'} py-1 pl-3 pr-8 text-base text-gray-900 sm:text-sm/6`}
                                            >
                                                <option value="">Select...</option>
                                                <option value="Site Visit">Site Visit</option>
                                                <option value="Product Demo">Product Demo</option>
                                                <option value="Order Follow-Up">Order Follow-Up</option>
                                                <option value="Inventory Check">Inventory Check</option>
                                                <option value="Customer Training">Customer Training</option>
                                                <option value="New Product Launch">New Product Launch</option>
                                                <option value="Annual Review">Annual Review</option>
                                                <option value="Contract Renewal">Contract Renewal</option>
                                                <option value="Technical Support Call">Technical Support Call</option>
                                                <option value="Trade Show Visit">Trade Show Visit</option>
                                            </Select>
                                            <ChevronDownIcon
                                                aria-hidden="true"
                                                className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="col-span-full">
                                        <label htmlFor="street-address" className="block text-sm/6 font-medium">
                                            Date
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="street-address"
                                                name="street-address"
                                                type="date"
                                                value={eventDate}
                                                onChange={(e) => setEventDate(e.target.value)}
                                                autoComplete="street-address"
                                                className={`rounded-lg block w-full p-1 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            />
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                            
                            <div className="mt-8 flex items-center justify-end gap-x-6">
                                <Button type="button" onClick={clear} className="text-sm/6 font-semibold">
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className="rounded-md bg-green-600 px-4 py-1 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    </div>
                    
                    {/*Calender*/}
                    <div className={`col-span-3 h-[30rem] row-span-3 col-start-2 p-2 row-start-1 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} rounded-lg`}>
                        <Calendar
                            localizer={localizer}
                            defaultDate={new Date()}
                            defaultView="month"
                            events={rawEvents}
                            eventPropGetter={eventPropGetter}
                            className={`relative h-full text-black rounded-md p-1.5`}
                        />
                    </div>
                    
                    {/*Calender Right*/}
                    <div className={`row-span-3 h-[30rem] p-2 overflow-y-scroll scrollbar-hide col-start-5 row-start-1 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} rounded-lg`}>
                        
                        
                        {/*Header*/}
                        <div className="w-full flex flex-row items-center mb-2">
                            <div
                                className={`w-fit mr-auto text-center flex items-center flex-row text-lg tracking-widest`}>
                                <CalendarDateRangeIcon
                                    className={`h-5 mr-3 text-green-600 border-r-2 border-dotted pr-2 ${darkMode ? 'border-borderColor' : ''}`}/> Events
                                
                                {/*Buttons Delete*/}
                                <div
                                    className={`flex flex-col items-center ml-1 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                                    <Button
                                        className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                        onClick={onUpdateEvents}
                                    >
                                        <ArrowPathIcon className="h-4 text-borderColor m-auto"/>
                                    </Button>
                                </div>
                            
                            </div>
                        
                        </div>
                        
                        
                        {/*Body*/}
                        <div
                            className={`w-full h-[92%] p-2 overflow-y-scroll scrollbar-hide relative rounded-lg ${darkMode ? 'border-neutral-700' : ''} border-2`}>
                            
                            {events.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex w-full h-full justify-center items-center py-10">
                                    
                                    <div
                                        className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-8 py-2 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                        <div
                                            className=" rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                            <CalendarDateRangeIcon className={`w-8 stroke-2 mr-3`}/>
                                            <p className="text-md mt-2 line-clamp-1 tracking-widest flex font-semibold text-center">You
                                                Have No Events</p>
                                        </div>
                                    </div>
                                
                                </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty
                                <div>
                                    {events.map((event, index) => {
                                        const bgColorClass = typeColors[event.type] || '#999999'; // Default color
                                        const abbreviation = typeShortened[event.type] || '---'; // Default abbreviation
                                        
                                        return (
                                            <div
                                                key={index}
                                                className={`flex flex-row items-center px-2 py-2 text-sm font-semibold ${darkMode ? 'bg-darkBgColor/30' : 'bg-bgColor'} rounded-lg mb-2`}
                                            >
                                                <div className={`bg-green-600 p-0.5 rounded-md mr-2`}>
                                                    <CalendarDateRangeIcon className={`w-6 text-white`}/>
                                                </div>
                                                
                                                <div className={`flex flex-col line-clamp-1`}>
                                                    <div className={`text-xs line-clamp-1`}>{event.name}</div>
                                                    <div
                                                        className={`text-xxs ml-2 text-gray-500 line-clamp-1`}>{event.date}</div>
                                                </div>
                                                
                                                <div className={`ml-auto rounded-md p-0.5 text-xs text-white`}
                                                     style={{backgroundColor: bgColorClass}}>{abbreviation || "--"}</div>
                                                
                                                {/*<Button*/}
                                                {/*    onClick={() => handleDelete(event.id)}*/}
                                                {/*    className="flex-shrink-0 ml-auto text-red-500 hover:text-opacity-50 hover:animate-pulse">*/}
                                                {/*    <CheckIcon className={`w-5`}/>*/}
                                                {/*</Button>*/}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        
                        </div>
                    
                    </div>
                    
                    {/*PFC Updates*/}
                    <div className={`col-span-3 h-[19.5rem] row-span-2 col-start-1 row-start-4 p-2 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} rounded-lg`}>
                        
                        {/*Header*/}
                        <div className="w-full flex flex-row items-center mb-2">
                            <div className={`w-fit mr-auto text-center flex items-center flex-row text-lg tracking-widest`}>
                                <CheckBadgeIcon className={`h-5 mr-3 text-green-600 border-r-2 border-dotted pr-2 ${darkMode ? 'border-neutral-700' : ''}`}/> PFC Updates
                                
                                {/*Buttons Delete*/}
                                <div className={`flex flex-col items-center ml-1 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                                    <Button
                                        className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                        onClick={onUpdatePFC}
                                    >
                                        <ArrowPathIcon className="h-4 text-borderColor m-auto"/>
                                    </Button>
                                </div>
                            
                            </div>
                            
                            {["D"].includes(accountLevel) && (
                                <>
                                    <Button
                                        onClick={toggleAddUpdate}
                                        className={`w-fit flex items-center bg-green-600 hover:opacity-60 hover:scale-105 text-white text-xs p-1 rounded-lg line-clamp-1`}
                                    >
                                        <PlusCircleIcon className={`h-4 mr-1`}/>PFC Update
                                    </Button>
                                </>
                            )}
                        
                        
                        </div>
                        
                        <div className={`w-full h-[88%] p-2 overflow-y-scroll scrollbar-hide relative rounded-lg ${darkMode ? 'border-neutral-700' : ''} border-2`}>
                            
                            {pfcUpdates.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex w-full h-full justify-center items-center py-10">
                                    
                                    <div
                                        className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-neutral-700' : 'text-black bg-neutral-50'} w-fit px-8 py-2 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                        <div
                                            className=" rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                            <NoSymbolIcon className={`w-8 stroke-2 mr-3`}/>
                                            <p className="text-md mt-2 line-clamp-1 tracking-widest flex font-semibold">There
                                                are no PFC Updates</p>
                                        </div>
                                    </div>
                                
                                </div>
                            ) : (
                                // Display PFC Update components if sprays array is not empty
                                <div>
                                    {pfcUpdates.map((up, index) => {
                                        
                                        return (
                                            <li key={index} className={`relative ${darkMode ? 'border-neutral-700' : ''} border-b-2 grid grid-cols-4 items-center space-x-4 py-2`}>
                                                
                                                <div className="col-span-1 min-w-0 flex-auto">
                                                    <div className="flex items-center gap-x-3">
                                                        <div className={'flex-none rounded-full bg-green-600/30 p-1'}>
                                                            <div className="size-2 rounded-full bg-green-600"/>
                                                        </div>
                                                        <h2 className="min-w-0 text-sm/6 font-semibold">
                                                            <div className="flex gap-x-2">
                                                                <span className="truncate">{up.title}</span>
                                                            </div>
                                                        </h2>
                                                    </div>
                                                    <div className="mt-1 flex items-center gap-x-2.5 text-xs/5 text-gray-500">
                                                        <p className="whitespace-nowrap">{formatTimestamp(up.time)}</p>
                                                        
                                                        {["D"].includes(accountLevel) && (
                                                            <div className={``}>
                                                                <Button
                                                                    onClick={() => deleteSingleUpdate(up.id)}
                                                                    className=" w-full flex-shrink-0 ml-auto mr-3 text-red-500 hover:text-opacity-50 hover:animate-pulse">
                                                                    <TrashIcon className={`w-5`}/>
                                                                </Button>
                                                            </div>
                                                        )}
                                                        
                                                    </div>
                                                </div>
                                                
                                                <div className={`col-span-3 flex`}>
                                                    <div className={'flex-none rounded-lg w-full px-2 py-1 text-xs font-medium ring-1 ring-inset ring-green-600'}>
                                                        {up.body}
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                            </li>
                                            // <div
                                            //     key={index}
                                            //     className={`flex flex-row items-center px-2 py-2 text-sm font-semibold ${darkMode ? 'bg-darkBgColor/30' : 'bg-bgColor'} rounded-lg mb-2`}
                                            // >
                                            //     {up.title}
                                            // </div>
                                        );
                                    })}
                                </div>
                            )}
                        
                        </div>
                    
                    </div>
                    
                    {/*Notifications*/}
                    <div className={`col-span-2 h-[19.5rem] row-span-2 col-start-4 row-start-4 p-2 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} rounded-lg`}>
                        
                        {/*Header*/}
                        <div className="w-full flex flex-row items-center mb-2">
                            <div className={`w-fit mr-auto text-center flex items-center flex-row text-lg tracking-widest`}>
                                <BellAlertIcon className={`h-5 mr-3 text-green-600 border-r-2 border-dotted pr-2 ${darkMode ? 'border-neutral-700' : ''}`}/> Notifications
                                
                                
                                {/*Buttons Delete*/}
                                <div className={`flex flex-col items-center ml-1 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                                    <Button
                                        className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                        onClick={onUpdate}
                                    >
                                        <ArrowPathIcon className="h-4 text-borderColor m-auto"/>
                                    </Button>
                                </div>
                            
                            
                            </div>
                            <Button
                                onClick={deleteAllNotifications}
                                className={`w-fit flex items-center ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} hover:opacity-60 hover:scale-105 text-green-600 text-xs p-1 rounded-lg line-clamp-1`}
                            >
                                <CheckIcon className={`h-4 mr-1`}/> Mark all as read
                            </Button>
                        </div>
                        
                        <div className={`w-full h-[88%] p-2 overflow-y-scroll scrollbar-hide relative rounded-lg ${darkMode ? 'border-neutral-700' : ''} border-2`}>
                            
                            {notifications.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex w-full h-full justify-center items-center py-10">
                                    
                                    <div
                                        className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-neutral-700' : 'text-black bg-neutral-50'} w-fit px-8 py-2 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                        <div
                                            className=" rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                            <BellSlashIcon className={`w-8 stroke-2 mr-3`}/>
                                            <p className="text-md mt-2 line-clamp-1 tracking-widest flex font-semibold">You
                                                Have No Notifications</p>
                                        </div>
                                    </div>
                                
                                </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty
                                <div>
                                    {notifications.map((not, index) => {
                                        const notificationContent = notificationTypes[not.type] || {
                                            title: "Unknown Notification",
                                            description: "No details available for this notification type.",
                                            icon: <BellIcon className="h-6 w-6 text-gray-500"/>, // Default icon
                                        };
                                        
                                        return (
                                            <div
                                                key={index}
                                                className={`flex flex-row items-center px-2 py-2 text-sm font-semibold ${darkMode ? 'bg-darkBgColor/30' : 'bg-bgColor'} rounded-lg mb-2`}
                                            >
                                                <div className="mr-3 flex-shrink-0">
                                                    {notificationContent.icon}
                                                </div>
                                                {/* Text Content Section */}
                                                <div>
                                                    <div className="text-sm font-semibold line-clamp-1">
                                                        {not.id || "--"} {notificationContent.title}
                                                    </div>
                                                    <p className="text-xs text-gray-500 line-clamp-2">
                                                        {notificationContent.description} {not.createdAt}
                                                    </p>
                                                </div>
                                                
                                                <Button
                                                    onClick={() => handleDelete(not.id)}
                                                    className="flex-shrink-0 ml-auto text-red-500 hover:text-opacity-50 hover:animate-pulse">
                                                    <CheckIcon className={`w-5`}/>
                                                </Button>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        
                        </div>
                    
                    </div>
                
                </div>
                
                
                {/*Grid Layout*/}
                <div className="grid grid-cols-5 gap-3 h-full">
                    
                    {/*PFC Updates*/}
                    <div className={`xl:col-span-3 sm:col-span-full h-full p-2 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} rounded-lg`}>
                        
                        {/*Header*/}
                        <div className="w-full flex flex-row items-center mb-2">
                            <div className={`w-fit mr-auto text-center flex items-center flex-row text-lg tracking-widest`}>
                                <CheckBadgeIcon className={`h-5 mr-3 text-green-600 border-r-2 border-dotted pr-2 ${darkMode ? 'border-neutral-700' : ''}`}/> PFC Updates
                                
                                {/*Buttons Delete*/}
                                <div className={`flex flex-col items-center ml-1 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                                    <Button
                                        className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                        onClick={onUpdatePFC}
                                    >
                                        <ArrowPathIcon className="h-4 text-borderColor m-auto"/>
                                    </Button>
                                </div>
                            
                            </div>
                            
                            {["D"].includes(accountLevel) && (
                                <>
                                    <Button
                                        onClick={toggleAddUpdate}
                                        className={`w-fit flex items-center bg-green-600 hover:opacity-60 hover:scale-105 text-white text-xs p-1 rounded-lg line-clamp-1`}
                                    >
                                        <PlusCircleIcon className={`h-4 mr-1`}/>PFC Update
                                    </Button>
                                </>
                            )}
                        
                        
                        </div>
                        
                        <div className={`w-full h-[calc(100dvh-22.25rem)] p-2 overflow-y-scroll scrollbar-hide relative rounded-lg ${darkMode ? 'border-neutral-700' : ''} border-2`}>
                            
                            {pfcUpdates.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex w-full h-full justify-center items-center py-10">
                                    
                                    <div
                                        className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-neutral-700' : 'text-black bg-neutral-50'} w-fit px-8 py-2 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                        <div
                                            className=" rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                            <NoSymbolIcon className={`w-8 stroke-2 mr-3`}/>
                                            <p className="text-md mt-2 line-clamp-1 tracking-widest flex font-semibold">There
                                                are no PFC Updates</p>
                                        </div>
                                    </div>
                                
                                </div>
                            ) : (
                                // Display PFC Update components if sprays array is not empty
                                <div>
                                    {pfcUpdates.map((up, index) => {
                                        
                                        return (
                                            <li key={index} className={`relative ${darkMode ? 'border-neutral-700' : ''} border-b-2 grid grid-cols-4 items-center space-x-4 py-2`}>
                                                
                                                <div className="col-span-1 min-w-0 flex-auto">
                                                    <div className="flex items-center gap-x-3">
                                                        <div className={'flex-none rounded-full bg-green-600/30 p-1'}>
                                                            <div className="size-2 rounded-full bg-green-600"/>
                                                        </div>
                                                        <h2 className="min-w-0 text-sm/6 font-semibold">
                                                            <div className="flex gap-x-2">
                                                                <span className="truncate">{up.title}</span>
                                                            </div>
                                                        </h2>
                                                    </div>
                                                    <div className="mt-1 flex items-center gap-x-2.5 text-xs/5 text-gray-500">
                                                        <p className="whitespace-nowrap">{formatTimestamp(up.time)}</p>
                                                        
                                                        {["D"].includes(accountLevel) && (
                                                            <div className={``}>
                                                                <Button
                                                                    onClick={() => deleteSingleUpdate(up.id)}
                                                                    className=" w-full flex-shrink-0 ml-auto mr-3 text-red-500 hover:text-opacity-50 hover:animate-pulse">
                                                                    <TrashIcon className={`w-5`}/>
                                                                </Button>
                                                            </div>
                                                        )}
                                                        
                                                    </div>
                                                </div>
                                                
                                                <div className={`col-span-3 flex`}>
                                                    <div className={'flex-none rounded-lg w-full px-2 py-1 text-xs font-medium ring-1 ring-inset ring-green-600'}>
                                                        {up.body}
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                            </li>
                                            // <div
                                            //     key={index}
                                            //     className={`flex flex-row items-center px-2 py-2 text-sm font-semibold ${darkMode ? 'bg-darkBgColor/30' : 'bg-bgColor'} rounded-lg mb-2`}
                                            // >
                                            //     {up.title}
                                            // </div>
                                        );
                                    })}
                                </div>
                            )}
                        
                        </div>
                    
                    </div>
                    
                    {/*Notifications*/}
                    <div className={`xl:col-span-2 sm:col-span-full h-full p-2 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} rounded-lg`}>
                        
                        {/*Header*/}
                        <div className="w-full flex flex-row items-center mb-2">
                            <div className={`w-fit mr-auto text-center flex items-center flex-row text-lg tracking-widest`}>
                                <BellAlertIcon className={`h-5 mr-3 text-green-600 border-r-2 border-dotted pr-2 ${darkMode ? 'border-neutral-700' : ''}`}/> Notifications
                                
                                
                                {/*Buttons Delete*/}
                                <div className={`flex flex-col items-center ml-1 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                                    <Button
                                        className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                        onClick={onUpdate}
                                    >
                                        <ArrowPathIcon className="h-4 text-borderColor m-auto"/>
                                    </Button>
                                </div>
                            
                            
                            </div>
                            <Button
                                onClick={deleteAllNotifications}
                                className={`w-fit flex items-center ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} hover:opacity-60 hover:scale-105 text-green-600 text-xs p-1 rounded-lg line-clamp-1`}
                            >
                                <CheckIcon className={`h-4 mr-1`}/> Mark all as read
                            </Button>
                        </div>
                        
                        <div className={`w-full h-[calc(100dvh-22.25rem)] p-2 overflow-y-scroll scrollbar-hide relative rounded-lg ${darkMode ? 'border-neutral-700' : ''} border-2`}>
                            
                            {notifications.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex w-full h-full justify-center items-center py-10">
                                    
                                    <div
                                        className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-neutral-700' : 'text-black bg-neutral-50'} w-fit px-8 py-2 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                        <div
                                            className=" rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                            <BellSlashIcon className={`w-8 stroke-2 mr-3`}/>
                                            <p className="text-md mt-2 line-clamp-1 tracking-widest flex font-semibold">You
                                                Have No Notifications</p>
                                        </div>
                                    </div>
                                
                                </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty
                                <div>
                                    {notifications.map((not, index) => {
                                        const notificationContent = notificationTypes[not.type] || {
                                            title: "Unknown Notification",
                                            description: "No details available for this notification type.",
                                            icon: <BellIcon className="h-6 w-6 text-gray-500"/>, // Default icon
                                        };
                                        
                                        return (
                                            <div
                                                key={index}
                                                className={`flex flex-row items-center px-2 py-2 text-sm font-semibold ${darkMode ? 'bg-darkBgColor/30' : 'bg-bgColor'} rounded-lg mb-2`}
                                            >
                                                <div className="mr-3 flex-shrink-0">
                                                    {notificationContent.icon}
                                                </div>
                                                {/* Text Content Section */}
                                                <div>
                                                    <div className="text-sm font-semibold line-clamp-1">
                                                        {not.id || "--"} {notificationContent.title}
                                                    </div>
                                                    <p className="text-xs text-gray-500 line-clamp-2">
                                                        {notificationContent.description} {not.createdAt}
                                                    </p>
                                                </div>
                                                
                                                <Button
                                                    onClick={() => handleDelete(not.id)}
                                                    className="flex-shrink-0 ml-auto text-red-500 hover:text-opacity-50 hover:animate-pulse">
                                                    <CheckIcon className={`w-5`}/>
                                                </Button>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        
                        </div>
                    
                    </div>
                
                </div>
            
                
                
                
                {/*Products Updated Popup*/}
                <div
                    aria-live="assertive"
                    className="pointer-events-none z-50 fixed inset-0 top-16 flex items-end px-4 py-6 sm:items-start sm:p-6"
                >
                    <div className="flex w-full flex-col items-center space-y-1 sm:items-end animate-glideInRight">
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition show={show}>
                            <div className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg animate-wobble ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow-lg`}>
                                <div className="p-2">
                                    <div className="flex items-center">
                                        <div className="shrink-0">
                                            <CurrencyDollarIcon aria-hidden="true" className="size-6 text-green-400"/>
                                        </div>
                                        <div className="ml-3 w-0 flex-1">
                                            <p className="text-sm font-medium">Products Updated</p>
                                            <p className="text-sm text-gray-500">Pricing or data has been updated.</p>
                                        </div>
                                        <div className="ml-4 flex shrink-0">
                                            <a
                                                type="button"
                                                // onClick={() => {
                                                //     setShow(false)
                                                // }}
                                                href="/catalog"
                                                className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-green-600 hover:text-opacity-50"
                                            >
                                                View
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            
                
                
                
            </div>
            
            
            <Dialog open={addUpdate} onClose={setAddUpdate} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <CheckBadgeIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle as="h3" className="text-base font-semibold">
                                Add A PFC Update
                            </DialogTitle>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    This will push a company wide update to every single users account!
                                </p>
                            </div>
                            
                            
                            <div className={`flex mt-2 flex-row items-center`}>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className={`rounded-lg block w-1/2 px-2 py-1 ${darkMode ? 'dark:bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-primary-600 focus:border-primary-600'}`}
                                    placeholder="Type Update Title"
                                    value={updateTitle} onChange={(e) => setUpdateTitle(e.target.value)}
                                    required
                                />
                            </div>
                            
                            <div className={`flex mt-2 flex-row items-center`}>
                                <Textarea
                                    type="text"
                                    name="name"
                                    id="name"
                                    className={`rounded-lg block w-full h-32 px-2 py-1 ${darkMode ? 'dark:bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-primary-600 focus:border-primary-600'}`}
                                    placeholder="Type Update Body"
                                    value={updateBody} onChange={(e) => setUpdateBody(e.target.value)}
                                    required
                                />
                            </div>
                        
                        
                        </div>
                    </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <Button
                              type="button"
                              onClick={onSubmitUpdate}
                              className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                          >
                              Add Update
                          </Button>
                          <Button
                              type="button"
                              data-autofocus
                              onClick={() => setAddUpdate(false)}
                              className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                          >
                              Cancel
                          </Button>
                      </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>

            
        </div>
    )
};


export default Home;
