import { useRef } from 'react'
import { Fireworks } from '@fireworks-js/react'

export default function FireworksEffect({ show }) {
    const ref = useRef(null)

    if (show && ref.current) {
        ref.current.start()
    }

    return (
        <Fireworks
            ref={ref}
            options={{
                opacity: 0.5,
                intensity: 40,
                explosion: 5,
                speed: 3,
                friction: 0.97,
                gravity: 1.5,
            }}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                zIndex: 50,
            }}
        />
    )
}