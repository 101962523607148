import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import logo from "../../main-logo.png"
import {
    EnvelopeIcon,
    PhoneIcon,
} from "@heroicons/react/24/outline";
import {accountLevelMapping, pricingMapping} from "../GlobalFunctions";

const UserCard = ({ user }) => {

    const { darkMode } = useDarkMode();

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    
    const regionColors = {
        AG: { bg: "bg-green-600/15", border: "border-green-600", text: "text-green-600" },
        DIST1: { bg: "bg-blue-600/15", border: "border-blue-600", text: "text-blue-600" },
        DIST2: { bg: "bg-purple-600/15", border: "border-purple-600", text: "text-purple-600" },
        NE: { bg: "bg-blue-600/15", border: "border-blue-600", text: "text-blue-600" },
        SE: { bg: "bg-yellow-600/15", border: "border-yellow-600", text: "text-yellow-600" },
    };
    
    return (
        <li
            key={user.email}
            className={`col-span-1 flex flex-col border divide-y-2 rounded-lg ${darkMode ? 'bg-darkMainColor text-white border-neutral-700 divide-neutral-700' : 'bg-white text-black'} ${isLoaded ? '' : 'translate-x-full opacity-0'} text-center shadow`}
        >
            <div className="relative flex flex-1 flex-col p-8">
                
                
                <img
                    alt=""
                    src={user.profileImageUrl || logo}
                    className="mx-auto size-32 object-cover border border-gray-500 rounded-full"
                />
                
                
                <h3 className="mt-6 text-sm font-medium">{user.fname + " " + user.lname}</h3>
                <dl className="mt-1 flex grow flex-col justify-between">
                    <dd className="text-sm text-gray-500">{user.address}</dd>
                    <dd className="mt-3">
                        <span className="inline-flex items-center rounded-full bg-green-600/15 border border-green-600 px-2 py-1 text-xs font-medium text-green-600">
                          {accountLevelMapping[user.accountLevel] || "--"}
                        </span>
                    </dd>
                </dl>
                
                {user.salesCode !== "Sales Code Not Found" && (
                    <span className="absolute top-0 right-0 inline-flex mt-3 mr-3 px-3 py-0.5 rounded-full z-10 bg-green-600/15 border border-green-600 text-xs font-medium text-green-600 select-none">
                        {user.salesCode || "--"}
                    </span>
                )}
                
                {user.region && (
                    <span className={`absolute top-0 left-0 inline-flex mt-3 ml-3 px-3 py-0.5 rounded-full z-10 border text-xs font-medium select-none
                        ${regionColors[user.region]?.bg || 'bg-gray-400/15'}
                        ${regionColors[user.region]?.border || 'border-gray-400'}
                        ${regionColors[user.region]?.text || 'text-gray-400'}`}
                    >
                        {pricingMapping[user.region] || "--"}
                    </span>
                )}
            
            </div>
            
            {/*Contact*/}
            <div>
                <div className={`-mt-px flex divide-x-2 ${darkMode ? 'divide-neutral-700' : ''}`}>
                    <div className="flex items-center justify-center relative w-0 flex-1">
                        <a
                            href={`mailto:${user.email}`}
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold"
                        >
                            <EnvelopeIcon aria-hidden="true" className="size-5 text-gray-500"/>
                            Email
                        </a>
                        
                        {!user.email && (
                            <div className={`absolute bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 px-2 py-0.5 text-xxs`}>No Email</div>
                        )}
                        
                    </div>
                    <div className="-ml-px flex relative items-center justify-center w-0 flex-1">
                        <a
                            href={`tel:${user.phone}`}
                            className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold"
                        >
                            <PhoneIcon aria-hidden="true" className="size-5 text-gray-500"/>
                            Call
                        </a>
                        
                        {!user.phone && (
                            <div className={`absolute bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 px-2 py-0.5 text-xxs`}>No Phone</div>
                        )}
                        
                    </div>
                </div>
            </div>
        </li>
    )
}

export default UserCard;

