import React, {useEffect, useState} from 'react';
import { collection, getDocs } from "firebase/firestore";
import SideBar from "./SideBar";
import {
    Button,
    Dialog,
    DialogBackdrop,
    DialogPanel, DialogTitle,
    Input,
    Popover,
    PopoverButton,
    PopoverPanel,
    Select, Switch
} from "@headlessui/react";
import {
    ArrowPathIcon, ExclamationTriangleIcon, IdentificationIcon, LinkSlashIcon,
    MagnifyingGlassIcon, QueueListIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import {useDarkMode} from "../contexts/darkModeContext";
import {ChevronDownIcon} from "@heroicons/react/16/solid";
import course from "../course.jpg";
import fullLogo from "../Plantfood Logo.png";
import Loading from "./Loading";
import CustomerSection from "./Customers/CustomerSection";
import { ReactComponent as ExcelSVG } from "../excel-file-type.svg"
import AccountView from "./Customers/AccountView";
import CustomerSearchInput from "./Customers/CustomerSearchInput";

const PlantCustomers = () => {
    const { currentUser } = useAuth();
    
    const [alertsOn] = useState(() => {
        const storedSettings = localStorage.getItem('alertsOn');
        return storedSettings ? JSON.parse(storedSettings).alertsOn : false; // Default to false if null
    });

    const [status, setStatus] = useState("P");
    // const [existing, setExisting] = useState(false);

    const { darkMode } = useDarkMode();

    const [allCustomers, setallCustomers] = useState([]);
    const [filteredallCustomers, setFilteredallCustomers] = useState([]);

    const [allPending, setAllPending] = useState([]);
    const [fileredAllPending, setFilteredAllPending] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [reps, setReps] = useState([]);


    // Handle dropdown change
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);
                    if (storedUserData) {
                        const userData = JSON.parse(storedUserData);
                        setReps(userData.reps || []);
                    } else {
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setReps(userData.reps || []);
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    useEffect(() => {

        const fetchAllUsersPendingData = async () => {
            try {

                // Check if reps is null or empty
                if (!reps || reps.length === 0) {
                    console.log("Reps is null or empty. Returning an empty array.");
                    setAllPending([]);
                    setFilteredAllPending([]);
                    return;
                }

                const storedPendingData = localStorage.getItem(`allPendingCustomers_${currentUser.uid}`);
                if (storedPendingData) {
                    const pendingData = JSON.parse(storedPendingData);
                    setAllPending(pendingData);
                    setFilteredAllPending(pendingData);
                } else {
                    // Extract user IDs from the reps array
                    const repsIds = reps.map((rep) => rep.id);

                    let allPendingData = [];

                    // Iterate over the allowed user IDs in repsIds
                    const userPromises = repsIds.map(async (userId) => {
                        // Reference to the pending collection for this user
                        const pendingCollectionRef = collection(db, `Customers/${userId}/pending`);
                        const pendingSnapshot = await getDocs(pendingCollectionRef);

                        // Push pending data to the allPendingData array
                        pendingSnapshot.forEach((pendingDoc) => {
                            allPendingData.push({
                                ...pendingDoc.data(),
                                id: pendingDoc.id, // Add document ID
                                salesID: userId, // ADD USER ID
                            });
                        });
                    });

                    // Run all user-level fetches in parallel
                    await Promise.all(userPromises);

                    // Handle empty data scenario
                    if (allPendingData.length > 0) {
                        setAllPending(allPendingData);
                        setFilteredAllPending(allPendingData);
                        localStorage.setItem(`allPendingCustomers_${currentUser.uid}`, JSON.stringify(allPendingData));
                    } else {
                        // Clear the state and localStorage when no data is found
                        setAllPending([]);
                        setFilteredAllPending([]);
                        localStorage.removeItem(`allPendingCustomers_${currentUser.uid}`);
                        console.log('No pending customers found, clearing data.');
                    }
                }
            } catch (error) {
                console.error("Error fetching pending data for all users: ", error);
            }
        };

        const fetchAllUsersActiveData = async () => {
            try {

                // Check if reps is null or empty
                if (!reps || reps.length === 0) {
                    console.log("Reps is null or empty. Returning an empty array.");
                    setallCustomers([]);
                    setFilteredallCustomers([]);
                    return;
                }

                const storedAllData = localStorage.getItem(`allCustomers_${currentUser.uid}`);
                if (storedAllData) {
                    const allData = JSON.parse(storedAllData);
                    setallCustomers(allData);
                    setFilteredallCustomers(allData);
                } else {
                    // Extract user IDs from the reps array
                    const repsIds = reps.map((rep) => rep.id);

                    let allClientsData = [];

                    // Iterate over the allowed user IDs in repsIds
                    const userPromises = repsIds.map(async (userId) => {
                        // Reference to the client's collection for this user
                        const clientsCollectionRef = collection(db, `Customers/${userId}/clients`);
                        const clientsSnapshot = await getDocs(clientsCollectionRef);

                        // Push client data to the allClientsData array
                        clientsSnapshot.forEach((clientDoc) => {
                            allClientsData.push({
                                ...clientDoc.data(),
                                id: clientDoc.id, // Add document ID
                                salesID: userId, // ADD USER ID
                            });
                        });
                    });

                    // Run all user-level fetches in parallel
                    await Promise.all(userPromises);

                    // Handle empty data scenario
                    if (allClientsData.length > 0) {
                        setallCustomers(allClientsData);
                        setFilteredallCustomers(allClientsData);
                        localStorage.setItem(`allCustomers_${currentUser.uid}`, JSON.stringify(allClientsData));
                    } else {
                        // Clear the state and localStorage when no data is found
                        setallCustomers([]);
                        setFilteredallCustomers([]);
                        localStorage.removeItem(`allCustomers_${currentUser.uid}`);
                        console.log('No clients found, clearing data.');
                    }
                }
            } catch (error) {
                console.error("Error fetching clients data for all users: ", error);
            }
        };

        fetchAllUsersPendingData();
        fetchAllUsersActiveData();

    }, [currentUser, reps]);



    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // User Data
        try {

            // Fetch user document from Firestore based on the current user's UID
            const userDoc = await db.collection('Users').doc(currentUser.uid).get();
            if (userDoc.exists) {
                // Get the user's name from the document data
                const userData = userDoc.data();
                setReps(userData.reps);

                // Save user data to localStorage
                localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
            } else {
                console.log('User document not found');
            }

        } catch (error) {
            console.error("Error fetching all pending orders", error);
        }

        // Pending
        try {
            // Extract user IDs from the reps array
            const repsIds = reps.map((rep) => rep.id);

            let allPendingData = [];

            // Iterate over the allowed user IDs in repsIds
            const userPromises = repsIds.map(async (userId) => {
                // Reference to the pending collection for this user
                const pendingCollectionRef = collection(db, `Customers/${userId}/pending`);
                const pendingSnapshot = await getDocs(pendingCollectionRef);

                // Push pending data to the allPendingData array
                pendingSnapshot.forEach((pendingDoc) => {
                    allPendingData.push({
                        ...pendingDoc.data(),
                        id: pendingDoc.id, // Add document ID
                        salesID: userId, // ADD USER ID
                    });
                });
            });

            // Run all user-level fetches in parallel
            await Promise.all(userPromises);

            // Handle empty data scenario
            if (allPendingData.length > 0) {
                setAllPending(allPendingData);
                setFilteredAllPending(allPendingData);
                localStorage.setItem(`allPendingCustomers_${currentUser.uid}`, JSON.stringify(allPendingData));
            } else {
                // Clear the state and localStorage when no data is found
                setAllPending([]);
                setFilteredAllPending([]);
                localStorage.removeItem(`allPendingCustomers_${currentUser.uid}`);
                console.log('No pending customers found, clearing data.');
            }

        } catch (error) {
            console.error("Error fetching all pending orders", error);
        }

        // Active
        try {
            // Extract user IDs from the reps array
            const repsIds = reps.map((rep) => rep.id);

            let allClientsData = [];

            // Iterate over the allowed user IDs in repsIds
            const userPromises = repsIds.map(async (userId) => {
                // Reference to the client's collection for this user
                const clientsCollectionRef = collection(db, `Customers/${userId}/clients`);
                const clientsSnapshot = await getDocs(clientsCollectionRef);

                // Push client data to the allClientsData array
                clientsSnapshot.forEach((clientDoc) => {
                    allClientsData.push({
                        ...clientDoc.data(),
                        id: clientDoc.id, // Add document ID
                        salesID: userId, // ADD USER ID
                    });
                });
            });

            // Run all user-level fetches in parallel
            await Promise.all(userPromises);

            // Handle empty data scenario
            if (allClientsData.length > 0) {
                setallCustomers(allClientsData);
                setFilteredallCustomers(allClientsData);
                localStorage.setItem(`allCustomers_${currentUser.uid}`, JSON.stringify(allClientsData));
            } else {
                // Clear the state and localStorage when no data is found
                setallCustomers([]);
                setFilteredallCustomers([]);
                localStorage.removeItem(`allCustomers_${currentUser.uid}`);
                console.log('No clients found, clearing data.');
            }

        } catch (error) {
            console.error("Error fetching active data for all users: ", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        if (alertsOn) {
            alert(`Orders Updated in ${timeElapsed} secs`)
        }
    };







    const handlePendingSearch = (query) => {
        setSearchQuery(query);
        const filtered = allPending.filter((customer) =>
            customer.name.toLowerCase().includes(query.toLowerCase()) ||
            customer.customerCode.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredAllPending(filtered);
    }

    const handleActiveSearch = (query) => {
        setSearchQuery(query);
        const filtered = allCustomers.filter((customer) =>
            customer.name.toLowerCase().includes(query.toLowerCase()) ||
            customer.customerCode.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredallCustomers(filtered);
    }


    // Function to toggle visibility
    // const toggleVisibility = () => {
    //     setExisting(!existing);
    // };


    const filterOrdersByIndustry = (industry) => {
        const filtered = allCustomers.filter(customer => industry === '' || customer.industry === industry);
        setFilteredallCustomers(filtered);
    };

    const filterPendingOrdersByIndustry = (industry) => {
        const filtered = allPending.filter(customer => industry === '' || customer.industry === industry);
        setFilteredAllPending(filtered);
    };


    // const filterOrdersByPrice = (price) => {
    //     const filtered = allCustomers.filter(customer => price === '' || customer.priceType === price);
    //     setFilteredallCustomers(filtered);
    // };
    //
    // const filterPendingOrdersByPrice = (price) => {
    //     const filtered = allPending.filter(customer => price === '' || customer.priceType === price);
    //     setFilteredAllPending(filtered);
    // };
    
    
    const filterCustomersByRep = (rep) => {
        const filtered = allCustomers.filter(customer => rep === '' || customer.salesRep === rep);
        setFilteredallCustomers(filtered);
    };

    const filterPendingCustomersByRep = (rep) => {
        const filtered = allPending.filter(customer => rep === '' || customer.salesRep === rep);
        setFilteredAllPending(filtered);
    };


    const filtering = (e) => {
        if(status === "A"){
            filterOrdersByIndustry(e.target.value);
        }
        if (status === "P"){
            filterPendingOrdersByIndustry(e.target.value);
        }
    }

    // const filteringPrice = (e) => {
    //     if(status === "A"){
    //         filterOrdersByPrice(e.target.value);
    //     }
    //     if (status === "P"){
    //         filterPendingOrdersByPrice(e.target.value);
    //     }
    // }
    
    const filteringRep = (e) => {
        if(status === "A"){
            filterCustomersByRep(e.target.value);
        }
        if (status === "P"){
            filterPendingCustomersByRep(e.target.value);
        }
    }


    const [count, setCount] = useState(0);

    useEffect(() => {

        if (status === "A"){
            setCount(filteredallCustomers.length);
        }
        if (status === "P"){
            setCount(fileredAllPending.length);
        }


    }, [status, filteredallCustomers, fileredAllPending]);
    
    const statusMap = {
        A: {
            customers: filteredallCustomers,
            emptyTitle: "Your Reps Have No Active Customers",
            emptyDescription: "You have to add pending customers to the ERP",
        },
        P: {
            customers: fileredAllPending,
            emptyTitle: "Your Reps Have No Pending Customers",
            emptyDescription: "You Must Wait For your Reps to Onboard New Customers",
        },
    };

    const currentStatus = statusMap[status] || {};
    
    
    
    const [accountView, setAccountView] = useState(false);
    const [plant, setPlant] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    
    const toggleAccountView = () => {
        if (!selectedCustomer) {
            setShowPopup(true); // Show popup if no customer is selected
        } else {
            setAccountView(!accountView);
        }
    };
    
    
    
    // Function to handle both accountView and selected customer
    const handleViewAccount = (customer) => {
        setAccountView(true);
        setSelectedCustomer(customer);
        setPlant(true);
    };
    
    
    

    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-[calc(100dvh-4rem)] w-full mt-16 transition-all duration-500 ease-in-out`}>

            {updating && (
                <Loading/>
            )}
            
            <SideBar/>
            
            <div className="z-40 animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] mt-3 mb-3 mr-3">
                
                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3">
                
                    <div className={`text-2xl w-1/4 ${darkMode ? 'text-white' : 'text-black'} flex flex-row items-center line-clamp-1`}>
                        <div className={`line-clamp-1`}>Rep Customers</div>
                        {/*Buttons Delete*/}
                        <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                            <Button className={`rounded-full ${darkMode ? 'bg-darkMainColor text-gray-500' : 'bg-white text-gray-700'} p-1 hover:scale-105 hover:rotate-12 mt-auto mb-auto h-7 w-7 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                onClick={onUpdate}
                            >
                                <ArrowPathIcon className="m-auto"/>
                            </Button>
                        </div>
                        
                        {/*Buttons Delete*/}
                        <Switch
                            checked={accountView}
                            onChange={toggleAccountView}
                            className="group relative inline-flex items-center ml-auto h-6 w-11 shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out"
                        >
                            <span className="sr-only">Use setting</span>
                            <span
                                aria-hidden="true"
                                className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-white'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                            />
                            <span className={`pointer-events-none relative inline-block size-5 transform rounded-full bg-green-600 border border-green-700 shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-4`}>
                            
                                {/*false*/}
                                <span
                                    aria-hidden="true"
                                    className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                >
                                <QueueListIcon className={`text-white size-3.5 stroke-2`}/>
                            </span>
                                
                                {/*true*/}
                                <span
                                    aria-hidden="true"
                                    className="absolute inset-0 right-1 flex size-full items-center justify-center text-green-600 opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                >
                                <IdentificationIcon className={`text-white size-3.5 stroke-2`}/>
                            </span>
                            
                            </span>
                        
                        </Switch>
                    </div>

                    <div className={`text-xl z-50 mr-1.5 relative border ${darkMode ? 'bg-darkMainColor border-neutral-700 text-white' : 'bg-white border text-black'} w-4/6 flex rounded-md border drop-shadow shadow-gray-900`}>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Active:</div>
                            <div className="text-sm rounded-md bg-orderColor bg-opacity-20 border-2 border-orderColor px-2 line-clamp-1">{allCustomers.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Pending:</div>
                            <div className="text-sm rounded-md bg-pendingColor bg-opacity-20 border-2 border-pendingColor px-2 line-clamp-1">{allPending.length}</div>
                        </div>

                        <Popover className="w-full h-full absolute z-50">
                            <div className="w-full h-full">
                                <div className="w-full mx-auto h-full flex items-center px-6 lg:px-8">
                                    <PopoverButton className="absolute right-0 inline-flex mr-3 items-center gap-x-1 text-xs font-semibold">
                                        Reps
                                        <ChevronDownIcon aria-hidden="true" className="size-5" />
                                    </PopoverButton>
                                </div>
                            </div>

                            <PopoverPanel
                                transition
                                className={`relative top-0 border ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-white'} mt-3 rounded-lg shadow-xl transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in`}
                            >
                                <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 px-6 py-10 lg:grid-cols-2 lg:px-8">

                                    {/*Column 1*/}
                                    <div className="grid grid-cols-1 gap-x-6 sm:gap-x-8">
                                        <div className={`w-full`}>
                                            <h3 className="text-sm/6 font-medium">Sales Reps List</h3>
                                            <h1 className="text-xs ml-3 text-gray-500 font-medium">These are the Sales
                                                Reps you are Currently Servicing</h1>
                                            <div className="mt-6 flow-root">
                                                <div className="-my-2">

                                                    {reps.length === 0 ? (
                                                        // Display custom dialog if sprays array is empty
                                                        <div className="flex w-full h-full justify-center items-center py-2">

                                                            <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-4 py-1 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                                <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                                    <LinkSlashIcon className={`w-4 stroke-2 mr-3`}/>
                                                                    <p className="text-xs mt-2 line-clamp-1 tracking-widest flex">You Have No Reps</p>
                                                                    <p className="text-xxs mt-2 line-clamp-1 tracking-widest flex text-gray-500">You may select reps in the profile and settings page</p>
                                                                    
                                                                    <a className={`text-xs bg-green-600 px-2 py-0.5 rounded-lg mt-2 hover:bg-opacity-50`}
                                                                        href={`/Profile`}
                                                                    >
                                                                        Go To Settings
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ) : (
                                                        // Display SprayCard components if sprays array is not empty
                                                        <div className="grid grid-cols-2 gap-2 ml-6">
                                                            {reps.map((item, index) => (
                                                                <div key={item.name}
                                                                     className="py-1 text-sm font-semibold">
                                                                    [{index + 1}] {item.name}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    
                                                    {/*{reps.map((item, index) => (*/}
                                                    {/*    <div*/}
                                                    {/*        key={item.name}*/}
                                                    {/*        className="flex ml-6 py-1 text-sm/6 font-semibold"*/}
                                                    {/*    >*/}
                                                    {/*        [{index + 1}] {item.name}*/}
                                                    {/*    </div>*/}
                                                    {/*))}*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Column 2*/}
                                    <div className="grid grid-cols-1 w-full sm:gap-8 lg:grid-cols-2 relative">
                                        <div className={`w-full h-full items-center justify-center flex rounded-lg isolate overflow-hidden`}>
                                            {/* Background Course Image */}
                                            <img
                                                alt=""
                                                src={course}
                                                className={`absolute border ${darkMode ? 'border-borderColor' : 'drop-shadow'} rounded-lg inset-0 -z-10 h-full w-full object-cover object-right md:object-center`}
                                            />

                                            {/* Centered Logo */}
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <img
                                                    alt="Logo"
                                                    src={fullLogo}
                                                    className="p-16 object-contain"
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </PopoverPanel>
                        </Popover>


                    </div>

                    <div className="w-1/6 flex items-center text-black ml-1.5">
                        <Button className="bg-green-600 h-fit w-full hover:scale-105 tracking-widest hover:bg-opacity-50 rounded-md py-1 px-6 text-white text-sm flex flex-row align-middle justify-center items-center"
                            // onClick={toggleVisibility}
                            disabled={true}
                        >
                            <ExcelSVG className={`w-8 h-6 hover:stroke-green-600 stroke-white fill-white`} />
                            <div className="w-full line-clamp-1">Download Excel</div>
                        </Button>
                    </div>


                </div>

                {/*Search bar row*/}
                <div className={`${accountView ? 'hidden' : ''} w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>

                    {/*Search Bar*/}
                    <div className="w-1/2 mr-1.5 h-fit">
                        {/*<div className="text-sm mb-1">Customer</div>*/}
                        
                        
                        <div className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                            <CustomerSearchInput
                                status={status}
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                                onSearch={
                                    status === "A" ? handleActiveSearch :
                                    status === "P" ? handlePendingSearch :
                                    () => {}
                                }
                                darkMode={darkMode}
                            />
                        </div>

                    </div>

                    {/*Status*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Status</div>*/}
                            <Select
                                className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                value={status}
                                onChange={handleStatusChange}
                            >
                                <option value="A">Active</option>
                                <option value="P">Pending</option>
                            </Select>
                        </div>

                    </div>

                    {/*Industry*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Industry</div>*/}

                            <Select
                                className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                onChange={filtering}
                            >
                                <option value="">Select Industry...</option>
                                <option value="BOTANICAL">Botanical Garden</option>
                                <option value="DISTRIB">Product Distributor</option>
                                <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                <option value="FARM">Agriculture - Farm</option>
                                <option value="GOLF">Golf Course</option>
                                <option value="GOVERNMENT">Government Entity</option>
                                <option value="INDUSTRIAL">Industrial</option>
                                <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                <option value="MRSALE">One Time Purchase Expected</option>
                                <option value="MUNICIPAL">Municipal/ School</option>
                                <option value="Non-Profit">Non-Profit Account</option>
                                <option value="OTHER">Other</option>
                                <option value="SPORTFLD">Sportsfield</option>
                            </Select>
                        </div>

                    </div>

                    {/*Pricing Type*/}
                    <div className="w-1/6 ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Pricing Type</div>*/}
                            {/*<Select*/}
                            {/*    className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}*/}
                            {/*    onChange={filteringPrice}*/}
                            {/*>*/}
                            {/*    <option value="">Select Price Type...</option>*/}
                            {/*    <option value="AG">Agriculture</option>*/}
                            {/*    <option value="DIST1">Distributor NE/INTL</option>*/}
                            {/*    <option value="DIST2">Distributor SE/CAN</option>*/}
                            {/*    <option value="NE">Northeast</option>*/}
                            {/*    <option value="SE">Southeast</option>*/}
                            {/*</Select>*/}
                            
                             <Select
                                className={`border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                onChange={filteringRep}
                             >
                                <option value="">Select Sales Rep...</option>
                                
                                {reps.map((rep, index) => {
                                    const label = rep.name; // Get label from mapping object
                                    return label ? (
                                        <option key={index} value={rep.name}>
                                            {label}
                                        </option>
                                    ) : null; // Exclude options with no matching label
                                })}
                             </Select>
                            
                        </div>

                    </div>


                </div>

                {/*Customer Body*/}
                <div className={`${accountView ? 'hidden' : ''} w-full h-full rounded-md`}>

                    {/*Orders Maps*/}
                    <div className="w-full flex h-full flex-col">


                        <div className={`w-full h-full border ${darkMode ? 'text-white bg-darkMainColor border-neutral-700' : 'text-black bg-white'} rounded-lg`}>

                            {/*HEADER*/}
                            <div className="w-full flex flex-row pl-3 pr-3">

                                <div className={`w-full flex flex-row border-b-2 ${darkMode ? 'border-darkBgColor' : ''}`}>

                                    {/*Buttons Delete*/}
                                    <div className="flex flex-col items-center mr-3">
                                        <div className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            {/*<TrashIcon className="h-6 text-transparent m-auto"/>*/}
                                            <div className="justify-center flex flex-col items-center h-full">
                                                <div className="text-sm font-semibold">[{count}]</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Contact Name*/}
                                    <div className="w-1/3 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Sales Rep</div>
                                        </div>

                                    </div>

                                    {/*Contact Phone*/}
                                    <div className="w-1/3 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Customer</div>
                                        </div>


                                    </div>

                                    {/*Status*/}
                                    <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">

                                        <div className="w-1/2 h-8 justify-center items-center rounded-full flex flex-row mr-auto">
                                            <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Status</div>
                                        </div>

                                        <div className="w-1/2 h-8 justify-center items-center rounded-full flex flex-row ml-auto">
                                            <div className="flex items-center text-center justify-center h-full text-sm font-semibold">
                                                {status === "A" ? 'Orders' : 'Submit'}
                                            </div>
                                        </div>

                                    </div>

                                    {/**/}
                                    <div className="w-1/3 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                                        <div className="w-fit p-1 h-8 rounded-full">
                                            <div className="flex items-center justify-center h-full text-sm font-semibold">YTD Sales</div>
                                        </div>


                                    </div>

                                    {/*Buttons DropDown*/}
                                    <div className="flex items-center">
                                        <div className=" mt-auto mb-auto h-8 w-8 mr-2 items-center justify-center align-middle">
                                            <TrashIcon className="h-6 text-transparent m-auto"/>
                                        </div>
                                        <div className=" mt-auto mb-auto h-8 w-8 mr-2 items-center justify-center align-middle">
                                            <TrashIcon className="h-6 text-transparent m-auto"/>
                                        </div>
                                        <div className=" mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            <TrashIcon className="h-6 text-transparent m-auto"/>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            {/*Orders MAP*/}
                            <div className={`overflow-y-auto h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} p-1 rounded-lg max-h-[calc(100dvh-14.5rem)] scroll`}>

                                <CustomerSection
                                    customers={currentStatus.customers || []}
                                    emptyTitle={currentStatus.emptyTitle}
                                    emptyDescription={currentStatus.emptyDescription}
                                    darkMode={darkMode}
                                    onUpdate={onUpdate}
                                    plant={true}
                                    handleViewAccount={handleViewAccount}
                                />

                            </div>


                        </div>


                    </div>

                </div>
                
                {accountView && (

                    <AccountView
                        customer={selectedCustomer}
                        onUpdate={onUpdate}
                        plant={plant}
                        reps={reps}
                    />
                )}
                

            </div>
            
            
            <Dialog open={showPopup} onClose={setShowPopup} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-600/15 sm:mx-0 sm:size-10">
                        <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          No Customer Selected
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                                Click the expand button on any customer account in the list below to open the Account View. Once selected, the Account View will automatically display.                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={() => setShowPopup(false)}
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      >
                        I understand
                      </Button>
                      <Button
                        type="button"
                        data-autofocus
                        onClick={() => setShowPopup(false)}
                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                      >
                        Cancel
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>


        </div>
    )
};


export default PlantCustomers;
